import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate, Format } from "../../shared/GlobalFns";

export class QuestionHeight extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(value) {
        let result = { questionCode: this.props.question.code, answer: value, isValid: this.IsValid(value) };
        this.setState({ result: result }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return true;
        else
            return !this.validate.IsEmpty(value);
    }

    componentDidMount() {
        if (this.props.result)
            this.setState({ result: this.props.result });
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.setState({ result: this.props.result });
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className="p-3">
                {this.props.isNested && (
                    <b><small dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></small></b>
                )}
                {!this.props.isNested && (
                    <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
                )}
                <Form.Group className="mb-2" ref="code_input">
                    <Form.Control
                        type="number"
                    />
                </Form.Group>
            </div>
        );
    }

}

