import React, { Component } from "react";
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { DisplayQuestion } from "./DisplayQuestion";
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { Validate } from '../shared/GlobalFns'

export class PlayForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: true,
            isFormValidated: false,
            isFormValid: true,
            isQuestionValid: true,
            questionMessage: 'Please answer this question',
            questionnaire: {
                id: -1,
                code: '',
                version: 1,
                language: '',
                text: {
                    text: '',
                    description: '',
                },
                questionGroups: [],
            },
            questionnaireIndex: -1,
            pageErrors: null,
            selectedQuestionGroupCode: '',
            results: {
                questionnaire: {
                    code: '',
                    version: 1,
                    languageCode: 'en-GB'
                },
                answers: [],
            },
            prevResults: {
                questionnaire: {
                    code: '',
                    version: 1,
                    languageCode: 'en-GB'
                },
                answers: [],
            },
            options: {
                previous: false,
                skip: false,
                next: false,
                nextEnabled: false,
                save: false
            },
            answerFields: [
                { key: 'questionCode', label: 'Question Code' },
                { key: 'answer', label: 'Answer' },
                { key: 'score', label: 'Score' },
                { key: 'timestamp', label: 'Time Stamp' },
            ],
            prettyAnswerFields: [
                { key: 'question', label: 'Question', className: 'pt-3' },
                { key: 'answer', label: 'Answer', className: 'font-weight-bold pt-3 ps-4 pe-4' },
            ],
            hasChanges: false,
        };

        this.questionnaireApi = new QuestionnaireApi();
        this.validate = new Validate();
    }

    async GetQuestionnaire(code, version, instance) {

        await this.questionnaireApi.GetByCode(code, version, (data) => {

            this.setState({ questionnaire: data, isBusy: false }, () => {
                this.SetInitialGroup(this.state.questionnaire);
            });

        }, (errors) => {

            this.setState({ pageErrors: errors, isBusy: false });

        });

    }

    async GetResults(code, version, instance) {

        await this.questionnaireApi.GetResults(code, version, instance, (data) => {

            // Add in stage code if applicable (ie. running in schedule)
            if (this.props.stageCode)
                data.questionnaire.stageCode = this.props.stageCode;

            // Undo isComplete restart questionnaire if "editSubmittedResults = true"
            if (data.isCompleted && this.props.editSubmittedResults) {
                data.isCompleted = false;
                data.questionnaire.currentQuestion = '';
            }

            this.setState({ results: data, questionnaireIndex: this.props.index }, () => {
                //console.log("RESULTS", this.state.results);
                this.GetQuestionnaire(this.state.results.questionnaire.code, this.state.results.questionnaire.version, this.state.results.questionnaire.instance)
            });

        }, (errors) => {

            this.setState({ pageErrors: errors, isBusy: false });

        });

    }

    SaveResults(isCompleted, isFromParent) {

        if (this.state.hasChanges || isCompleted) {
            this.setState({ isBusy: true, pageErrors: null }, async () => {

                let uploadResults = update(this.state.results, { isCompleted: { $set: isCompleted ?? false } });
                await this.questionnaireApi.SaveResults(uploadResults, (data) => {

                    this.setState({ isBusy: false, pageErrors: null, results: data, hasChanges: false }, () => {
                        this.SetGroupOptions(this.GetQuestionGroup(this.state.selectedQuestionGroupCode));
                        if (this.props.onSave && isFromParent)
                            this.props.onSave(this.state.questionnaireIndex, this.state.results);
                    });

                }, (errors) => {

                    this.setState({ isBusy: false, pageErrors: errors });

                })

            });
        } else {
            if (this.props.onSave && isFromParent)
                this.props.onSave(this.state.questionnaireIndex, this.state.results);
        }

    }

    TabulateResultsData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.results.answers, (data) => {
            let row = {};
            this.state.answerFields.forEach((field) => {
                switch (field.key) {
                    case 'questionCode':
                    case 'answer':
                    case 'score':
                        let value = data[field.key];
                        row[field.key] = !this.validate.IsEmpty(value) ? data[field.key] : "-";
                        break;
                    case 'timestamp':
                        row[field.key] = this.FormatDateTime(data[field.key], 'en-GB');
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulatePrettyResultsData(group) {
        let rows = [];
        Array.prototype.forEach.call(group.questions.filter(q => q.questionType !== "Instruction" && q.questionType !== "Submit"), (question) => {
            let result = this.GetResult(this.state.results, question.code);
            if (result && !this.validate.IsEmpty(result.answer)) {
                let row = {};
                this.state.prettyAnswerFields.forEach((field) => {
                    switch (field.key) {
                        case 'question':
                            row[field.key] = this.GetQuestionText(question);
                            break;
                        case 'answer':
                            row[field.key] = this.GetAnswerText(question, result.answer);
                            break;
                        case 'score':
                            row[field.key] = result[field.key] ?? "-";
                            break;
                        default:
                            if (result[field.key])
                                row[field.key] = JSON.stringify(result[field.key]);
                            break;
                    }
                });
                rows.push(row);
            }
        });
        return rows;
    }

    FormatDateTime(value, locale) {
        if (value) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            var date = new Date(value);
            return date.toLocaleDateString(locale ?? "en-GB", options);
        } else {
            return "-";
        }
    }

    SetInitialGroup(questionnaire) {
        if (this.state.results && this.state.results.questionnaire && this.state.results.questionnaire.currentQuestion) {

            let group = this.GetGroupQuestionIsIn(this.state.results.questionnaire.currentQuestion)
            this.setState({ selectedQuestionGroupCode: group.code }, () => {
                this.SetGroupOptions(group)
            })

        } else {
            if (questionnaire.questionGroups.length > 0) {

                let group = questionnaire.questionGroups[0];
                this.setState({ selectedQuestionGroupCode: group.code }, () => {
                    this.SetGroupOptions(group)
                })

            }
        }
    }

    PreviousClick() {

        let currentQuestionGroup = this.GetQuestionGroup(this.state.selectedQuestionGroupCode);
        let previousQuestionGroup = null;

        if (currentQuestionGroup && currentQuestionGroup.questions.length > 0) {

            // Every result for the questions in the group contains a previous question code
            // which equals the question we answered before we got here
            let firstResult = this.GetResult(this.state.results, currentQuestionGroup.questions[0].code);
            if (firstResult && !this.validate.IsEmpty(firstResult.prevQuestionCode)) {

                previousQuestionGroup = this.GetGroupQuestionIsIn(firstResult.prevQuestionCode);
                let updatedResults = update(this.state.results, { questionnaire: { currentQuestion: { $set: firstResult.prevQuestionCode } } });
                this.setState({ selectedQuestionGroupCode: previousQuestionGroup.code, results: updatedResults, hasChanges: true }, () => {
                    //this.SaveResults();
                    this.SetGroupOptions(previousQuestionGroup);
                });

            } else {
                this.SetGroupOptions(currentQuestionGroup);
            }

        }
    }

    NextClick(resetQuestionsInGroup) {

        let currentQuestionGroup = this.GetQuestionGroup(this.state.selectedQuestionGroupCode);
        let updatedResults = typeof structuredClone === 'function' ? structuredClone(this.state.results) : JSON.parse(JSON.stringify(this.state.results));
        let nextQuestionGroup = null;
        let gotoQuestionCodes = [];

        if (currentQuestionGroup && currentQuestionGroup.questions.length > 0) {

            // Put the goto question code from the last static question code in this group into the array - this makes sure that when we decide what the next question should be,
            // by seeing which question is furthest into the questionnaire, we will default to the first question in the next group.
            let staticQuestionsInGroup = currentQuestionGroup.questions.filter(q => q.style === "Static");
            //console.log("staticQuestionsInGroup", staticQuestionsInGroup)

            gotoQuestionCodes.push(staticQuestionsInGroup[staticQuestionsInGroup.length - 1].gotoQuestionCode)

            currentQuestionGroup.questions.forEach((question) => {

                let result = this.GetResult(updatedResults, question.code);

                // Read all the goto question codes
                let gotoQuestionCode = !this.validate.IsEmpty(result.gotoQuestionCode) ? result.gotoQuestionCode : question.gotoQuestionCode;

                if (gotoQuestionCode && gotoQuestionCodes.filter(questionCode => questionCode === gotoQuestionCode).length === 0)
                    gotoQuestionCodes.push(gotoQuestionCode)

                // While we are looping the current questions, clear any answers in this group if we pressed the "Skip" button
                if (resetQuestionsInGroup) {
                    result.answer = '';
                    updatedResults = this.UpdateResultList(updatedResults, result, "Clear any answers");
                }

            });

            if (gotoQuestionCodes.length > 0) {
                //console.log("GotoQuestionCodes", gotoQuestionCodes)

                // Get the "furthest" goto question code into the questionnaire and use that one 
                let furthestQuestionIndex = -1;
                let nextQuestionCode = '';
                gotoQuestionCodes.forEach(questionCode => {
                    let questionIndex = this.GetQuestionIndex(questionCode);
                    if (furthestQuestionIndex < questionIndex) {
                        furthestQuestionIndex = questionIndex;
                        nextQuestionCode = questionCode;
                    }
                })

                if (!this.validate.IsEmpty(nextQuestionCode)) {
                    nextQuestionGroup = this.GetGroupQuestionIsIn(nextQuestionCode)
                    if (nextQuestionGroup && nextQuestionGroup.code !== currentQuestionGroup.code) {

                        // Update all the results for the questions in this group so that the previous question code reflects where we came from
                        // Use the first question code in the previous group
                        nextQuestionGroup.questions.forEach(question => {
                            let result = this.GetResult(updatedResults, question.code);
                            result.prevQuestionCode = currentQuestionGroup.questions[0].code;
                            //console.log(`NEXT RESULT: ${result.prevQuestionCode}`, result);
                            updatedResults = this.UpdateResultList(updatedResults, result, "Set previous question code");
                        });

                        updatedResults = update(updatedResults, { questionnaire: { currentQuestion: { $set: nextQuestionCode } } });
                        this.setState({ selectedQuestionGroupCode: nextQuestionGroup.code, results: updatedResults, hasChanges: true }, () => {
                            this.SetGroupOptions(nextQuestionGroup);
                            //this.SaveResults();
                            //console.log(`NEXT GROUP SET: ${nextQuestionGroup.code}`);
                        });

                    } else {
                        console.warn(`NEXT: GROUP NOT FOUND FOR QUESTION`, nextQuestionCode);
                        this.SetGroupOptions(currentQuestionGroup);
                    }
                } else {
                    console.warn(`NEXT QUESTION CODE BLANK`, gotoQuestionCodes);
                }
            }
        }

    }

    SetGroupOptions(group) {

        let previous = false;
        let skippableQuestions = 0;
        let skip = false;
        let next = false;
        let questionNumber = 0;
        let nextEnabled = true;
        let save = false;
        let done = false;
        
        if (this.state.results && this.state.results.isCompleted) {

            previous = false;
            skip = false;
            next = false;
            nextEnabled = false;
            done = true;

        } else {

            let staticQuestions = group.questions ? group.questions.filter(q => q.style === "Static") : [];
            if (staticQuestions.length > 0) {

                staticQuestions.forEach(question => {

                    questionNumber++;
                    let result = this.GetResult(this.state.results, question.code);

                    // Allow previous if the first question in the group has a previous question code
                    if (questionNumber === 1 && !previous && result && !this.validate.IsEmpty(result.prevQuestionCode))
                        previous = true;

                    // Count the number of skippable questions in the group
                    if (question.validation.optional === true)
                        skippableQuestions++;

                    // Allow next if the last question in the group is not a submit question
                    if (questionNumber === staticQuestions.length && question.questionType !== 'Submit')
                        next = true;

                    // Allow save if the last question in the group is a submit question
                    if (questionNumber === staticQuestions.length && question.questionType === 'Submit')
                        save = true;

                    // Check every visible question is valid
                    nextEnabled = nextEnabled && this.QuestionIsValid(question, result);
                    if (result.enableQuestionCode && result.enableQuestionCode.length > 0) {
                        let enableQuestionCodes = result.enableQuestionCode.split(",");
                        enableQuestionCodes.forEach(code => {
                            let enabledQuestion = this.GetQuestion(code);
                            if (enabledQuestion && (enabledQuestion.style === "DynamicInserted" || enabledQuestion.style === "DynamicInline")) {
                                let enabledQuestionResult = this.GetResult(this.state.results, code);
                                nextEnabled = nextEnabled && this.QuestionIsValid(enabledQuestion, enabledQuestionResult);
                            }
                        });
                    }

                });

                // Allow skip if ALL questions in group are optional
                skip = (skippableQuestions > 0 && skippableQuestions === staticQuestions.length);

            }
        }

        if (this.props.onUpdateQuestionOptions) {
            let options = { previous: previous, skip: skip, next: next, save: save, nextEnabled: nextEnabled, done: done };
            this.setState({ options: options }, () => {
                this.props.onUpdateQuestionOptions(options);
            });
        }
    }

    QuestionIsValid(question, result) {
        let valid = true;
        if (question.questionType !== 'Unknown' && question.questionType !== 'Submit' && question.questionType !== 'Instruction' && question.validation) {

            if (question.validation && question.validation.optional === true) {
                valid = !result || !result.answer || result.isValid;
            } else {
                valid = result && result.isValid;
            }

        }
        return valid;
    }

    GetAllOptionsInGroup(group) {
        let options = [];
        if (group && group.questions.length > 0) {
            group.questions.forEach(q => {
                if (q.optionGroups && q.optionGroups.length > 0) {
                    q.optionGroups.forEach(og => {
                        if (og.options && og.options.length > 0) {
                            og.options.forEach(o => {
                                if (!options.includes(o.text))
                                    options.push(o.text);
                            })
                        }
                    })
                }
            });
        }
        return options;
    }

    GetAllOptionsInQuestion(question) {
        let options = [];
        if (question.optionGroups && question.optionGroups.length > 0) {
            question.optionGroups.forEach(og => {
                if (og.options && og.options.length > 0) {
                    og.options.forEach(o => {
                        if (!options.includes(o.text))
                            options.push(o.text);
                    })
                }
            })
        }
        return options;
    }

    SaveFailed(error) {
        this.setState({ isBusy: false, pageErrors: error });
    }

    GetQuestion(questionCode) {
        let question = null;
        this.state.questionnaire.questionGroups.every((questionGroup) => {
            if (questionGroup.questions && questionGroup.questions.length > 0) {
                let questions = questionGroup.questions.filter(q => this.validate.IsEmpty(questionCode) || q.code === questionCode);
                if (questions && questions.length > 0) {
                    question = questions[0];
                    return false;
                }
            }
            return true;
        });
        return question;
    }

    GetQuestionText(question) {
        if (question && question.text)
            return question.text.text;
        else
            return `NOT FOUND`
    }

    GetAnswerText(question, answer) {
        let results = [];
        if (!this.validate.IsEmpty(answer)) {
            if (question) {
                if (question.optionGroups && question.optionGroups.length > 0)
                    question.optionGroups.forEach((optionGroup) => {
                        if (optionGroup && optionGroup.options.length > 0)
                            optionGroup.options.forEach((option) => {

                                let answers = answer.split(",");
                                if (answers.includes(option.value)) {
                                    if (option.text)
                                        results.push(option.text);
                                }

                            });
                    });
            }
            return results.length > 0 ? results.join(", ") : answer;
        } else {
            return '';
        }
    }

    GetQuestionIndex(questionCode) {
        let questionIndex = -1;
        let found = false;
        this.state.questionnaire.questionGroups.every((questionGroup) => {
            if (questionGroup.questions && questionGroup.questions.length > 0) {
                questionGroup.questions.every((question) => {
                    questionIndex++;
                    found = question.code === questionCode;
                    return !found;
                });
            }
            return !found;
        });
        return questionIndex;
    }

    GetQuestionGroup(questionGroupCode) {
        let questionGroup = null;
        let questionGroups = this.state.questionnaire.questionGroups.filter(qg => qg.code === questionGroupCode);
        if (questionGroups.length > 0) 
            questionGroup = questionGroups[0];
        return questionGroup;
    }

    GetGroupQuestionIsIn(questionCode) {
        let parentQuestionGroup = null;
        this.state.questionnaire.questionGroups.every((questionGroup) => {
            if (questionGroup.questions.filter(q => q.code === questionCode).length > 0) {
                parentQuestionGroup = questionGroup;
                return false;
            }
            return true;
        });
        return parentQuestionGroup;
    }

    VirtualGroupsByQuestionTypes(group) {
        let virtualGroups = [];
        let previousQuestionType = '';
        let previousQuestionOptions = [];
        let virtualGroup = {};

        // Only look at all the Static and Enabled Dynamic inline questions
        let enableQuestionCodes = this.GetEnabledQuestionCodes();
        group.questions.filter(q => this.QuestionIsVisible(q, enableQuestionCodes)).forEach(q => {

            let questionOptions = this.GetAllOptionsInQuestion(q);
            let questionType = q.questionType;

            if (this.props.device.IsPhone() && questionType.indexOf("Horizontal") > -1) {
                questionType = questionType.replace("Horizontal", "");
            }

            // Create a new groups of questions when the question type or options change
            // this makes sure we write a new line of "header" options when required
            if (previousQuestionType !== questionType || !this.ArraysAreEqual(previousQuestionOptions, questionOptions)) {
                virtualGroup = { questionType: questionType, questions: [] };
                virtualGroups.push(virtualGroup);
                previousQuestionType = questionType;
                previousQuestionOptions = questionOptions;
            }
            virtualGroup.questions.push(q);

        });
        return virtualGroups;
    }

    QuestionIsVisible(question, enableQuestionCodes) {
        let result = false;
        if (question.style === "Static") {

            // Always shown
            result = true;

        } else if (question.style === "DynamicInserted") {

            // Handled inside Select questions as these are inserted into a list of options
            result = false;

        } else if (question.style === "DynamicInline" && enableQuestionCodes.includes(question.code)) {

            result = true;

        }
        return result;
    }

    // Look at every answer we already have and check the enabled question codes to see if this question is visible...
    // excluding any enabled question codes for questions that are not themselves enabled (these may have been answered and then "hidden" by changing a previous question)
    GetEnabledQuestionCodes() {
        let enableQuestionCodes = [];
        if (this.state.results && this.state.results.answers) {
            //console.log("Answers", this.state.results.answers)

            this.state.results.answers.forEach(answer => {

                // Record all the possibly enabled question codes
                if (answer.enableQuestionCode && answer.enableQuestionCode.length > 0) {
                    answer.enableQuestionCode.split(",").forEach(addQuestionCode => {

                        if (!enableQuestionCodes.includes(addQuestionCode)) {
                            enableQuestionCodes.push(addQuestionCode);
                        }

                    });
                }

            });
            //console.log("EnableQuestionCodes All", enableQuestionCodes)

            // Remove any enabled question codes that were added by inline questions that themselves are not enabled
            // TODO: This will not work for questions were multiple questions enable the same question code and one of those questions is not enabled
            this.state.results.answers.forEach(answer => {

                let question = this.GetQuestion(answer.questionCode);
                if (question.style === "DynamicInline" && !enableQuestionCodes.includes(answer.questionCode) && answer.enableQuestionCode && answer.enableQuestionCode.length > 0) {
                    answer.enableQuestionCode.split(",").forEach(removeQuestionCode => {

                        if (enableQuestionCodes.includes(removeQuestionCode)) {
                            enableQuestionCodes.splice(enableQuestionCodes.indexOf(removeQuestionCode), 1);
                        }

                    });
                }

            });
            //console.log("EnableQuestionCodes Trimmed", enableQuestionCodes)

        }
        return enableQuestionCodes;
    }

    ArraysAreEqual(array1, array2) {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element === array2[index];
        });
    }

    GetResult(resultsList, questionCode) {
        let results = resultsList.answers && resultsList.answers.length > 0 ? resultsList.answers.filter(a => a.questionCode === questionCode) : [];
        let result = { questionCode: questionCode, answer: '', gotoQuestionCode: '', enableQuestionCode: '', prevQuestionCode: '' };
        if (results.length === 1)
            result = results[0];
        return result;
    }

    RangeMinValue(question) {
        if (question && question.validation && question.validation.min)
            return question.validation.min
        else
            return 0;
    }

    RangeMaxValue(question) {
        if (question && question.validation && question.validation.max)
            return question.validation.max
        else
            return 100;
    }

    GetResultIndex(resultsList, questionCode) {
        return resultsList.answers.length > 0 ? resultsList.answers.findIndex(r => r.questionCode === questionCode) : -1;
    }

    GetNextQuestion(currentQuestionCode) {
        let getNextQuestion = false;
        let nextQuestion = null;
        if (currentQuestionCode) {
            this.state.questionnaire.questionGroups.every(qg => {
                qg.questions.every(q => {
                    if (getNextQuestion) {
                        nextQuestion = q;
                        return false;
                    } else {
                        getNextQuestion = (q.code === currentQuestionCode);
                        return true;
                    }
                })
                return nextQuestion === null;
            });
        }
        return nextQuestion;
    }

    UpdateQuestionResult(result) {
        let question = this.GetQuestion(result.questionCode);
        if (question) {
            let clone = typeof structuredClone === 'function' ? structuredClone(this.state.results) : JSON.parse(JSON.stringify(this.state.results));
            let updatedResults = this.UpdateResultList(clone, result, "UpdateQuestionResult");
            this.setState({ results: updatedResults, hasChanges: (question.questionType !== "Instruction") }, () => {

                //console.log("UPDATE QUESTION RESULT", this.state.results)
                this.SetGroupOptions(this.GetQuestionGroup(this.state.selectedQuestionGroupCode));

            });
        }
    }

    // Returns the updated array of results
    // Does not save the resultsList to the state.results... to avoid sync issues using setState when updating multiple results at the once
    // You will need to do this after updating all the results you want 
    UpdateResultList(resultsList, result, source) {
        let updatedResults;
        let currentResultIndex = this.GetResultIndex(resultsList, result.questionCode);
        if (currentResultIndex === -1) {
            updatedResults = update(resultsList, { questionnaire: { currentQuestion: { $set: result.questionCode } }, answers: { $push: [result] } }); 
        } else {
            updatedResults = update(resultsList, { questionnaire: { currentQuestion: { $set: result.questionCode } }, answers: { $splice: [[currentResultIndex, 1, result]] } });
        }
        return updatedResults;
    }

    ShowProgress() {
        let totalPages = 1;
        if (this.state.questionnaire && this.state.questionnaire.questionGroups) {
            totalPages = this.state.questionnaire.questionGroups.length;
        }
        return totalPages > 1;
    }

    ProgressPrecentage() {
        let totalPages = 1;
        let currentPage = 1;
        if (this.state.questionnaire && this.state.questionnaire.questionGroups) {
            totalPages = this.state.questionnaire.questionGroups.length;
            if (this.state.selectedQuestionGroupCode)
                currentPage = this.state.questionnaire.questionGroups.findIndex((group) => group.code === this.state.selectedQuestionGroupCode) + 1;
            else
                currentPage = 1
        }
        return totalPages > 1 ? currentPage !== totalPages && !this.state.options.save ? currentPage * 100 / totalPages : 100 : 0;
    }

    ProgressText() {
        let totalPages = 1;
        let currentPage = 1;
        if (this.state.questionnaire && this.state.questionnaire.questionGroups ) {
            totalPages = this.state.questionnaire.questionGroups.length;
            if (this.state.selectedQuestionGroupCode)
                currentPage = this.state.questionnaire.questionGroups.findIndex((group) => group.code === this.state.selectedQuestionGroupCode) + 1;
            else
                currentPage = 1
        }
        return totalPages > 1 ? currentPage !== totalPages && !this.state.options.save ? `${currentPage} of ${totalPages}` : 'Final Section' : '';
    }

    componentDidMount() {
        if (this.props.code && this.props.version && this.props.instance) 
            this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    componentDidUpdate(prevProps) {
        if (this.props.code !== prevProps.code || this.props.version !== prevProps.version || this.props.instance !== prevProps.instance)
            this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    render() {

        if (this.state.isBusy) {
            return (
                <div className="busy-overlay-questionnaire col d-flex h-100 align-items-center justify-content-center">
                    <Spinner animation="border" role="status" variant="dark" />
                </div>
            );
        } else {

            if (!this.props.editSubmittedResults && this.state.results && this.state.results.isCompleted) {
                return this.RenderPrettyResults();

            } else {

                let group = this.GetQuestionGroup(this.state.selectedQuestionGroupCode);
                if (group) {

                    let virtualGroups = this.VirtualGroupsByQuestionTypes(group);
                    return (
                        <div>
                            {this.ShowProgress() && (
                                <ProgressBar now={this.ProgressPrecentage()} label={this.ProgressText()} />
                            )}
                            <Form noValidate validated={this.state.isFormValidated}>

                                {!this.validate.IsEmpty(group.text.text) && (
                                    <div className="p-3">
                                        <span dangerouslySetInnerHTML={{ __html: group.text.text }}></span>
                                    </div>
                                )}
                                {virtualGroups.map((virtualGroup, vgIndex) => (
                                    <div key={`VIRTUALGROUP_${vgIndex}`}>
                                        {(virtualGroup.questionType === "SingleSelectHorizontal" || virtualGroup.questionType === "MultiSelectHorizontal") && (
                                            <div className="ms-3 me-3 mb-3">
                                                <table className="w-100">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            {this.RenderHorizontalOptionHeaders(virtualGroup)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {virtualGroup.questions.map((question, index) => (

                                                            <DisplayQuestion
                                                                key={`QUESTION_${index}`}
                                                                questionnaire={this.state.questionnaire}
                                                                results={this.state.results}
                                                                prevResults={this.state.prevResults}
                                                                allOptionsInGroup={this.GetAllOptionsInGroup(virtualGroup)}
                                                                question={question}
                                                                result={this.GetResult(this.state.results, question.code)}
                                                                format={question.validation.format}
                                                                onUpdate={(result) => this.UpdateQuestionResult(result)}
                                                                user={this.props.user}
                                                                device={this.props.device}
                                                                theme={this.props.theme}
                                                            />

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {(virtualGroup.questionType !== "SingleSelectHorizontal" && virtualGroup.questionType !== "MultiSelectHorizontal") && (
                                            virtualGroup.questions.map((question, index) => (

                                                <DisplayQuestion
                                                    key={`QUESTION_${index}`}
                                                    questionnaire={this.state.questionnaire}
                                                    results={this.state.results}
                                                    prevResults={this.state.prevResults}
                                                    allOptionsInGroup={this.GetAllOptionsInGroup(virtualGroup)}
                                                    question={question}
                                                    result={this.GetResult(this.state.results, question.code)}
                                                    format={question.validation.format}
                                                    onUpdate={(result) => this.UpdateQuestionResult(result)}
                                                    user={this.props.user}
                                                    device={this.props.device}
                                                    theme={this.props.theme}
                                                />

                                            ))
                                        )}
                                    </div>
                                ))}

                            </Form>

                            {this.state.pageErrors && (
                                <Alert variant="danger">
                                    {this.state.pageErrors.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </Alert>
                            )}

                        </div>
                    );
                } else {

                    if (this.state.pageErrors)
                        return (
                            <Alert variant="danger">
                                <h2>Sorry!</h2>
                                {this.state.pageErrors.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                                <div className="pt-2">Please try again. If that doesn't work... wait a while and try later.</div>
                            </Alert>
                        );
                }
            }
        }
    }

    RenderHorizontalOptionHeaders(virtualGroup) {
        let optionCells = [];
        this.GetAllOptionsInGroup(virtualGroup).forEach((optionText, index) => {
            optionCells.push(
                <th key={`OPT_${index}`} className="text-center">
                    <b><span className="miniLabel" dangerouslySetInnerHTML={{ __html: optionText }}></span></b>
                </th>
            );
        })
        return optionCells;
    }

    RenderRawResults() {
        let rows = this.TabulateResultsData();
        return (
            <Table responsive className="transparent-table" variant="dark">
                <tbody>
                    {rows.map((_, index) => (
                        <tr key={index}>
                            {this.state.answerFields.map((item, subindex) => (
                                <td key={subindex}>
                                    <div><span className="miniLabel">{item.label}:</span></div>
                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    RenderPrettyResults() {
        let renderResults = [];
        Array.prototype.forEach.call(this.state.questionnaire.questionGroups, (group) => {
            let rows = this.TabulatePrettyResultsData(group);
            if (rows.length > 0) {
                if (group.text && group.text.text) {
                    renderResults.push(
                        <tr key="ROW_0">
                            <td colSpan={this.state.prettyAnswerFields.length} className="pt-3">
                                <span dangerouslySetInnerHTML={{ __html: group.text.text }}></span>
                            </td>
                        </tr>
                    );
                }
                rows.forEach((_, index) => {
                    renderResults.push(
                        <tr key={`ROW_${group.code}_${index}`}>
                            {this.state.prettyAnswerFields.map((item, subindex) => (
                                <td key={`CELL_${group.code}_${subindex}`} className={item.className}>
                                    <div dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></div>
                                </td>
                            ))}
                        </tr>
                    );
                });
            }
        });
        return (
            <div>
                <p><b>You have completed this questionnaire and we have the following results recorded:</b></p>
                <Table responsive className="transparent-table" variant="light">
                    <tbody>
                        {renderResults}
                    </tbody>
                </Table>
            </div>
        );
    }
}
