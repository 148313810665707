import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';
import './BrainTrainingGame_PROTECT.css'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
const ResizeObserver = window.ResizeObserver || Polyfill;

export class BrainTrainingGame_PROTECT extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cognitiveItemCode: null,
            cognitiveItemVersion: null,
            cognitiveItemInputType: null,
            refreshCount: 1, // Ensures refresh of state if same game is loaded again
            errorMessage: null,
            isBusy: false,
        };
        this.frameIsLoaded = false;
        this.frameLoadedAttempt = 1;
        this.customConsoleMessages = [];

        this.resizeObserver = null;

        this.container = React.createRef();
        this.frame = React.createRef();

        this.CallBackResults = this.CallBackResults.bind(this);
        this.CallBackQuit = this.CallBackQuit.bind(this);
        this.CallBackReplay = this.CallBackReplay.bind(this);
        this.CallBackUpdateHeader = this.CallBackUpdateHeader.bind(this);
        this.CallBackCustomConsole = this.CallBackCustomConsole.bind(this);
        this.CallBackFrameLoaded = this.CallBackFrameLoaded.bind(this);

    }

    Mute() {
        let frame = this.frame.current;
        if (frame) {
            frame.contentWindow.muted = true;
            frame.contentWindow.Howler.mute();
        }
    }

    Unmute() {
        let frame = this.frame.current;
        if (frame) {
            frame.contentWindow.muted = false;
            frame.contentWindow.Howler.unmute();
        }
    }

    AddCallBackFns() {
        window.CB = this.CallBackResults;
        window.CBQ = this.CallBackQuit;
        window.Ended = this.CallBackQuit;
        window.loadtask = this.CallBackReplay;
        window.updateHeader = this.CallBackUpdateHeader;
        window.onConsoleMessage = this.CallBackCustomConsole;
        window.frameLoaded = this.CallBackFrameLoaded;
    }

    RemoveCallBackFns() {
        window.CB = null;
        window.CBQ = null;
        window.Ended = null;
        window.loadtask = null;
        window.updateHeader = null;
        window.onConsoleMessage = null;
        window.frameLoaded = null;
    }

    CallBackCustomConsole(src, message) {
        this.customConsoleMessages.push({
            source: "Game Code",
            message: `${src}: ${message}`
        });
        if (src === "error") {
            this.setState({ isBusy: false }, () => {
                this.ReportError("GameFrameConsole", "It looks like we encountered an unexpected problem. Please try again, but if the problem persists click on the button to report the error and we will see if it's something we can fix.");
            });
        }
    }

    CallBackResults(data, attempts) {
        this.customConsoleMessages.push({
            source: "window.CB",
            message: data
        });

        var result = {
            internalVersion: data.version,
            attempts: data.attempts ?? attempts,
            startComplexity: data.startComplexity,
            endComplexity: data.endComplexity,
            startTime: data.startTime,
            endTime: data.endTime,
            summaryScore: data.SummaryScore,
            scores: data.Scores,
            rawData: data.Rawdata
        };
        if (this.props.onComplete) {
            this.setState({ isBusy: true }, () => {
                this.props.onComplete(result);
            });
        } else {
            this.customConsoleMessages.push({
                source: "window.CB",
                message: "onComplete NOT specified"
            });
        }
    }

    CallBackQuit() {
        this.customConsoleMessages.push({
            source: "window.CBQ",
            message: ""
        });
        if (this.props.onQuit)
            this.props.onQuit();
        else
            this.customConsoleMessages.push({
                source: "window.CBQ",
                message: "onQuit NOT specified"
            });
    }

    CallBackReplay(itemId) {
        this.customConsoleMessages.push({
            source: "window.loadtask",
            message: itemId
        });
        if (this.props.onReplay)
            this.props.onReplay();
        else
            this.customConsoleMessages.push({
                source: "window.loadtask",
                message: "onReplay NOT specified"
            });
    }

    CallBackUpdateHeader(header) {
        var headerUpdate = {}
        if (Object.prototype.hasOwnProperty.call(header, 'type')) {
            switch (header.type) {
                case 'name': if (Object.prototype.hasOwnProperty.call(header, 'tName')) headerUpdate.name = header.tName; break;
                case 'time': if (Object.prototype.hasOwnProperty.call(header, 'tTime')) headerUpdate.time = header.tTime; break;
                case 'score': if (Object.prototype.hasOwnProperty.call(header, 'tScore')) headerUpdate.score = header.tScore; break;
                case 'progress': if (Object.prototype.hasOwnProperty.call(header, 'tProgress')) headerUpdate.score = header.tProgress; break;
                default: break;
            }
        }
        if (headerUpdate && (
            Object.prototype.hasOwnProperty.call(headerUpdate, 'name') ||
            Object.prototype.hasOwnProperty.call(headerUpdate, 'time') ||
            Object.prototype.hasOwnProperty.call(headerUpdate, 'progress') ||
            Object.prototype.hasOwnProperty.call(headerUpdate, 'score'))
        ) {
            if (this.props.onUpdateHeader)
                this.props.onUpdateHeader(headerUpdate);
            else
                this.customConsoleMessages.push({
                    source: "CallBackUpdateHeader",
                    message: "onUpdateHeader NOT specified"
                });
        }
    }

    CallBackFrameLoaded() {
        //console.log("FRAME LOADED");
        this.FrameLoaded();
    }

    ReportError(source, message) {

        this.customConsoleMessages.push({
            source: source,
            message: message
        });

        if (Object.prototype.hasOwnProperty.call(message, 'isTrusted') && message['isTrusted'] === true)
            message = "An error occured in a script from a different domain.";

        this.setState({ errorMessage: "Unable to load game" });

    }

    SaveError(source, message) {
        if (this.props.onError)
            this.props.onError({ exception: { message: "Unable to load game.", console: this.customConsoleMessages } });
    }

    // Resize not working if resize observer is generic?!
    SetResize(category) {

        if (this.resizeObserver) {
            this.resizeObserver.unobserve(document.body);
            this.resizeObserver = null;
        }

        this.resizeObserver = new ResizeObserver(this.Resize);
        this.resizeObserver.observe(document.body);
    }

    // If updating the frame with a new cognitive item
    // make sure the old contents are fully detached...
    ClearAll() {

        this.customConsoleMessages.push({
            source: "CLEAR ALL",
            message: ''
        });

        let frame = this.frame.current;
        let container = this.container.current;

        if (frame && frame.contentWindow && container) {

            this.RemoveCallBackFns();
            this.SetResize();

            frame.onLoad = null;
            frame.onError = null;

            frame.contentWindow.location.assign("about:blank"); 
        }
    }

    GetFrameSource() {
        var location;
        location = `${process.env.PUBLIC_URL}/TestsAndGames/v3/PROTECT/taskModule.html`;
        return location;
    }

    LoadCognitiveItem(eventName) {

        if (navigator && navigator.userAgent) {
            this.customConsoleMessages.push({
                source: eventName,
                message: navigator.userAgent
            });
        } else {
            this.customConsoleMessages.push({
                source: eventName,
                message: "navigator.userAgent not found"
            });
        }

        this.frameIsLoaded = false;
        let frame = this.frame.current;
        let container = this.container.current;

        if (this.props.cognitiveItemCode) {

            let location = this.GetFrameSource();
            this.setState({
                refreshCount: this.state.refreshCount + 1,
                cognitiveItemCode: this.props.cognitiveItemCode,
                cognitiveItemVersion: this.props.cognitiveItemVersion,
                cognitiveItemInputType: this.props.cognitiveItemInputType,
                isBusy: true
            }, () => {

                if (frame && frame.contentWindow && container) {

                    container.className = `frame-container-PROTECT2`;
                    frame.className = `iframe-PROTECT2`;

                    this.AddCallBackFns();
                    //frame.onLoad = this.FrameLoaded();
                    frame.onError = function (msg, url, line) { this.ReportError("frame.onError", msg) };

                    this.SetResize(this.props.category);

                    this.customConsoleMessages.push({
                        source: "SET FRAME LOCATION",
                        message: location ?? 'about:blank'
                    });
                    frame.contentWindow.location.assign(location ?? 'about:blank');
                }

            })
        }
    }

    FrameLoaded() {

        let container = this.container.current;
        let frame = this.frame.current;

        if (container && frame) {

            let requiredLocation = this.GetFrameSource()
            let actualLocation = frame.contentWindow.location.pathname;

            //if (actualLocation === requiredLocation && !this.frameIsLoaded && (
            //    typeof frame.contentWindow.init === "function" ||
            //    typeof frame.contentWindow.document.startTasks === "function")) {
            if (actualLocation === requiredLocation && !this.frameIsLoaded) {

                this.customConsoleMessages.push({
                    source: "FRAME LOADED",
                    message: actualLocation
                });

                this.frameIsLoaded = true;

                try {

                    // if (typeof frame.contentWindow.init === "function" && this.props.cognitiveItemCode && this.props.languageCode && this.props.seedValue && this.props.seedValue > 0) {
                    if (this.props.cognitiveItemCode && this.props.languageCode && this.props.seedValue && this.props.seedValue > 0) {

                        // TestCode, Language, [VLevel1, VLevel2, VLevel3] Where VLevel1 = the start complexity
                        this.customConsoleMessages.push({
                            source: "INIT",
                            message: `frame.contentWindow.init('${this.props.cognitiveItemCode}', '${this.props.languageCode}', [${this.props.seedValue}, 0, 0]);`
                        });
                        //console.log("INIT", `frame.contentWindow.init('${this.props.cognitiveItemCode}', '${this.props.languageCode}', [${this.props.seedValue}, 0, 0]);`)
                        frame.contentWindow.init(this.props.cognitiveItemCode, this.props.languageCode, [this.props.seedValue, 0, 0]);

                        this.Resize();
                        frame.focus();

                    } else {
                        this.ReportError("FrameLoaded catch(e)", `INVALID INIT: frame.contentWindow.init('${this.props.cognitiveItemCode}', '${this.props.languageCode}', [${this.props.seedValue}, 0, 0]);`)
                    }

                } catch (e) {
                    this.ReportError("FrameLoaded catch(e)", e.message)
                }

                if (this.state.isBusy)
                    this.setState({ isBusy: false });

            } else {
                if (!this.frameIsLoaded) {
                    if (this.frameLoadedAttempt < 10)
                    {
                        this.frameLoadedAttempt++;
                        setTimeout(() => {
                            this.customConsoleMessages.push({
                                source: "RETRY",
                                //message: `ACTUAL '${actualLocation}' - REQUIRED '${requiredLocation}' - frame.contentWindow.init: ${typeof frame.contentWindow.init} - frame.contentWindow.document.startTasks: ${typeof frame.contentWindow.document.startTasks}`
                                message: `ACTUAL '${actualLocation}' - REQUIRED '${requiredLocation}'`
                            });
                            this.FrameLoaded()
                        }, 1000);
                    }
                    else {
                        this.setState({ errorMessage: `We made multiple attempts, but we were unable to load this game. This may be due to a slow internet connection, or you may be using an older device (or a device with not enough memory).`})
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.LoadCognitiveItem("MOUNT");
    }

    componentDidUpdate(prevProps) {
        if (this.props.cognitiveItemCode && this.props.cognitiveItemVersion && (this.props.cognitiveItemCode !== this.state.cognitiveItemCode || this.props.cognitiveItemVersion !== this.state.cognitiveItemVersion)) {
            this.LoadCognitiveItem("UPDATE");
        }
    }

    componentWillUnmount() {
        this.ClearAll();
    }

    render() {

        if (this.state.errorMessage) {
            return (
                <div id="frameContainer" className="p-5 m-5">
                    <h1>Sorry!</h1>
                    <p>{this.state.errorMessage}</p>
                    <button className="btn btn-danger" onClick={() => { this.SaveError() }}>Report Error</button>
                </div>
            );
        } else {
            return (
                <div>
                    <div id="frameContainer" ref={this.container}>
                        <iframe id="frame" ref={this.frame} title="Cognitive Item" scrolling="no"></iframe>
                    </div>
                    {this.state.isBusy && (
                        <div ref={this.spinner} className={`frame-container-PROTECT2-spinner d-flex align-items-center justify-content-center`}>
                            <Spinner animation="border" role="status" variant="light" />
                        </div>
                    )}
                </div>
            );
        }

    }

    Resize() {
        let originalWidth = 510;
        let originalHeight = 900;
        let container = document.getElementById("frameContainer")
        let frame = document.getElementById("frame")
        if (container && frame) {

            let modalWindow = container;
            let containerPadding = 0;
            let framePadding = 10;
            let xOffset = 15;

            let availableWidth = Math.max(modalWindow.clientWidth - containerPadding * 2, framePadding * 2);
            let availableHeight = Math.max(Math.min(modalWindow.clientHeight - containerPadding * 2, originalHeight), framePadding * 2);

            container.style.height = `${availableHeight}px`;

            let frameWidthRequired = Math.min(availableWidth, originalWidth);
            let frameHeightRequired = Math.min(availableHeight, originalHeight);

            let widthScale = Math.min(frameWidthRequired / originalWidth, 1);
            let heightScale = Math.min(frameHeightRequired / originalHeight, 1);
            let scale = Math.min(widthScale, heightScale);

            let frameWidthActual = originalWidth * scale;
            let frameHeightActual = originalHeight * scale;

            let transform = `translate(${(availableWidth - frameWidthActual + xOffset) / 2}px, ${(availableHeight - frameHeightActual) / 2}px) scale(${scale})`;
            let transformOrigin = 'top left';

            frame.style.webkitTransform = transform;
            frame.style.webkitTransformOrigin = transformOrigin;

            frame.style.MozTransform = transform;
            frame.style.MozTransformOrigin = transformOrigin;

            frame.style.msTransform = transform;
            frame.style.msTransformOrigin = transformOrigin;

            frame.style.OTransform = transform;
            frame.style.OTransformOrigin = transformOrigin;

            frame.style.transform = transform;
            frame.style.transformOrigin = transformOrigin;

        }
    }

}

