import update from 'immutability-helper';
import { ConsoleLogging, ErrorHandler } from './APIHelpers'

export class QuestionnaireApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "questionnaire";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // QuestionnaireApi
    async UploadFile(file, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/upload`;
        var formData = new FormData()
        formData.append('file', file);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn)

    }

    // QuestionnaireApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let formData = new FormData();
        formData.append('dataQuery', JSON.stringify(dataQuery));
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async List(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/list`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async GetResults(code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getResultsByCode/${code}/${version}/${instance}`;
        if (code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // BatteryApi
    async GetResultsByParticipantIdAndCode(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getParticipantResultsByCode/${participantStudyId}/${code}/${version}/${instance}`;
        if (participantStudyId && code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // QuestionnaireApi
    async GetParticipantResults(resultDataQuery, questionnaire, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/GetParticipantResults`;
        if (questionnaire && questionnaire.code && questionnaire.version) {

            let formData = new FormData();
            formData.append('dataQuery', JSON.stringify(resultDataQuery));
            formData.append('code', questionnaire.code);
            formData.append('version', questionnaire.version);
            let postData = {
                method: "POST",
                body: formData,
            };
            await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // QuestionnaireApi
    async Get(questionnaire, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Get/${questionnaire.id}`;
        if (questionnaire && questionnaire.id && questionnaire.id > 0) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // QuestionnaireApi
    async GetByCode(code, version, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getByCode/${code}/${version}`;
        if (code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // QuestionnaireApi
    async SaveItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async DeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async DevDeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/DeleteWithResults/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async SaveResults(uploadResults, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/saveResults`;
        let formData = new FormData();
        formData.append('uploadedResults', JSON.stringify(uploadResults));
        let postData = {
            method: "POST",
            //headers: { "Content-Type": "application/json" },
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }

    async FetchBlob(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.blob();
                        if (data) {

                            this.consoleLog.Log(url, "File Downloaded");
                            if (callBackSuccessFn)
                                callBackSuccessFn(data);

                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class AccountApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "account";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // AccountApi
    async MyDetails(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/mydetails`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // AccountApi
    async KeepLoggedIn(callBackSuccessFn, callBackErrorFn) {

        try {
            let url = `${this.endPoint}/KeepLoggedIn`;

            await fetch(url)
                .then(async response => {
                    if (!response.ok) {
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    } else {

                        this.consoleLog.Log(url);
                        if (callBackSuccessFn)
                            callBackSuccessFn();

                    }

                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });

        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class UserApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "user";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // UserApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataQuery),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // UserApi
    async SaveItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // UserApi
    async DeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${item.id}`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}