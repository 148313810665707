import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

export class ButtonCopyToClipboard extends React.Component {

    CopyToClipboard()
    {
        if (this.props.text)
            navigator.clipboard.writeText(this.props.text);
    }

    render() {
        if (document.queryCommandSupported('copy')) {
            return (
                <div>
                    <button className="btn bg-transparent" onClick={() => this.CopyToClipboard()}><span className="bi bi-copy"></span></button>
                </div>
            );
        }
    }

}