import React, { Component } from "react";
import './DiploriaDiv';

export class DiploriaDiv extends Component {

    constructor(props) {
        super(props);

        this.resizeObserver = null;
        this.Resize = this.Resize.bind(this);

        this.diploriaDiv = React.createRef();

        this.CallBackDiploriaResults = this.CallBackDiploriaResults.bind(this);
        this.CallBackDiploriaTaskStarted = this.CallBackDiploriaTaskStarted.bind(this);
        this.CallBackDiploriaTaskClose = this.CallBackDiploriaTaskClose.bind(this);
    }

    CallBackDiploriaResults(data) {
        //console.debug("window.document.taskComplete()", JSON.stringify(data));
        if (this.props.onComplete) {
            this.setState({ isBusy: true }, () => {
                this.props.onComplete(data);
            });
        } else {
            console.warn("CallBackDiploriaResults - onComplete NOT specified")
        }
    }

    CallBackDiploriaTaskStarted() {
        //console.debug("window.document.taskStarted()");
        if (this.props.onStarted) {
            this.props.onStarted();
        } else {
            console.warn("CallBackDiploriaTaskStarted - onStarted NOT specified")
        }
    }

    CallBackDiploriaTaskClose() {
        //console.debug("window.document.onTaskClose()");
        if (this.props.onQuit)
            this.props.onQuit();
    }

    CloseTaskSequence() {
        //console.debug("window.document.stopDiploria()");
        window.document.stopDiploria();
        window.document.taskComplete = null;
        window.document.taskStarted = null;
        window.document.onTaskClose = null;
        window.document.taskSequence = null;
    }

    StartTaskSequence(taskSequence) {
        if (taskSequence && taskSequence.length > 0) {

            window.document.taskComplete = this.CallBackDiploriaResults;
            window.document.taskStarted = this.CallBackDiploriaTaskStarted;
            window.document.onTaskClose = this.CallBackDiploriaTaskClose;
            window.document.taskSequence = taskSequence;

            if (this.resizeObserver) {
                this.resizeObserver.unobserve(document.body);
                this.resizeObserver = null;
            }
            this.resizeObserver = new ResizeObserver(this.Resize);
            this.resizeObserver.observe(document.body);

            window.document.startTasks();
            console.debug(`STARTED TASK SEQUENCE`, taskSequence)

            this.Resize();
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    componentWillUnmount() {
        window.document.taskComplete = null;
        window.document.taskStarted = null;
        window.document.onTaskClose = null;
        window.document.taskSequence = null;
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(document.body);
            this.resizeObserver = null;
        }
    }

    render() {
        return (<div ref={this.diploriaDiv} className="m-auto diploria-div" id="diploria"></div>);
    }

    Resize() {
        let diploriaDiv = this.diploriaDiv.current;
        if (diploriaDiv) {

            let diploriaStyle = window.getComputedStyle(diploriaDiv, null);

            let top = parseInt(diploriaStyle.getPropertyValue('top'));
            if (isNaN(top)) top = 40;
            let left = parseInt(diploriaStyle.getPropertyValue('left'));
            if (isNaN(left)) left = 15;

            diploriaDiv.style.width = `${window.innerWidth - left}px`;
            diploriaDiv.style.height = `${window.innerHeight - top}px`;

            //console.debug("RESIZED DIPLORIA DIV", top, diploriaDiv.style.width, diploriaDiv.style.height);
            window.dispatchEvent(new Event('diploriaResize'));

        }
    }
}

