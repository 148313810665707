import React, { Component } from 'react';
import './Privacy.css'

export class Privacy extends Component {
    static displayName = Privacy.name;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill privacy-notice">

                <h1>BEACON Privacy Policy</h1>
                <p>The University of Exeter is committed to safeguarding your personal information.</p>
                <p>This policy (together with our terms of use which can be found at [insert terms and condition link] and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. </p>
                <p>For the purposes of the data protection laws, the Data Controller in relation to any personal data you supply is  the University of Exeter of Northcote House, The Queen's Drive, Exeter, Devon, EX4 4QJ, United Kingdom. </p>

                <h3>What data do we hold? </h3>
                <p>For the purposes of the study we collect certain personal and sensitive personal information from you as follows: </p>
                <ul>
                    <li>Full demographic details, including your name, date of birth, email address, home address (including post code), gender, ethnic origin, GP details, NHS number, marital status, employment status and highest level of education completed. Submitting these demographic details is a requirement for participating in this study because they are basic data that are used in statistical analysis alongside the information you provide in questionnaires and cognitive tests. This gives context and meaning to your information in relation to the information provided by other participants. </li>
                    <li>Lifestyle information including physical exercise, diet and cognitive activities. </li>
                    <li>Medical history including conditions such as Long COVID. </li>
                    <li>Major aspects of your brain health such as memory or reasoning that influence how well you perform everyday activities which we gather using validated cognitive tests. </li>
                    <li>Responses to a detailed mental health questionnaire including validated scales for depression, stress, anxiety,  social cognition and mood. </li>
                    <li>Detailed information on everyday behaviour. </li>
                    <li>All personal information will be treated as confidential.</li>
                </ul>
                <p>In order to ensure that we can improve our site where required and to provide you with a good user experience, we collect information about your use, such as browser type and version, operating system and platform, information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), tests you viewed or searched for, page response times, any download errors, length of visits to certain pages and page interaction information (such as scrolling, clicks, and mouse-overs). </p>

                <h3>How do we use your data?</h3>
                <p>Here are some of the ways we will use your personal data; this is not exhaustive, but is intended to provide you with an idea of the things that we may do throughout your relationship with the University of Exeter.  All personal and sensitive personal information is stored in an encrypted format in the Participant Database located in the UK West region Microsoft Azure cloud servers. Pseudonymised data (where your name is replaced by an ID number)  will be statistically analysed as part of our clinical trial of approximately 1608 participants by the University of Exeter. This pseudonymised data does not contain any information that may directly identify you. The key will be held separately and will not be accessible by anyone who is analysing the data. The data gathered online through questionnaires and cognitive tests will be completely anonymised. The data will be used for the publication of results of the study in academic/scientific publications or in resources for research, education and public engagement. We will maintain the data for use in future research projects or research archives. </p>
                <p>We may use your email address to send reminders, updates and to enable communication regarding any emerging safety issues and to maintain participant dignity and wellbeing. The University of Exeter will not use your personal data to contact you about anything not directly related to this study.  </p>

                <h3>What is our legal basis for processing your data? </h3>
                <p>You will be asked to consent to providing this information as part of the registration process. </p>
                <p>However, your personal data is collected and processed by the University of Exeter to enable the University to carry out research in the public interest. </p>

                <h3>How will we share your data? </h3>
                <p>The University of Exeter will not use or share with third parties your personal data for a purpose other than set out in this policy. </p>

                <h3>How long do we keep your data? </h3>
                <p>Your personal information will be held securely so long as you are part of the study. When the study is completed we will securely store your personal information for as long as is required for the purposes of the study but no more than 10 years. It will then be securely destroyed. We will store all anonymised data indefinitely on an archive such as the University of Exeter's open access archive, Open Research Exeter (ORE). </p>
                <p>If you choose to withdraw from the study we will retain a record of your consent to participate in the study for 10 years after the study is completed. It will then be securely destroyed. This record includes all versions of consent you gave including your name, date and time consented, the first four alpha-numeric characters of your postcode and your registration ID. We will also retain all anonymised assessment results data you have submitted as detailed above because it will not be possible to re-identify and remove your personal data once it has been anonymised. During the withdrawal process we will give you the option to remove your remaining personal information (i.e. data that directly identifies you) which includes your email, home address, telephone number, and doctor's name and address. If you choose to remove this personal data you will no longer receive any communication from us. If you request to re-join the study at a later date, you would need to start from the beginning and wouldn't be able to re-activate your account. You can choose whether you receive communications about the study if you decide that we can retain your data. If you choose to withdraw from the study, you will no longer be able to log into the study website or app. </p>

                <h3>How do we protect your data? </h3>
                <p>All personal data and sensitive personal data is collected via the website www.beaconstudy.org.uk or the progressive web application (PWA) using Secure Sockets Layer (SSL) software, which encrypts the information you input. This is delivered through the PROTECT platform which was developed at the University of Exeter in 2017-18 and adapted for PWA delivery in 2023. Personal and sensitive personal information will be stored securely by Microsoft Azure, UK West, and will be managed within the framework of Microsoft Azure governance procedures. Personal and sensitive personal information will be stored in separate databases in encrypted format and in a restricted area, known as a Participant Database, accessible by University of Exeter approved research teams only. All data extracted from the Participant Database for statistical analysis is pseudonymised and is stored in a separate database, known as the Results Database; this data set contains no link to any information that can personally identify you, such as name, telephone number or email address. </p>
                <p>The pseudonymised data that we store in the Results Database will be stored securely by Microsoft Azure, UK West, and will be managed within the framework of Microsoft Azure governance procedures. Data held in the Results Database may be shared with global researcher partners of the University of Exeter.</p>
                <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. </p>
                <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data inputted to our site; any such inputting is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. </p>

                <h3>Your rights and preferences </h3>
                <p>You have a number of rights in relation to the personal data that is collected and held about you: </p>
                <ul>
                    <li>The right to be informed.</li>
                    <li>The right of access.</li>
                    <li>The right to rectification.</li>
                    <li>The right to erasure.</li>
                    <li>The right to restrict processing.</li>
                    <li>The right to data portability.</li>
                    <li>The right to object.</li>
                </ul>
                <p>In the information above and in the participant information sheets, we have provided information on how these rights apply in the BEACON study and the implications of exercising these rights. </p>
                <p>If you have any questions about this policy, or any other aspects of your privacy with respect to the BEACON Study, or if you wish to invoke one of your above rights, please write to us at the following relevant address: </p>
                <p>The Trial Manager <br />
                    BEACON Study  <br />
                    College House 1.23  <br />
                    St Luke's Campus  <br />
                    The University of Exeter <br />
                    Exeter, EX1 2LU  <br />
                    United Kingdom  <br /></p>
                <p>Alternatively, you may also contact:</p>
                <p>The University of Exeter's Data Protection Officer who is responsible for monitoring compliance with relevant legislation in relation to personal data, by emailing <a href="mailto:dataprotection@exeter.ac.uk">dataprotection@exeter.ac.uk</a>. </p>
                <p>You have the right to lodge a complaint with the Information Commissioner's Office at <a target="_blank" href="www.ico.org.uk/concerns">www.ico.org.uk/concerns</a>. </p>

                <h3>Further information </h3>
                <p>This Privacy Notice will be kept under review. Any changes will be updated on our website or app and communicated as appropriate. </p>

                <h3>PROTECT Cookies Policy </h3>
                <p>This privacy policy describes the practices of the University of Exeter in regard to information that we obtain via this website or app www.beaconstudy.org.uk. The guidelines at this site or app are applicable only to this website app. It does not describe practices with respect to other sites. We do not control and are not responsible for the privacy practices of, or the data available on, the website of third parties, and we urge you to evaluate the suitability of these practices and data for yourself. </p>
                <p>Our site uses cookies to help give you a better experience. By continuing to use it you consent to the use of cookies as set out in our privacy policy. </p>

                <h3>Cookies </h3>
                <p>A cookie is a small amount of data, which often includes a unique identifier that is sent to your computer, tablet or mobile phone (referred to here as a "device") browser from a website's computer and is stored on your device's hard drive. Each website can send its own cookie to your browser or app if your browser or app preferences allow it, but (to protect your privacy) your browser or app only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other web sites. </p>
                <p>You block cookies by activating the setting on your browser or app that allows you to refuse the setting of all or some cookies. However, if you use your browser or app settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site or app. </p>

                <h3>How Does the BEACON Study Use Cookies </h3>
                <p>The BEACON study website and app uses cookies to distinguish you from other users of our website or app. This helps us to provide you with a good experience when you browse or use our website or app and also allows us to improve our site. When you register to participate in the BEACON study, we ask you to agree to allow cookies. On the BEACON study website or app, cookies contain a unique identifier which is used to track your user session when logged-on and to ensure that the input data you provide is collected securely and associated with your user account. Other cookies may be used to remember user preferences and to personalise content. No cookies store personal sensitive information or information that can be used to personally identify you. </p>

                <h3>Third Party Cookies </h3>
                <p>During your visits to this website or app you may notice some cookies that are not related to this study. When you visit a page containing embedded content from another site, for example, YouTube, you may be presented with cookies from these web sites. The University of Exeter do not control the dissemination of these cookies. You should check the third party web sites for more information about these. </p>

            </div>
        );
    }
}
