import React, { Component } from "react";
import { Format } from './GlobalFns'
import Badge from 'react-bootstrap/Badge';

export class CountDown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timer: null,
            text: null,
            status: null,
        }

        this.urgent = 1 * 24 * 60 * 60 * 1000;      // Less than one day
        this.not_urgent = 3 * 24 * 60 * 60 * 1000;  // Less than 3 days
        this.long_time = 10 * 24 * 60 * 60 * 1000;  // Less than 10 days

        this.Format = new Format();
    }

    Refresh() {
        if (this.props.endDate) {

            let startDate = new Date();
            let endDate = this.props.endDate;

            let text = this.Format.TimeBetween(startDate, endDate);

            if (endDate < startDate) {

                this.setState({ status: "FINISHED", text: text })
                this.ClearTimer("Finished")
                if (this.props.onFinished)
                    this.props.onFinished();

            } else if ((endDate - startDate) < this.urgent) {

                // Keep the timer running to update the time
                this.setState({ status: "URGENT", text: text })

            } else if ((endDate - startDate) < this.not_urgent) {

                this.setState({ status: "NOT_URGENT", text: text })
                this.ClearTimer("Not Urgent")

            } else if ((endDate - startDate) < this.long_time) {

                this.setState({ status: "LONG_TIME", text: text })
                this.ClearTimer("Long Time")

            } else {

                this.setState({ status: "DO_NOT_DISPLAY", text: text })
                this.ClearTimer("Do not display")

            }
        }
    }

    ClearTimer(reason) {
        //console.log(`CountDown Cleared - ${reason}`)
        if (this.state.timer) {
            clearInterval(this.state.timer);
            this.setState({ timer: null })
        }
    }

    componentDidMount() {
        //console.log("Countdown", { startDate: this.props.startDate, endDate: this.props.endDate, currentDate: new Date(), timeRemaining: this.Format.ExactTimeBetween(new Date(), this.props.endDate) });
        this.setState({ timer: setInterval(() => this.Refresh(), 1000) })
    }

    componentWillUnmount() {
        this.ClearTimer("Component Will Unmount")
    }

    render() {
        switch (this.state.status) {
            case "FINISHED":
            case "URGENT":

                return (
                    <div>
                        <Badge pill bg="danger" text="light">
                            {this.state.text}
                        </Badge>
                    </div>
                );

            case "NOT_URGENT":

                return (
                    <div>
                        <Badge pill bg="warning" text="dark">
                            {this.state.text}
                            {this.props.device && !this.props.device.IsPhone() && (<span> remaining</span>)}
                        </Badge>
                    </div>
                );

            case "LONG_TIME":

                return (
                    <div>
                        <Badge pill bg="dark" text="light">
                            {this.state.text}
                            {this.props.device && !this.props.device.IsPhone() && (<span> remaining</span>)}
                        </Badge>
                    </div>
                );

            default:
                return (
                    <div>
                    </div>
                );

        }
    }

}