import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';

export class CognitiveItemDiploriaDiv extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            cognitiveItemCode: null,
            cognitiveItemVersion: null,
            cognitiveItemInputType: null,
        };

        this.resizeObserver = null;
        this.spinner = React.createRef();
        this.diploriaDiv = React.createRef();

        this.Resize = this.Resize.bind(this);

        this.CallBackDiploriaResults = this.CallBackDiploriaResults.bind(this);
        this.CallBackDiploriaTaskStarted = this.CallBackDiploriaTaskStarted.bind(this);
        this.CallBackDiploriaTaskClose = this.CallBackDiploriaTaskClose.bind(this);
    }

    CallBackDiploriaResults(data) {
        //console.debug("window.document.taskComplete", JSON.stringify(data));
        if (this.props.onComplete) {
            this.setState({ isBusy: true }, () => {
                console.debug("window.document.stopDiploria");
                window.document["stopDiploria"]();
                this.props.onComplete(data);
            });
        } else {
            console.warn("CallBackDiploriaResults - onComplete NOT specified")
        }
    }

    CallBackDiploriaTaskStarted() {
        //console.debug("window.document.taskStarted");
        if (this.props.onStarted) {
            this.props.onStarted();
        } else {
            console.warn("CallBackDiploriaTaskStarted - onStarted NOT specified")
        }
    }

    CallBackDiploriaTaskClose() {
        //console.debug("window.document.onTaskClose");
        if (this.props.onQuit)
            this.props.onQuit(() => {
                // OK
                console.debug("window.document.stopDiploria");
                window.document["stopDiploria"]();
                if (this.props.onQuit)
                    this.props.onQuit();
            }, () => {
                // CANCEL
                console.debug("onQuit Cancelled");
            });
    }

    SetResize() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(document.body);
            this.resizeObserver = null;
        }
        this.resizeObserver = new ResizeObserver(this.Resize);
        this.resizeObserver.observe(document.body);
        //console.debug("RESIZE OBSERVER ATTACHED");
    }

    LoadCognitiveItem(eventName) {

        console.debug(`CognitiveItemFrame - ${eventName}`, {
            cognitiveItemCode: this.props.cognitiveItemCode,
            cognitiveItemVersion: this.props.cognitiveItemVersion,
            cognitiveItemInputType: this.props.cognitiveItemInputType,
            category: this.props.category,
            seedValue: this.props.seedValue,
            languageCode: this.props.languageCode,
            theme: this.props.theme,
            device: this.props.device,
            user: this.props.user,
        });

        let taskSequence = [];
        let task = { "taskId": this.props.cognitiveItemCode.toLowerCase() };
        switch (this.props.cognitiveItemCode) {
            case 'picture-presentation':

                task.pictureSet = this.props.practiceMode ? 0 : ((this.props.seedValue - 1) % 5) + 1;
                break;

            case 'picture-recognition':

                task.inputType = this.props.cognitiveItemInputType.toLowerCase();
                task.pictureSet = this.props.practiceMode ? 0 : ((this.props.seedValue - 1) % 5) + 1;
                break;

            case 'digit-span':

                if (this.props.practiceMode) task.taskDuration = 20;
                break;

            case 'verbal-reasoning':
            case 'paired-associate-learning':
            case 'self-ordered-search':

                if (this.props.practiceMode) task.taskDuration = 10;
                break;

            case 'simple-reaction-time':
            case 'choice-reaction-time':

                task.inputType = this.props.cognitiveItemInputType.toLowerCase();
                if (this.props.practiceMode) task.taskDuration = 10;
                break;

            case 'digit-vigilance':

                task.inputType = this.props.cognitiveItemInputType.toLowerCase();
                if (this.props.practiceMode) task.taskDuration = 35;
                break;

            case 'trail-making':

                task.practiceMode = this.props.practiceMode;
                break;

            default:

                if (this.props.practiceMode) task.taskDuration = 10;
                if (this.props.cognitiveItemInputType !== "BOTH")
                    task.inputType = this.props.cognitiveItemInputType.toLowerCase();
                break;
        }
        taskSequence.push(task);

        if (!window.document.taskSequence || !this.DiploriaSequencesAreEqual(window.document.taskSequence, taskSequence)) {

            window.document.taskComplete = this.CallBackDiploriaResults;
            window.document.taskStarted = this.CallBackDiploriaTaskStarted;
            window.document.onTaskClose = this.CallBackDiploriaTaskClose;

            this.SetResize(this.props.category);

            window.document.taskSequence = taskSequence;
            window.document.startTasks();

            console.debug("STARTED TASK SEQUENCE", taskSequence)
        }
    }

    DiploriaSequencesAreEqual(array1, array2) {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element.taskId === array2[index].taskId && element.inputType === array2[index].inputType;
        });
    }

    componentDidMount() {
        this.LoadCognitiveItem("MOUNT");
        this.Resize()
    }

    componentDidUpdate(prevProps) {
        if (this.props.cognitiveItemCode && this.props.cognitiveItemVersion &&
        (
            this.props.cognitiveItemCode !== this.state.cognitiveItemCode ||
            this.props.cognitiveItemVersion !== this.state.cognitiveItemVersion)
        )
        {
            this.LoadCognitiveItem("UPDATE");
        }
        this.Resize()
    }

    componentWillUnmount() {
        window.document.taskSequence = null;
        window.document.taskComplete = null;
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(document.body);
            this.resizeObserver = null;
        }
    }

    render() {

        switch (this.props.category) {
            case 'DIPLORIA':

                return (<div ref={this.diploriaDiv} className="m-auto diploria-div" id="diploria"></div>);

            default:
                return (
                    <div ref={this.spinner} className="busy-overlay-cognitiveItem d-flex align-items-center justify-content-center">
                        <Spinner animation="border" role="status" variant={this.props.theme.GetSpinnerContrastVariant()} />
                    </div>
                );
        }

    }

    Resize() {
        let diploriaDiv = this.diploriaDiv.current;
        if (diploriaDiv) {
            diploriaDiv.style.width = `${window.innerWidth}px`;
            diploriaDiv.style.height = `${window.innerHeight}px`;
            //console.debug("RESIZED DIPLORIA DIV", diploriaDiv.style.width, diploriaDiv.style.height);
            window.dispatchEvent(new Event('diploriaResize'));
        }
    }
}

