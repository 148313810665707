import React, { Component } from "react";
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { CodeVersionCategory } from '../shared/Inputs/CodeVersionCategory';
import { ButtonDelete } from "../shared/Buttons/Delete";
import { ButtonAdd } from '../shared/Buttons/Add';
import { FileUpload } from "../shared/Inputs/FileUpload";
import { Validate } from '../shared/GlobalFns'

export class EditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            validation: [
                { key: 'text', isValid: true, message: 'Please enter a valid display name.' },
                { key: 'description', isValid: true, message: 'Please enter a valid description.' },
                { key: 'backgroundColour', isValid: true, message: 'Please enter a valid colour code.' },
            ],
            editItem: {
                code: null,
                version: null,
                languageCode: 'en-GB',
                status: 'NEW',
                targetMinutesDay: 0,
                targetMinutes7Days: 0,
            },
            editIndex: -1,
            existingCodes: [],
            saveError: null,
            itemFields: [
                { key: 'code', label: 'Code / Extracted As' },
                { key: 'decimalPlaces', label: 'Decimal Places' },
                { key: 'actions', label: '' },
            ],
        };

        this.codeVersionCategory = React.createRef();
        this.validate = new Validate();
        this.newDataPoint = { code: '', extractionCode: '', decimalPlaces: 0}
    }

    TabulateDataItems() {
        let rows = [];
        if (this.state.editItem && this.state.editItem.dataPoints && this.state.editItem.dataPoints.length > 0) {
            Array.prototype.forEach.call(this.state.editItem.dataPoints, (data) => {
                let row = {};
                this.state.itemFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                            row[field.key] = data[field.key] + (!this.validate.IsEmpty(data['extractionCode']) ? `<div class="text-success"><i><small>${data['extractionCode']}<small></i></div>` : `<div class="text-dark"><i><small>${data[field.key]}</small></i></div>`);
                            break;
                        case 'decimalPlaces':
                            row[field.key] = data[field.key];
                            break;
                        case 'actions':
                            row[field.key] = 'Actions';
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    CanEdit() {
        return !this.props.user.IsGuest();
    }

    UpdateCodeVersionCategory(code, version, category) {
        let updatedItem = update(this.state.editItem, { code: { $set: code }, version: { $set: version }, category: { $set: category } });
        this.setState({ editItem: updatedItem });
    }

    UpdateItem(key, value) {
        let updatedItem = null;
        switch (key) {
            case "text":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { text: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { text: { $set: value } } });
                }
                break;
            case "description":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { description: { $set: { text: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { description: { $set: value } } });
                }
                break;
            case "targetMinutesDay":
                updatedItem = update(this.state.editItem, { targetMinutesDay: { $set: parseFloat(value) } });
                break;
            case "targetMinutes7Days":
                updatedItem = update(this.state.editItem, { targetMinutes7Days: { $set: parseFloat(value) } });
                break;
            case "backgroundColour":
                updatedItem = update(this.state.editItem, { backgroundColour: { $set: value } });
                break;
            case "icon":
                updatedItem = update(this.state.editItem, { icon: { $set: value } });
                break;
            default:
                break;
        }
        if (updatedItem)
            this.setState({ editItem: updatedItem });
    }

    SetStatus(status) {
        console.log("SET STATUS", status)
        let updatedItem = update(this.state.editItem, { status: { $set: status } });
        this.setState({ editItem: updatedItem });
    }

    ItemIsValid(key, setMessage) {
        let valid = true;
        let validation = null;
        let index = this.state.validation.findIndex(v => v.key === key);
        if (index > -1) {
            let message = '';
            switch (key) {
                case "text":
                    break;
                case "description":
                    break;
                case "backgroundColour":
                    break;
                default:
                    break;
            }
            if (setMessage && validation) {
                validation = update(this.state.validation, { $splice: [[{ code: key, isValid: valid, message: message }, index, 1]] });
                this.setState({ validation: validation });
            }
        }
        return valid;
    }

    GetValue(key) {
        let value = '';
        switch (key) {
            case "code":
                value = this.state.editItem.code;
                break;
            case "version":
                value = this.state.editItem.version;
                break;
            case "text":
                if (this.state.editItem.text && this.state.editItem.text.text)
                    value = this.state.editItem.text.text;
                break;
            case "description":
                if (this.state.editItem.text && this.state.editItem.text.description)
                    value = this.state.editItem.text.description;
                break;
            case "backgroundColour":
                if (this.state.editItem.backgroundColour)
                    value = this.state.editItem.backgroundColour;
                else
                    value = "#FFFFFF";
                break;
            case "targetMinutesDay":
                value = this.state.editItem.targetMinutesDay;
                break;
            case "targetMinutes7Days":
                value = this.state.editItem.targetMinutes7Days;
                break;
            case "icon":
                value = this.state.editItem.icon;
                break;
            case "category":
                value = this.state.editItem.category;
                break;
            default:
                break;
        }
        return value;
    }

    GetValidationMessage(key) {
        let message = '';
        let index = this.state.validation.findIndex(v => v.key === key);
        if (index > -1) {
            message = this.state.validation[index].message;
        }
        return message;
    }

    GetPlaceholder(key) {
        let value = '';
        switch (key) {
            case "description":
                value = "A description of this item";
                break;
            default:
                break;
        }
        return value;
    }

    GetMaxLength(key) {
        let value = 500;
        switch (key) {
            case "text":
                value = 250;
                break;
            default:
                break;
        }
        return value;
    }

    CodeOnly(value) {
        return value.replace(/[^a-z0-9 _]/gi, '').replace(' ', '_').toUpperCase();
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    SaveClick() {
        if (this.props.onSave) {
            this.setState({ isBusy: true, saveError: null });
            let valid = true;
            this.state.validation.forEach(v => {
                valid = valid & this.ItemIsValid(v.key, true);
            });
            if (this.codeVersionCategory.current)
                valid = valid & this.codeVersionCategory.current.IsValid();

            this.setState({
                isFormValidated: true,
                isFormValid: valid,
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    UpdateDataPoint(index, key, value) {
        if (index === -1) {
            switch (key) {
                case "decmalPlaces":
                    this.newDataPoint[key] = parseInt(value);
                    break;
                default:
                    this.newDataPoint[key] = value;
                    break;
            }
        } else {
            let updatedDataPoint = null;
            let clone = typeof structuredClone === 'function' ? structuredClone(this.state.editItem.dataPoints[index]) : JSON.parse(JSON.stringify(this.state.editItem.dataPoints[index]));
            switch (key) {
                case "decmalPlaces":
                    updatedDataPoint = clone[key] = parseInt(value);
                    break;
                default:
                    updatedDataPoint = clone[key] = value;
                    break;
            }
            let updatedEditItem = update(this.state.editItem, { dataPoints: { $splice: [index, 1, updatedDataPoint] } });
            this.setState({ editItem: updatedEditItem });
        }
    }

    AddDataPoint() {
        if (!this.validate.IsEmpty(this.newDataPoint.code)) {
            let index = this.state.editItem.dataPoints ? this.state.editItem.dataPoints.findIndex(dp => dp.code.trim().toUpperCase() === this.newDataPoint.code.trim().toUpperCase()) : -1;
            if (index === -1) {
                if (this.newDataPoint.extractionCode)
                    this.newDataPoint.extractionCode = '';
                if (this.validate.IsEmpty(this.newDataPoint.decimalPlaces))
                    this.newDataPoint.decimalPlaces = 0;

                let updatedEditItem;
                if (!this.state.editItem.dataPoints) {
                    updatedEditItem = update(this.state.editItem, { dataPoints: { $set: [this.newDataPoint] } });
                } else {
                    updatedEditItem = update(this.state.editItem, { dataPoints: { $push: [this.newDataPoint] } });
                }

                this.newDataPoint = {};
                this.setState({ editItem: updatedEditItem });
            } else {
                console.warn(`DataPoint Already Exists ${index}`, this.state.editItem.dataPoints[index]);
            }
        } else {
            console.warn("DataPoint must have a valid code");
        }
    }

    DeleteDataPoint(index) {
        let updatedEditItem = update(this.state.editItem, { dataPoints: { $splice: [[index, 1]] } });
        this.setState({ editItem: updatedEditItem }, () => {
            //console.log(`DELETED ${index}`, this.state.editItem.dataPoints);
        });
    }

    SaveFailed(error) {
        this.setState({ isBusy: false, saveError: error });
    }

    GetExistingCodes() {
        return this.props.existing
            .filter(item => this.props.item === undefined || !(
                item.code === this.props.item.code &&
                item.version === this.props.item.version &&
                item.category === this.props.item.category
            ))
            .map(item => `${item.code.trim().toUpperCase()}_${item.version}_${item.category.toString().trim().toUpperCase()}}`);
    }

    componentDidMount() {
        this.setState({ editIndex: this.props.index });
        if (this.props.item)
            this.setState({ editItem: this.props.item });
        if (this.props.existing)
            this.setState({ existingCodes: this.GetExistingCodes() });
    }

    componentDidUpdate(prevProps) {
        if (this.props.index !== prevProps.index)
            this.setState({ editIndex: this.props.index });
        if (this.props.item !== prevProps.item)
            this.setState({ editItem: this.props.item });
        if (this.props.existing !== prevProps.existing)
            this.setState({ existingCodes: this.GetExistingCodes() });
    }

    componentWillUnmount() {
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        return (
            <div>
                <Form noValidate>
                    {this.RenderForm()}
                </Form>
                {this.state.isBusy ?
                    <div className="busy-overlay col d-flex align-items-center justify-content-center">
                        <Spinner animation="border" role="status" variant="dark" />
                    </div>
                    : ''
                }
                {errors && (
                    <Alert variant="danger">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
            </div>
        );
    }

    RenderForm() {
        return (
            <div>
                {this.RenderCodeVersionCategory()}

                <Tabs
                    defaultActiveKey="details"
                    id="justify-tab-example"
                    className="mb-3 tabs-app"
                    justify>
                    <Tab eventKey="details" title="Details">
                        <div className="d-flex justify-content-start">
                            {this.RenderImageUpload("icon", "Icon")}
                            <div className="w-100 ps-5">
                                {this.RenderFormGroup("text", "Display Name", "text")}
                                {this.RenderTextArea("description", "Description", 4)}
                            </div>
                        </div>
                        {this.props.cognitiveItemType === "BRAINTRAINING" && (
                            <div className="d-flex justify-content-start">
                                {this.RenderFormGroup("targetMinutesDay", "Daily Target (minutes)", "number")}&nbsp;
                                {this.RenderFormGroup("targetMinutes7Days", "7 Day Target (minutes)", "number")}
                            </div>
                        )}
                        {this.RenderFormGroup("backgroundColour", "Background Colour", "color")}
                    </Tab>
                    <Tab eventKey="datapoints" title="Data Points">
                        {this.RenderDataPoints()}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    RenderDataPoints() {
        let rows = this.TabulateDataItems();
        return (
            <div>
                <p>
                    When found, the following values are <i>automatically</i> extracted and do not need to be added here as data points: SeedValue, StartTime, EndTime, SummaryScore
                    {this.props.cognitiveItemType === "BRAINTRAINING" && (
                        <span>, StartComplexity, EndComplexity</span>
                    )}
                    .
                </p>
                <Table className="noEndBorder transparent-table">
                    <tbody>
                        {rows.map((_, index) => (
                            <tr key={index}>
                                {this.state.itemFields.map((item, subindex) => (
                                    rows[index][item.key] === 'Actions' ?
                                        this.ItemRowActions(index, subindex, this.props.user.Role()) :
                                        <td key={subindex} className={item.className}>
                                            <div><span className="miniLabel">{item.label}:</span></div>
                                            <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                        </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            {this.state.itemFields.map((item, subindex) => (
                                item.key === 'actions' ?
                                    <td key={subindex} className="text-end text-nowrap pt-4"><ButtonAdd onClick={() => this.AddDataPoint()} /></td> :
                                    this.ItemRowEdit(-1, subindex, item)
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    ItemRowActions(index, subindex, role) {
        if (role === 'Developer' || role === 'Admin' || role === 'User') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DeleteDataPoint(index)} />&nbsp;
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4"></td>
            )
        }
    }

    ItemRowEdit(index, subindex, item) {
        switch (item.key) {
            case "code":
                return (
                    <td key={subindex} className={item.className}>
                        <div><span className="miniLabel">Code:</span></div>
                        <div><input id={`INPUT_${item.key}`} type="text" onChange={(event) => this.UpdateDataPoint(index, item.key, event.target.value)} /></div>
                        <div><span className="miniLabel">Extracted As:</span></div>
                        <div><input id={`INPUT_extractionCode`} type="text" onChange={(event) => this.UpdateDataPoint(index, item.key, event.target.value)} /></div>
                    </td>
                );
            case "decimalPlaces":
                return (
                    <td key={subindex} className={item.className}>
                        <div><span className="miniLabel">{item.label}:</span></div>
                        <input id={`INPUT_${item.key}`} type="number" onChange={(event) => this.UpdateDataPoint(index, item.key, event.target.value) } />
                    </td>
                );
                break;
            default:
                return (
                    <td key={subindex} className={item.className}>
                        <div><span className="miniLabel">{item.label}:</span></div>
                        <input id={`INPUT_${item.key}`} type="text" onChange={(event) => this.UpdateDataPoint(index, item.key, event.target.value)} />
                    </td>
                );
                break;
        }
    }

    RenderImageUpload(key, displayName, mandatory = false) {
        return (
            <FileUpload
                displayName={displayName}
                disabled={this.CanEdit() ? '' : 'disabled'}
                imageBase64={this.GetValue(key)}
                onUpdate={(base64Image) => { this.UpdateItem(key, base64Image) } }
            />
        );
    }

    RenderCodeVersionCategory() {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <CodeVersionCategory
                    ref={this.codeVersionCategory}
                    code={this.GetValue("code")}
                    version={this.GetValue("version")}
                    category={this.GetValue("category")}
                    existingCodes={this.state.existingCodes}
                    onChange={(code, version, category) => { this.UpdateCodeVersionCategory(code, version, category); }}
                />
                <Form.Control.Feedback type="invalid">
                    Error Message
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderSelect(key, displayName, values) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Select
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}>

                    {values.map((value, index) => (
                        <option key={`SELECT-${value.value}`} value={value.value}>{value.text}</option>
                    ))}

                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderFormGroup(key, displayName, type, mandatory = false) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Control
                    required={mandatory}
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    type={type}
                    placeholder={this.GetPlaceholder(key)}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}
                    maxLength={this.GetMaxLength(key)}
                />
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderTextArea(key, displayName, rows, mandatory = false) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Control
                    required={mandatory}
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    as="textarea" rows={rows}
                    placeholder={this.GetPlaceholder(key)}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}
                    maxLength={this.GetMaxLength(key)}
                />
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

}
