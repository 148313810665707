import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import './Home.css'

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (

            <Carousel className="full-login-page-carousel">

                <Carousel.Item className="full-login-page-carousel-item-splash" interval={5000}>
                    <div className="d-flex justify-content-center">
                        <img className="splash-logo-img" src={require(`../assets/logoSplash.svg`).default} alt="Logo" />&nbsp;
                    </div>
                    <div className="splash-img">
                        <img src={require(`../assets/splash.svg`).default} alt="Splash" />&nbsp;
                    </div>
                </Carousel.Item>

                <Carousel.Item className="full-login-page-carousel-item-explainer1" interval={15000}>
                    <div className="explainer-img">
                        <img src={require(`../assets/explainer1.svg`).default} alt="Splash" />&nbsp;
                    </div>
                    <Carousel.Caption className="carousel-caption">
                        <h3>About the study</h3>
                        <p>The study is being run by world-leading researchers at the University of Exeter and follows on from extensive research around brain function and finding ways to prevent and treat dementia.
                            The findings from this study will help us develop new and accessible ways to help the millions of people suffering with long COVID.</p>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="full-login-page-carousel-item-explainer2" interval={15000}>
                    <div className="explainer-img">
                        <img src={require(`../assets/explainer2.svg`).default} alt="Splash" />&nbsp;
                    </div>
                    <Carousel.Caption className="carousel-caption">
                        <h3>How you'll help</h3>
                        <p>
                            Over the next 26 weeks, we'll ask you to check in every few days and play a series of games. These activities will challenge, tease and test your brain as part of the largest digital study of its kind.
                            Your scores from these games will be combined and compared with the 1,600 other participants to give us a clearer picture of how we can help people with long COVID.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="full-login-page-carousel-item-explainer3" interval={15000}>
                    <div className="explainer-img">
                        <img src={require(`../assets/explainer3.svg`).default} alt="Splash" />&nbsp;
                    </div>
                    <Carousel.Caption className="carousel-caption">
                        <h3>Who can take part</h3>
                        <p>
                            You can join BEACON if:
                        </p>
                        <ol>
                            <li>You are aged 18 or over</li>
                            <li>You live in the UK</li>
                            <li>You have regular access to a smartphone, device or computer with the internet</li>
                            <li>You have had COVID-19 at least once</li>
                            <li>Since having COVID-19 you have experienced changes to your memory, concentration or other brain functions</li>
                        </ol>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="full-login-page-carousel-item-explainer4" interval={15000}>
                    <div className="explainer-img">
                        <img src={require(`../assets/explainer4.svg`).default} alt="Splash" />&nbsp;
                    </div>
                    <Carousel.Caption className="carousel-caption">
                        <h3>How the study works</h3>
                        <p>
                            You'll be assigned to one of two groups:
                        </p>
                        <ol>
                            <li>The test group. This group will be assessed at regular intervals. </li>
                            <li>The control group. This group will make sure we can prove the benefits of brain games for people with long COVID.</li>
                        </ol>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="full-login-page-carousel-item-explainer5" interval={15000}>
                    <div className="explainer-img">
                        <img src={require(`../assets/explainer5.svg`).default} alt="Splash" />&nbsp;
                    </div>
                    <Carousel.Caption className="carousel-caption">
                        <h3>Being a part of BEACON</h3>
                        <p>
                            It's an exciting opportunity to challenge yourself and play fun games while tackling a huge challenge millions of people are still struggling with to this day.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

        );
    }
}
