import React, { Component } from 'react';
import { ButtonContinueGame } from '../Buttons/ContinueGame';
import Table from 'react-bootstrap/Table';
import { Format } from '../GlobalFns'

export class CognitiveTestSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.format = new Format();
    }

    HasValidResults() {
        return this.props.resultsSummary && this.props.resultsSummary.passedCount > 0;
    }

    HasAnyResults() {
        return this.props.resultsSummary !== null;
    }

    HasInlineContinueButton() {
        return this.props.device.ShowMobileUI() && this.props.cognitiveItem && this.props.cognitiveItem.category === "PROTECT";
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {

        if (this.HasValidResults()) {

            return (
                <div className={this.props.theme.GetTextBootstrapContrastVariant()}>
                    {this.props.resultsSummary && this.props.resultsSummary.mostRecentSummaryScore > 0 && (
                        <Table className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <tbody>
                                <tr>
                                    <td><span className="jumboLabel">{this.props.resultsSummary.mostRecentSummaryScore}</span></td>
                                    <td className="align-middle w-100"><h2>Your<br />Score</h2></td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                    <h2>Summary</h2>
                    <Table className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            <tr>
                                <td>
                                    <i className="veryBigLabel bi bi-clipboard-pulse"></i>
                                </td>
                                <td className="align-middle">
                                    {this.RenderCognitiveTestTimes(this.props.totalCount)}
                                </td>
                            </tr>
                            {this.props.resultsSummary && this.props.resultsSummary.maxSummaryScore > 0 && (
                                <tr>
                                    <td>
                                        <i className="veryBigLabel bi bi-graph-up-arrow"></i>
                                    </td>
                                    <td className="align-middle">
                                        The most you have scored in this cognitive test is {this.props.resultsSummary.maxSummaryScore}.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {this.HasInlineContinueButton() && (
                        <div className="d-flex justify-content-center pt-5 pb-5">
                            <ButtonContinueGame text="Continue" variant={this.props.theme.GetPlayBackgroundVariant()} className="pt-3" onClick={() => { this.PlaySelectInstance(0) }} />
                        </div>
                    )}
                </div>
            );
        }
    }

    RenderCognitiveTestTimes(value) {
        if (value) {
            if (value === 1)
                return 'You have only taken this cognitive test once.'
            else if (value === 2)
                return `You have taken this cognitive test twice.`
            else
                return `You have taken this cognitive test ${value} times.`
        } else {
            return 'We don\'t have any results recorded for you yet.'
        }
    }

}
