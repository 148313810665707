import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { ButtonDelete } from "../Buttons/Delete";

export class FileUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            imageBase64: null,
            fileReader: null,
        };

        this.iconRef = React.createRef();
        this.iconUploadRef = React.createRef();
    }

    OpenSelectFile() {
        if (this.iconUploadRef.current) this.iconUploadRef.current.click();
    }

    async UpdateImage(files) {
        if (files && files.length > 0) {

            let imageBlobUrl = URL.createObjectURL(files[0]);
            if (imageBlobUrl) {

                await fetch(imageBlobUrl)
                    .then(async response => {
                        if (!response.ok) {
                            throw new Error("Response was not OK");
                        }
                        let imageBlob = await response.blob();
                        if (imageBlob) {

                            let fileReader = new FileReader();
                            fileReader.onloadend = this.FileLoadEnd.bind(this);
                            fileReader.onerror = this.FileLoadError.bind(this);
                            this.setState({ fileReader: fileReader }, () => {

                                this.state.fileReader.readAsDataURL(imageBlob);

                            });

                        } else {
                            console.warn("File BLOB not available", files);
                        }
                    })
                    .catch(error => {
                        console.warn("There has been a problem opening the selected file", error);
                    });
            } else {
                console.warn("File BLOB URL not available", files);
            }
        } else {
            console.warn("File not found", files);
        }
    }

    FileLoadError(error) {
        console.warn(error);
    }

    FileLoadEnd(event) {
        if (this.state.fileReader && this.state.fileReader.result) {
            let base64data = this.state.fileReader.result;
            this.setState({ imageBase64: base64data }, () => {
                if (this.props.onUpdate)
                    this.props.onUpdate(this.state.imageBase64)
            });
        }
    }

    DeleteImage() {
        this.setState({ imageBase64: '', fileReader: null }, () => {

            if (this.iconUploadRef.current) this.iconUploadRef.current.value = null;

            if (this.props.onUpdate)
                this.props.onUpdate(this.state.imageBase64)
        });
    }

    componentDidMount() {
        if (this.props.imageBase64)
            this.setState({ imageBase64: this.props.imageBase64, fileReader: null });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageBase64 !== this.props.imageBase64) 
            this.setState({ imageBase64: this.props.imageBase64, fileReader: null });
    }

    render() {
        return (
            <Form.Group className="mb-4 icon-upload" ref="code_input">
                <Form.Label><b>{this.props.displayName}</b></Form.Label>
                <img alt="" ref={this.iconRef} src={this.state.imageBase64} onClick={() => { this.OpenSelectFile() }} />
                <div className="icon-upload-delete text-align-end"><ButtonDelete onClick={() => { this.DeleteImage() }} /></div>
                <Form.Control
                    ref={this.iconUploadRef}
                    disabled={this.props.disabled}
                    type="file"
                    onChange={(event) => { this.UpdateImage(Array.from(event.target.files)); }}
                />
            </Form.Group>
        );
    }
}