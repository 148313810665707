import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import update from 'immutability-helper';
import InputGroup from 'react-bootstrap/InputGroup';

export class CodeAndVersion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            fileUploadError: null,
            editItem: {
                code: '',
                version: 0,
            },
            existingCodes: [],
        };

        this.validation = [
            { key: 'code', isValid: true, message: 'Please enter a valid code.' },
            { key: 'version', isValid: true, message: 'Please enter a valid version number.' },
        ];

    }

    UpdateItem(key, value) {
        let updatedItem = null;
        switch (key) {
            case "code":
                updatedItem = update(this.state.editItem, { code: { $set: value } });
                break;
            case "version":
                let version = !this.IsEmpty(value) ? parseFloat(value) : 0;
                updatedItem = update(this.state.editItem, { version: { $set: version } });
                break;
            default:
                break;
        }
        if (updatedItem) {
            this.setState({ editItem: updatedItem }, () => {
                this.ItemIsValid(key);
                this.Update();
            });
        }
    }

    ItemIsValid(key) {
        let valid = true;
        let value = '';
        this.validation.forEach(v => { v.message = '' });
        let index = this.validation.findIndex(v => v.key === key);
        if (index > -1) {
            let message = '';
            let version = 0;
            let codeAndVersion = '';
            switch (key) {
                case "code":
                    value = this.state.editItem.code.trim();
                    version = !this.IsEmpty(this.state.editItem.version) ? parseFloat(this.state.editItem.version) : 0;
                    codeAndVersion = `${value.toUpperCase()}_${version}`;
                    if (this.IsEmpty(value)) {
                        message = "The code cannot be blank";
                        valid = false;
                    } else if (this.state.existingCodes.includes(codeAndVersion)) {
                        message = "This code and version are already being used (either change the code or the version number)";
                        valid = false;
                    }
                    break;
                case "version":
                    version = !this.IsEmpty(this.state.editItem.version) ? parseFloat(this.state.editItem.version) : 0;
                    codeAndVersion = `${this.state.editItem.code.toUpperCase()}_${version}`;
                    if (version <= 0) {
                        message = `The version must be a valid number greater than zero`;
                        valid = false;
                    } else if (this.state.existingCodes.includes(codeAndVersion)) {
                        message = "This code and version are already being used (either change the code or the version number)";
                        valid = false;
                    }
                    break;
                default:
                    break;
            }
            this.validation[index].isValid = valid;
            this.validation[index].message = message;
        }
        return valid;
    }

    GetValue(key) {
        let value = '';
        switch (key) {
            case "code":
                value = this.state.editItem.code;
                break;
            case "version":
                value = this.state.editItem.version;
                break;
            default:
                break;
        }
        return value;
    }

    GetValidationMessage(key) {
        let message = '';
        let index = this.validation.findIndex(v => v.key === key);
        if (index > -1) {
            message = this.validation[index].message;
        }
        return message;
    }

    GetPlaceholder(key) {
        let value = '';
        switch (key) {
            case "code":
                value = "Unique code";
                break;
            default:
                break;
        }
        return value;
    }

    GetMaxLength(key) {
        let value = 500;
        switch (key) {
            case "code":
                value = 100;
                break;
            default:
                break;
        }
        return value;
    }

    GetErrorMessage() {
        let codeMessage = this.GetValidationMessage("code");
        let versionMessage = this.GetValidationMessage("version");
        if (this.IsEmpty(versionMessage))
            return `${codeMessage}`
        else
            return `${versionMessage}`
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    Update() {
        if (this.ItemIsValid("code") & this.ItemIsValid("version"))
            if (this.props.onChange)
                this.props.onChange(this.state.editItem.code, this.state.editItem.version);
    }

    IsValid() {
        let valid = this.ItemIsValid("code") & this.ItemIsValid("version");
        this.setState({ isFormValidated: true, isFormValid: valid });
        return valid;
    }

    componentDidMount() {
        this.setState({ editItem: { code: `${this.props.code ?? ''}`, version: this.props.version ?? 0 }, existingCodes: this.props.existingCodes ?? [] });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.code !== this.props.code || prevProps.version !== this.props.version)
            this.setState({ editItem: { code: `${this.props.code ?? ''}`, version: this.props.version ?? 0 }, existingCodes: this.props.existingCodes ?? [] });
    }

    render() {
        return (
            <div>
                <InputGroup>
                    <InputGroup.Text><b>Code</b></InputGroup.Text>
                    <Form.Control
                        required
                        disabled={this.props.disabled}
                        type="text"
                        placeholder={this.GetPlaceholder("code")}
                        value={this.GetValue("code")}
                        onChange={(event) => { this.UpdateItem("code", event.target.value) }}
                        isInvalid={!this.ItemIsValid("code")}
                        maxLength={this.GetMaxLength("code")}
                    />
                    <InputGroup.Text><b>Version</b></InputGroup.Text>
                    <Form.Control
                        required
                        className="narrow-input"
                        disabled={this.props.disabled}
                        type="number"
                        min="0"
                        placeholder={this.GetPlaceholder("version")}
                        value={this.GetValue("version")}
                        onChange={(event) => { this.UpdateItem("version", event.target.value) }}
                        isInvalid={!this.ItemIsValid("version")}
                        maxLength={this.GetMaxLength("version")}
                    />
                </InputGroup>
                {this.state.isFormValidated && !this.state.isFormValid && (
                    <small className="text-danger p-0 m-0">
                        {this.GetErrorMessage()}
                    </small>
                )}
            </div>
        );
    }
}
