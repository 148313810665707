import React, { Component } from 'react';
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import update from 'immutability-helper';
import { Format } from '../shared/GlobalFns';

import './Activity.css'

export class ActivityQuestionnaireResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activityResults: [],
        };

        this.Format = new Format();
        this.questionnaireApi = new QuestionnaireApi();
    }

    async GetParticipantQuestionnaireResults(activity) {

        if (activity) {
            await this.questionnaireApi.GetByCode(activity.code, activity.version, async (questionnaire) => {
                let dataQuery = {
                    pageNumber: 1,
                    itemsInPage: 10,
                    sortingCol: "ModifiedDate",
                    sortType: "DESC",
                    filter: "",
                }
                await this.questionnaireApi.GetParticipantResults(dataQuery, questionnaire, (data) => {

                    let updatedResults = [];
                    data.data.forEach(result => {

                        let occurenceInstance = result.questionnaire.instance.toString().split(".");
                        let occurrence = parseInt(occurenceInstance[0]);
                        let instance = 0;
                        if (occurenceInstance.length > 1)
                            instance = parseInt(occurenceInstance[1]);

                        if (this.props.activity.occurrence === occurrence) {

                            let activityToDisplay = typeof structuredClone === 'function' ? structuredClone(this.props.activity) : JSON.parse(JSON.stringify(this.props.activity));
                            activityToDisplay.status = result.status;
                            activityToDisplay.occurrence = occurrence;
                            activityToDisplay.instance = instance;
                            activityToDisplay.statusDate = result.modifiedDate;
                            updatedResults.push(activityToDisplay);

                        }

                    });

                    this.setState({ activityResults: updatedResults }, () => {
                        //console.log(`Results for questionnaire ${activity.code}`, this.state.activityResults);
                    });

                }, (errors) => {
                    console.warn("GetParticipantQuestionnaireResults", errors);
                })
            }, (errors) => {
                console.warn("GetParticipantQuestionnaire", errors);
            });
        }
    }

    OpenActivity(result) {

        // Build Activity
        if (this.props.onOpenActivity) {
            let activityToOpen = update(this.props.activity, { instance: { $set: result.instance } });
            this.props.onOpenActivity(activityToOpen);
        }

    }

    LoadPage() {
        if (this.props.activity)
            this.GetParticipantQuestionnaireResults(this.props.activity);
    }

    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activity && this.props.activity && (this.props.activity.code !== prevProps.activity.code || this.props.activity.version !== prevProps.activity.version))
            this.LoadPage();
    }

    render() {
        let resultsToDisplay = [];
        if (this.state.activityResults.length > 0) {
            this.state.activityResults.forEach((result, index) => {

                resultsToDisplay.push(

                    <div key={`Q_RESULT_${this.props.activity.code}_${index}`} className="d-flex justify-content-start">
                        <div onClick={() => this.OpenActivity(result)}>{this.RenderActivityIcon(result)}</div>
                        <div className=" pt-1 ps-2 w-100">

                            <div onClick={() => this.OpenActivity(result)}>
                                <span>{this.Format.FormatDateTime(result.statusDate)}</span>
                            </div>

                        </div>
                    </div>

                );

            });
        }
        if (resultsToDisplay.length > 0) {
            return (<div className="pt-2">{resultsToDisplay}</div>);
        } else {
            return (<div></div>);
        }
    }

    RenderActivityIcon(activity) {
        let icon = (<span className="bi bi-lock-fill activity-danger"></span>);
        switch (activity.status) {
            case "PENDING":
                icon = (<span className="bi bi-lock-fill activity-warning"></span>)
                break;
            case "TRIGGERED":
                icon = (<span className="bi bi-hourglass-split activity-warning"></span>)
                break;
            case "OPEN":
                icon = (<span className="bi bi-play-circle-fill activity-info"></span>)
                break;
            case "ACTIVE":
                icon = (<span className="bi bi-pencil-square activity-info"></span>)
                break;
            case "COMPLETED":
            case "PASSED":
                icon = (<span className="bi bi bi-check-circle-fill activity-success"></span>)
                break;
            case "FAILED":
                icon = (<span className="bi bi-x-circle-fill activity-danger"></span>)
                break;
            case "CLOSED":
            case "EXPIRED":
                icon = (<span className="bi bi-exclamation-triangle-fill activity-danger"></span>)
                break;
            default:
                icon = (<span className="bi bi-lock-fill activity-danger"></span>)
                break;
        }
        return (<h3>{icon}</h3>)
    }

}
