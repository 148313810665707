import React, { Component } from "react";
import './MuteToggle.css'

export class ButtonMuteToggle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMuted: false,
        }
    }

    MuteClick() {
        this.setState({ isMuted: true }, () => {
            if (this.props.onMute)
                this.props.onMute();
        });
    }

    UnmuteClick() {
        this.setState({ isMuted: false }, () => {
            if (this.props.onUnmute)
                this.props.onUnmute();
        })
    }

    render() {
        if (!this.state.isMuted) {

            return (
                <div className="mute-toggle" onClick={() => { this.MuteClick() }}>
                    <span className="ps-1 bi bi-volume-up"></span>
                </div>
            );

        } else {

            return (
                <div className="mute-toggle" onClick={() => { this.UnmuteClick() }}>
                    <span className="ps-1 bi bi-volume-mute-fill"></span>
                </div>
            );

        }
    }

}
