import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
export class ButtonContinueGame extends Component {

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    GetBtnVariant() {
        if (this.props.variant) {// Light or Dark
            switch (this.props.variant.toLowerCase()) {
                case 'dark':
                    return 'btn-light';
                case 'light':
                    return 'btn-dark';
                default:
                    return 'btn-light';
            }
        }
        else
            return 'btn-light';
    }

    render() {

        return (
            <button type="button" className={`text-nowrap btn btn-sm ${this.GetBtnVariant()} rounded-pill wide-button`} disabled={ this.props.disabled ?? false } onClick={() => { this.ButtonClick() }}>
                {this.props.text &&
                    <small>{this.props.text}&nbsp;</small>
                }
            </button>
        );
    }

}
