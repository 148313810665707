import React, { Component } from "react";
import { Validate } from '../../shared/GlobalFns'

export class QuestionSubmit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    SetSubmitResults() {
        let result = { questionCode: this.props.question.code, answer: 'OK', isValid: true };
        if (this.props.onUpdate)
            this.props.onUpdate(result);

    //    console.log("SUBMIT", this.props.result);
    //    if (this.props.questionnaire && this.props.questionnaire.questionGroups && this.props.questionnaire.questionGroups.length > 0) {
    //        this.props.questionnaire.questionGroups.forEach((questionGroup) => {
    //            if (questionGroup.questions && questionGroup.questions.length > 0) {
    //                questionGroup.questions.filter(question => question.questionType === "Submit").forEach(question => {
    //                    let result = { questionCode: question.code, answer: question.code === this.props.question.code ? 'OK' : '', isValid: true };
    //                    if (this.props.onUpdate)
    //                        this.props.onUpdate(result);
    //                });
    //            }
    //        });
    //    }
    }

    QuestionContainerClass() {
        if (this.props.device.IsPhone())
            return "pb-3 pt-3 ps-2 pe-1"
        else
            return "p-3"
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className={this.QuestionContainerClass()} >
                <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
            </div>
        );
    }

}

