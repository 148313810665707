import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Dropdown from 'react-bootstrap/Dropdown';
export class ComplexitySelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            complexity: 1,
            maxComplexity: 1,
            levels: null,
        };

        this.levels = [
            { text: "EASY", value: 1, className: "text-nowrap btn btn-sm btn-success rounded-pill white" },
            { text: "MEDIUM", value: 300, className: "text-nowrap btn btn-sm btn-info rounded-pill white" },
            { text: "HARD", value: 500, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
            { text: "DIFFICULT", value: 750, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
            { text: "IMPOSSIBLE", value: 999, className: "text-nowrap btn btn-sm btn-danger rounded-pill white" }
        ];
    }

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    GetAvailableLevels(maxComplexity) {
        if (maxComplexity && maxComplexity > 0)
            return this.state.levels.filter(s => s.value <= maxComplexity);
        else
            return this.state.levels;
    }

    GetLevel(complexity) {
        let level = this.state.levels[0];
        if (complexity && complexity > 0) {
            let levels = this.state.levels.filter(s => s.value <= complexity);
            if (levels.length > 0)
                level = levels[levels.length - 1]
        }
        return level;
    }

    Update(levels) {
        this.setState({ complexity: levels.value }, () => {
            if (this.props.onUpdated)
                this.props.onUpdated(this.state.complexity);
        });
    }

    componentDidMount() {
        if (this.props.levels)
            this.setState({ complexity: this.props.complexity, maxComplexity: this.props.maxComplexity, levels: this.props.levels })
        else
            this.setState({ complexity: this.props.complexity, maxComplexity: this.props.maxComplexity, levels: this.levels })
    }

    componentDidUpdate(prevProps) {
        if (this.props.complexity !== prevProps.complexity || this.props.maxComplexity !== prevProps.maxComplexity || this.props.levels !== prevProps.levels) {
            if (this.props.levels)
                this.setState({ complexity: this.props.complexity, maxComplexity: this.props.maxComplexity, levels: this.props.levels })
            else
                this.setState({ complexity: this.props.complexity, maxComplexity: this.props.maxComplexity, levels: this.levels })
        }
    }

    render() {
        if (this.state.levels) {
            let currentLevel = this.GetLevel(this.state.complexity);
            let availableLevels = this.GetAvailableLevels(this.state.maxComplexity);
            if (availableLevels.length > 1) {
                return (
                    <Dropdown>
                        <Dropdown.Toggle className={currentLevel.className} id="dropdown-basic">
                            {currentLevel.text}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {availableLevels.map((level, index) => (
                                <Dropdown.Item onClick={() => { this.Update(level) }} key={index}>{level.text}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            } else {
                let availableLevel = availableLevels[0];
                return (
                    <button size="sm" type="button" className={availableLevel.className} onClick={() => { this.ButtonClick() }}>
                        <small>{availableLevel.text}</small>
                    </button>
                );
            }
        } else {
            return (<div></div>);
        }
    }

}
