import React, { Component } from 'react';
import { ButtonDownload } from './shared/Buttons/Download'
import Table from 'react-bootstrap/Table';

export class Reports extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="flex-fill">

                <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                    <thead>
                        <tr>
                            <th colSpan="2">
                                <h1>Reports</h1>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <h2>Participant Status</h2>
                                <p>Full list of every participant and their progress through the study.</p>
                            </td>
                            <td className="text-center align-middle">
                                <ButtonDownload reportName="BeaconStatus" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h2>Recruitment Source</h2>
                                <p>Where did participants hear about the study.</p>
                            </td>
                            <td className="text-center align-middle">
                                <ButtonDownload reportName="BeaconSource" />
                            </td>
                        </tr>
                    </tbody>
                </Table>

            </div>
        );
    }
}
