import React, { Component } from "react";
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { DiploriaDiv } from '../shared/CognitiveItem/DiploriaDiv';
import { Validate } from '../shared/GlobalFns';
import { BatteryApi } from '../shared/CTAGApi';
import { ModalConfirm } from '../shared/ModalConfirm';

export class PlayDiploriaBattery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            battery: {
                id: -1,
                code: '',
                version: 1,
                text: {
                    text: '',
                    description: '',
                },
                cognitiveItems: [],
                dataPoints: [],
            },
            batteryIndex: -1,
            pageErrors: null,
            options: {
                begin: false,
                done: false,
            },
            results: {},
            resultFields: [
                { key: 'code', label: 'Data Point', className: 'ps-3 text-start' },
                { key: 'value', label: 'Value', className: 'pe-3 text-end' },
            ],
            showModal: {
                confirmEndSession: false,
            },
            cognitiveItemResultsCount: 0,
            taskSequence: {},
            instructions: "",
            instructionsShown: false,
            sessionStarted: false,
        };

        this.batteryApi = new BatteryApi();
        this.diploriaDivRef = React.createRef();
        this.Validate = new Validate();

    }

    async GetBattery(code, version, results) {

        await this.batteryApi.GetByCode(code, version, (data) => {

            let taskSequence = this.GetTaskSequence(data, results);
            let instructions = this.GetInstructions(data, results);
            let noInstructions = this.Validate.IsEmpty(instructions);

            this.setState({ battery: data, results: results, taskSequence: taskSequence, sessionStarted: false, instructions: instructions, instructionsShown: noInstructions ? true : false }, () => {
                this.SetOptions();
                if (noInstructions) {
                    this.BeginSession();
                }
            });

        }, (errors) => {

            console.debug("GetBattery Error", errors)
            this.setState({ pageErrors: errors });

        })

    }

    async GetResults(code, version, instance) {
        if (code && version) {
            await this.batteryApi.GetResults(code, version, instance, (data) => {

                // Add in stage code if applicable (ie. running in schedule)
                if (this.props.stageCode)
                    data.battery.stageCode = this.props.stageCode;

                if (this.props.inputType)
                    data.battery.inputType = this.props.inputType;
                if (this.props.mode)
                    data.battery.mode = this.props.mode;
                
                this.GetBattery(code, version, data)

            }, (errors) => {

                console.debug("GetResults Error", errors)
                this.setState({ pageErrors: errors });
                this.GetBattery(code, version, null)

            })
        }
    }

    // TODO - Add all previous results to upload incase there is a drop in internet connection (seeing this occasionally)
    SaveResults(rawData, isError = false) {

        let cognitiveItem = null;
        let cognitiveItemIndex = this.state.battery.cognitiveItems.findIndex(c => c.code === rawData.taskId);
        if (cognitiveItemIndex > -1) cognitiveItem = this.state.battery.cognitiveItems[cognitiveItemIndex];

        if (cognitiveItem) {

            let numberOfResults = this.state.cognitiveItemResultsCount > 0 ? this.state.cognitiveItemResultsCount + 1 : this.state.results.cognitiveItems.length + 1;
            this.setState({ isBusy: true, pageErrors: null, cognitiveItemResultsCount: numberOfResults }, async () => {

                let uploadResults = null;
                if (isError)
                    uploadResults = update(this.state.results, { cognitiveItem: { $set: cognitiveItem }, rawData: { $set: rawData }, exception: { $set: rawData.exception ?? 'Unexpected error occurred in javascript.' } });
                else
                    uploadResults = update(this.state.results, { cognitiveItem: { $set: cognitiveItem }, rawData: { $set: rawData } });

                await this.batteryApi.SaveResults(uploadResults, (data) => {

                    // Update number of battery results on screen... once
                    if (this.state.cognitiveItemResultsCount === 1 && this.props.onSavedBatteryResults) {
                        this.props.onSavedBatteryResults(this.state.results);
                    }

                    if (data.isCompleted) {
                        console.debug("window.document.stopDiploria");
                        window.document["stopDiploria"]();
                    }

                    if (this.props.onComplete && (data.isCompleted || data.exception)) {
                        this.props.onComplete()
                    } else {

                        this.setState({ pageErrors: null, isBusy: false, results: data }, () => {
                            console.debug("Updated Results", this.state.results);
                            this.SetOptions();
                        });

                    }

                }, (errors) => {

                    console.warn("Save Results Error", errors);
                    this.setState({ pageErrors: errors, isBusy: false });

                })

            });
        }

    }

    Exists(value) {
        return !!value && value !== undefined;
    }

    GetInstructions(battery, results) {

        let instructions = "";
        let practiceMode = this.props.mode === "PRACTICE";

        if (!this.Exists(results)) {

            if (practiceMode)
                instructions = battery.text.practiceInstructions;
            else
                instructions = battery.text.instructions;

        } else {

            if (results.status === "ACTIVE" && battery.text && battery.text.resumeInstructions) {

                if (practiceMode)
                    instructions = battery.text.resumePracticeInstructions;
                else
                    instructions = battery.text.resumeInstructions;

            } else if ((results.status === "PENDING" || results.status === "OPEN") && battery.text && battery.text.instructions) {

                if (practiceMode)
                    instructions = battery.text.practiceInstructions;
                else
                    instructions = battery.text.instructions;

            } else if (results.status === "EXPIRED") {

                if (battery.text && battery.text.expiredText) 
                    instructions = battery.text.expiredText;
                else
                    instructions = "This session has expired."

            }
        }
        return instructions;

    }

    GetTaskSequence(battery, results) {

        let practiceMode = this.props.mode === "PRACTICE";

        let taskSequence = [];
        if (battery && battery.cognitiveItems && battery.cognitiveItems.length > 0) {
            battery.cognitiveItems.forEach((cognitiveItem, index) => {

                if (!results || !results.cognitiveItems || results.cognitiveItems.findIndex(ci => ci.code === cognitiveItem.code && ci.version === cognitiveItem.version) === -1) {

                    let task = { "taskId": cognitiveItem.code };
                    switch (cognitiveItem.code) {
                        case 'picture-presentation':

                            // Practice: pictureSet = 0 (a reduced number of images)
                            task.pictureSet = practiceMode ? 0 : ((this.GetSeedValue() - 1) % 5) + 1;
                            break;

                        case 'picture-recognition':

                            // Practice: pictureSet = 0 (a reduced number of images)
                            task.inputType = this.props.inputType.toLowerCase();
                            task.pictureSet = practiceMode ? 0 : ((this.GetSeedValue() - 1) % 5) + 1;
                            break;

                        case 'verbal-reasoning':

                            // Practice: duration = number of seconds
                            if (practiceMode) task.taskDuration = 25;
                            break;

                        case 'digit-span':
                        case 'paired-associate-learning':
                        case 'self-ordered-search':

                            // Practice: duration = number of levels
                            if (practiceMode) task.taskDuration = 2;
                            break;

                        case 'simple-reaction-time':
                        case 'choice-reaction-time':

                            // Practice: duration = number of stimuli
                            task.inputType = this.props.inputType.toLowerCase();
                            if (practiceMode) task.taskDuration = 5;
                            break;

                        case 'digit-vigilance':

                            // Practice: duration = total number of digits shown in sequences
                            task.inputType = this.props.inputType.toLowerCase();
                            if (practiceMode) task.taskDuration = 40;
                            break;

                        default:

                            if (practiceMode) task.taskDuration = 5;
                            if (task.inputType !== "BOTH")
                                task.inputType = this.props.inputType.toLowerCase();
                            break;
                    }
                    taskSequence.push(task);

                }

            });

        }
        return taskSequence;
    }

    BeginSession() {
        this.setState({ instructionsShown: true }, () => {

            this.SetOptions(() => {
                if (this.diploriaDivRef.current) {
                    this.diploriaDivRef.current.StartTaskSequence(this.state.taskSequence);
                }
            });

        })
    }

    SetOptions(callBackFn) {

        let results = this.state.results;
        let startSession = false;
        let resumeSession = false;
        //let endSession = false;
        let back = false;

        if (!this.Exists(results)) {

            back = true;

        } else {
            if (results.status === "ACTIVE") {

                if (!this.state.instructionsShown) {
                    startSession = true;
                    back = true;
                } else {
                    //endSession = true;
                }

            } else if (results.status === "PENDING" || results.status === "OPEN") {

                if (!this.state.instructionsShown) {
                    startSession = true;
                    back = true;
                } else {
                    //endSession = true;
                }

            } else if (results.status === "EXPIRED" || results.status === "PASSED" || results.status === "FAILED" || results.status === "COMPLETED") {

                back = true;

            }
        }
        let options = { startSession: startSession, resumeSession: resumeSession, endSession: false, back: back }; // endSession not required by Diploria batteries

        this.setState({ options: options }, () => {
            //console.debug("OPTIONS", options)
            if (callBackFn)
                callBackFn();
            if (this.props.onUpdateOptions) {
                this.props.onUpdateOptions(options);
            }
        });

    }

    StartedSession() {
        this.setState({ sessionStarted: true });
    }

    EndSessionTitle() {
        if (this.state.battery && this.state.battery.text && this.state.battery.text.confirmEndSessionTitle)
            return this.state.battery.text.confirmEndSessionTitle;
        else
            return "End Session";
    }

    EndSessionText() {
        if (this.state.battery && this.state.battery.text && this.state.battery.text.confirmEndSessionText)
            return this.state.battery.text.confirmEndSessionText;
        else
            return "<p>If you end this session you will need to complete it as soon as possible for the results to be accurate.</p><p><b>After 12 hours the session will expire and you will not be able to resume.</b></p><p>Are you sure you want to end this session now?</p>";
    }

    EndSession() {
        if (this.state.sessionStarted) {

            let modalState = update(this.state.showModal, { confirmEndSession: { $set: true } });
            this.setState({ showModal: modalState });

        } else {

            this.EndSessionConfirm();

        }
    }

    EndSessionConfirm() {
        let modalState = update(this.state.showModal, { confirmEndSession: { $set: false } });
        this.setState({ showModal: modalState }, () => {
            if (this.diploriaDivRef.current)
                this.diploriaDivRef.current.CloseTaskSequence();
            if (this.props.onSessionEnded) {

                // Delay to avoid click registering on results page and causing the results for a test to be immediately shown
                setTimeout(() => {
                    this.props.onSessionEnded();
                }, 200);

            }
        });
    }

    EndSessionModalClose() {
        let modalState = update(this.state.showModal, { confirmEndSession: { $set: false } });
        this.setState({ showModal: modalState });
    }

    TabulateResultsData() {
        let rows = [];
        if (this.state.results.dataPoints) {
            Array.prototype.forEach.call(this.state.results.dataPoints, (data) => {
                let row = {};
                this.state.resultFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                            row[field.key] = data['cognitiveItemCode'] ? `${data['cognitiveItemCode']} - ${data[field.key]}` : data[field.key];
                            break;
                        case 'value':
                            row[field.key] = data[field.key];
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    FormatDateTime(value, locale) {
        if (value) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            var date = new Date(value);
            return date.toLocaleDateString(locale ?? "en-GB", options);
        } else {
            return "-";
        }
    }

    GetSeedValue() {
        let seedValue = 1;
        if (this.state.results && this.state.results.seedValue) {
            seedValue = this.state.results.seedValue;
        }
        return seedValue;
    }

    ShowProgress() {
        return this.ProgressTotalPages() > 1;
    }

    ProgressTotalPages() {
        if (this.state.battery && this.state.battery.cognitiveItems) {
            return this.state.battery.cognitiveItems.length;
        } else {
            return 1;
        }
    }

    ProgressCurrentPage() {
        if (this.state.results.cognitiveItems) {
            return this.state.results.cognitiveItems.length + 1;
        } else {
            return 1;
        }
    }

    ProgressPrecentage() {
        let totalPages = this.ProgressTotalPages();
        let currentPage = this.ProgressCurrentPage();
        return totalPages > 1 ? currentPage !== totalPages && !this.state.options.save ? currentPage * 100 / totalPages : 100 : 0;
    }

    ProgressText() {
        let totalPages = this.ProgressTotalPages();
        let currentPage = this.ProgressCurrentPage();
        return totalPages > 1 ? `${currentPage} of ${totalPages}` : '';
    }

    componentDidMount() {
        this.setState({ batteryIndex: this.props.index });
        this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    componentDidUpdate(prevProps) {
        if (this.props.code !== prevProps.code || this.props.version !== prevProps.version || this.props.instance !== prevProps.instance || this.props.mode !== prevProps.mode)
            this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    render() {

        let pageItems = [];
        if (this.Exists(this.state.pageErrors) || !this.Exists(this.state.results)) {
            pageItems.push(

                <div key="PAGE_SECTION_ERRORS" className="p-5">
                    <h1>Sorry!</h1>
                    {this.Exists(this.state.pageErrors) && this.state.pageErrors.map((item, index) => (<div key={index}>{item}</div>))}
                </div>

            );

        } else if (this.state.results && (this.state.results.isCompleted || this.state.results.exception || this.state.results.status === "PASSED" || this.state.results.status === "FAILED")) {

            pageItems.push(
                <div key="PAGE_SECTION_RESULTS" className="p-4">
                    {this.RenderResults()}
                </div>

            );

        } else if (!this.state.instructionsShown) {

            pageItems.push(

                <div key="PAGE_SECTION_INSTR" className="p-5">
                    <div dangerouslySetInnerHTML={{ __html: this.state.instructions }}></div>
                </div>

            );

        } else if (this.state.taskSequence && this.state.taskSequence.length > 0) {

            if (this.ShowProgress())
                pageItems.push(
                    <div key="PAGE_SECTION_PROGRESS" className="px-3 py-1">
                        <ProgressBar now={this.ProgressPrecentage()} label={this.ProgressText()} />
                    </div>
                );

            pageItems.push(
                <DiploriaDiv
                    key="PAGE_SECTION_DIPLORIA"
                    ref={this.diploriaDivRef}

                    theme={this.props.theme}
                    user={this.props.user}
                    device={this.props.device}

                    onQuit={() => { this.EndSession(); }}
                    onComplete={(results) => { this.SaveResults(results, false); }}
                    onError={(error) => { this.SaveResults(error, true); }}
                    onStarted={() => { this.StartedSession() }}
                />
            );

        }

        pageItems.push(
            <ModalConfirm
                key="PAGE_SECTION_CONFIRM"
                ref={this.confirmEndSessionModal}
                show={this.state.showModal.confirmEndSession}
                onCancel={() => this.EndSessionModalClose()}
                onConfirm={() => this.EndSessionConfirm()}
                title={this.EndSessionTitle()}
                message={this.EndSessionText()}
                device={this.props.device}
                user={this.props.user}
                theme={this.props.theme}
            />
        );

        return pageItems;
    }

    RenderResults() {
        return (
            <div>
                <h1>Completed</h1>
                <p>Thank you for completing the FLAME battery of cognitive tests.</p>
                <p><i>We don't display your results here as the "scores" for these tests are calculated later when all the study results are analysed together.</i></p>
            </div>
        );
    }
}
