import React, { Component } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './SideBar.css';

export class SideBar extends Component {
    static displayName = SideBar.name;

    constructor(props) {
        super(props);

        this.batteryIcon = require(`../assets/Flame.svg`).default;
        this.cognitiveItemIcon = require(`../assets/HeadGuage.svg`).default;
        this.packageIcon = require(`../assets/GamesBox.svg`).default;
        this.gameIcon = require(`../assets/Puzzle.svg`).default;

    }

    RandomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    render() {
        if (this.props.user) {
            if (this.props.user.IsDeveloper())
                return this.renderDevSidebar();
            else
                return this.renderAdminSidebar();
        }
    }

    renderAdminSidebar() {
        return (
            <div className="sidebar-app-admin container">

                {this.RenderIconMenu("/DashboardAdmin", "bi bi-person-circle", "Dashboard")}
                {this.RenderIconMenu("/StudyAdmin", "bi bi-gear-fill", "Study")}
                {this.RenderIconMenu("/Reports", "bi bi-cloud-download", "Reports")}

                <hr className="sidebar-divider" />

                {this.RenderIconMenu("/Randomisation", `app-nav-icon bi bi-dice-${this.RandomInt(1, 6)}`, "Randomisation")}

                <hr className="sidebar-divider" />

                {this.RenderIconMenu("/Questionnaires", "bi bi-question-circle-fill", "Questionnaires")}
                {this.RenderIconMenu("/GPSurgeries", "bi bi-heart-pulse", "GPs")}

                <hr className="sidebar-divider" />

                {this.RenderImgMenu("/Batteries", this.batteryIcon, "Batteries")}
                {this.RenderImgMenu("/CognitiveTests", this.cognitiveItemIcon, "Tests")}

                <hr className="sidebar-divider" />

                {this.RenderImgMenu("/Packages", this.packageIcon, "Packages")}
                {this.RenderImgMenu("/BrainTrainingGames", this.gameIcon, "Games")}

            </div>
        );

    }

    renderDevSidebar() {
        return (
            <div className="sidebar-app-admindev container">

                {this.RenderIconMenu("/DashboardAdmin", "bi bi-person-circle", "Dashboard")}
                {this.RenderIconMenu("/StudyAdmin", "bi bi-gear-fill", "Study")}
                {this.RenderIconMenu("/Reports", "bi bi-cloud-download", "Reports")}

                <hr className="sidebar-divider" />

                {this.RenderIconMenu("/Randomisation", `app-nav-icon bi bi-dice-${this.RandomInt(1, 6)}`, "Randomisation")}

                <hr className="sidebar-divider" />

                {this.RenderIconMenu("/Questionnaires", "bi bi-question-circle-fill", "Questionnaires")}
                {this.RenderIconMenu("/GPSurgeries", "bi bi-heart-pulse", "GPs")}

                <hr className="sidebar-divider" />

                {this.RenderImgMenu("/Batteries", this.batteryIcon, "Batteries")}
                {this.RenderImgMenu("/CognitiveTests", this.cognitiveItemIcon, "Tests")}

                <hr className="sidebar-divider" />

                {this.RenderImgMenu("/Packages", this.packageIcon, "Packages")}
                {this.RenderImgMenu("/BrainTrainingGames", this.gameIcon, "Games")}

                <hr className="sidebar-divider" />

                {this.RenderIconMenu("/Users", "bi bi-people-fill", "Users")}

            </div>
        );
    }

    RenderIconMenu(href, icon, text) {
        return (
            <OverlayTrigger placement="right" overlay={<Tooltip>{text}</Tooltip>}>
                <ul className="nav nav-bg sidebar sidebar-dark">
                    <li className="nav-item">
                            <a className="nav-link" href={href}>
                                <span className={`app-nav-icon ${icon}`}></span>
                            </a>
                    </li>
                </ul>
            </OverlayTrigger>
        );
    }

    RenderImgMenu(href, src, text) {
        return (
            <OverlayTrigger placement="right" overlay={<Tooltip>{text}</Tooltip>}>
                <ul className="nav nav-bg sidebar sidebar-dark">
                    <li className="nav-item">
                        <a className="nav-link" href={href}>
                            <img src={src} alt={text} className="app-nav-img" />
                        </a>
                    </li>
                </ul>
            </OverlayTrigger>
        );
    }
}
