import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate } from '../../shared/GlobalFns'

export class QuestionMultiText extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(value) {
        let result = { questionCode: this.props.question.code, answer: `${value}`, isValid: this.IsValid(value) };
        this.setState({ result: result }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return true;
        else
            return !this.validate.IsEmpty(value) && this.IsValidFormat(value);
    }

    IsValidFormat(value) {
        let valid = true;
        if (this.props.format) {
            let regExp = new RegExp(this.props.format);
            valid = regExp.test(this.Value());
        }
        return valid;
    }

    MaxLength() {
        let maxLength = 1000;
        if (this.props.question && this.props.question.validation && this.props.question.validation.max)
            maxLength = this.props.question.validation.max;
        return maxLength;
    }

    Value() {
        if (this.state.result && this.state.result.answer)
            return this.state.result.answer
        else
            return "";
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className="p-4">
                {this.props.isNested && (
                    <b><small dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></small></b>
                )}
                {!this.props.isNested && (
                    <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
                )}
                <div className="d-flex justify-content-end p-2">
                    <Form.Group className="mb-2 w-100" ref="code_input">
                        <Form.Control
                            as="textarea" rows={3}
                            maxLength={this.MaxLength()}
                            value={this.Value()}
                            onChange={(event) => this.UpdateResult(event.target.value)}
                        />
                    </Form.Group>
                </div>
            </div>
        );
    }

}
