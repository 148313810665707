import React, { Component } from 'react';
import { StudyApi } from './shared/BEACONApi';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { PlayQuestionnaireModal } from './Questionnaire/PlayQuestionnaireModal';
import { PlayBatteryModal } from './Battery/PlayBatteryModal';
import Accordion from 'react-bootstrap/Accordion';
import { Format } from './shared/GlobalFns';
import { CountDown } from './shared/CountDown';
import { ActivityList } from './Activities/ActivityList'

import './MyDetails.css'

import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

export class MyDetails extends Component {
    static displayName = MyDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            schedule: null,
            isBusy: true,
            selectedActivity: null,
            showModal: {
                playQuestionnaire: false,
                playBattery: false,
            },
            scrollPosition: 0,
            isAuthenticated: false,
            userName: null,
        }

        this.StudyApi = new StudyApi();
        this.Format = new Format();
    }

    GetData() {

        this.setState({ isBusy: true }, () => {
            if (this.props.getSchedule) {
                this.props.getSchedule((data) => {

                    this.setState({ scrollPosition: window.pageYOffset, schedule: data, isBusy: false }, () => {
                        //console.log("SCROLL")
                        window.scrollTo(0, this.state.scrollPosition);
                    });

                }, (errors) => {

                    this.setState({ isBusy: false });

                });

            } else {
                this.setState({ isBusy: false });
            }
        });

    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    OpenActivity(activity) {
        //console.log("OPEN ACTIVITY", activity);
        switch (activity.type) {
            case "QUESTIONNAIRE":
                this.PlayQuestionnaire(activity);
                break;
            case "BATTERY":
                this.PlayBattery(activity);
                break;
            case "PACKAGE":
                break;
            default:
                break;
        }
    }

    PlayQuestionnaire(activity) {
        this.setState({ showModal: { playQuestionnaire: true }, selectedActivity: activity })
    }

    PlayQuestionnaireClose() {
        this.setState({ showModal: { playQuestionnaire: false }, selectedActivity: null })
        this.GetData();
    }

    PlayBattery(activity) {
        this.setState({ showModal: { playBattery: true }, selectedActivity: activity })
    }

    PlayBatteryClose() {
        this.setState({ showModal: { playBattery: false }, selectedActivity: null })
        this.GetData();
    }

    StageIsActiveOrOpen(stage) {
        return stage.status === "ACTIVE" ||
            stage.status === "OPEN";
    }

    LoadPage() {
        this.setState({ schedule: this.props.schedule, isBusy: false }, () => {
            //console.log("OPEN SCHEDULE", this.state.schedule);
        });
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule !== prevProps.schedule)
            this.LoadPage();
    }

    render() {

        if (this.state.isBusy) {

            return (
                <div></div>
            )

        } else if (this.state.schedule) {

            return (
                <div className="flex-fill">

                    <ActivityList
                        schedule={this.props.schedule}
                        dashboardType="ALWAYS"
                        owner="PARTICIPANT"
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        onSelectActivity={(activity) => this.OpenActivity(activity)}
                    />

                    {/*<ActivityList*/}
                    {/*    schedule={this.props.schedule}*/}
                    {/*    dashboardType="STUDY"*/}
                    {/*    user={this.props.user}*/}
                    {/*    theme={this.props.theme}*/}
                    {/*    device={this.props.device}*/}
                    {/*    onSelectActivity={(activity) => this.OpenActivity(activity)}*/}
                    {/*/>*/}

                    <div className="dashboard-item">
                        <Accordion className="accordian-dashboard" defaultActiveKey="1">
                            <Accordion.Item className="accordian-item-dashboard w-100" eventKey="1">
                                <Accordion.Header className="accordian-header-dashboard">
                                    <div>
                                        <h4>Account</h4>
                                        <p>Manage your account</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="accordian-body-dashboard">
                                    <div>
                                        {this.RenderActivity("Manage", "Manage your profile data and change your password.", () => { window.location.replace(`${ApplicationPaths.Profile}`) })}
                                        {this.RenderActivity("Logout", "Logout of BEACON", () => { window.location.replace(`${ApplicationPaths.LogOut}`) })}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>


                    <div className="dashboard-item">
                        <Accordion className="accordian-dashboard" defaultActiveKey="1">
                            <Accordion.Item className="accordian-item-dashboard w-100" eventKey="1">
                                <Accordion.Header className="accordian-header-dashboard">
                                    <div>
                                        <h4>BEACON</h4>
                                        <p>About the BEACON study</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="accordian-body-dashboard">
                                    <div>
                                        {this.RenderActivity("Participant Information Sheet", "Click here to download the participant information sheet. This is a PDF version of the 'online' version is included in the consent form.", () => { window.location.replace(`/PIS/BEACON_Participant Information Sheet_v3.0_09JAN2024.pdf`) })}
                                        {this.RenderActivity("Frequently asked questions", "Find answers to questions we are frequently asked.", () => { window.location.replace(`/faq`) })}
                                        {this.RenderActivity("Privacy Notice", "Find out how we use the data we collect from you during the BEACON study.", () => { window.location.replace(`/privacy`) })}
                                        {this.RenderActivity("Terms and Conditions", "Terms and conditions...", () => { window.location.replace(`/terms`) })}
                                        {this.RenderActivity("Contact Us", "Useful phone, email and address details.", () => { window.location.replace(`/contactDetails`) })}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <PlayQuestionnaireModal
                        show={this.state.showModal.playQuestionnaire}
                        activity={this.state.selectedActivity}
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        onHide={() => this.PlayQuestionnaireClose()}
                    />

                    <PlayBatteryModal
                        show={this.state.showModal.playBattery}
                        activity={this.state.selectedActivity}
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        onHide={() => this.PlayBatteryClose()}
                    />

                </div>
            );
        }
    }

    RenderActivity(name, text, action) {
        return (
            <Card className="clickable-card m-1 p-0">
                <Card.Body className="p-2" onClick={() => { if (action) action(); }}>
                    <Card.Text as="div">

                        <div className="d-flex justify-content-start">
                            <h1 className="bi bi-play-circle-fill activity-info"></h1>
                            <div className="ps-3 w-100">

                                <div className="d-flex justify-content-between">
                                    <div><h5 dangerouslySetInnerHTML={{ __html: name }}></h5></div>
                                </div>

                                {text && (
                                    <div>
                                        <span dangerouslySetInnerHTML={{ __html: text }}></span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}
