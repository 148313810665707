import React, { Component } from 'react';
import './Terms.css'

export class Terms extends Component {
    static displayName = Terms.name;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill terms-and-conditions">

                <h1>BEACON Terms and Conditions</h1>

                <h3>Use of our site</h3>
                <p>This site is operated by the University of Exeter ('We'). We are established by Royal Charter in the UK (RC000653) and our main administrative address is Northcote House, The Queen’s Drive, Exeter, Devon, EX4 4QJ, United Kingdom.</p>
                <p>By using our site, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site.</p>
                <p>Our <a href="/privacy">Privacy Policy</a>, which includes information on how we collect and use your personal data and our cookie policy, also applies to your use of our site.</p>
                <p>We may amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
                <p>Our site is made available free of charge. We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may amend, suspend, withdraw or restrict the availability of all or any part of our site for operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
                <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>

                <h3>Account Details</h3>
                <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
                <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
                <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:beacon@exeter.ac.uk.">beacon@exeter.ac.uk.</a>.</p>

                <h3>Material on our site</h3>
                <p>We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. The content on our site may be viewed, printed or downloaded for personal, non-commercial use only. It is not to be modified, reproduced, transmitted, published or otherwise made available in whole or in part without the prior written consent of the University of Exeter. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
                <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
                <p>In no event shall any user of this website or app use the name or logo or trade marks, whether registered or unregistered, of the University of Exeter, other than with the express written consent of an authorised representative of the University of Exeter.</p>
                <p>If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
                <p>The University of Exeter gives no warranty as to the accuracy of the information or guidance for any specific purpose. The information and guidance contained on the website or app does not constitute legal or professional advice and you must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site or app. Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>

                <h3>Responsibilities for Loss or Damage Caused/Disclaimer</h3>
                <p>The University of Exeter is in no way responsible or liable for any damage or loss whatsoever arising from viewing or downloading information from this website or app and from the use of, or reliance on, the information and guidance on this website or app. We do not exclude or limit in any way our liability to you where it would be unlawful to do so.</p>
                <p>By accessing and using this site you acknowledge and accept that the use of the website or app is at your own risk. We shall not be liable for any direct, indirect, incidental, consequential or punitive damage or for damages for loss of profit or revenue arising out of any use of, access to, or inability to use or access the website or app. Without limiting the foregoing:</p>
                <p>YOUR ACCESS TO AND USE OF OUR WEBSITE OR APP IS AT YOUR SOLE RISK AND IS PROVIDED 'AS IS', 'AS AVAILABLE'. THIS WEBSITE OR APP IS FOR YOUR PERSONAL USE ONLY AND WE MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. REFERENCE IN THIS WEBSITE OR APP TO ANY THIRD PARTY PRODUCTS, EVENTS OR SERVICES DO NOT CONSTITUTE OR IMPLY OUR ENDORSEMENT OR RECOMMENDATION OF ANY KIND.</p>
                <p>WE ASSUME NO RESPONSIBILITY AND SHALL NOT BE LIABLE (TO THE EXTENT PERMITTED BY LAW) FOR ANY DAMAGE OR INJURY ARISING OUT OF ANY USE OF OR ACCESS TO THE WEBSITE OR APP, OR ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, INTERCEPTION OF ONLINE COMMUNICATION, SOFTWARE OR HARDWARE PROBLEMS (INCLUDING WITHOUT LIMITATION LOSS OF DATA OR COMPATIBILITY PROBLEMS), THEFT, DESTRUCTION OR ALTERATION OF THE WEBSITE OR APP, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOUR, NEGLIGENCE OR, UNDER ANY OTHER CAUSE OF ACTION RESULTING DIRECTLY OR INDIRECTLY FROM ANY ACCESS OR USE OF THE WEBSITE OR APP, OR ANY UPLOADING, DOWNLOADING OR PUBLICATION OF DATA, TEXT, IMAGES OR OTHER MATERIAL OR INFORMATION TO OR FROM THE WEBSITE OR APP.</p>
                <p>The limitation of liability contained in these terms of use will apply to the fullest extent permitted by applicable laws.</p>
                <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
                <p>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software. You are responsible for your own hardware, software and facilities and we cannot guarantee compatibility of this website or app with your systems.</p>
                <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
                <p>Any links we include from our site to other websites and resources provided by third parties are provided for convenience and for general information purposes only. The inclusion of such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. The University of Exeter has no control over such websites and accepts no liability whatsoever in respect of any such information. Any use of and reliance that you place on such information is at your own risk.</p>
                <h3>Acceptable Use</h3>
                <ul>
                    <li>You may use our site only for lawful purposes. You must not use this website or app, and/or any interactive facilities which are available through it:</li>
                    <li>To breach in any way any applicable local, national or international law or regulation;</li>
                    <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect including but not limited to misrepresenting your identity;</li>
                    <li>To hack, or attempt to hack, any part of the website or app or any system used to run it;</li>
                    <li>To store or use any personal data derived from the website or app;</li>
                    <li>To post material that is obscene, offensive, defamatory or otherwise unlawful;</li>
                    <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                </ul>
                <p>Any complaint made about any material available on the website or app or information relating to the study will be addressed as soon as possible. Feedback should be sent to the study email address <a href="mailto:beacon@exeter.ac.uk.">beacon@exeter.ac.uk</a>.</p>

                <h3>Governing Law and Jurisdiction</h3>
                <p>These terms and condition represent the entire agreement between the parties in regard to the use of the website or app. Please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction.</p>


            </div>
        );
    }
}
