import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate } from '../../shared/GlobalFns'

export class QuestionRange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(value) {
        let result = { questionCode: this.props.question.code, answer: `${value}`, isValid: this.IsValid(value), hasChanges: value !== this.state.result.answer };
        this.setState({ result: result }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return this.validate.IsEmpty(value) || (this.IsInRange(value) && this.IsValidFormat(value));
        else
            return !this.validate.IsEmpty(value) && this.IsInRange(value) && this.IsValidFormat(value);
    }

    IsValidFormat(value) {
        let valid = true;
        if (this.props.format) {
            let regExp = new RegExp(this.props.format);
            valid = regExp.test(this.Value());
        }
        return valid;
    }

    IsInRange(value) {
        if (!this.validate.IsEmpty(value))
            return parseInt(this.MinValue()) <= parseInt(value) && parseInt(value) <= parseInt(this.MaxValue());
        else
            return false;
    }

    MinValue() {
        if (this.props.question && this.props.question.validation && this.props.question.validation.min)
            return this.props.question.validation.min
        else
            return 0;
    }

    MaxValue() {
        if (this.props.question && this.props.question.validation && this.props.question.validation.max)
            return this.props.question.validation.max
        else
            return 100;
    }

    Value() {
        if (this.state.result && this.state.result.answer) {
            return this.state.result.answer
        }
        else
            return "";
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    QuestionContainerClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mt-2 p-2"
            else
                return "p-3 pe-4"
        } else {
            if (this.props.device.IsPhone())
                return "mt-2 p-2 phone-question-frame"
            else
                return "p-3"
        }
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className={this.QuestionContainerClass()} >
                {this.props.isNested && (
                    <b><small dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></small></b>
                )}
                {!this.props.isNested && (
                    <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
                )}
                <div className="ps-4 pe-4 pt-4 w-100 d-flex justify-content-between">
                    <div className="ps-4 pe-4 w-100">
                        <Form.Group className="w-100">
                            <Form.Range
                                min={this.MinValue()}
                                max={this.MaxValue()}
                                value={this.Value()}
                                onChange={(event) => this.UpdateResult(event.target.value)}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-between rangeLabels">
                            <div><small>{this.MinValue()}</small></div>
                            <div><small>{this.MaxValue()}</small></div>
                        </div>
                    </div>
                    <div className="border p-1 text-nowrap rangeAmount mb-auto text-center"><small><b>{this.Value()}</b></small></div>
                </div>
            </div>
        );
    }

}

