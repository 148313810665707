import React, { Component } from 'react';
import { ModalConfirm } from './shared/ModalConfirm';
import { ButtonAdd } from './shared/Buttons/Add';
import { ButtonBackApp } from './shared/Buttons/BackApp';
import { ButtonEdit } from './shared/Buttons/Edit';
import { ButtonPlay } from './shared/Buttons/Play';
import { ButtonStatus } from './shared/Buttons/Status';
import { StatusSelect } from './shared/Inputs/StatusSelect';
import { ButtonStatusResults } from './shared/Buttons/StatusResults';
import { ButtonDelete } from './shared/Buttons/Delete';
import { PlayBatteryForm } from './Battery/PlayBatteryForm';
import { PlayDiploriaBattery } from './Battery/PlayDiploriaBattery';
import { PlayPackageForm } from './Battery/PlayPackageForm';

import { EditForm } from './Battery/EditForm';
import { TablePagination } from './shared/TablePagination';
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { CognitiveItemsApi, BatteryApi } from './shared/CTAGApi';
import { Format } from './shared/GlobalFns';

import './BatteryItems.css'

export class BatteryItems extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            batteryFields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'cognitiveItems', label: 'Items', className: 'hide-if-smaller-than-xl' },
                { key: 'numberOfResults', label: 'Total Results', className: 'hide-if-smaller-than-xl' },
                { key: 'actions', label: '' },
            ],
            packageFields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'cognitiveItems', label: 'Items', className: 'hide-if-smaller-than-xl' },
                { key: 'actions', label: '' },
            ],
            resultsFields: [
                { key: 'status', label: 'Status' },
                { key: 'startTime', label: 'Start Date' },
                { key: 'endTime', label: 'End Date' },
                { key: 'cognitiveItems', label: 'Completed' },
                { key: 'actions', label: '' },
            ],
            showModal: {
                confirm: false,
                confirmDevDelete: false,
                confirmEndSession: false,
                play: false,
                edit: false,
            },
            selectedItem: {},
            selectedIndex: -1,
            selectedInstance: -1, // -1 = List results (if available), 0 = start new session, >0 show results for that session
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            options: {
                begin: false,
                next: false,
                nextEnabled: false,
                done: false,
            },
            participantResults: [],
            header: null,
            cognitiveItemsList: null,
            nextInstance: 1,
        };

        this.editForm = React.createRef();
        this.playBatteryForm = React.createRef();
        this.playDiploriaBattery = React.createRef();
        this.playPackageForm = React.createRef();
        this.playFormModalBody = React.createRef();
        this.confirmModal = React.createRef();
        this.confirmDevDeleteModal = React.createRef();
        this.confirmEndSessionModal = React.createRef();

        this.cognitiveItemsApi = new CognitiveItemsApi();
        this.batteryApi = new BatteryApi();

        this.format = new Format();
    }

    async GetData() {

        await this.batteryApi.Query(this.state.dataQuery, this.props.cognitiveItemType, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                data: data.data,
                dataQuery: updatedDataQuery,
            });

        }, (error) => {

        })

    }

    async GetCognitiveItemsList() {

        await this.cognitiveItemsApi.List(this.props.cognitiveItemType, (data) => {

            this.setState({
                cognitiveItemsList: data
            });

        }, (error) => {

        })

    }

    async GetParticipantResults(battery, callBackFn) {

        await this.batteryApi.GetParticipantResults(this.state.resultDataQuery, battery, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.resultDataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                participantResults: data.data,
                selectedInstance: (data.totalCount > 0 ? -1 : 0),
                resultsSummary: data.summary,
                updatedDataQuery: updatedDataQuery,
                nextInstance: parseFloat(`0.${data.totalCount + 1}`),
            }, () => {
                if (callBackFn)
                    callBackFn();
            });

        }, (error) => {

        })

    }

    async UpdateBatteryResults(index) {

        let battery = this.state.data[index];
        await this.batteryApi.Get(battery, (data) => {

            let updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
            this.setState({ data: updatedData });

        }, (error) => {

        });

    }

    async SaveItem(index, item) {

        await this.batteryApi.SaveItem(index, item, (data) => {

            this.UpdateItemInList(data);

        }, (errors) => {

            if (this.editForm.current) this.editForm.current.SaveFailed(errors)

        });

    }

    async DeleteItem() {

        await this.batteryApi.DeleteItem(this.state.selectedIndex, this.state.selectedItem, () => {

            let updatedData = update(this.state.data, { $splice: [[this.state.selectedIndex, 1]] });
            this.setState({ showModal: { confirm: false }, data: updatedData });
            this.GetData();

        }, (errors) => {

            if (this.confirmModal.current) this.confirmModal.current.ConfirmFailed(errors)

        });

    }

    async DevDeleteItem() {

        await this.batteryApi.DevDeleteItem(this.state.selectedIndex, this.state.selectedItem, () => {

            let updatedData = update(this.state.data, { $splice: [[this.state.selectedIndex, 1]] });
            this.setState({ showModal: { confirmDevDelete: false }, data: updatedData });
            this.GetData();

        }, (errors) => {

            if (this.confirmDevDeleteModal.current) this.confirmDevDeleteModal.current.ConfirmFailed(errors)

        });

    }

    TabulateData(fields) {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            fields.forEach((field) => {
                switch (field.key) {
                    case 'code':
                        row[field.key] = data.text.text ? `<div>${data[field.key]}</div><div><i><small>${data.text.text}</small></i></div>` : `<div>${data[field.key]}</div>`;
                        break;
                    case 'cognitiveItems':
                        row[field.key] = data[field.key].map(dp => `${dp.category}/${dp.code} v${dp.version}`).join("<br/>");
                        break;
                    case 'version':
                    case 'numberOfResults':
                        row[field.key] = data[field.key] ?? 0;
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulateResultsData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.participantResults, (data) => {
            let row = {};
            this.state.resultsFields.forEach((field) => {
                switch (field.key) {
                    case 'createdDate':
                    case 'modifiedDate':
                    case 'startTime':
                    case 'endTime':
                        row[field.key] = this.format.DateTime(data[field.key], "en-GB");
                        break;
                    case 'instance':
                        row[field.key] = data.battery[field.key] ?? '-';
                        break;
                    case 'cognitiveItems':
                        row[field.key] = data[field.key] && this.state.selectedItem && this.state.selectedItem.cognitiveItems ? `${data[field.key].length}/${this.state.selectedItem.cognitiveItems.length }` : '-';
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    IsPackage() {
        if (this.props.cognitiveItemType)
            return this.props.cognitiveItemType === "BRAINTRAINING";
        else
            return false;
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    GetSelectedName() {
        if (this.state.selectedItem.text && this.state.selectedItem.text.text) {
            return this.state.selectedItem.text.text;
        } else if (this.state.selectedItem && this.state.selectedItem.code && this.state.selectedItem.version) {
            return `${this.state.selectedItem.code} v${this.state.selectedItem.version}`;
        }  else {
            if (this.props.cognitiveItemType === "COGNITIVETEST")
                return "Battery";
            else
                return "Package";
        }
    }

    GetScore() {
        return this.state.header && this.state.header.score ? this.state.header.score : '';
    }

    GetTime() {
        return this.state.header && this.state.header.time ? this.state.header.time : '';
    }

    EditOpen(index, item) {
        if (item)
            this.setState({ showModal: { edit: true }, selectedIndex: index ?? -1, selectedItem: item });
        else
            this.setState({ showModal: { edit: true }, selectedIndex: -2, selectedItem: { type: this.props.cognitiveItemType, code: '' } });
    }

    EditClose() {
        this.setState({ showModal: { edit: false } });
    }

    EditSave() {
        if (this.editForm.current) this.editForm.current.SaveClick();
    }

    PlayOpen(index, item) {
        if (item) {

            let updatedDataQuery = update(this.state.resultDataQuery, { pageNumber: { $set: 1 } });
            this.setState({ resultDataQuery: updatedDataQuery, header: null }, () => {

                this.GetParticipantResults(item, () => {

                    // If there are no results start a new session automatically (instance = 0) otherwise list results (instance = -1)
                    let instance = (this.state.participantsResults && this.state.participantsResults.length > 0) ? 0.1 : -1;
                    this.setState({ showModal: { play: true }, selectedIndex: index ?? -1, selectedItem: item, selectedInstance: instance });

                });
            });
        }
    }

    PlaySelectInstance(instance) {
        this.setState({ selectedInstance: instance }, () => {
            //console.debug(`INSTANCE ${this.state.selectedInstance}`, this.state.selectedItem);
        });
    }

    PlayClose() {
        this.setState({ showModal: { play: false } });
    }

    EndSessionTitle() {
        if (this.state.selectedItem && this.state.selectedItem.text && this.state.selectedItem.text.confirmEndSessionTitle)
            return this.state.selectedItem.text.confirmEndSessionTitle;
        else
            return "End Session";
    }

    EndSessionText() {
        if (this.state.selectedItem && this.state.selectedItem.text && this.state.selectedItem.text.confirmEndSessionText)
            return this.state.selectedItem.text.confirmEndSessionText;
        else
            return "<p>If you end this session you will need to complete it as soon as possible for the results to be accurate.</p><p><b>After 12 hours the session will expire and you will not be able to resume.</b></p><p>Are you sure you want to end this session now?</p>";
    }

    EndSession() {
        let modalState = update(this.state.showModal, { confirmEndSession: { $set: true } });
        this.setState({ showModal: modalState });
    }

    EndSessionModalClose() {
        let modalState = update(this.state.showModal, { confirmEndSession: { $set: false } });
        this.setState({ showModal: modalState });
    }

    SavedBatteryResults(cognitiveItemResults) {
        let index = this.state.data.findIndex(item => item.id === this.state.selectedItem.id);
        if (index !== -1) {
            this.state.data[index].numberOfResults++;
        }
    }

    RefreshResults() {
        this.GetParticipantResults(this.state.selectedItem, () => {

            //console.debug("Refresh Results", this.state.participantResults)
            if (!this.state.participantResults || this.state.participantResults.length === 0)
                this.setState({ showModal: { play: false }, selectedInstance: -1 });
            else 
                this.setState({ selectedInstance: -1 });
        });
    }

    SetPlayBackGround() {
        if (this.playFormModalBody.current && this.state.selectedItem && this.state.selectedItem.backgroundColour)
            this.playFormModalBody.current.style.background = this.state.selectedItem.backgroundColour;
    }

    DevDeleteOpen(index, item) {
        this.setState({ showModal: { confirmDevDelete: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DevDeleteClose() {
        this.setState({ showModal: { confirmDevDelete: false } });
    }

    DeleteOpen(index, item) {
        this.setState({ showModal: { confirm: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DeleteClose() {
        this.setState({ showModal: { confirm: false } });
    }

    UpdateItemInList(data) {
        let index = this.state.data.findIndex(item => item.id === data.id);
        let updatedData = {};
        if (index >= 0)
            updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
        else
            updatedData = update(this.state.data, { $splice: [[0, 0, data]] });
        this.setState({ data: updatedData }, () => { this.EditClose() });
    }

    UpdateQuestionOptions(options) {
        this.setState({ questionOptions: options })
    }

    UpdateHeader(header) {
        this.setState({ header: header })
    }

    SetItemStatus(status) {
        if (this.editForm.current)
            this.editForm.current.SetStatus(status);
    }

    BeginSession() {
        if (this.IsDiploriaBattery()) {
            if (this.playDiploriaBattery.current)
                this.playDiploriaBattery.current.BeginSession();
        } else {
            if (this.playBatteryForm.current)
                this.playBatteryForm.current.BeginSession();
        }
    }

    IsDiploriaBattery() {
        return this.state.selectedItem && this.state.selectedItem.cognitiveItems && this.state.selectedItem.cognitiveItems.filter(c => c.category !== "DIPLORIA").length === 0;
    }

    SkipCognitiveItem() {
        if (this.playBatteryForm.current)
            this.playBatteryForm.current.Skip();
    }

    NeedsPlayFooter() {

        let hasOptions = false;
        if (this.props.cognitiveItemType === "COGNITIVETEST") {
            hasOptions = this.state.selectedInstance === -1 || this.state.options.startSession || (!this.props.device.ShowMobileUI() && this.state.options.back);
        }
        if (this.props.cognitiveItemType === "BRAINTRAINING") {
            hasOptions = !this.props.device.ShowMobileUI();
        }
        return hasOptions;

    }

    IsBrainTrainingPackage() {
        return this.props.cognitiveItemType && this.props.cognitiveItemType === "BRAINTRAINING";
    }

    componentDidMount() {
        if (this.props.device.ShowMobileUI()) {
            let updatedDataQuery = update(this.state.dataQuery, { itemsInPage: { $set: 0 } });
            this.setState({ dataQuery: updatedDataQuery }, () => {
                this.GetData();
                this.GetCognitiveItemsList();
            });
        } else {
            this.GetData();
            this.GetCognitiveItemsList();
        }
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill">
                
                {this.RenderBatteries()}

                <Modal show={this.state.showModal.edit} onHide={() => this.EditClose()} size="lg" fullscreen="lg-down" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">{this.RenderEditHeader()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <EditForm
                            ref={this.editForm}
                            cognitiveItemType={this.props.cognitiveItemType}
                            cognitiveItemsList={this.state.cognitiveItemsList}
                            index={this.state.selectedIndex}
                            item={this.state.selectedItem}
                            existing={this.state.data}
                            onSave={(index, data) => this.SaveItem(index, data)}
                            onUpdate={(index, data) => this.UpdateItemInList(data)}
                            user={this.props.user}
                        />

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.EditClose()}>
                            Close
                        </Button>
                        {!this.props.user.IsGuest() && (
                            <Button variant="primary" onClick={() => this.EditSave()}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal.play}
                    onEntered={() => this.SetPlayBackGround()}
                    onHide={() => this.PlayClose()}
                    fullscreen={true}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="cognitive-item-modal"
                    scrollable>

                    <Modal.Header className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">{this.RenderPlayHeader()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${this.props.cognitiveItemType === "COGNITIVETEST" ? 'p-0 cognitive-item-modal-body-test' : 'p-3'}`} ref={this.playFormModalBody}>

                        {this.props.cognitiveItemType === "COGNITIVETEST" && (this.RenderResultsOrCognitiveItem())}
                        {this.props.cognitiveItemType === "BRAINTRAINING" && (this.RenderPackage())}

                    </Modal.Body>
                    {this.NeedsPlayFooter() && (
                        <Modal.Footer className="modal-footer-app">
                            {this.RenderOptions()}
                        </Modal.Footer>
                    )}
                </Modal>

                <ModalConfirm
                    ref={this.confirmModal}
                    show={this.state.showModal.confirm}
                    onCancel={() => this.DeleteClose()}
                    onConfirm={() => this.DeleteItem()}
                    title="Delete Cognitive Item"
                    message="Are you sure you want to delete this cognitive item?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    ref={this.confirmDevDeleteModal}
                    show={this.state.showModal.confirmDevDelete}
                    onCancel={() => this.DevDeleteClose()}
                    onConfirm={() => this.DevDeleteItem()}
                    title="Delete Cognitive Item"
                    message="Are you sure you want to delete this cognitive item?"
                    warning="<b>YOU ARE LOGGED IN WITH A DEVELOPER ACCOUNT.</b><br/>Any results associated with this cognitive item will also be deleted."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    ref={this.confirmEndSessionModal}
                    show={this.state.showModal.confirmEndSession}
                    onCancel={() => this.EndSessionModalClose()}
                    onConfirm={() => { this.EndSessionModalClose(); this.RefreshResults(); }}
                    title={this.EndSessionTitle()}
                    message={this.EndSessionText()}
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderBatteries() {
        let rows = this.TabulateData(this.IsPackage() ? this.state.packageFields : this.state.batteryFields);
        if (this.props.device.ShowMobileUI()) {

            return (
                <div>
                    <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {this.RenderDashboardTableRows(rows)}
                        </tbody>
                    </Table>
                </div>
            );

        } else {

            return (
                <div>
                    <div className="d-flex pt-4">
                        <TablePagination
                            canSearch
                            canChangePageResults
                            dataQuery={this.state.dataQuery}
                            onUpdated={(query) => this.setState({ dataQuery: query }, () => { this.GetData(); })}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                        {(this.props.user.IsGuest() || this.props.user.IsUser()) && (
                            <span></span>
                        )}
                        {(this.props.user.IsAdmin() || this.props.user.IsDeveloper()) && (
                            <span className="mt-auto">
                                <ButtonAdd onClick={() => this.EditOpen()} text={this.props.cognitiveItemType === "COGNITIVETEST" ? "New Battery" : "New Package"} />
                            </span>
                        )}
                    </div>
                    <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {this.RenderFullWidthTableRows(rows, this.IsPackage() ? this.state.packageFields : this.state.batteryFields)}
                        </tbody>
                    </Table>
                </div>
            );
        }
    }

    RenderDashboardTableRows(rows) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={index}>
                    <Card as="td" className="clickable-card m-1">
                        <Card.Body className="p-2" onClick={() => this.PlayOpen(index, this.state.data[index])}>
                            <Card.Text as="div">
                                <div className="d-flex justify-content-between">
                                    <div className="pt-1">
                                        {this.state.data[index].text && !this.IsEmpty(this.state.data[index].text.text) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.text }}></span>
                                        )}
                                        {(!this.state.data[index].text || this.IsEmpty(this.state.data[index].text.text)) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].code }}></span>
                                        )}
                                        &nbsp;v<span dangerouslySetInnerHTML={{ __html: this.state.data[index].version }}></span>
                                    </div>
                                    <ButtonStatus size="sm" status={this.state.data[index].status} />
                                </div>
                                {this.state.data[index].text && this.state.data[index].text.description && (
                                    <div>
                                        <hr />
                                        <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.description }}></span>
                                    </div>
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </tr>
            );
        })
        return tableRows;
    }

    RenderFullWidthTableRows(rows, fields) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={index}>
                    {fields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            this.RowActions(index, subindex, this.props.user.Role()) :
                            rows[index][item.key] === 'Status' ?
                                <td key={subindex} className="pt-4">
                                    <ButtonStatus status={this.state.data[index].status} />
                                </td> :
                                <td key={subindex} className={item.className}>
                                    <div><span className="miniLabel">{item.label}:</span></div>
                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                </td>
                    ))}
                </tr>
            );
        })
        return tableRows;
    }

    RowActions(index, subindex, role) {
        if (role === 'Developer') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DevDeleteOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else if (role === 'Admin') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    {this.state.data[index].numberOfResults === 0 && (
                        <span>
                            <ButtonDelete onClick={() => this.DeleteOpen(index, this.state.data[index])} />&nbsp;
                        </span>
                    )}                    
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        }
    }

    RenderPackage() {
        return (
            <PlayPackageForm
                nextEnabled={false}
                ref={this.playBatteryForm}
                cognitiveItemType={this.props.cognitiveItemType}
                index={this.state.selectedIndex}
                item={this.state.selectedItem}
                onSavedBatteryResults={(cognitiveItemResults) => this.SavedBatteryResults(cognitiveItemResults)}
                onUpdateHeader={(header) => this.UpdateHeader(header)}
                onUpdateOptions={(options) => this.setState({ options: options })}
                user={this.props.user}
                device={this.props.device}
                theme={this.props.theme}
            />
        );
    }

    RenderResultsOrCognitiveItem() {
        if (this.state.selectedInstance === -1) {
            if (this.props.device.ShowMobileUI()) {

                return (
                    <div className="p-4">
                        {this.RenderDashboardResults()}
                    </div>
                );

            } else {

                return (
                    <div className="p-4 cognitive-item-summary">
                        {this.RenderFullWidthTableResults()}
                    </div>
                );

            }

        } else if (this.IsDiploriaBattery()) {

            //return (
            //    <PlayDiploriaBattery
            //        ref={this.playDiploriaBattery}
            //        index={this.state.selectedIndex}
            //        item={this.state.selectedItem}
            //        instance={this.state.selectedInstance}
            //        languageCode={this.state.selectedLanguage}
            //        theme={this.props.theme}
            //        user={this.props.user}
            //        device={this.props.device}
            //        onSessionEnded={() => this.RefreshResults()}
            //        onSavedBatteryResults={(results) => this.SavedBatteryResults(results)}
            //        onUpdateOptions={(options) => this.setState({ options: options })}
            //        onComplete={() => { this.RefreshResults() }}
            //        className="full-size"
            //    />
            //);

            if (this.state.selectedItem) {
                return (

                    <PlayDiploriaBattery
                        mode="PRACTICE"
                        inputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                        cognitiveItemType={this.state.selectedItem.type}

                        ref={this.playDiploriaBattery}

                        index="0"
                        stageCode=""
                        code={this.state.selectedItem.code}
                        version={this.state.selectedItem.version}
                        instance={this.state.selectedInstance}
                        languageCode="en-GB"

                        theme={this.props.theme}
                        user={this.props.user}
                        device={this.props.device}

                        onSessionEnded={() => this.RefreshResults()}
                        onSavedBatteryResults={(results) => this.SavedBatteryResults(results)}
                        onUpdateOptions={(options) => this.setState({ options: options }, () => { console.log("OPTIONS", options) })}
                        onComplete={() => { this.RefreshResults() }}

                        className="full-size"
                    />

                );
            }

        } else {

            return (
                <PlayBatteryForm

                    mode="PRACTICE"
                    inputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                    nextEnabled={!this.props.user.IsGuest()}
                    ref={this.playBatteryForm}
                    cognitiveItemType={this.props.cognitiveItemType}

                    index={this.state.selectedIndex}
                    code={this.state.selectedItem.code}
                    version={this.state.selectedItem.version}
                    instance={this.state.selectedInstance}

                    theme={this.props.theme}
                    user={this.props.user}
                    device={this.props.device}

                    onSavedBatteryResults={(results) => this.SavedBatteryResults(results)}
                    onUpdateHeader={(header) => this.UpdateHeader(header)}
                    onUpdateOptions={(options) => this.setState({ options: options })}
                    onComplete={() => { this.RefreshResults() }}
                    className="full-size"
                />
            );

        }
    }

    RenderPlayHeader() {
        let headerItems = [];

        if (this.props.cognitiveItemType === "COGNITIVETEST") {

            if (this.props.device.ShowMobileUI()) {

                if (this.state.selectedInstance === -1) {

                    headerItems.push(
                        <div key="HEADER_NAME" className="pe-3 d-flex">
                            <div className="pe-3">
                                <ButtonBackApp onClick={() => this.PlayClose()}></ButtonBackApp>
                            </div>
                            <div className="text-start">{this.GetSelectedName()}</div>
                        </div>
                    );

                } else if (this.state.options.startSession || this.state.options.endSession || this.state.options.back) {

                    headerItems.push(
                        <div key="HEADER_NAME" className="pe-3 d-flex">
                            <div className="pe-3">
                                <ButtonBackApp onClick={() => this.RefreshResults()}></ButtonBackApp>
                            </div>
                            <div className="text-start">{this.GetSelectedName()}</div>
                        </div>
                    );

                } else {

                    headerItems.push(
                        <div key="HEADER_NAME" className="text-start">{this.GetSelectedName()}</div>
                    );

                }


            } else {

                headerItems.push(
                    <div key="HEADER_NAME" className="text-start">{this.GetSelectedName()}</div>
                );

            }

        } else {

            if (this.NeedsPlayFooter()) {

                headerItems.push(
                    <div key="HEADER_NAME" className="pe-3 d-flex">
                        <div className="text-start">{this.GetSelectedName()}</div>
                    </div>
                );

            } else {

                headerItems.push(
                    <div key="HEADER_NAME" className="pe-3 d-flex">
                        <div className="pe-3">
                            <ButtonBackApp onClick={() => this.PlayClose()}></ButtonBackApp>
                        </div>
                        <div className="text-start">{this.GetSelectedName()}</div>
                    </div>
                );

            }

        }

        return (
            <div className="d-flex justify-content-between">
                {headerItems}
            </div>
        );

    }

    RenderOptions() {
        let buttons = [];
        let index = 0;
        if (this.props.cognitiveItemType === "COGNITIVETEST") {

            if (this.state.selectedInstance === -1) {

                if (!this.props.device.ShowMobileUI())
                    buttons.push(
                        <Button key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                            Back
                        </Button>
                    );
                buttons.push(
                    <Button className="text-nowrap" key={index++} variant="primary" onClick={() => this.PlaySelectInstance(this.state.nextInstance)}>
                        Create New Session
                    </Button>
                );

            } else {

                if (this.state.options.startSession) {

                    if (!this.props.device.ShowMobileUI())
                        buttons.push(
                            <Button key={index++} variant="secondary" onClick={() => this.RefreshResults()}>
                                Back
                            </Button>
                        );

                    if (this.state.selectedInstance > 1)
                        buttons.push(
                            <Button className="text-nowrap" key={index++} variant="primary" onClick={() => this.BeginSession()}>
                                Resume Session
                            </Button>
                        );
                    else
                        buttons.push(
                            <Button className="text-nowrap" key={index++} variant="primary" onClick={() => this.BeginSession()}>
                                Start Session
                            </Button>
                        );

                } else {

                    if (this.state.options.nextEnabled && this.state.options.next) {
                        buttons.push(
                            <Button key={index++} variant="info" onClick={() => this.SkipCognitiveItem()}>
                                Skip
                            </Button>
                        );
                    }
                    if ((this.state.options.endSession || this.state.options.back) && !this.props.device.ShowMobileUI()) {
                        buttons.push(
                            <Button key={index++} variant="secondary" onClick={() => this.RefreshResults()}>
                                Back
                            </Button>
                        );
                    }
                }

            }
        }
        if (this.props.cognitiveItemType === "BRAINTRAINING") {

            if (!this.props.device.ShowMobileUI())
                buttons.push(
                    <Button key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                        Close
                    </Button>
                );

        }
        return buttons;
    }

    RenderDashboardResults() {
        let rows = this.TabulateResultsData();
        let tableRows = [];
        let additionalResults = this.state.resultDataQuery ? this.state.resultDataQuery.totalCount - this.state.resultDataQuery.itemsInPage : 0;

        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={index}>
                    <Card as="td" className="clickable-card m-1">
                        <Card.Body className="p-2" onClick={() => this.PlaySelectInstance(this.state.participantResults[index].battery.instance)}>
                            <Card.Text as="div">
                                <div className="d-flex justify-content-between">
                                    <div className="pt-1">
                                        <div className="miniLabel">End Date</div>
                                        {this.format.DateTime(this.state.participantResults[index].endTime, "en-GB")}
                                    </div>
                                    <div className="pt-1 pe-3">
                                        <div className="miniLabel">Completed</div>
                                        {rows[index]['cognitiveItems']}
                                    </div>
                                    <div>
                                        <ButtonStatusResults status={this.state.participantResults[index].status} />
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </tr>
            )
        })

        return (
            <div>
                <Table responsive className="transparent-table" variant="light">
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
                {additionalResults > 0 && (
                    <p className="ps-2">NOTE: <i>{additionalResults} additional results not shown.</i></p>
                )}
            </div>
        );
    }

    RenderFullWidthTableResults() {
        let rows = this.TabulateResultsData();
        let tableRows = [];

        rows.map((_, index) => (
            tableRows.push(
                <tr key={index}>
                    {this.state.resultsFields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            this.ResultRowActions(index, subindex, this.state.participantResults[index].battery.instance, this.props.user.Role()) :
                            rows[index][item.key] === 'Status' ?
                                <td key={subindex} className="pt-4">
                                    <ButtonStatusResults status={this.state.participantResults[index].status} />
                                </td> :
                                <td key={subindex}>
                                    <div><span className="miniLabel">{item.label}:</span></div>
                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                </td>
                    ))}
                </tr>
            )
        ))

        return (
            <Table responsive className="noEndBorder transparent-table" variant="light">
                <thead>
                    <tr>
                        <th colSpan={this.state.resultsFields.length} className="text-start text-nowrap">
                            <TablePagination
                                dataQuery={this.state.resultDataQuery}
                                onUpdated={(query) => this.setState({ resultDataQuery: query }, () => { this.RefreshResults(); })}
                                user={this.props.user}
                                device={this.props.device}
                                theme={this.props.theme}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        );
    }

    ResultRowActions(index, subindex, instance, role) {
        if (role === 'Developer' || role === 'Admin' || role === 'User') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlaySelectInstance(instance)} />
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlaySelectInstance(instance)} />
                </td>
            )
        }
    }

    RenderEditHeader() {
        return (
            <div className="d-flex justify-content-center">
                <div className="text-start w-100">{this.GetSelectedName()}</div>
                <div className="text-end w-100">
                    <StatusSelect status={this.state.selectedItem.status} onUpdated={(status) => { this.SetItemStatus(status) } } />
                </div>
            </div>
        )
    }

}
