import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Validate } from './shared/GlobalFns'
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.Validate = new Validate();
    }

    render() {
        return (
            <header className="header-app fixed-top">
                <div className="container">
                    <Navbar>
                        <NavbarBrand tag={Link} to="/">
                            <div className="logo-img">
                                <img src={require(`../assets/logo.svg`).default} alt="Logo" />&nbsp;
                            </div>
                        </NavbarBrand>
                        {!this.Validate.IsEmpty(this.props.user.RegistrationCode()) && (
                            <NavLink tag={Link} to="/authentication/profile"><div className="tinyLabel">Registration Code</div><div className="p-0 m-0">{this.props.user.RegistrationCode()}</div></NavLink>
                        )}
                    </Navbar>
                </div>
            </header>
        );
    }
}

export class NavMenuPages extends Component {
    static displayName = NavMenuPages.name;

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <header className="header-app fixed-top">
                <div className="container">
                    <Navbar>
                        <NavbarBrand tag={Link} to="/">
                            <div className="logo-img">
                                <img src={require(`../assets/logo.svg`).default} alt="Logo" />&nbsp;
                            </div>
                        </NavbarBrand>
                        <div class="close-app-x">
                            <a id="close" class="nav-link" href="/" title="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </a>
                        </div>
                    </Navbar>
                </div>
            </header>
        );
    }
}