import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Validate, Format } from "../../shared/GlobalFns";
import Button from 'react-bootstrap/Button';

import './QuestionLengthOfTime.css'

export class QuestionLengthOfTime extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hour: '',
            minute: '',
            hasChanges: false,
        };

        this.defaultMin = '00:00';
        this.defaultMax = '23:59';

        this.validate = new Validate();
        this.format = new Format();

        this.hourRef = React.createRef();
        this.minuteRef = React.createRef();
    }

    ErrorMessage() {
        let message = 'Something is wrong with your answer. Please try a different value.';
        if (!this.IsInRange(this.Value())) {

            if (this.MinValue() === this.MaxValue())
                message = `Please enter ${this.MaxValue()}`;

            else if (this.MinValue() !== this.MinValue && this.MaxValue() !== this.MaxValue)
                message = `Must be between ${this.MinValue()} and ${this.MaxValue()}`;

            else if (this.MinValue() !== this.MinValue)
                message = `Must be greater than ${this.MinValue()}`;

            else if (this.MaxValue() !== this.MaxValue)
                message = `Must be less than ${this.MaxValue()}`;

        } else if (this.validate.IsEmpty(this.Value())) {
            message = 'We need an answer to this question'

        } else if (!this.IsValidFormat(this.Value())) {
            message = 'Please enter a valid date';

        }
        return message;
    }

    IsValid(value) {
        let valid = false;
        let isEmpty = this.validate.IsEmpty(value);
        let isInRange = this.IsInRange(value);
        let isValidFormat = this.IsValidFormat(value);
        if (this.props.question.validation && this.props.question.validation.optional)
            valid = isEmpty || (isInRange && isValidFormat);
        else
            valid = !isEmpty && isInRange && isValidFormat;

        //console.log("Valid", { value: value, valid: valid, empty: isEmpty, range: isInRange, format: isValidFormat });
        return valid;
    }

    IsValidFormat(value) {
        let valid = false;
        var regEx = /^\d{2}:\d{2}$/;
        if (value.match(regEx)) {
            valid = true;
        }
        return valid;
    }

    IsInRange(value) {
        if (!this.validate.IsEmpty(value)) {
            let amount = this.GetIntAmount(value);
            let amountMin = this.GetIntAmount(this.MinValue());
            let amountMax = this.GetIntAmount(this.MaxValue());
            return (amountMin <= amount && amount <= amountMax);
        } else {
            return false;
        }
    }

    GetIntAmount(value) {
        let amount = 0;
        if (value.indexOf(":") > -1) {
            let h = parseInt(value.split(":")[0]);
            let m = parseInt(value.split(":")[1]);
            if (!isNaN(h) && !isNaN(m))
                amount = (h * 60) + m;
        }
        return amount;
    }

    RemoveResult() {
        let result = {
            questionCode: this.props.question.code,
            answer: '',
            isValid: this.IsValid(this.Value())
        };
        this.setState({ result: result, hasChanges: result.answer !== this.state.result.answer, hour: '', minute: '' }, () => {
            this.minuteRef.current.focus();
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    UpdateResult(value) {
        if (this.IsValidFormat(value)) {
            let parts = value.split(":");
            let result = {
                questionCode: this.props.question.code,
                answer: value,
                isValid: this.IsValid(value)
            };
            this.setState({ result: result, hasChanges: result.answer !== this.state.result.answer, hour: parts[0], minute: parts[1] }, () => {
                if (this.props.onUpdate)
                    this.props.onUpdate(this.state.result);
            });
        }
    }

    MaxLength() {
        return 5;
    }

    MinValue() {
        let result = this.defaultMin;
        if (this.props.question && this.props.question.validation && this.props.question.validation.min) {
            let min = this.props.question.validation.min;
            if (min === "Now") {
                let nowTime = new Date().toISOString().split("T")[1];
                result = `${nowTime.split(":")[0]}:${nowTime.split(":")[1]}`;
            } else
                result = min;
        }
        return result;
    }

    MaxValue() {
        let result = this.defaultMax;
        if (this.props.question && this.props.question.validation && this.props.question.validation.max) {
            let max = this.props.question.validation.max;
            if (max === "Now") {
                let nowTime = new Date().toISOString().split("T")[1];
                result = `${nowTime.split(":")[0]}:${nowTime.split(":")[1]}`;
            }  else
                result = max;
        }
        return result;
    }

    FormatNumber(value, min, max, length) {
        let valueInt = parseInt(value);
        if (!isNaN(valueInt)) {
            return String(parseInt(Math.max(min, Math.min(max, valueInt)))).padStart(length, '0');
        } else {
            return '';
        }
    }

    FormatHour() {
        let formatted = this.FormatNumber(this.state.hour, 0, 11, 2);
        if (formatted !== this.state.hour) {
            this.setState({ hour: formatted }, () => {
                this.UpdateResult(`${this.GetHour()}:${this.GetMinute()}`);
            });
        } else {
            this.UpdateResult(`${this.GetHour()}:${this.GetMinute()}`);
        }
    }

    UpdateHour(value) {
        value = value.replace(/[^0-9]/g, '');
        this.setState({ hour: value }, () => {
            if (this.state.hour.length === 2) {
                this.FormatHour();
                this.minuteRef.current.focus();
            }
        });
    }

    GetHour() {
        return this.state.hour;
    }

    FormatMinute() {
        let formatted = this.FormatNumber(this.state.minute, 0, 59, 2);
        if (formatted !== this.state.minute) {
            this.setState({ minute: formatted }, () => {
                this.UpdateResult(`${this.GetHour()}:${this.GetMinute()}`);
            });
        } else {
            this.UpdateResult(`${this.GetHour()}:${this.GetMinute()}`);
        }
    }

    UpdateMinute(value) {
        value = value.replace(/[^0-9]/g, '');
        this.setState({ minute: value }, () => {
            if (this.state.minute.length === 2) {
                this.FormatMinute();
            }
        });
    }

    GetMinute() {
        return this.state.minute;
    }

    Value() {
        let value = '';
        if (this.state.result && this.state.result.answer && this.IsValidFormat(this.state.result.answer))
            value = this.state.result.answer;
        return value;
    }

    GetQuestionText() {
        if (this.props.question && this.props.question.text)
            return this.props.question.text.text;
        else
            return '';
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        }, () => {
            let value = this.Value();
            if (this.IsValidFormat(value)) {
                let parts = value.split(":");
                this.setState({
                    hour: parts[0], minute: parts[1]
                });
            }
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result) {
            this.Load();
        }
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    QuestionContainerClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mt-2 p-2"
            else
                return "p-3 pe-4"
        } else {
            if (this.props.device.IsPhone())
                return "mt-2 p-2 phone-question-frame"
            else
                return "p-3"
        }
    }

    QuestionControlClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mb-0 w-100"
            else
                return "mb-0 w-50"
        } else {
            if (this.props.device.IsPhone())
                return "mb-2 w-100"
            else
                return "mb-2 w-50"
        }
    }

    RenderPreview() {
        if (this.props.isNested)
            return (
                <div className={this.QuestionContainerClass()} >
                    <b><small dangerouslySetInnerHTML={{ __html: this.GetQuestionText() }}></small></b>
                    <div className="d-flex justify-content-end pb-2">
                        <Form.Group className={this.QuestionControlClass()} ref="code_input">
                            {this.RenderControl()}
                        </Form.Group>
                    </div>
                </div>
            );
        else
            return (
                <div className={this.QuestionContainerClass()}>
                    <span dangerouslySetInnerHTML={{ __html: this.GetQuestionText() }}></span>
                    <div className="d-flex justify-content-end p-2">
                        <Form.Group className={this.QuestionControlClass()} ref="code_input">
                            {this.RenderControl()}
                        </Form.Group>
                    </div>
                </div>
            );
    }

    RenderControl() {
        return (
            <div>
                <div>
                    <InputGroup className="d-flex justify-content-end">
                        <Form.Control
                            ref={this.hourRef}
                            className="inputgroup-HH"
                            type="text"
                            placeholder="HH"
                            maxLength="2"
                            value={this.GetHour()}
                            onChange={(event) => this.UpdateHour(event.target.value)}
                            onBlur={() => this.FormatHour()}
                            inputMode="numeric"
                        />
                        <div className="inputgroup-spacer">:</div>
                        <Form.Control
                            ref={this.minuteRef}
                            className="inputgroup-mm"
                            type="text"
                            placeholder="MM"
                            maxLength="2"
                            value={this.GetMinute()}
                            onChange={(event) => this.UpdateMinute(event.target.value)}
                            onBlur={() => this.FormatMinute()}
                            inputMode="numeric"
                        />
                        <Button className="inputgroup-clear-button" onClick={() => { this.RemoveResult() }}><span className="bi bi-x"></span></Button>
                    </InputGroup>
                </div>
                {!this.IsValid(this.Value()) && (
                    <Form.Control.Feedback className="d-flex justify-content-end question-error-message" type="invalid">
                        {this.ErrorMessage()}
                    </Form.Control.Feedback>
                )}
            </div>
        )
    }
}

