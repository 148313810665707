import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { RandomisationApi } from '../shared/RandomisationApi'

export class PlayForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            randomisationType: 'Blind', // 'Blind', 'Unblinded', 'PartiallyBlinded',
            editIndex: -1,
            editItem: {
                code: '',
                studyGroups: [],
                stratificationGroups: [],
                summary: { numberRandomised: 0, numberRandomlyAllocated: 0, summary: [] },
                strata: [],
            },
            fileUploadError: null,
        };

        this.fileUploadInput = React.createRef();
        this.RandomisationApi = new RandomisationApi();

    } 

    UploadFile() {
        this.setState({ isBusy: true, fileUploadError: null }, async () => {
            if (this.fileUploadInput.current && this.fileUploadInput.current.files[0]) {

                await this.RandomisationApi.UploadFile(this.state.editItem.id, this.state.randomisationType, this.fileUploadInput.current.files[0], (fileBlob) => {

                    if (fileBlob)
                        this.DownloadFile(fileBlob, "RandomisedParticipants.xlsx");

                    this.setState({ isBusy: false, fileUploadError: null });

                }, (errors) => {

                    this.setState({ isBusy: false, fileUploadError: errors });

                });

            } else {

                this.setState({ isBusy: false, fileUploadError: { title: 'System', status: 'UNKNOWN', errors: { error: ["Please choose a file to upload and randomise"] } } });

            }
        });
    }

    GetData() {

        this.setState({ isBusy: true }, async () => {

            await this.RandomisationApi.Get(this.state.editItem.id, (data) => {

                this.setState({ editItem: data, isBusy: false, fileUploadError: null }, () => {

                    if (this.props.onRandomised) {
                        this.props.onRandomised(this.state.editIndex, this.state.editItem);
                    }

                });

            }, (errors) => {

                this.setState({ isBusy: false, fileUploadError: errors });

            });

        });

    }

    GetRandomStratificationGroupValue(group) {
        let band = group.bands[this.RandomInt(0, group.bands.length - 1)];
        if (band.startsWith('RANGE(')) {
            let range = band.replace('RANGE(', '').replace(')', '').split(",");
            band = range[this.RandomInt(0, range.length - 1)];
        }
        return band;
    }

    RandomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    DownloadFile(fileResponseData, fileName) {
        const url = window.URL.createObjectURL(new Blob([fileResponseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    componentDidMount() {
        let _this = this;
        this.setState({ editIndex: this.props.index });
        if (this.props.item) 
            this.setState({ editItem: this.props.item });
    }

    componentWillUnmount() {
    }

    render() {
        let errors = this.state.fileUploadError && this.state.fileUploadError.errors ? this.state.fileUploadError.errors.error : null;
        return (
            <div>
                <Form noValidate validated={this.state.isFormValidated}>
                    {this.state.isBusy ?
                        <div className="busy-overlay col d-flex align-items-center justify-content-center">
                            <Spinner animation="border" role="status" variant="dark" />
                        </div>
                    : ''}

                    <Form.Group controlId="formFile">
                        <Form.Label><h3>Upload File</h3></Form.Label>
                        <div className="mb-2">Upload a spreadsheet and allocate a randomisation code to each participant. The spreadsheet must include a unique Participant Id and values for each of the stratifiaction groups. For example:</div>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Participant Id</th>
                                    {this.state.editItem.stratificationGroups.map((item, index) => (
                                        <th key={index}>{ item.name }</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {[0, 1, 2, 3].map((_, rowIndex) => (
                                    <tr key={ rowIndex }>
                                        <td>P{ rowIndex + 1 }</td>
                                        {this.state.editItem.stratificationGroups.map((item, index) => (
                                            <td key={index}>{ this.GetRandomStratificationGroupValue(item) }</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </Form.Group>

                    <div className="mb-4 mx-2"><small><i>
                        NOTE: Any participants with values that do not match a stratification group value/range will be skipped and not be randomised.
                        You can upload participants that have already been randomised, they will be returned with the original randomisation code and not re-randomised. 
                    </i></small></div>

                    <Form.Group className="mb-1" id="file_input">
                        <Form.Control ref={this.fileUploadInput} type="file" />
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <div className="align-middle px-2 mt-2">Return the results with:</div>
                        <Form.Group className="mb-1" id="name_input">
                            <Form.Control as="select" value={this.state.randomisationType} onChange={(event) => this.setState({ randomisationType: event.currentTarget.value })} className="prevent-validation">
                                <option value="Blind">Codes Only</option>
                                <option value="PartiallyBlinded">Codes and Study Groups</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    {errors && (
                        <Alert variant="danger my-1">{errors.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}</Alert>
                    )}

                </Form>
            </div>
        );
    }

}
