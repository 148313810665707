import React, { Component } from "react";
import { TablePagination } from '../../shared/TablePagination';
import { Validate } from '../../shared/GlobalFns';
import { GPSurgeryApi } from '../../shared/GPSurgeryApi';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

export class QuestionGPSurgery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
            showModal: {
                find: false,
            },
            data: [],
            fields: [
                { key: 'siteName', label: 'Site Name' },
                { key: 'address', label: 'Address' },
                { key: 'actions', label: '' },
            ],
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "siteName",
                sortType: "ASC",
                filter: "",
                totalCount: 0,
            },
            selectedItem: '',
            filtered: '',
        };

        this.validate = new Validate();
        this.GPSurgeryApi = new GPSurgeryApi();
        this.readOnlyInputControl = React.createRef();
    }

    async GetData() {
        this.setState({ isBusy: true }, async () => {

            this.GPSurgeryApi.Query(this.state.dataQuery, (data) => {

                let updatedDataQuery = update(data.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: data.totalCount } });
                this.setState({
                    data: data.data,
                    dataQuery: updatedDataQuery,
                    isBusy: false,
                });

            }, (errors) => {

                this.setState({ isBusy: false });

            });

        });
    }

    async GetSelectedItem(odsCode) {
        if (!this.validate.IsEmpty(odsCode)) {

            this.GPSurgeryApi.GetByODSCode(odsCode, (data) => {

                this.setState({ selectedItem: data });

            }, (errors) => {

            });

        } else {
            this.setState({ selectedItem: null });
        }
    }

    GetText() {
        if (this.state.selectedItem) {
            return `${this.state.selectedItem.siteName}`;
        } else {
            return '';
        }
    }

    TabulateData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            this.state.fields.forEach((field) => {
                switch (field.key) {
                    case 'odsCode':
                    case 'status':
                        row[field.key] = `<span class="text-nowrap">${data[field.key] ?? ""}</span>`;
                        break;
                    case 'siteName':
                        row[field.key] = data[field.key] ?? "";
                        break;
                    case 'address':
                        let address = data['address1'] ? `${data['address1']}` : "";
                        address += data['address2'] ? `, ${data['address2']}` : "";
                        address += data['address3'] ? `, ${data['address3']}` : "";
                        address += data['address4'] ? `, ${data['address4']}` : "";
                        address += data['address5'] ? `, ${data['address5']}` : "";
                        address += data['postcode'] ? `, ${data['postcode']}` : "";
                        row[field.key] = address;
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    UpdateResult(value) {
        if (value) {
            let result = { questionCode: this.props.question.code, answer: value, isValid: this.IsValid(value) };
            this.setState({ result: result });
        }
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return true;
        else
            return !this.validate.IsEmpty(value);
    }

    UpdateDataQuery(query) {
        this.setState({ dataQuery: query }, () => {
            this.ClearResult(() => { this.GetData() });
        })
    }

    ClearResult(done) {
        let updatedResult = update(this.state.result, { answer: { $set: '' }, isValid: { $set: this.IsValid('') } });
        this.setState({ filtered: this.state.dataQuery.filter, result: updatedResult }, () => {
            if (done) done();
        })
    }

    RemoveResult() {
        this.ClearResult(() => {
            this.GetSelectedItem('');
            this.FindSave();
        });
    }

    FindOpen() {
        //this.GetData();
        this.setState({ showModal: { find: true } });
    }
    FindClose() {
        this.setState({ showModal: { find: false } });
    }
    FindSave() {
        this.setState({ showModal: { find: false } });
        if (this.props.onUpdate)
            this.props.onUpdate(this.state.result);
    }

    componentDidMount() {
        if (this.props.result)
            this.setState({ result: this.props.result }, () => {
                if (!this.validate.IsEmpty(this.state.result.answer))
                    this.GetSelectedItem(this.state.result.answer);
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result) {
            this.setState({ result: this.props.result }, () => {
                if (!this.validate.IsEmpty(this.state.result.answer))
                    this.GetSelectedItem(this.state.result.answer);
            });
        }
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        let rows = this.TabulateData();
        return (
            <div className="p-3 pb-4">
                {this.props.isNested && (
                    <b><small dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></small></b>
                )}
                {!this.props.isNested && (
                    <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
                )}

                <div className="d-flex justify-content-end">
                    <InputGroup>
                        <Form.Control
                            ref={this.readOnlyInputControl}
                            readOnly
                            placeholder="Find your surgery"
                            onClick={() => { this.FindOpen() }}
                            value={this.GetText()}
                        />
                        <Button className="inputgroup-clear-button" onClick={() => { this.RemoveResult() }}><span className="bi bi-x"></span></Button>
                        <Button className="inputgroup-clear-button" onClick={() => { this.FindOpen() }}><span className="bi bi-search"></span></Button>
                    </InputGroup>
                </div>

                <Modal show={this.state.showModal.find} fullscreen="lg-down" onHide={() => this.FindClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>GP Surgeries</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2 questionnaire-item-modal-body">

                        <Table className="noEndBorder transparent-table">
                            <thead>
                                <tr>
                                    <th colSpan={this.state.fields.length}>
                                        <TablePagination
                                            canSearch
                                            forceSearch
                                            dataQuery={this.state.dataQuery}
                                            onUpdated={(query) => this.UpdateDataQuery(query)}
                                            user={this.props.user}
                                            device={this.props.device}
                                            theme={this.props.theme}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isBusy ?
                                    <tr>
                                        <td className="text-center" colSpan={this.state.fields.length}>
                                            <div className="busy-overlay-local d-flex align-items-center justify-content-center">
                                                <Spinner animation="border" role="status" variant="dark" />
                                            </div>
                                        </td>
                                    </tr>
                                    : rows.length === 0 ?
                                        <tr>
                                            {this.validate.IsEmpty(this.state.filtered) && (
                                                <td className="text-center" colSpan={this.state.fields.length}>Type your surgery name, address, post code or phone number in the search box above. Then select your surgery from the results.</td>
                                            )}
                                            {!this.validate.IsEmpty(this.state.filtered) && (
                                                <td className="text-center" colSpan={this.state.fields.length}>No records found... try a different search.</td>
                                            )}
                                        </tr>
                                        : this.DataRows(rows)
                                    }
                            </tbody>
                        </Table>


                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.FindClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.FindSave()} disabled={this.validate.IsEmpty(this.state.result.answer)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

    DataRows(rows) {
        return (
            rows.map((_, index) => (
                <tr key={index}>
                    {this.state.fields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            <td key={subindex}>
                                <Form.Check
                                    reverse
                                    type="radio"
                                    name="Surgery"
                                    checked={this.state.data[index].odsCode === this.state.result.answer}
                                    value={this.state.data[index]}
                                    onChange={() => { this.UpdateResult(this.state.data[index].odsCode) }}
                                />
                            </td> :
                            <td key={subindex} onClick={() => { this.UpdateResult(this.state.data[index].odsCode) }}>
                                <div><span className="miniLabel text-nowrap">{item.label}:</span></div>
                                <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                            </td>
                    ))}
                </tr>
            ))
        );
    }
}

