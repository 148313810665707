import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate } from '../../shared/GlobalFns'

export class QuestionWeight extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(value) {
        let result = { questionCode: this.props.question.code, answer: value, isValid: this.IsValid(value) };
        this.setState({ result: result }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return this.validate.IsEmpty(value) || (this.IsInRange(value) && this.IsValidFormat(value));
        else
            return !this.validate.IsEmpty(value) && this.IsInRange(value) && this.IsValidFormat(value);
    }

    IsValidFormat(value) {
        let valid = true;
        if (this.props.format) {
            let regExp = new RegExp(this.props.format);
            valid = regExp.test(this.Value());
        }
        return valid;
    }

    IsInRange(value) {
        if (!this.validate.IsEmpty(value))
            return (new Date(this.MinValue()) <= new Date(value) && new Date(value) <= new Date(this.MaxValue()));
        else
            return false;
    }

    MinValue() {
        if (this.props.question && this.props.question.validation && this.props.question.validation.min)
            return this.props.question.validation.min
        else
            return 0;
    }

    MaxValue() {
        if (this.props.question && this.props.question.validation && this.props.question.validation.max)
            return this.props.question.validation.max
        else
            return 100;
    }

    Value() {
        if (this.state.result && this.state.result.answer) {
            return this.state.result.answer
        }
        else
            return "";
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    render() {
        if (this.state.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className="p-3">
                {this.state.isNested && (
                    <b><small dangerouslySetInnerHTML={{ __html: this.state.question.text.text }}></small></b>
                )}
                {!this.state.isNested && (
                    <span dangerouslySetInnerHTML={{ __html: this.state.question.text.text }}></span>
                )}
                <Form.Group className="mb-2" ref="code_input">
                    <Form.Control
                        type="number"
                    />
                </Form.Group>
            </div>
        );
    }

}

