import React, { Component } from 'react';
import { StudyApi } from '../shared/BEACONApi';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { Format } from '../shared/GlobalFns';
import { CountDown } from '../shared/CountDown';
import { ActivityQuestionnaireResults } from './ActivityQuestionnaireResults';

import './Activity.css'

export class ActivityList extends Component {

    constructor(props) {
        super(props);

        this.StudyApi = new StudyApi();
        this.Format = new Format();
    }

    StageIsActiveOrOpen(stage) {
        return stage.status === "ACTIVE" ||
            stage.status === "OPEN";
    }

    OpenActivity(activity) {
        //console.log("OPEN ACTIVITY", activity)
        if (this.props.onSelectActivity)
            this.props.onSelectActivity(activity);
    }

    TimerCompleted() {
        if (this.props.onTimerCompleted)
            this.props.onTimerCompleted();
    }

    ScrollToTopOfOpenStage() {
        setTimeout(() => {
            const yOffset = -65; 
            const element = document.getElementById("scrollToHere");
            if (element) {
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }, 100);
    }

    componentDidMount() {

    }

    render() {
        let stageCards = [];
        if (this.props.schedule) {
            let stagesToDisplay = this.props.schedule.stages.filter(st => st.availability === this.props.dashboardType);
            stagesToDisplay.forEach((stage, index) => {

                let lastItem = stagesToDisplay.length == index + 1;
                let openCard = stage.status === "ACTIVE" || stage.status === "OPEN" || (lastItem && stage.status !== "ACTIVE" && stage.status !== "OPEN");
                let stageActivities = stage.activities.filter(activity => !this.props.owner || activity.owner === this.props.owner);

                if (!this.props.hideIfEmpty || stageActivities.length > 0) {
                    stageCards.push(
                        <div key={`STAGE_${index}`} className="dashboard-item">

                            {index > 1 && openCard && (
                                <div id="scrollToHere"></div>
                            )}

                            <Accordion
                                className="accordian-dashboard"
                                defaultActiveKey={openCard ? `${stage.code}_${index}` : ''}>

                                <Accordion.Item
                                    className={openCard ? "accordian-item-dashboard w-100" : "accordian-item-dashboard-inactive w-100"}
                                    eventKey={`${stage.code}_${index}`}>

                                    <Accordion.Header className="accordian-header-dashboard">
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <h4 dangerouslySetInnerHTML={{ __html: stage.displayName }}></h4>
                                                {this.RenderStageBadge(stage)}
                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: stage.text }}></p>
                                        </div>
                                    </Accordion.Header>

                                    {stageActivities && stageActivities.length > 0 && (
                                        <Accordion.Body className="accordian-body-dashboard">
                                            {this.RenderActivities(stageActivities, "ACTIVE")}
                                            {this.RenderActivities(stageActivities, "OPEN")}
                                            {this.RenderActivities(stageActivities, "TRIGGERED")}
                                            {this.RenderActivities(stageActivities, "PENDING")}
                                            {this.RenderActivities(stageActivities, "PASSED")}
                                            {this.RenderActivities(stageActivities, "COMPLETED")}
                                            {this.RenderActivities(stageActivities, "SKIPPED")}
                                            {this.RenderActivities(stageActivities, "FAILED")}
                                            {this.RenderActivities(stageActivities, "EXPIRED")}
                                            {this.RenderActivities(stageActivities, "CLOSED")}
                                        </Accordion.Body>
                                    )}

                                </Accordion.Item>
                            </Accordion>
                        </div>
                    );
                }

            });

            return (
                <div className="flex-fill">
                    {stageCards}
                </div>
            );
        }
    }

    RenderActivities(stageActivities, status) {
        let activities = [];
        let packageAdded = false;
        stageActivities.filter(activity => status.split(",").includes(activity.status)).forEach((activity, subindex) => {

            if (activity.type === "PACKAGE") {
                if (!packageAdded && activity.status === "OPEN") {
                    packageAdded = true;

                    activities.push(
                        <tr key={`ACTIVITY_${subindex}`}>

                            <Card as="td" className="clickable-card-dark m-1 p-0">
                                <Card.Body className="p-2" onClick={() => this.OpenActivity(activity)}>
                                    <Card.Text as="div">
                                        <div className="d-flex justify-content-start">
                                            <div className="pe-3">{this.RenderActivityIcon(activity)}</div>
                                            <div>
                                                <h1 dangerouslySetInnerHTML={{ __html: activity.displayName }}></h1>
                                                {activity.text && (
                                                    <div>
                                                        <span dangerouslySetInnerHTML={{ __html: activity.text }}></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                         </tr>
                    )

                }
            } else {

                let activityResults = (<div></div>);
                if (activity.availability === "MULTIPLE") {
                    switch (activity.type) {
                        case "QUESTIONNAIRE":
                            activityResults = (<ActivityQuestionnaireResults activity={activity} onOpenActivity={(activityResult) => this.OpenActivity(activityResult)} />);
                            break;
                        default:
                            break;
                    }
                }

                activities.push(
                    <tr key={`ACTIVITY_${subindex}`}>

                        <Card as="td" className="clickable-card m-1 p-0">
                            <Card.Body className="p-2">
                                <Card.Text as="div">

                                    <div className="d-flex justify-content-start">
                                        <div onClick={() => this.OpenActivity(activity)}>{this.RenderActivityIcon(activity)}</div>
                                        <div className="ps-3 w-100">

                                            <div onClick={() => this.OpenActivity(activity)} className="d-flex justify-content-between">
                                                <div><h5 dangerouslySetInnerHTML={{ __html: activity.displayName }}></h5></div>
                                                {this.RenderActivityBadge(activity)}
                                            </div>

                                            {activity.text && (
                                                <div onClick={() => this.OpenActivity(activity)}>
                                                    <span dangerouslySetInnerHTML={{ __html: activity.text }}></span>
                                                </div>
                                            )}
                                                
                                            {activityResults}

                                        </div>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </tr>
                )
            }
        });
        if (activities.length > 0)
            return (
                <table className="w-100 mt-2">
                    <tbody>
                        {activities}
                    </tbody>
                </table>
            );
        else
            return (<div></div>)

    }

    RenderStageBadge(stage) {
        if (stage.status === "ACTIVE" || stage.status === "OPEN")
            return (<div></div>);
        else
            return (<div><span className="bi bi bi-check-circle-fill"></span></div>);
    }

    RenderActivityBadge(activity) {

        switch (activity.status) {
            case "PENDING":
                return (
                    <div className="miniLabel ps-2">

                    </div>
                );
            case "TRIGGERED":
                return (
                    <CountDown
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        startDate={new Date(activity.statusDate)}
                        endDate={activity.openDate}
                        onFinished={() => { this.TimerCompleted() }}
                    />
                );
            case "ACTIVE":
                let timeAllowed = new Date(new Date(activity.statusDate).getTime() + (this.Format.TimeSpanInSeconds(activity.timeAllowed) * 1000));
                return (
                    <CountDown
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        startDate={new Date(activity.statusDate)}
                        endDate={timeAllowed}
                        onFinished={() => { this.TimerCompleted() }}
                    />
                );
            case "OPEN":
                let windowCloseDate = new Date(activity.statusDate).getTime() + (this.Format.TimeSpanInSeconds(activity.window) * 1000);
                return (
                    <CountDown
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        startDate={new Date(activity.statusDate)}
                        endDate={windowCloseDate}
                        onFinished={() => { this.TimerCompleted() }}
                    />
                );
            default:
                return (<div></div>);
        }

    }

    RenderActivityIcon(activity) {
        let icon = (<span className="bi bi-lock-fill activity-danger"></span>);
        switch (activity.status) {
            case "PENDING":
                icon = (<span className="bi bi-lock-fill activity-warning"></span>)
                break;
            case "TRIGGERED":
                icon = (<span className="bi bi-hourglass-split activity-warning"></span>)
                break;
            case "OPEN":
                if (activity.availability === "MULTIPLE")
                    icon = (<span className="bi bi-plus-circle-fill activity-add"></span>)
                else
                    icon = (<span className="bi bi-play-circle-fill activity-info"></span>)
                break;
            case "ACTIVE":
                icon = (<span className="bi bi-pencil-square activity-info"></span>)
                break;
            case "COMPLETED":
            case "PASSED":
                if (activity.availability === "MULTIPLE")
                    icon = (<span className="bi bi-plus-circle-fill activity-add"></span>)
                else
                    icon = (<span className="bi bi bi-check-circle-fill activity-success"></span>)
                break;
            case "FAILED":
                if (activity.availability === "MULTIPLE")
                    icon = (<span className="bi bi-plus-circle-fill activity-add"></span>)
                else
                    icon = (<span className="bi bi-x-circle-fill activity-danger"></span>)
                break;
            case "CLOSED":
            case "EXPIRED":
                icon = (<span className="bi bi-exclamation-triangle-fill activity-danger"></span>)
                break;
            default:
                icon = (<span className="bi bi-lock-fill activity-danger"></span>)
                break;
        }
        return (<h1>{icon}</h1>)
    }

    RenderActivity(name, text, action) {
        return (
            <Card className="clickable-card m-1 p-0">
                <Card.Body className="p-2" onClick={() => { if (action) action(); }}>
                    <Card.Text as="div">

                        <div className="d-flex justify-content-start">
                            {this.RenderActivityIcon({ status: "OPEN", availability: "ONCE" })}
                            <div className="ps-3 w-100">

                                <div className="d-flex justify-content-between">
                                    <div><h5 dangerouslySetInnerHTML={{ __html: name }}></h5></div>
                                </div>

                                {text && (
                                    <div>
                                        <span dangerouslySetInnerHTML={{ __html: text }}></span>
                                    </div>
                                )}
                            </div>
                        </div>

                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}
