import React, { Component } from 'react';
import { ButtonCopyToClipboard } from '../shared/Buttons/CopyToClipboard'
import { StudyApi, AdminApi } from '../shared/BEACONApi'
import { BatteryApi } from '../shared/CTAGApi'
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { Validate, Format } from '../shared/GlobalFns'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ParticipantDetailsActivityAdmin } from './ParticipantDetailsActivityAdmin'
import { ActivityList } from '../Activities/ActivityList'
import { PlayQuestionnaireModal } from '../Questionnaire/PlayQuestionnaireModal';
import { PlayBatteryModal } from '../Battery/PlayBatteryModal';
import { ModalConfirm } from '../shared/ModalConfirm';

import update from 'immutability-helper';

export class ParticipantDetailsAdmin extends Component {
    static displayName = ParticipantDetailsAdmin.name;
    static isAdmin = true;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            fileUploadError: null,
            isBusy: true,
            regCodeSearch: '',
            participant: null,
            participantActivity: null,
            participantActivityResults: null,
            searchErrors: [],
            showModal: {
                participant: false,
                participantActivity: false,
                playQuestionnaire: false,
                playBattery: false,
                cannotOpenActivity: false,
            }
        }

        this.StudyApi = new StudyApi();
        this.AdminApi = new AdminApi();
        this.Validate = new Validate();
        this.Format = new Format();
        this.BatteryApi = new BatteryApi();
        this.QuestionnaireApi = new QuestionnaireApi();

        this.activityList = React.createRef();
        this.cannotOpenActivity = React.createRef();
    }

    RefreshSchedule() {
        // TODO: See if we can refresh the page when a user is in the middle of an activity without ending the activity.
        //if (this.props.getSchedule)
        //    this.props.getSchedule();
    }

    OpenActivity(activity) {
        //console.debug("OPEN ACTIVITY", activity);
        switch (activity.status) {
            case "OPEN":
            case "ACTIVE":
            case "PASSED":
            case "FAILED":
            case "COMPLETED":
                switch (activity.type) {
                    case "QUESTIONNAIRE":
                        this.PlayQuestionnaire(activity);
                        break;
                    case "BATTERY":
                        this.PlayBattery(activity);
                        break;
                    case "PACKAGE":
                        window.location.href = "/games";
                        break;
                    default:
                        this.OpenCannotOpenActivityConfirm("Study Activity", "This activity is not recognised.");
                        break;
                }
                break;
            case "EXPIRED":
                this.OpenCannotOpenActivityConfirm("Study Activity", "This activity has expired.");
                break;
            default:
                this.OpenCannotOpenActivityConfirm("Study Activity", "Please complete the open activities first and we will open this one as soon as it becomes available.");
                break;
        }
    }

    OpenCannotOpenActivityConfirm(title, message) {
        let updated = update(this.state.showModal, { cannotOpenActivity: { $set: true } });
        this.setState({ showModal: updated, cannotOpenTitle: title, cannotOpenMessage: message });
    }

    CloseCannotOpenActivityConfirm() {
        let updated = update(this.state.showModal, { cannotOpenActivity: { $set: false } });
        this.setState({ showModal: updated });
    }

    GetFactDate(stageCode, activity, status) {
        let date = null;
        if (this.state.participant) {
            let fact = this.state.participant.schedule.facts.filter(f => f.code === `${stageCode}.${activity.code}.${activity.occurrence}.${activity.instance}` && f.version === activity.version && f.status === status);
            if (fact && fact.length > 0) {
                date = fact[0].statusDate;
            }
        }
        return date;
    }

    ParticipantClose() {
        let showModal = update(this.state.showModal, { participant: { $set: false } });
        this.setState({ participant: null, isBusy: false, searchErrors: null, showModal: showModal });
    }

    ParticipantActivityOpen(stage, activity, status) {

        activity.stageCode = stage.code;
        let participantActivity = this.GetCurrentParticipantActivity(this.state.participant, stage.code, activity, status);
        let showModal = update(this.state.showModal, { participantActivity: { $set: true } });

        this.setState({ participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false, showModal: showModal });
    }

    GetCurrentParticipantActivity(participant, stageCode, activity, status) {

        let participantActivity = null;
        let scheduleStages = participant.schedule.stages.filter(s => s.code === stageCode);
        if (scheduleStages.length === 1) {

            let stageActivities = scheduleStages[0].activities.filter(a =>
                a.code === activity.code &&
                a.version === activity.version &&
                a.occurrence === activity.occurrence &&
                a.instance === activity.instance &&
                (status === null || a.status === status));

            if (stageActivities.length === 1) {
                participantActivity = stageActivities[0];
            }

        }
        return participantActivity;
    }

    GetCurrentParticipantStageStatus(participant, stageCode) {

        let participantStageStatus = "PENDING";
        let scheduleStages = participant.schedule.stages.filter(s => s.code === stageCode);
        if (scheduleStages.length === 1) {
            participantStageStatus = scheduleStages[0].status;
        }
        return participantStageStatus;
    }

    GetStudyActivity(stageCode, code, version, occurrence, instance) {
        let studyActivity = null;
        this.state.study.stages.filter(s => s.code === stageCode).forEach((stage, sIndex) => {
            stage.activities.every((activity, aIndex) => {
                if (activity.code === code && activity.version === version && activity.occurrence === occurrence && activity.instance === instance)
                    studyActivity = activity;
                return studyActivity === null;
            });
        });
        return studyActivity;
    }

    SummaryActivityOpen(stage, activity) {

    }
 
    ParticipantActivityClose() {
        let showModal = update(this.state.showModal, { participantActivity: { $set: false } });
        this.setState({ participantActivity: null, isBusy: false, showModal: showModal });
    }

    PlayQuestionnaire(activity) {
        this.setState({ scrollPosition: window.scrollY, showModal: { playQuestionnaire: true }, selectedActivity: activity })
    }

    PlayQuestionnaireClose() {
        this.setState({ showModal: { playQuestionnaire: false }, selectedActivity: null })
        //this.GetData();
    }

    PlayBattery(activity) {
        this.setState({ scrollPosition: window.scrollY, showModal: { playBattery: true }, selectedActivity: activity })
    }

    PlayBatteryClose() {
        this.setState({ showModal: { playBattery: false }, selectedActivity: null })
        //this.GetData();
    }

    LoadPage() {
        //this.GetStudyTemplate();
        this.setState({ study: this.props.study, participant: this.props.participant });
    }

    componentDidMount() {
        this.LoadPage();
    }

    render() {

        return (
            <div>

                <Tabs
                    defaultActiveKey="details"
                    id="justify-tab-example"
                    className="mb-3 tabs-app"
                    justify>
                    <Tab eventKey="details" title="Study Details">

                        {this.state.participant && this.state.participant.studyDetails && this.state.participant.studyDetails.accountDeleted && (
                            <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="tinyLabel">Registration Code</div>
                                            <div className="d-flex justify-content-between">
                                                {this.state.participant.studyDetails.registrationCode}
                                                <ButtonCopyToClipboard text={this.state.participant.studyDetails.registrationCode}></ButtonCopyToClipboard>
                                            </div>
                                        </td>
                                        <td colSpan="2">
                                            <div className="tinyLabel">Account Status</div>
                                            <div>ACCOUNT DELETED</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">
                                            <div className="tinyLabel">Study Status</div>
                                            <div>{this.state.participant.schedule.status}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        )}

                        {this.state.participant && this.state.participant.studyDetails && !this.state.participant.studyDetails.accountDeleted && (
                            <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="tinyLabel">Registration Code</div>
                                            <div className="d-flex justify-content-between">
                                                {this.state.participant.studyDetails.registrationCode}
                                                <ButtonCopyToClipboard text={this.state.participant.studyDetails.registrationCode}></ButtonCopyToClipboard>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="tinyLabel">Name</div>
                                            <div className="d-flex justify-content-between">
                                                <div>{this.state.participant.studyDetails.name}</div>
                                                <ButtonCopyToClipboard text={this.state.participant.studyDetails.name}></ButtonCopyToClipboard>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="tinyLabel">Date of Birth</div>
                                            <div className="d-flex justify-content-between">
                                                <div>{this.Format.FormatDate(this.state.participant.studyDetails.dob)} (age: {this.state.participant.studyDetails.age})</div>
                                                <ButtonCopyToClipboard text={`${this.Format.FormatDate(this.state.participant.studyDetails.dob)} (age: ${this.state.participant.studyDetails.age})`}></ButtonCopyToClipboard>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tinyLabel">Email</div>
                                            <div className="d-flex justify-content-between">
                                                {this.state.participant.studyDetails.email}
                                                <ButtonCopyToClipboard text={this.state.participant.studyDetails.email}></ButtonCopyToClipboard>
                                            </div>
                                        </td>
                                        <td colSpan="2">
                                            <div className="tinyLabel">Address</div>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <div>{this.state.participant.studyDetails.address}</div>
                                                    {this.state.participant.studyDetails.postcode && this.state.participant.studyDetails.postcode.isValid && (
                                                        <div>{this.state.participant.studyDetails.postcode.full}</div>
                                                    )}
                                                    {this.state.participant.studyDetails.postcode && !this.state.participant.studyDetails.postcode.isValid && (
                                                        <div>{this.state.participant.studyDetails.postcode.raw}</div>
                                                    )}
                                                </div>
                                                {this.state.participant.studyDetails && this.state.participant.studyDetails.postcode && (
                                                    <ButtonCopyToClipboard text={`${this.state.participant.studyDetails.address}, ${this.state.participant.studyDetails.postcode.isValid ? this.state.participant.studyDetails.postcode.full : this.state.participant.studyDetails.postcode.raw}`}></ButtonCopyToClipboard>
                                                )}
                                                {this.state.participant.studyDetails && !this.state.participant.studyDetails.postcode && (
                                                    <ButtonCopyToClipboard text={`${this.state.participant.studyDetails.address}`}></ButtonCopyToClipboard>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <div className="tinyLabel">Study Status</div>
                                            <div>{this.state.participant.schedule.status}</div>
                                        </td>
                                        <td colSpan="2">
                                            <div className="tinyLabel">Randomised Into Study</div>
                                            <div>{this.state.participant.studyDetails.randomisationCode ? "YES" : "NO"}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        )}

                        {this.state.participant && this.state.participant.schedule && (
                            this.RenderSummary()
                        )}

                    </Tab>


                    {this.props.user.IsDeveloper() && this.state.participant && this.state.participant.schedule && this.state.participant.schedule.facts && (
                        <Tab eventKey="comms" title="Communications">
                                {this.RenderComms(this.state.participant.schedule.facts)}
                        </Tab>
                    )}

                    <Tab eventKey="activity" title="Admin Activities">

                        {this.state.participant && this.state.participant.schedule && (
                            <ActivityList
                                ref={this.activityList}
                                schedule={this.state.participant.schedule}
                                dashboardType="ONCE"
                                owner="ADMIN"
                                hideIfEmpty={true}
                                onSelectActivity={(activity) => this.OpenActivity(activity)}
                                onTimerCompleted={() => { this.RefreshSchedule() }}
                                user={this.props.user}
                                theme={this.props.theme}
                                device={this.props.device}
                            />
                        )}

                        {this.state.participant && this.state.participant.schedule && (
                            <ActivityList
                                ref={this.activityList}
                                schedule={this.state.participant.schedule}
                                dashboardType="ALWAYS"
                                owner="ADMIN"
                                onSelectActivity={(activity) => this.OpenActivity(activity)}
                                onTimerCompleted={() => { this.RefreshSchedule() }}
                                user={this.props.user}
                                theme={this.props.theme}
                                device={this.props.device}
                            />
                        )}

                    </Tab>

                </Tabs>

                <Modal show={this.state.showModal.participantActivity} onHide={() => this.ParticipantActivityClose()} size="xl" fullscreen="xl-down" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">
                            {this.state.participantActivity && this.state.participantActivity.current && (
                                <div>{this.state.participantActivity.current.displayName}</div>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <ParticipantDetailsActivityAdmin
                            participant={this.state.participant}
                            study={this.state.study}
                            participantActivity={this.state.participantActivity}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                            onUpdateParticipant={(participant) => this.setState({ participant: participant }) }
                        />

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.ParticipantActivityClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <PlayQuestionnaireModal
                    show={this.state.showModal.playQuestionnaire}
                    activity={this.state.selectedActivity}
                    user={this.props.user}
                    theme={this.props.theme}
                    device={this.props.device}
                    onHide={() => this.PlayQuestionnaireClose()}
                />

                <PlayBatteryModal
                    show={this.state.showModal.playBattery}
                    activity={this.state.selectedActivity}
                    user={this.props.user}
                    theme={this.props.theme}
                    device={this.props.device}
                    onHide={() => this.PlayBatteryClose()}
                />

                <ModalConfirm
                    ref={this.cannotOpenActivity}
                    show={this.state.showModal.cannotOpenActivity}
                    onConfirm={() => this.CloseCannotOpenActivityConfirm()}
                    title={this.state.cannotOpenTitle}
                    message={this.state.cannotOpenMessage}
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>

        );
    }

    OnlyUnique(value, index, array) {
        return array.findIndex(item => item.code === value.code && item.version === value.version && item.occurrence === value.occurrence && item.instance === value.instance) === index;
    }

    RenderComms(facts) {
        let communications = [];
        facts.filter(f => f.category === "COMMUNICATION").sort((a, b) => new Date(b.statusDate) - new Date(a.statusDate)).forEach(f => {

            communications.push(
                <tr>
                    <td>{this.Format.FormatDateTime(f.statusDate)}</td>
                    <td>{f.code}</td>
                    <td>{f.status}</td>
                </tr>
            )
        });
        return (<table responsive className="noEndBorder transparent-table w-100" variant={this.props.theme.GetPlayBackgroundVariant()}><tbody>{communications}</tbody></table>);
    }

    RenderSummary() {

        let tabulatedData = [];
        if (this.state.study) {
            this.state.study.stages.filter(s => s.owner === "PARTICIPANT" || s.owner === "SYSTEM").forEach((stage, sIndex) => {

                let activities = [];
                if (stage.activities) {
                    stage.activities.filter(s => s.owner === "PARTICIPANT" || s.owner === "SYSTEM").filter(this.OnlyUnique).forEach((activity, aIndex) => {

                        activities.push(

                            <Card key={`ACTIVITY_${aIndex}`} className="clickable-card m-1 p-0" onClick={() => { this.ParticipantActivityOpen(stage, activity, null) }}>
                                <Card.Body className="p-2">
                                    <Card.Text as="div">

                                        <div className="container">
                                            <div className="row justify-content-md-start">
                                                <div className="col">
                                                    {activity.text.displayName && (
                                                        <div>
                                                            <div><b>{activity.text.displayName}</b></div>
                                                            <div className="miniLabel">{activity.code} v{activity.version} ({activity.occurrence}.1)</div>
                                                        </div>
                                                    )}
                                                    {!activity.text.displayName && (
                                                        <div>
                                                            <div><b>{activity.code} v{activity.version} ({activity.occurrence}.1)</b></div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-auto">

                                                </div>

                                                {this.RenderValue("PENDING", stage, activity, "variant-pending", "Pending")}
                                                {this.RenderValue("TRIGGERED", stage, activity, "variant-triggered", "Triggered")}
                                                {this.RenderValue("OPEN", stage, activity, "variant-open", "Open")}
                                                {this.RenderValue("ACTIVE", stage, activity, "variant-active", "Active")}
                                                {this.RenderValue("PASSED", stage, activity, "variant-passed", "Passed")}
                                                {this.RenderValue("FAILED", stage, activity, "variant-failed", "Failed")}
                                                {this.RenderValue("EXPIRED", stage, activity, "variant-expired", "Expired")}
                                                {this.RenderValue("CLOSED", stage, activity, "variant-expired", "Closed")}

                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        )

                    });
                }

                if (activities.length > 0 || this.GetCurrentParticipantStageStatus(this.state.participant, stage.code) === "OPEN") {
                    tabulatedData.push(

                        <Accordion key={`STAGE_${sIndex}`} className="pb-2 accordian-dashboard" defaultActiveKey={`${stage.code}_${sIndex}`}>
                            <Accordion.Item className="accordian-item-dashboard w-100" eventKey={`${stage.code}_${sIndex}`}>
                                <Accordion.Header className="accordian-header-dashboard">
                                    <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="p-0 m-0" dangerouslySetInnerHTML={{ __html: stage.text.displayName }}></h4>
                                            <div><small>{this.GetCurrentParticipantStageStatus(this.state.participant, stage.code)}</small></div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="accordian-body-dashboard">
                                    {activities}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    );
                }

            });
        }
        return tabulatedData;
    }

    RenderValue(status, stage, activity, cssName, desc) {

        let date = this.GetFactDate(stage.code, activity, status);
        if (date) {
            return (
                <div className={`col col-lg-1 text-center ${cssName}`} onClick={() => { this.ParticipantActivityOpen(stage, activity, status) }}>
                    <div className="tinyLabel">{desc}</div>
                    <div className="smallFont">{this.Format.FormatDate(date)}</div>
                </div>
            );
        } else {
            return (
                <div className="col col-lg-1 text-center variant-empty" onClick={() => { this.ParticipantActivityOpen(stage, activity, null) }}>
                    <div className="tinyLabel">{desc}</div>
                </div>
            );
        }
    }
}