import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
export class ButtonStatusResults extends Component {

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    StatusClass() {
        let classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
        switch (this.props.status) {
            case "PENDING":
            case "TRIGGERED":
            case "OPEN":
                classes = "text-nowrap btn btn-sm btn-info rounded-pill white";
                break;
            case "ACTIVE":
                classes = "text-nowrap btn btn-sm btn-primary rounded-pill white";
                break;
            case "COMPLETED":
            case "PASSED":
                classes = "text-nowrap btn btn-sm btn-success rounded-pill white";
                break;
            case "FAILED":
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
            case "SKIPPED":
            case "EXPIRED":
            case "CLOSED":
                classes = "text-nowrap btn btn-sm btn-warning rounded-pill white";
                break;
            default:
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
        }
        return classes;
    }

    render() {
        return (
            <button type="button" className={this.StatusClass()} onClick={() => { this.ButtonClick() }}>
                <small>{this.props.status }</small>
            </button>
        );
    }

}
