import React, { Component } from "react";
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonAdd } from '../shared/Buttons/Add';

export class EditStratificationGroupForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            isNameValid: true,
            nameMessage: 'Please enter a valid name',
            areBandsValid: true,
            bandsMessage: 'You must add one or more bands or values',
            editItem: {
                name: '',
                bands: [],
            },
            editIndex: -1,
            existingNames: [],
            listType: "values",
            listOfFixedValues: [],
            listOfRanges: [],
            bandErrorMessage: null,
        };

        this.newValueRef = React.createRef();
        this.newValueRef2 = React.createRef();
    }

    UpdateName(event) {
        let updatedItem = update(this.state.editItem, { name: { $set: event.target.value } });
        this.setState({ editItem: updatedItem });
    }

    NameIsValid() {
        let valid = true;
        var value = this.state.editItem.name.trim().toUpperCase();
        if (this.IsEmpty(value)) {
            this.setState({ nameMessage: "The name cannot be blank" });
            valid = false;
        } else if (this.state.existingNames.includes(value)) {
            this.setState({ nameMessage: "This name already exists" });
            valid = false;
        }
        this.setState({ isNameValid: valid });
        return valid;
    }

    BandsAreValid() {
        let valid = true;
        switch (this.state.listType) {
            case "values":
                valid = this.state.listOfFixedValues.length > 0;
                break;
            default: // case "bands":
                valid = this.state.listOfRanges.length > 0;
                break;
        }
        this.setState({ areBandsValid: valid });
        return valid;
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    AddBand(target) {
        this.setState({ bandErrorMessage: null });
        if (!target || target.charCode === 13) {
            switch (this.state.listType) {
                case "values":
                    if (!this.newValueRef.current.value || this.IsEmpty(this.newValueRef.current.value)) {
                        this.setState({ bandErrorMessage: "You must enter a value" })
                    } else {
                        if (this.state.listOfFixedValues.filter(item => item.toLowerCase() === this.newValueRef.current.value.toLowerCase()).length > 0) {
                            this.setState({ bandErrorMessage: "Values must be unique" })
                        } else {

                            let updatedList = update(this.state.listOfFixedValues, { $push: [this.newValueRef.current.value] });
                            this.setState({ listOfFixedValues: updatedList });
                            this.newValueRef.current.value = '';

                        }
                    }
                    break;
                default: // case "bands":
                    if (!this.newValueRef.current.value || this.IsEmpty(this.newValueRef.current.value) ||
                        !this.newValueRef2.current.value || this.IsEmpty(this.newValueRef2.current.value)) {
                        this.setState({ bandErrorMessage: "You must enter a minumum and maximum value" })
                    } else {
                        let min = parseFloat(this.newValueRef.current.value);
                        let max = parseFloat(this.newValueRef2.current.value);
                        if (isNaN(min) || isNaN(max) || min >= max) {
                            this.setState({ bandErrorMessage: "Both values must be a number, with the minimum less than the maximum" })
                        } else {
                            let overlaps = false;
                            this.state.listOfRanges.forEach((item => {
                                let minMaxRange = this.SplitBand(item);
                                if (minMaxRange.length === 2 && (
                                    (min >= minMaxRange[0] && min <= minMaxRange[1]) || // New min fits inside existing min-max
                                    (max >= minMaxRange[0] && max <= minMaxRange[1]) || // New max fits inside existing min-max
                                    (min <= minMaxRange[0] && max >= minMaxRange[1])    // New min-max wraps existing min-max
                                )) {
                                    overlaps = true;
                                }
                            }));
                            if (overlaps) {
                                this.setState({ bandErrorMessage: "Ranges cannot overlap" })
                            } else {

                                let updatedList = update(this.state.listOfRanges, { $push: [`RANGE(${this.newValueRef.current.value},${this.newValueRef2.current.value})`] });
                                this.setState({ listOfRanges: updatedList });
                                this.newValueRef.current.value = '';
                                this.newValueRef2.current.value = '';

                            }
                        }
                    }
                    break;
            }
            this.setState({ areBandsValid: true });
        }
    }

    RemoveBand(index) {
        if (index >= 0) {
            let updatedList = [];
            switch (this.state.listType) {
                case "values":
                    updatedList = update(this.state.listOfFixedValues, { $splice: [[index, 1]] });
                    this.setState({ listOfFixedValues: updatedList });
                    break;
                default: // case "bands":
                    updatedList = update(this.state.listOfRanges, { $splice: [[index, 1]] });
                    this.setState({ listOfRanges: updatedList });
                    break;
            }
        }
    }

    GetRange(item) {
        return item.startsWith('RANGE(') ? item.replace('RANGE(','').replace(')', '') : item;
    }

    FormatBand(item) {
        return item.startsWith('RANGE(') ? this.GetRange(item).replace(',', ' to ') : item;
    }

    SplitBand(item) {
        return item.startsWith('RANGE(') ? this.GetRange(item).split(',') : item;
    }

    CompareValue(v1, v2) {
        return v1.toLowerCase().localeCompare(v2.toLowerCase());
    }

    CompareRange(r1, r2) {
        let min1 = parseInt(r1.replace('RANGE(', '').replace(')', '').split(',')[0]);
        let min2 = parseInt(r2.replace('RANGE(', '').replace(')', '').split(',')[0]);
        return min1 - min2;
    }

    SaveClick() {
        if (this.props.onSave) {

            let updatedItem = update(this.state.editItem, { bands: { $set: this.state.listType === "values" ? this.state.listOfFixedValues : this.state.listOfRanges } });
            this.setState({ isBusy: true });
            this.setState({
                isFormValidated: true,
                isFormValid: this.NameIsValid() & this.BandsAreValid(),
                editItem: updatedItem,
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    componentDidMount() {
        console.log("this.props", this.props);
        if (this.props.item) {
            this.setState({ editIndex: this.props.index });
            let listType = this.props.item.bands.length > 0 && this.props.item.bands[0].startsWith("RANGE(") ? "bands" : "values";
            if (this.props.item.bands.length > 0) {
                switch (listType) {
                    case "values":
                        this.setState({ listOfFixedValues: this.props.item.bands });
                        break;
                    default: // case "bands":
                        this.setState({ listOfRanges: this.props.item.bands });
                        break;
                }
            }
            this.setState({ editItem: this.props.item, listType: listType });
        }
        if (this.props.existing) {
            this.setState({ existingNames: this.props.existing.filter(item => this.props.item === undefined || item.name !== this.props.item.name).map(item => `${item.name.trim().toUpperCase()}`) });
        }
    }

    componentWillUnmount() {
    }

    render() {
        let bands = [];
        switch (this.state.listType) {
            case "values":
                bands = this.state.listOfFixedValues.sort(this.CompareValue);
                break;
            default: // case "bands":
                bands = this.state.listOfRanges.sort(this.CompareRange).map(item => this.FormatBand(item));
                break;
        }
        return (
            <div>
                <Form noValidate validated={this.state.isFormValidated}>
                    {this.state.isBusy ?
                        <div className="busy-overlay col d-flex align-items-center justify-content-center">
                            <Spinner animation="border" role="status" variant="dark" />
                        </div>
                        : ''}
                    <Form.Group className="mb-4" id="name_input">
                        <Form.Label><b>Name</b></Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Please enter a unique name"
                            value={this.state.editItem.name}
                            onChange={(event) => { this.UpdateName(event) }}
                            isInvalid={!this.state.isNameValid}
                            maxLength="50"
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.nameMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-1" id="name_input">
                        <Form.Label><b>Values/Bands</b></Form.Label>
                        <Form.Control as="select" value={this.state.listType} onChange={(event) => this.setState({ listType: event.currentTarget.value })} className="prevent-validation">
                            <option value="values">List of fixed values</option>
                            <option value="bands">List of bands (eg. 18 to 30)</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Row} className="align-items-center" id="name_input">

                        {this.state.listType === "values" && (
                            <Col>
                                <Form.Control
                                    ref={this.newValueRef}
                                    type="text"
                                    placeholder="Enter a value"
                                    onKeyPress={(target) => this.AddBand(target)}
                                    className="prevent-validation"
                                    maxLength="50"
                                />
                            </Col>
                        )}
                        {this.state.listType === "bands" && (
                            <Col>
                                <Row className="align-items-center" >
                                    <Col>
                                        <Form.Control
                                            ref={this.newValueRef}
                                            type="text"
                                            placeholder="Min"
                                            className="prevent-validation"
                                        />
                                    </Col>
                                    <Col xs="auto">to
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            ref={this.newValueRef2}
                                            type="text"
                                            placeholder="Max"
                                            onKeyPress={(target) => this.AddBand(target)}
                                            className="prevent-validation"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        <Col xs="auto">
                            <ButtonAdd onClick={() => this.AddBand()} />
                        </Col>

                    </Form.Group>

                    {this.state.bandErrorMessage &&
                        <div className="invalid-feedback d-inline">
                            {this.state.bandErrorMessage}
                        </div>
                    }

                    {bands.length > 0 && (
                        <div className="mt-3">
                            {bands.map((item, index) => (
                                <button key={index} type="button" className="text-nowrap btn btn-sm btn-secondary rounded-pill m-1">
                                    <span className="mx-2">{item}</span>
                                    <span className="bi bi-x-circle" onClick={() => { this.RemoveBand(index) }}></span>
                                </button>
                            ))}
                        </div>
                    )}

                    {!this.state.bandErrorMessage && !this.state.areBandsValid &&
                        <div className="invalid-feedback d-inline">
                            {this.state.bandsMessage}
                        </div>
                    }

                </Form>
            </div>
        );
    }

}
