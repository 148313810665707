import React, { Component } from 'react';
import { StudyApi, AdminApi } from '../shared/BEACONApi'
import { BatteryApi } from '../shared/CTAGApi'
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { Validate, Format } from '../shared/GlobalFns'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { ModalConfirm } from '../shared/ModalConfirm';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import update from 'immutability-helper';

export class ParticipantDetailsActivityAdmin extends Component {
    static displayName = ParticipantDetailsActivityAdmin.name;
    static isAdmin = true;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            isBusy: true,
            summaryData: null,
            participant: null,
            participantActivity: null,
            participantActivityResults: null,
            showModal: {
                participant: false,
                participantActivity: false,
                confirmReOpen: false,
                confirmReset: false,
                confirmPass: false,
                confirmFail: false,
                confirmRandomise: false,
            }
        }

        this.StudyApi = new StudyApi();
        this.AdminApi = new AdminApi();
        this.Validate = new Validate();
        this.Format = new Format();
        this.BatteryApi = new BatteryApi();
        this.QuestionnaireApi = new QuestionnaireApi();
    }

    async ParticipantActivityReOpen(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        if (participantStudyId > 0 && !this.Validate.IsEmpty(activity.stageCode) && !this.Validate.IsEmpty(activity.code) && activity.version > 0 && occurenceInstance > 0) {
            await this.StudyApi.TriggerActivity(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {

                let participantActivity = this.GetParticipantActivity(activity.stageCode, activity, data);
                this.setState({ participant: data, participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false }, () => {

                    this.ConfirmReOpenClose();
                    if (this.props.onUpdateParticipant)
                        this.props.onUpdateParticipant(this.state.participant);

                });

            }, (errors) => {

                this.setState({ participant: null, isBusy: false }, () => {
                    this.ConfirmReOpenClose();
                });

            });
        }
    }

    async ParticipantActivityReset(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        if (participantStudyId > 0 && !this.Validate.IsEmpty(activity.stageCode) && !this.Validate.IsEmpty(activity.code) && activity.version > 0 && occurenceInstance > 0) {
            await this.StudyApi.ResetActivity(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {

                let participantActivity = this.GetParticipantActivity(activity.stageCode, activity, data);
                this.setState({ participant: data, participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false }, () => {

                    this.ConfirmResetClose();
                    if (this.props.onUpdateParticipant)
                        this.props.onUpdateParticipant(this.state.participant);

                });

            }, (errors) => {

                this.setState({ participant: null, isBusy: false }, () => {
                    this.ConfirmResetClose();
                });

            });
        }
    }

    async ParticipantActivityPass(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        if (participantStudyId > 0 && !this.Validate.IsEmpty(activity.stageCode) && !this.Validate.IsEmpty(activity.code) && activity.version > 0 && occurenceInstance > 0) {
            await this.StudyApi.PassActivity(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {

                let participantActivity = this.GetParticipantActivity(activity.stageCode, activity, data);
                this.setState({ participant: data, participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false }, () => {

                    this.ConfirmPassClose();
                    if (this.props.onUpdateParticipant)
                        this.props.onUpdateParticipant(this.state.participant);

                });

            }, (errors) => {

                this.setState({ participant: null, isBusy: false }, () => {
                    this.ConfirmPassClose();
                });

            });
        }
    }

    async ParticipantActivityFail(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        if (participantStudyId > 0 && !this.Validate.IsEmpty(activity.stageCode) && !this.Validate.IsEmpty(activity.code) && activity.version > 0 && occurenceInstance > 0) {
            await this.StudyApi.FailActivity(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {

                let participantActivity = this.GetParticipantActivity(activity.stageCode, activity, data);
                this.setState({ participant: data, participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false }, () => {

                    this.ConfirmFailClose();
                    if (this.props.onUpdateParticipant)
                        this.props.onUpdateParticipant(this.state.participant);

                });

            }, (errors) => {

                this.setState({ participant: null, isBusy: false }, () => {
                    this.ConfirmFailClose();
                });

            });
        }
    }

    async ParticipantRandomise(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        if (participantStudyId > 0 && !this.Validate.IsEmpty(activity.stageCode) && !this.Validate.IsEmpty(activity.code) && activity.version > 0 && occurenceInstance > 0) {
            await this.StudyApi.Randomise(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {

                let participantActivity = this.GetParticipantActivity(activity.stageCode, activity, data);
                this.setState({ participant: data, participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false }, () => {

                    this.ConfirmRandomiseClose();
                    if (this.props.onUpdateParticipant)
                        this.props.onUpdateParticipant(this.state.participant);

                });

            }, (errors) => {

                this.setState({ participant: null, isBusy: false }, () => {
                    this.ConfirmRandomiseClose();
                });

            });
        }
    }

    async GetParticipantActivityResults(participantStudyId, activity) {

        let occurenceInstance = parseFloat(`${activity.occurrence}.${activity.instance}`);
        switch (activity.type) {
            case "PACKAGE":

                await this.BatteryApi.GetResultsByParticipantIdAndCode(participantStudyId, activity.code, activity.version, occurenceInstance, (data) => {
                    if (data.id > 0) {
                        this.setState({ participantActivityResults: data }, () => {
                            //console.log("PACKAGE", this.state.participantActivityResults)
                        });
                    } else {
                        this.setState({ participantActivityResults: null });
                    }
                }, (errors) => {
                    this.setState({ participantActivityResults: null });
                })
                break;

            case "BATTERY":

                await this.BatteryApi.GetResultsByParticipantIdAndCode(participantStudyId, activity.code, activity.version, occurenceInstance, (data) => {
                    if (data.id > 0) {
                        this.setState({ participantActivityResults: data }, () => {
                            //console.log("BATTERY", this.state.participantActivityResults)
                        });
                    } else {
                        this.setState({ participantActivityResults: null });
                    }
                }, (errors) => {
                    this.setState({ participantActivityResults: null });
                })
                break;

            case "QUESTIONNAIRE":

                await this.QuestionnaireApi.GetResultsByParticipantIdAndCode(participantStudyId, activity.code, activity.version, occurenceInstance, (data) => {
                    if (data.id > 0) {
                        this.setState({ participantActivityResults: data }, () => {
                            //console.log("QUESTIONNAIRE", this.state.participantActivityResults)
                        });
                    } else {
                        this.setState({ participantActivityResults: null });
                    }
                }, (errors) => {
                    this.setState({ participantActivityResults: null });
                })
                break;

            case "RANDOMISATION":

                await this.StudyApi.GetRandomisationResults(participantStudyId, `${activity.stageCode}.${activity.code}`, activity.version, occurenceInstance, (data) => {
                    this.setState({ participantActivityResults: data }, () => {
                        //console.log("RANDOMISATION", this.state.participantActivityResults);
                    });
                }, (errors) => {
                    this.setState({ participantActivityResults: null });
                })
                break;

            default:

                this.setState({ participantActivityResults: null });
                break;
        }

    }

    ParticipantClose() {
        let showModal = update(this.state.showModal, { participant: { $set: false } });
        this.setState({ participant: null, isBusy: false, showModal: showModal });
    }

    GetParticipantActivity(stageCode, activity, participant) {

        let participantActivity = null;
        let scheduleStages = participant.schedule.stages.filter(s => s.code === stageCode);
        if (scheduleStages.length === 1) {
            let stageActivities = scheduleStages[0].activities.filter(a => a.code === activity.code && a.version === activity.version && a.occurrence === activity.occurrence && a.instance === activity.instance);
            if (stageActivities.length === 1) {
                participantActivity = stageActivities[0];
            }
        }
        return participantActivity;
    }

    GetStudyActivity(stageCode, code, version, occurrence, instance) {
        let studyActivity = null;
        this.state.study.stages.filter(s => s.code === stageCode).forEach((stage, sIndex) => {
            stage.activities.every((activity, aIndex) => {
                if (activity.code === code && activity.version === version && activity.occurrence === occurrence && activity.instance === instance)
                    studyActivity = activity;
                return studyActivity === null;
            });
        });
        return studyActivity;
    }

    ParticipantActivityClose() {
        let showModal = update(this.state.showModal, { participantActivity: { $set: false } });
        this.setState({ participantActivity: null, isBusy: false, showModal: showModal });
    }

    ConfirmReOpenOpen() {
        let showModal = update(this.state.showModal, { confirmReOpen: { $set: true } });
        this.setState({ showModal: showModal });
    }

    ConfirmResetOpen() {
        let showModal = update(this.state.showModal, { confirmReset: { $set: true } });
        this.setState({ showModal: showModal });
    }

    ConfirmPassOpen() {
        let showModal = update(this.state.showModal, { confirmPass: { $set: true } });
        this.setState({ showModal: showModal });
    }

    ConfirmFailOpen() {
        let showModal = update(this.state.showModal, { confirmFail: { $set: true } });
        this.setState({ showModal: showModal });
    }

    ConfirmRandomiseOpen() {
        let showModal = update(this.state.showModal, { confirmRandomise: { $set: true } });
        this.setState({ showModal: showModal });
    }

    ConfirmReOpenClose() {
        let showModal = update(this.state.showModal, { confirmReOpen: { $set: false } });
        this.setState({ showModal: showModal });
    }

    ConfirmResetClose() {
        let showModal = update(this.state.showModal, { confirmReset: { $set: false } });
        this.setState({ showModal: showModal });
    }

    ConfirmPassClose() {
        let showModal = update(this.state.showModal, { confirmPass: { $set: false } });
        this.setState({ showModal: showModal });
    }

    ConfirmFailClose() {
        let showModal = update(this.state.showModal, { confirmFail: { $set: false } });
        this.setState({ showModal: showModal });
    }

    ConfirmRandomiseClose() {
        let showModal = update(this.state.showModal, { confirmRandomise: { $set: false } });
        this.setState({ showModal: showModal });
    }

    LoadPage() {
        //this.GetStudyTemplate();
        this.setState({ study: this.props.study, summaryData: this.props.study.summary, participant: this.props.participant, participantActivity: this.props.participantActivity }, () => {
            //console.log("ACTIVITY", this.props.participantActivity);
            this.GetParticipantActivityResults(this.state.participant.studyDetails.participantStudyId, this.props.participantActivity.studyActivity);
        });
    }

    componentDidMount() {
        this.LoadPage();
    }

    render() {

        let hasActivity = this.state.participantActivity && this.state.participantActivity.current ? true : false;
        let hasResults = this.state.participantActivityResults && this.state.participantActivityResults.id > 0 ? true : false;

        let triggeredDate = hasActivity ? this.state.participantActivity.current.triggeredDate : null;
        let openDate = hasActivity ? this.state.participantActivity.current.openDate : null;
        let window = hasActivity ? this.state.participantActivity.current.window : null;
        let timeAllowed = hasActivity ? this.state.participantActivity.current.timeAllowed : null;

        let stageCode = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.stageCode : null;
        let activityCode = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.code : null;
        let occurrence = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.occurrence : null;
        let instance = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.instance : null;
        let mandatory = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.mandatory : false;
        let trigger = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.trigger : false;
        let schedule = this.state.participantActivity && this.state.participantActivity.studyActivity ? this.state.participantActivity.studyActivity.schedule : false;

        let activityStatus = hasActivity ? this.state.participantActivity.current.status : '-';
        if (hasResults && this.state.participantActivityResults.status)
            activityStatus = this.state.participantActivityResults.status;

        let activityStatusDate = hasActivity ? this.state.participantActivity.current.statusDate : null;
        if (hasResults && this.state.participantActivityResults.modifiedDate)
            activityStatusDate = this.state.participantActivityResults.modifiedDate;

        return (
            <div>

                <Tabs
                    defaultActiveKey="details"
                    id="justify-tab-example"
                    className="mb-3 tabs-app"
                    justify>

                    <Tab eventKey="details" title="Details">

                        <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="tinyLabel">Stage</div>
                                        <div>{stageCode}</div>
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Activity</div>
                                        <div>{activityCode}</div>
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Occurrence</div>
                                        <div>{occurrence}</div>
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Instance</div>
                                        <div>{instance}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tinyLabel">Mandatory</div>
                                        <div>{mandatory ? "YES" : "NO"}</div>
                                    </td>
                                    <td colSpan="3">
                                        <div className="tinyLabel">Rule</div>
                                        {trigger && (<span>{trigger};</span>)}
                                        <span>{schedule}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tinyLabel">Triggered</div>
                                        {triggeredDate && (
                                            <div>{this.Format.FormatDateTime(triggeredDate)}</div>
                                        )}
                                        {!triggeredDate && (
                                            <div>-</div>
                                        )}
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Open</div>
                                        {openDate && (
                                            <div>{this.Format.FormatDateTime(openDate)}</div>
                                        )}
                                        {!openDate && (
                                            <div>-</div>
                                        )}
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Window</div>
                                        {window && (
                                            <div>{this.Format.TimeSpan(window)}</div>
                                        )}
                                        {!window && (
                                            <div>-</div>
                                        )}
                                    </td>
                                    <td>
                                        <div className="tinyLabel">Time Allowed</div>
                                        {timeAllowed && (
                                            <div>{this.Format.TimeSpan(timeAllowed)}</div>
                                        )}
                                        {!timeAllowed && (
                                            <div>-</div>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="4">
                                        <div className="tinyLabel">Status</div>
                                        <div><span>{activityStatus}</span>{activityStatusDate && (<span>: {this.Format.FormatDateTime(activityStatusDate)}</span>)}</div>
                                    </td>
                                </tr>

                                {this.props.participantActivity && this.props.participantActivity.studyActivity && (
                                    <tr>
                                        <td colSpan="4">
                                            {this.RenderOptions(this.props.participantActivity.studyActivity.type, activityStatus, hasResults)}
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </Table>

                    </Tab>

                    <Tab eventKey="results" title="Results">
                        {this.props.participantActivity && this.props.participantActivity.studyActivity && (
                            <div>
                                {this.RenderResults(this.props.participantActivity.studyActivity.type)}
                            </div>
                        )}
                    </Tab>

                </Tabs>

                <ModalConfirm
                    show={this.state.showModal.confirmReOpen}
                    onCancel={() => this.ConfirmReOpenClose()}
                    onConfirm={() => this.ParticipantActivityReOpen(this.state.participant.studyDetails.participantStudyId, this.state.participantActivity.studyActivity)}
                    title="OPEN Expired Activity"
                    message="Are you sure you want to reopen this activity?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    show={this.state.showModal.confirmReset}
                    onCancel={() => this.ConfirmResetClose()}
                    onConfirm={() => this.ParticipantActivityReset(this.state.participant.studyDetails.participantStudyId, this.state.participantActivity.studyActivity)}
                    title="RESET Activity"
                    message="Resetting this activity will DELETE any previous answers and require the user to start again. Are you sure you want to reset this activity?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    show={this.state.showModal.confirmPass}
                    onCancel={() => this.ConfirmPassClose()}
                    onConfirm={() => this.ParticipantActivityPass(this.state.participant.studyDetails.participantStudyId, this.state.participantActivity.studyActivity)}
                    title="PASS Activity"
                    message="Marking this activity as PASSED will update the schedule but will not add any actual results to the database?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    show={this.state.showModal.confirmFail}
                    onCancel={() => this.ConfirmFailClose()}
                    onConfirm={() => this.ParticipantActivityFail(this.state.participant.studyDetails.participantStudyId, this.state.participantActivity.studyActivity)}
                    title="FAIL Activity"
                    message="Marking this activity as FAILED will update the schedule but will not add any actual results to the database?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    show={this.state.showModal.confirmRandomise}
                    onCancel={() => this.ConfirmRandomiseClose()}
                    onConfirm={() => this.ParticipantRandomise(this.state.participant.studyDetails.participantStudyId, this.state.participantActivity.studyActivity)}
                    title="RANDOMISE Participant"
                    message="Are you sure that you want to run the randomisation rule?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>

        );
    }

    RenderOptions(type, activityStatus, hasResults) {

        let options = [];
        switch (type) {
            case "PACKAGE":
                break;

            case "BATTERY":

                switch (activityStatus) {
                    case "-":
                    case "PENDING":
                    case "TRIGGERED":

                        options.push(
                            <Button className="me-2" variant="warning" onClick={() => this.ConfirmReOpenOpen()}>
                                OPEN Activity
                            </Button>
                        );
                        break;

                    case "OPEN":
                    case "ACTIVE":

                        options.push(
                            <Button className="me-2" variant="success" onClick={() => this.ConfirmPassOpen()}>
                                PASS Activity
                            </Button>
                        );
                        options.push(
                            <Button className="me-2" variant="danger" onClick={() => this.ConfirmFailOpen()}>
                                FAIL Activity
                            </Button>
                        );
                        break;

                    case "PASSED":
                        if (hasResults) {

                            options.push(
                                <Button className="me-2" variant="danger" onClick={() => this.ConfirmFailOpen()}>
                                    FAIL Activity
                                </Button>
                            );

                        } else {

                            options.push(
                                <Button className="me-2" variant="warning" onClick={() => this.ConfirmReOpenOpen()}>
                                    OPEN Activity
                                </Button>
                            );

                        }
                        break;

                    case "FAILED":
                        if (hasResults) {

                            options.push(
                                <Button className="me-2" variant="success" onClick={() => this.ConfirmPassOpen()}>
                                    PASS Activity
                                </Button>
                            );

                        } else {

                            options.push(
                                <Button className="me-2" variant="warning" onClick={() => this.ConfirmReOpenOpen()}>
                                    OPEN Activity
                                </Button>
                            );

                        }
                        break;

                    case "CLOSED":
                    case "EXPIRED":

                        if (hasResults) {

                            options.push(
                                <Button className="me-2" variant="success" onClick={() => this.ConfirmPassOpen()}>
                                    PASS Activity
                                </Button>
                            );
                            options.push(
                                <Button className="me-2" variant="danger" onClick={() => this.ConfirmFailOpen()}>
                                    FAIL Activity
                                </Button>
                            );

                        } else {

                            options.push(
                                <Button className="me-2" variant="warning" onClick={() => this.ConfirmReOpenOpen()}>
                                    OPEN Activity
                                </Button>
                            );

                        }
                        break;

                    default:
                        break;

                }
                break;

            case "QUESTIONNAIRE":

                switch (activityStatus) {
                    case "-":
                    case "PENDING":
                    case "TRIGGERED":
                    case "PASSED":
                    case "FAILED":
                    case "EXPIRED":

                        options.push(
                            <Button className="me-2" variant="warning" onClick={() => this.ConfirmReOpenOpen()}>
                                OPEN Activity
                            </Button>
                        );
                        if (this.props.user.IsDeveloper() && hasResults) {
                            options.push(
                                <Button className="me-2" variant="danger" onClick={() => this.ConfirmResetOpen()}>
                                    RESET Activity
                                </Button>
                            );
                        }
                        break;

                    case "OPEN":

                        options.push(
                            <Button className="me-2" variant="success" onClick={() => this.ConfirmPassOpen()}>
                                PASS Activity
                            </Button>
                        );
                        options.push(
                            <Button className="me-2" variant="danger" onClick={() => this.ConfirmFailOpen()}>
                                FAIL Activity
                            </Button>
                        );
                        break;

                    case "ACTIVE":

                        if (this.props.user.IsDeveloper() && hasResults) {
                            options.push(
                                <Button className="me-2" variant="danger" onClick={() => this.ConfirmResetOpen()}>
                                    RESET Activity
                                </Button>
                            );
                        }
                        break;

                    default:
                        break;

                }
                break;

            case "RANDOMISATION":

                switch (activityStatus) {
                    case "-":
                    case "PENDING":
                    case "TRIGGERED":
                    case "OPEN":
                    case "ACTIVE":
                    case "PASSED":
                        break;

                    case "FAILED":
                    case "EXPIRED":

                        <Button className="me-2" variant="warning" onClick={() => this.ConfirmRandomiseOpen()}>
                            RUN Randomisation Rule
                        </Button>
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }

        return options;

    }

    RenderResults(type) {

        let activityResults = [];

        if (this.state.participantActivityResults) {
            switch (type) {
                case "PACKAGE":
                    break;

                case "BATTERY":

                    if (this.state.participantActivityResults.rawResults) {

                        this.state.participantActivityResults.rawResults.forEach((result, index) => {
                            activityResults.push(
                                <div className="selectable" key={`RESULT_${index}`}><pre>{JSON.stringify(JSON.parse(result.rawResults), null, 2)}</pre></div>
                            );
                        });
                    }
                    break;

                case "QUESTIONNAIRE":
                    if (this.state.participantActivityResults.answers) {

                        this.state.participantActivityResults.answers.forEach((result, index) => {
                            activityResults.push(
                                <div className="selectable" key={`RESULT_${index}`}><pre>{JSON.stringify(result, null, 2)}</pre></div>
                            );
                        });

                    }
                    break;

                case "RANDOMISATION":
                    if (this.state.participantActivityResults.randomisedParticipant) {

                        activityResults.push(
                            <div className="selectable" key={`RESULT_1`}><pre>{JSON.stringify(this.state.participantActivityResults.randomisedParticipant.conditions, null, 2)}</pre></div>
                        );

                    }
                    if (this.state.participantActivityResults.participantStudyFact) {

                        activityResults.push(
                            <div className="selectable" key={`RESULT_2`}>{this.state.participantActivityResults.participantStudyFact.message}</div>
                        );
                    }
                    break;

                default:
                    break;
            }
        }
        return activityResults;

    }
}