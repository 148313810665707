import React, { Component } from "react";
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { Validate } from '../shared/GlobalFns'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PlayForm } from './PlayForm';
import { ModalConfirm } from '../shared/ModalConfirm';

export class PlayQuestionnaireModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            questionOptions: {
                previous: false,
                skip: false,
                next: false,
                nextEnabled: false,
                save: false
            },
            showCannotContinue: false,
        };

        this.questionnaireApi = new QuestionnaireApi();
        this.validate = new Validate();

        this.modalBody = React.createRef();
        this.playForm = React.createRef();
    }

    UpdateQuestionOptions(options) {
        this.setState({ questionOptions: options })
    }

    CloseAndSaveResults() {
        if (this.playForm.current) this.playForm.current.SaveResults(false, true);
    }

    SubmitAndSaveResults() {
        if (this.playForm.current) this.playForm.current.SaveResults(true, true);
    }

    SavedResults(index, results) {
        this.PlayClose();
    }

    RefreshResults() {
        this.PlayClose();
    }

    PlayClose() {
        if (this.props.onHide)
            this.props.onHide();
    }

    PlayNext() {
        if (this.playForm.current) this.playForm.current.NextClick();
        this.ScrollToTopOfSection();
    }

    PlayPrevious() {
        if (this.playForm.current) this.playForm.current.PreviousClick();
        this.ScrollToTopOfSection();
    }

    SkipNext() {
        if (this.playForm.current) this.playForm.current.NextClick(true);
        this.ScrollToTopOfSection();
    }

    ScrollToTopOfSection() {
        if (this.modalBody.current) {
            this.modalBody.current.scroll(0, 0);
        }
    }

    ShowOptions() {
        return (this.props.instance === 0 && !this.props.device.ShowMobileUI()) ||
            (this.ShowDone() || this.ShowPrevious() || this.ShowSkip() || this.ShowNext() || this.ShowSave());
    }

    ShowDone() {
        return this.state.questionOptions.done === true;
    }

    ShowPrevious() {
        return this.state.questionOptions.previous === true;
    }

    ShowSkip() {
        return this.state.questionOptions.skip === true;
    }

    ShowNext() {
        return this.state.questionOptions.next === true;
    }

    ShowSave() {
        return this.state.questionOptions.save === true;
    }

    NextEnabled() {
        return this.state.questionOptions.nextEnabled === true;
    }

    LoadPage() {
        this.setState({ activity: this.props.activity })
    }

    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activity !== prevProps.activity)
            this.LoadPage();
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={() => this.PlayClose()}
                    onEntered={() => this.LoadPage()}
                    size="lg"
                    fullscreen={this.props.device.ShowMobileUI() ? true : "lg-down"}
                    backdrop="static"
                    keyboard={false}
                    scrollable>

                    <Modal.Header closeButton={this.props.device.ShowMobileUI() && this.state.instance === 0} className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">
                            {this.RenderQuestionnaireHeaderText()}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="p-2 questionnaire-item-modal-body" ref={this.modalBody} >
                        {this.RenderQuestionnaire()}
                    </Modal.Body>

                    {this.ShowOptions() && (
                        <Modal.Footer className="modal-footer-app">
                            {this.RenderQuestionnaireOptions()}
                        </Modal.Footer>
                    )}

                </Modal>

                <ModalConfirm
                    show={this.state.showCannotContinue}
                    onConfirm={() => this.setState({ showCannotContinue: false })}
                    title="Sorry..."
                    message="It looks like you haven't answered a mandatory question."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderQuestionnaireHeaderText() {
        return (
            <div className="d-flex justify-content-between">
                <div>{this.state.activity && this.state.activity.displayName ? this.state.activity.displayName : 'Questionnaire'}</div>
                {this.state.activity && this.state.activity.instance > 0 && this.state.questionOptions && !this.state.questionOptions.done && (
                    <div>
                        <Button variant="danger" onClick={() => this.CloseAndSaveResults()}>
                            Quit
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    RenderQuestionnaire() {
        if (this.state.activity) {
            return (
                <PlayForm
                    ref={this.playForm}
                    index="0"
                    stageCode={this.state.activity.stageCode}
                    code={this.state.activity.code}
                    version={this.state.activity.version}
                    instance={parseFloat(`${this.state.activity.occurrence}.${this.state.activity.instance}`)}
                    onSave={(index, results) => this.SavedResults(index, results)}
                    onUpdateQuestionOptions={(options) => this.UpdateQuestionOptions(options)}
                    user={this.props.user}
                    device={this.props.device}
                    theme={this.props.theme}
                    editSubmittedResults={this.state.activity.status === "FAILED"}
                />
            );
        }
    }

    RenderQuestionnaireOptions() {
        let buttons = [];
        let index = 0;
        if (this.props.instance === 0 && !this.props.device.ShowMobileUI()) {

            buttons.push(
                <Button key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                    Close
                </Button>
            );

        } else {
            if (this.ShowDone()) {

                buttons.push(
                    <Button key={index++} variant="secondary" onClick={() => this.RefreshResults()}>
                        Done
                    </Button>
                );

            }
            if (this.ShowPrevious()) {

                buttons.push(
                    <Button key={index++} className="button-questionnaire" onClick={() => this.PlayPrevious()}>
                        Previous
                    </Button>
                );

            }
            if (this.ShowSkip()) {

                buttons.push(
                    <Button key={index++} className="button-questionnaire" onClick={() => this.SkipNext()}>
                        Skip
                    </Button>
                );

            }
            if (this.ShowNext()) {

                if (this.NextEnabled()) {

                    buttons.push(
                        <Button key={index++} className="button-questionnaire" onClick={() => { this.PlayNext() }}>
                            Next
                        </Button>
                    );

                } else {

                    buttons.push(
                        <Button key={index++} className="button-questionnaire-disabled" onClick={() => { this.setState({ showCannotContinue: true }) }}>
                            Next
                        </Button>
                    );

                }

            }
            if (this.ShowSave()) {

                if (this.NextEnabled()) {

                    buttons.push(
                        <Button key={index++} className="button-questionnaire" onClick={() => this.SubmitAndSaveResults()}>
                            Submit
                        </Button>
                    );

                } else {

                    buttons.push(
                        <Button key={index++} className="button-questionnaire-disabled" onClick={() => this.setState({ showCannotContinue: true })}>
                            Submit
                        </Button>
                    );

                }

            }
        }
        return buttons;
    }
}
