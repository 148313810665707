import React, { Component } from 'react';
import { ModalConfirm } from './shared/ModalConfirm';
import { ButtonAdd } from './shared/Buttons/Add';
import { ButtonEdit } from './shared/Buttons/Edit';
import { ButtonInfo } from './shared/Buttons/Info';
import { ButtonPlay } from './shared/Buttons/Play';
import { ButtonDelete } from './shared/Buttons/Delete';
import { EditForm } from './Randomisation/EditForm';
import { PlayForm } from './Randomisation/PlayForm';
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TablePagination } from './shared/TablePagination';
import { RandomisationApi } from './shared/RandomisationApi'

export class Randomise extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fields: [
                { key: 'code', label: 'Code' },
                { key: 'studyGroups', label: 'Study Groups' },
                { key: 'stratificationGroups', label: 'Stratification Groups' },
                { key: 'algorithm', label: 'Algorithm' },
                { key: 'participantCount', label: 'Participants' },
                { key: 'actions', label: '' },
            ],
            showModal: {
                edit: false,
                confirm: false,
                play: false,
            },
            selectedItem: {},
            selectedIndex: -1,
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
        };

        this.editForm = React.createRef();
        this.playForm = React.createRef();
        this.confirmModal = React.createRef();

        this.RandomisationApi = new RandomisationApi();

    }

    async GetData() {

        await this.RandomisationApi.Query(this.state.dataQuery, (results) => {

            let updatedDataQuery = update(results.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: results.totalCount } });
            this.setState({
                data: results.data,
                dataQuery: updatedDataQuery,
            });

        }, (errors) => {

        });

    }

    async PlayOpen(index, item) {

        await this.RandomisationApi.Get(item.id, (data) => {

            this.setState({ showModal: { play: true }, selectedIndex: index, selectedItem: data });

        }, (errors) => {

        });

    }

    async SaveItem(index, item) {

        await this.RandomisationApi.SaveItem(item, (data) => {

            this.UpdateItemInList(index, data);

        }, (errors) => {

            if (this.editForm.current) this.editForm.current.SaveFailed(errors)

        });

    }

    async DeleteItem() {

        await this.RandomisationApi.DeleteItem(this.state.selectedItem.id, (data) => {

            let updatedData = update(this.state.data, { $splice: [[this.state.selectedIndex, 1]] });
            this.setState({ showModal: { confirm: false }, data: updatedData });

        }, (errors) => {

            if (this.confirmModal.current) this.confirmModal.current.ConfirmFailed(errors)

        });

    }

    TabulateData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            this.state.fields.forEach((field) => {
                switch (field.key) {
                    case 'code':
                    case 'participantCount':
                        row[field.key] = data[field.key] ?? 0;
                        break;
                    case 'studyGroups':
                        row[field.key] = data[field.key].map(studyGroup => studyGroup.name).join(', ');
                        break;
                    case 'stratificationGroups':
                        row[field.key] = data[field.key].map(stratificationGroups => `${stratificationGroups.name}`).join(', ');
                        break;
                    case 'algorithm':
                        row[field.key] = data[field.key].type;
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    EditOpen(index, item) {
        this.setState({ showModal: { edit: true }, selectedIndex: index ?? -1, selectedItem: item ?? { code: '', studyGroups: [], stratificationGroups: [], algorithm: { type: 'Minimisation', minimisationProbability: 1, minBlockRepeat: 4, maxBlockRepeat: 6 }, summary: { numberRandomised: 0, numberRandomlyAllocated: 0, summary: [] }, participantCount: 0 } });
    }

    EditClose() {
        this.setState({ showModal: { edit: false } });
    }

    EditSave() {
        if (this.editForm.current) this.editForm.current.SaveClick();
    }

    DeleteOpen(index, item) {
        this.setState({ showModal: { confirm: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DeleteClose() {
        this.setState({ showModal: { confirm: false } });
    }

    PlayClose() {
        let modalState = update(this.state.showModal, { play: { $set: false } });
        this.setState({ showModal: modalState });
    }

    PlayUpload() {
        if (this.playForm.current) this.playForm.current.UploadFile();
    }

    UpdateItemInList(index, data) {
        let updatedData = {};
        if (index >= 0)
            updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
        else
            updatedData = update(this.state.data, { $push: [data] });

        let modalState = update(this.state.showModal, { edit: { $set: false } });
        this.setState({ showModal: modalState, data: updatedData });
    }

    componentDidMount() {
        this.GetData();
    }

    render() {
        let rows = this.TabulateData();
        return (
            <div className="flex-fill">

                {this.RenderRules()}

                <Modal show={this.state.showModal.edit} onHide={() => this.EditClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Randomisation Rule Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><EditForm ref={this.editForm} index={this.state.selectedIndex} item={this.state.selectedItem} existing={this.state.data} onUpdate={(index, data) => this.UpdateItemInList(index, data)} onSave={(index, data) => this.SaveItem(index, data)} user={this.props.user} device={this.props.device} theme={this.props.theme} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.EditClose()}>
                            Close
                        </Button>
                        {!this.props.user.IsGuest() && this.state.selectedItem.participantCount === 0 && (
                            <Button variant="primary" onClick={() => this.EditSave()}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal.play} onHide={() => this.PlayClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>{this.state.selectedItem.code ? this.state.selectedItem.code + ' - ' : '' } Randomise Participants</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><PlayForm ref={this.playForm} index={this.state.selectedIndex} item={this.state.selectedItem} onRandomised={(index, data) => this.SaveItem(index, data)} user={this.props.user} device={this.props.device} theme={this.props.theme} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.PlayClose()}>
                            Close
                        </Button>
                        {!this.props.user.IsGuest() && (
                            <Button variant="primary" onClick={() => this.PlayUpload()}>
                                Randomise File
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                <ModalConfirm ref={this.confirmModal} show={this.state.showModal.confirm} onCancel={() => this.DeleteClose()} onConfirm={() => this.DeleteItem()} title="Delete Rule" message="Are you sure you want to delete this randomisation rule?" />

            </div>
        );
    }

    RenderRules() {
        let rows = this.TabulateData();
        return (
            <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                <thead>
                    <tr>
                        <th colSpan={this.state.fields.length - 1} className="text-start text-nowrap">
                            <TablePagination canSearch canChangePageResults dataQuery={this.state.dataQuery} onUpdated={(query) => this.setState({ dataQuery: query }, () => { this.GetData(); })} />
                        </th>
                        <th className="text-end text-nowrap">
                            {this.props.user.IsGuest() && (
                                <span></span>
                            )}
                            {!this.props.user.IsGuest() && (
                                <ButtonAdd onClick={() => this.EditOpen()} text="New Rule" />
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((rule, index) => (
                        <tr key={index}>
                            {this.state.fields.map((item, subindex) => (
                                rows[index][item.key] === 'Actions' ?
                                    this.RowActions(index, subindex, rule.participantCount) :
                                    <td key={subindex}>
                                        <div><span className="miniLabel">{item.label}:</span></div>
                                        <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                    </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    RowActions(index, subindex, participantCount) {
        if (this.props.user && (this.props.user.IsDeveloper() || this.props.user.IsAdmin() || this.props.user.IsUser())) {
            if (participantCount === 0)
                return (
                    <td key={subindex} className="text-end text-nowrap">
                        <div><span className="miniLabel">&nbsp;</span></div>
                        <ButtonDelete onClick={() => this.DeleteOpen(index, this.state.data[index])} />&nbsp;
                        <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                        <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                    </td>
                )
            else
                return (
                    <td key={subindex} className="text-end text-nowrap">
                        <div><span className="miniLabel">&nbsp;</span></div>
                        <ButtonInfo onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    </td>
                )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap">
                    <div><span className="miniLabel">&nbsp;</span></div>
                    <ButtonInfo onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        }
    }
}
