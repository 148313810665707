import React, { Component } from "react";
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonAdd } from '../shared/Buttons/Add';
import { ButtonStatus } from '../shared/Buttons/Status';
import { ButtonDelete } from '../shared/Buttons/Delete';

import { CodeAndVersion } from '../shared/Inputs/CodeAndVersion';

export class EditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            itemFields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'category', label: 'Category', className: 'hide-if-smaller-than-xl' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'actions', label: '' },
            ],
            isFormValidated: false,
            isFormValid: true,
            validation: [
                { key: 'text', isValid: true, message: 'Please enter a valid display name.' },
                { key: 'description', isValid: true, message: 'Please enter a valid description.' },
                { key: 'instructions', isValid: true, message: 'Please enter some valid instruction.' },
                { key: 'resumeInstructions', isValid: true, message: 'Please enter some valid instruction.' },
                { key: 'practiceInstructions', isValid: true, message: 'Please enter some valid instruction.' },
                { key: 'resumePracticeInstructions', isValid: true, message: 'Please enter some valid instruction.' },
                { key: 'confirmEndSessionTitle', isValid: true, message: 'Please enter a title for the confirm modal.' },
                { key: 'confirmEndSessionText', isValid: true, message: 'Please the message to display to the user when they want to end a session.' },
                { key: 'expiredText', isValid: true, message: 'Please the text to display to the user when the a session has expired.' },
                { key: 'backgroundColour', isValid: true, message: 'Please enter a valid colour code.' },
                { key: 'hoursToResume', isValid: true, message: 'Please add the number of hours after which the session cannot be resumed.' },
            ],
            editItem: {
                code: '',
                version: 0,
                languageCode: 'en-GB',
                status: 'NEW',
                text: {
                    text: '',
                    description: ''
                },
                targetMinutesDay: 0,
                targetMinutes7Day: 0,
                isPlacebo: false,
            },
            editIndex: -1,
            existingCodes: [],
            saveError: null,
            cognitiveItemsList: [],
        };

        this.booleanSelect = [
            { value: 'True', text: 'Yes' },
            { value: 'False', text: 'No' },
        ];

        this.codeAndVersion = React.createRef();

    }

    CanEdit() {
        return !this.props.user.IsGuest();
    }

    TabulateItemData() {
        let rows = [];
        if (this.state.editItem && this.state.editItem.cognitiveItems && this.state.editItem.cognitiveItems.length > 0) {
            Array.prototype.forEach.call(this.state.editItem.cognitiveItems, (data) => {
                let row = {};
                this.state.itemFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                            row[field.key] = data.text && data.text.text ? `<div>${data[field.key]}</div><div><i><small>${data.text.text}</small></i></div>` : `<div>${data[field.key]}</div>`;
                            break;
                        case 'version':
                        case 'category':
                            row[field.key] = data[field.key];
                            break;
                        case 'status':
                            row[field.key] = 'Status';
                            break;
                        case 'actions':
                            row[field.key] = 'Actions';
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    GetUnusedCognitiveItemsList() {
        let unused = [];
        if (this.state.editItem.cognitiveItems && this.state.editItem.cognitiveItems.length > 0) {
            this.state.cognitiveItemsList.forEach(itemToAdd => {
                if (!this.state.editItem.cognitiveItems.find(c => c.code === itemToAdd.code && c.version === itemToAdd.version))
                    unused.push(itemToAdd);
            });
        } else {
            unused = this.state.cognitiveItemsList;
        }
        return unused;
    }

    UpdateCodeAndVersion(code, version) {
        let updatedItem = update(this.state.editItem, { code: { $set: code }, version: { $set: version } });
        this.setState({ editItem: updatedItem });
    }

    UpdateItem(key, value) {
        let updatedItem = null;
        switch (key) {
            case "text":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { text: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { text: { $set: value } } });
                }
                break;
            case "description":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { description: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { description: { $set: value } } });
                }
                break;
            case "instructions":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { instructions: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { instructions: { $set: value } } });
                }
                break;
            case "resumeInstructions":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { resumeInstructions: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { resumeInstructions: { $set: value } } });
                }
                break;
            case "practiceInstructions":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { practiceInstructions: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { practiceInstructions: { $set: value } } });
                }
                break;
            case "resumePracticeInstructions":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { resumePracticeInstructions: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { resumePracticeInstructions: { $set: value } } });
                }
                break;
            case "confirmEndSessionTitle":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { confirmEndSessionTitle: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { confirmEndSessionTitle: { $set: value } } });
                }
                break;
            case "confirmEndSessionText":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { confirmEndSessionText: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { confirmEndSessionText: { $set: value } } });
                }
                break;
            case "expiredText":
                if (!this.state.editItem.text) {
                    updatedItem = update(this.state.editItem, { text: { $set: { expiredText: value } } });
                } else {
                    updatedItem = update(this.state.editItem, { text: { expiredText: { $set: value } } });
                }
                break;
            case "backgroundColour":
                updatedItem = update(this.state.editItem, { backgroundColour: { $set: value } });
                break;
            case "hoursToResume":
                if (!this.IsEmpty(value))
                    updatedItem = update(this.state.editItem, { hoursToResume: { $set: parseFloat(value ?? 0) } });
                else
                    updatedItem = update(this.state.editItem, { hoursToResume: { $set: "" } });
                break;
            case "targetMinutesDay":
                if (!this.IsEmpty(value))
                    updatedItem = update(this.state.editItem, { targetMinutesDay: { $set: parseFloat(value ?? 0) } });
                else
                    updatedItem = update(this.state.editItem, { targetMinutesDay: { $set: "" } });
                break;
            case "targetMinutes7Days":
                if (!this.IsEmpty(value))
                    updatedItem = update(this.state.editItem, { targetMinutes7Days: { $set: parseFloat(value ?? 0) } });
                else
                    updatedItem = update(this.state.editItem, { targetMinutes7Days: { $set: "" } });
                break;
            case "maxMinutesDay":
                if (!this.IsEmpty(value))
                    updatedItem = update(this.state.editItem, { maxMinutesDay: { $set: parseFloat(value ?? 0) } });
                else
                    updatedItem = update(this.state.editItem, { maxMinutesDay: { $set: "" } });
                break;
            case "isPlacebo":
                updatedItem = update(this.state.editItem, { isPlacebo: { $set: value === "True" ? true : false } });
                break;
            default:
                break;
        }
        if (updatedItem)
            this.setState({ editItem: updatedItem });
    }

    SetStatus(status) {
        console.log("SET STATUS", status)
        let updatedItem = update(this.state.editItem, { status: { $set: status } });
        this.setState({ editItem: updatedItem });
    }

    ItemIsValid(key, setMessage) {
        let valid = true;
        let validation = null;
        let index = this.state.validation.findIndex(v => v.key === key);
        if (index > -1) {
            let message = '';
            switch (key) {
                case "text":
                    break;
                case "description":
                    break;
                case "instructions":
                    break;
                case "confirmEndSessionTitle":
                    break;
                case "confirmEndSessionText":
                    break;
                case "expiredText":
                    break;
                case "confirm":
                    break;
                case "backgroundColour":
                    break;
                case "hoursToResume":
                    break;
                default:
                    break;
            }
            if (setMessage && validation) {
                validation = update(this.state.validation, { $splice: [[{ code: key, isValid: valid, message: message }, index, 1]] });
                this.setState({ validation: validation });
            }
        }
        return valid;
    }

    GetValue(key) {
        let value = '';
        switch (key) {
            case "code":
                value = this.state.editItem.code;
                break;
            case "version":
                value = this.state.editItem.version;
                break;
            case "text":
                if (this.state.editItem.text && this.state.editItem.text.text)
                    value = this.state.editItem.text.text;
                break;
            case "description":
                if (this.state.editItem.text && this.state.editItem.text.description)
                    value = this.state.editItem.text.description;
                break;
            case "instructions":
                if (this.state.editItem.text && this.state.editItem.text.instructions)
                    value = this.state.editItem.text.instructions;
                break;
            case "resumeInstructions":
                if (this.state.editItem.text && this.state.editItem.text.resumeInstructions)
                    value = this.state.editItem.text.resumeInstructions;
                break;
            case "practiceInstructions":
                if (this.state.editItem.text && this.state.editItem.text.practiceInstructions)
                    value = this.state.editItem.text.practiceInstructions;
                break;
            case "resumePracticeInstructions":
                if (this.state.editItem.text && this.state.editItem.text.resumePracticeInstructions)
                    value = this.state.editItem.text.resumePracticeInstructions;
                break;
            case "confirmEndSessionTitle":
                if (this.state.editItem.text && this.state.editItem.text.confirmEndSessionTitle)
                    value = this.state.editItem.text.confirmEndSessionTitle;
                break;
            case "confirmEndSessionText":
                if (this.state.editItem.text && this.state.editItem.text.confirmEndSessionText)
                    value = this.state.editItem.text.confirmEndSessionText;
                break;
            case "expiredText":
                if (this.state.editItem.text && this.state.editItem.text.expiredText)
                    value = this.state.editItem.text.expiredText;
                break;
            case "backgroundColour":
                if (this.state.editItem.backgroundColour)
                    value = this.state.editItem.backgroundColour;
                else
                    value = "#FFFFFF";
                break;
            case "hoursToResume":
                if (this.state.editItem.hoursToResume)
                    value = this.state.editItem.hoursToResume;
                else
                    value = 12;
                break;
            case "targetMinutesDay":
                value = this.state.editItem.targetMinutesDay ?? 0;
                break;
            case "targetMinutes7Days":
                value = this.state.editItem.targetMinutes7Days ?? 0;
                break;
            case "maxMinutesDay":
                value = this.state.editItem.maxMinutesDay ?? 0;
                break;
            case "isPlacebo":
                value = (this.state.editItem.isPlacebo ?? false) === false ? "False" : "True";
                break;
            default:
                break;
        }
        return value;
    }

    GetValidationMessage(key) {
        let message = '';
        let index = this.state.validation.findIndex(v => v.key === key);
        if (index > -1) {
            message = this.state.validation[index].message;
        }
        return message;
    }

    GetPlaceholder(key) {
        let value = '';
        switch (key) {
            case "description":
                value = "A description of this item";
                break;
            case "instructions":
                value = "Some instructions to display to the user before they BEGIN a full session.";
                break;
            case "resumeInstructions":
                value = "Some instructions to display to the user before they RESUME a full session.";
                break;
            case "practiceInstructions":
                value = "Some instructions to display to the user before they BEGIN a practice session.";
                break;
            case "resumePracticeInstructions":
                value = "Some instructions to display to the user before they RESUME a practice session.";
                break;
            case "confirmEndSessionTitle":
                value = "Text used as the title of the confirm modal.";
                break;
            case "expiredText":
                value = "Text shown to the user when viewing an expired session.";
                break;
            case "confirmEndSessionText":
                value = "Text used as the message in the confirm modal.";
                break;
            default:
                break;
        }
        return value;
    }

    GetMaxLength(key) {
        let value = 500;
        switch (key) {
            case "text":
                value = 250;
                break;
            default:
                break;
        }
        return value;
    }

    CodeOnly(value) {
        return value.replace(/[^a-z0-9 _]/gi, '').replace(' ', '_').toUpperCase();
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    SaveClick() {
        if (this.props.onSave) {
            this.setState({ isBusy: true, saveError: null });
            let valid = true;
            this.state.validation.forEach(v => {
                valid = valid & this.ItemIsValid(v.key, true);
            });
            if (this.codeAndVersion.current)
                valid = valid & this.codeAndVersion.current.IsValid();

            this.setState({
                isFormValidated: true,
                isFormValid: valid,
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    SaveFailed(error) {
        this.setState({ isBusy: false, saveError: error });
    }

    AddCognitiveItem(selectedValue) {
        let itemToAdd = JSON.parse(selectedValue);
        if (itemToAdd) {
            if (this.state.editItem) {
                if (!this.state.editItem.cognitiveItems) {
                    let updatedItem = update(this.state.editItem, { cognitiveItems: { $set: [itemToAdd] } })
                    this.setState({ editItem: updatedItem });
                } else {
                    if (!this.state.editItem.cognitiveItems.find(c => c.code === itemToAdd.code && c.version === itemToAdd.version)) {
                        let updatedItem = update(this.state.editItem, { cognitiveItems: { $push: [itemToAdd] } })
                        this.setState({ editItem: updatedItem });
                    }
                }
            }
        } else {
            console.warn("AddCognitiveItem - MISSING ITEM TO ADD", selectedValue);
        }
    }

    DeleteCognitiveItem(index) {
        if (this.state.editItem && this.state.editItem.cognitiveItems) {
            let updatedItem = update(this.state.editItem, { cognitiveItems: { $splice: [[index, 1]] } })
            this.setState({ editItem: updatedItem });
        }
    }

    GetExistingCodes() {
        return this.props.existing
            .filter(item => this.props.item === undefined || !(
                item.code === this.props.item.code &&
                item.version === this.props.item.version
            ))
            .map(item => `${item.code.trim().toUpperCase()}_${item.version}}`);
    }

    LoadPage() {
        this.setState({ editIndex: this.props.index, editItem: this.props.item, cognitiveItemsList: this.props.cognitiveItemsList, existingCodes: this.GetExistingCodes()});
    }

    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.index !== prevProps.index || this.props.item !== prevProps.item || this.props.existing !== prevProps.existing || this.props.cognitiveItemsList !== prevProps.cognitiveItemsList)
            this.LoadPage();
    }

    componentWillUnmount() {
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        return (
            <div>
                <Form noValidate>
                    {this.RenderForm()}
                </Form>
                {this.state.isBusy ?
                    <div className="busy-overlay col d-flex align-items-center justify-content-center">
                        <Spinner animation="border" role="status" variant="dark" />
                    </div>
                    : ''
                }
                {errors && (
                    <Alert variant="danger">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
            </div>
        );
    }

    RenderForm() {
        return (
            <div>
                {this.RenderCodeAndVersion()}

                <Tabs
                    defaultActiveKey="details"
                    id="justify-tab-example"
                    className="mb-3 tabs-app"
                    justify>
                    <Tab eventKey="details" title="Details">
                        {this.RenderFormGroup("text", "Display Name", "text")}
                        {this.RenderTextArea("description", "Description", 4)}
                        {this.props.cognitiveItemType === "COGNITIVETEST" && (
                            this.RenderFormGroup("hoursToResume", "Hours to Resume", "number")
                        )}
                        {this.props.cognitiveItemType === "BRAINTRAINING" && (
                            <div className="d-flex justify-content-start">
                                {this.RenderFormGroup("targetMinutesDay", "Daily Target (minutes)", "number")}&nbsp;
                                {this.RenderFormGroup("targetMinutes7Days", "7 Day Target (minutes)", "number")}&nbsp;
                                {this.RenderFormGroup("maxMinutesDay", "Max Target (minutes)", "number")}
                            </div>
                        )}
                        {this.props.cognitiveItemType === "BRAINTRAINING" && (
                            this.RenderSelect("isPlacebo", "Placebo", this.booleanSelect)
                        )}
                        {this.RenderFormGroup("backgroundColour", "Background Colour", "color")}
                    </Tab>
                    <Tab eventKey="items" title="Items">
                        {this.RenderItems()}
                    </Tab>
                    {this.props.cognitiveItemType === "COGNITIVETEST" && (
                        <Tab eventKey="instr" title="Begin">
                            {this.RenderTextArea("instructions", "Instructions (when beginning a FULL session)", 7)}
                            {this.RenderTextArea("practiceInstructions", "Instructions (when beginning a PRACTICE session)", 7)}
                        </Tab>
                    )}
                    {this.props.cognitiveItemType === "COGNITIVETEST" && (
                        <Tab eventKey="resume" title="Resume">
                            {this.RenderTextArea("resumeInstructions", "Instructions (when resuming a FULL session)", 7)}
                            {this.RenderTextArea("resumePracticeInstructions", "Instructions (when resuming a PRACTICE session)", 7)}
                        </Tab>
                    )}
                    {this.props.cognitiveItemType === "COGNITIVETEST" && (
                        <Tab eventKey="end" title="End Session">
                            {this.RenderFormGroup("confirmEndSessionTitle", "Title of 'confirm end session' modal.", "text")}
                            {this.RenderTextArea("confirmEndSessionText", "Message", 4)}
                        </Tab>
                    )}
                    <Tab eventKey="expired" title="Expired">
                        {this.RenderTextArea("expiredText", "Text shown when a session has expired", 15)}
                    </Tab>
                </Tabs>

            </div>

        );
    }

    RenderCodeAndVersion() {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <CodeAndVersion
                    ref={this.codeAndVersion}
                    code={this.GetValue("code")}
                    version={this.GetValue("version")}
                    existingCodes={this.state.existingCodes}
                    onChange={(code, version) => { this.UpdateCodeAndVersion(code, version); }}
                />
                <Form.Control.Feedback type="invalid">
                    Error Message
                </Form.Control.Feedback>
            </Form.Group>
        );
    }


    RenderFormGroup(key, displayName, type, mandatory = false) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Control
                    required={mandatory}
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    type={type}
                    placeholder={this.GetPlaceholder(key)}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}
                    maxLength={this.GetMaxLength(key)}
                />
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderTextArea(key, displayName, rows, mandatory = false) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Control
                    required={mandatory}
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    as="textarea" rows={rows}
                    placeholder={this.GetPlaceholder(key)}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}
                    maxLength={this.GetMaxLength(key)}
                />
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderSelect(key, displayName, values) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <Form.Label><b>{displayName}</b></Form.Label>
                <Form.Select
                    disabled={this.CanEdit() ? '' : 'disabled'}
                    value={this.GetValue(key)}
                    onChange={(event) => { this.UpdateItem(key, event.target.value) }}
                    isInvalid={!this.ItemIsValid(key, false)}>

                    {values.map((value, index) => (
                        <option key={`SELECT-${value.value}`} value={value.value}>{value.text}</option>
                    ))}

                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {this.GetValidationMessage(key)}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderItems() {
        let rows = this.TabulateItemData();
        return (
            <div>
                <Form.Label><b>{this.props.cognitiveItemType === "COGNITIVETEST" ? "Cognitive Tests" : "Brain Training Games"}</b></Form.Label>
                <Table responsive className="noEndBorder transparent-table">
                    <tbody>
                        {rows.map((_, index) => (
                            <tr key={index}>
                                {this.state.itemFields.map((item, subindex) => (
                                    rows[index][item.key] === 'Actions' ?
                                        this.ItemRowActions(index, subindex, this.props.user.Role()) :
                                        rows[index][item.key] === 'Status' ?
                                            <td key={subindex} className="pt-4">
                                                <ButtonStatus status={this.state.editItem.cognitiveItems[index].status} />
                                            </td> :
                                            <td key={subindex} className={item.className}>
                                                <div><span className="miniLabel">{item.label}:</span></div>
                                                <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                            </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <hr />
                <div className="d-flex justify-content-center">
                    <Form.Select id="selectCognitiveItemList" className="me-2">
                        {this.RenderCognitiveItemsOptions()}
                    </Form.Select>
                    <div className="pt-1">
                        <ButtonAdd onClick={() => this.AddCognitiveItem(document.getElementById("selectCognitiveItemList").value)} text={this.props.cognitiveItemType === "COGNITIVETEST" ? "Add Cognitive Test" : "Add Brain Training Game"} />
                    </div>
                </div>
            </div>
        );
    }

    ItemRowActions(index, subindex, role) {
        if (role === 'Developer' || role === 'Admin' || role === 'User') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DeleteCognitiveItem(index)} />&nbsp;
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4"></td>
            )
        }
    }

    RenderCognitiveItemsOptions() {
        let items = [];
        let index = 0;
        this.GetUnusedCognitiveItemsList().forEach(item => {
            let value = JSON.stringify({ status: item.status, code: item.code, version: item.version, category: item.category });
            items.push(<option key={index++} value={value}>{item.category}/{item.code} v{item.version}</option>);
        });
        return items;
    }
}
