export class ConsoleLogging {

    constructor() {
        this.verbose = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
    }

    LogError(message) {
        console.debug("API", message);
    }

    Log(title, message) {
        if (this.verbose) {
            if (message)
                console.debug(`API ${title}`, message)
            else
                console.debug(`API ${title}`)
        }
    }
}

export class ErrorHandler {

    constructor() {
        this.consoleLog = new ConsoleLogging();
    }

    async ReturnProblemDetails(response, callBackErrorFn) {
        let checkStr = "Identity/Account";
        if (response.url && response.url.indexOf(checkStr) > -1) {

            let checkIndex = response.url.indexOf(checkStr) + checkStr.length + 1;
            let accountResponse = response.url.substring(checkIndex, response.url.indexOf("?"))
            let errorMessage = "";
            switch (accountResponse) {
                case "AccessDenied":
                    errorMessage = "You haven't been given access to something required to dispay this page."
                    break
                default:
                    errorMessage = accountResponse;
                    break;
            }
            let errors = [errorMessage];
            this.consoleLog.LogError(errors);

            if (callBackErrorFn)
                callBackErrorFn(errors);

        } else {

            if (response.status === 400) {
                let error = await response.json();
                if (error) {
                    if (error.hasOwnProperty('errors')) {

                        this.consoleLog.LogError(error.errors.error);
                        if (callBackErrorFn)
                            callBackErrorFn(error.errors.error);

                    } else {

                        this.consoleLog.LogError(error);
                        if (callBackErrorFn)
                            callBackErrorFn(error);

                    }
                }
            } else {
                let errors = [`Network response was ${response.status}`];
                this.consoleLog.LogError(errors);
                if (callBackErrorFn)
                    callBackErrorFn(errors);
            }

        }
    }

    ReturnErrorMessage(error, callBackErrorFn) {
        this.consoleLog.LogError(error);
        if (callBackErrorFn)
            callBackErrorFn([error]);
    }

}
