import update from 'immutability-helper';
import { ConsoleLogging, ErrorHandler } from './APIHelpers'

export class CognitiveItemsApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "cognitiveitem";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // CognitiveItemsApi
    async Query(dataQuery, cognitiveItemType, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let formData = new FormData();
        formData.append('dataQuery', JSON.stringify(dataQuery));
        formData.append('type', cognitiveItemType);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // CognitiveItemsApi
    async List(cognitiveItemType, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/list/${cognitiveItemType}`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // CognitiveItemsApi
    async GetResultsWithPrevious(code, version, category, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getResultsByCode/${code}/${version}/${category}/${instance}`;
        if (code && version) {
            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // CognitiveItemsApi
    async GetParticipantResults(resultDataQuery, cognitiveItem, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/GetParticipantResults`;
        if (cognitiveItem && cognitiveItem.code && cognitiveItem.version) {

            let formData = new FormData();
            formData.append('dataQuery', JSON.stringify(resultDataQuery));
            formData.append('category', cognitiveItem.category);
            formData.append('code', cognitiveItem.code);
            formData.append('version', cognitiveItem.version);
            formData.append('inputType', cognitiveItem.inputType);
            let postData = {
                method: "POST",
                body: formData,
            };
            await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // CognitiveItemsApi
    async Get(cognitiveItem, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Get/${cognitiveItem.id}`;
        if (cognitiveItem && cognitiveItem.id && cognitiveItem.id > 0) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // CognitiveItemsApi
    async GetByCode(code, version, category, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getByCode/${code}/${version}/${category}`;
        if (code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // CognitiveItemsApi
    async SaveItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // CognitiveItemsApi
    async DeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // CognitiveItemsApi
    async DevDeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/DeleteWithResults/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // CognitiveItemsApi
    async SaveResults(uploadResults, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/saveResults`;
        let formData = new FormData();
        formData.append('uploadedResults', JSON.stringify(uploadResults));
        let postData = {
            method: "POST",
            //headers: { "Content-Type": "application/json" },
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class BatteryApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "battery";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // BatteryApi
    async Query(dataQuery, cognitiveItemType, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let formData = new FormData();
        formData.append('dataQuery', JSON.stringify(dataQuery));
        formData.append('type', cognitiveItemType);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // BatteryApi
    async GetResults(code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getResultsByCode/${code}/${version}/${instance}`;
        if (code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // BatteryApi
    async GetResultsByParticipantIdAndCode(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getParticipantResultsByCode/${participantStudyId}/${code}/${version}/${instance}`;
        if (participantStudyId && code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // BatteryApi
    async GetParticipantResults(resultDataQuery, battery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/GetParticipantResults`;
        if (battery && battery.code && battery.version) {

                let formData = new FormData();
                formData.append('dataQuery', JSON.stringify(resultDataQuery));
                formData.append('code', battery.code);
                formData.append('version', battery.version);
                let postData = {
                    method: "POST",
                    body: formData,
                };
                await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // BatteryApi
    async Get(battery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Get/${battery.id}`;
        if (battery && battery.id && battery.id > 0) {
            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // BatteryApi
    async GetByCode(code, version, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getByCode/${code}/${version}`;
        if (code && version) {
            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // BatteryApi
    async SaveItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        this.consoleLog.Log(`SAVE BATTERY [${index}]`, item);
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // BatteryApi
    async DeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // BatteryApi
    async DevDeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/DeleteWithResults/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // BatteryApi
    async SaveResults(uploadResults, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/saveResults`;
        let formData = new FormData();
        formData.append('uploadedResults', JSON.stringify(uploadResults));
        let postData = {
            method: "POST",
            //headers: { "Content-Type": "application/json" },
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class AccountApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "account";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // AccountApi
    async MyDetails(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/mydetails`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // AccountApi
    async KeepLoggedIn(callBackSuccessFn, callBackErrorFn) {

        try {
            let url = `${this.endPoint}/KeepLoggedIn`;

            await fetch(url)
                .then(async response => {
                    if (!response.ok) {
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    } else {

                        this.consoleLog.Log(url);
                        if (callBackSuccessFn)
                            callBackSuccessFn();

                    }

                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });

        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class UserApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "user";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // UserApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataQuery),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // UserApi
    async SaveItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        this.consoleLog.Log(`SAVE BATTERY [${index}]`, item);
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // UserApi
    async DeleteItem(index, item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${item.id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}
