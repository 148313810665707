import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
export class ButtonStatus extends Component {

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    StatusClass() {
        let classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
        switch (this.props.status) {
            case "NEW":
                classes = "text-nowrap btn btn-sm btn-primary rounded-pill white";
                break;
            case "IN_TEST":
                classes = "text-nowrap btn btn-sm btn-warning rounded-pill white";
                break;
            case "LIVE":
                classes = "text-nowrap btn btn-sm btn-success rounded-pill white";
                break;
            case "ARCHIVED":
                classes = "text-nowrap btn btn-sm btn-info rounded-pill white";
                break;
            case "DO_NOT_USE":
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
            default:
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
        }
        return classes;
    }

    StatusText() {
        let text = "";
        switch (this.props.status) {
            case "NEW":
                text = "NEW";
                break;
            case "IN_TEST":
                text = "TESTING";
                break;
            case "LIVE":
                text = "LIVE";
                break;
            case "ARCHIVED":
                text = "ARCHIVED";
                break;
            case "DO_NOT_USE":
                text = "DO NOT USE";
                break;
            default:
                text = "UNKNOWN";
                break;
        }
        return text;
    }

    GetButtonSize() {
        if (this.props.size) {
            return this.props.size;
        } else {
            return '';
        }
    }

    render() {
        switch (this.GetButtonSize()) {
            case "sm":
                return (
                    <button size={this.GetButtonSize()} type="button" className={this.StatusClass()} onClick={() => { this.ButtonClick() }}>
                        <small><small>{this.StatusText()}</small></small>
                    </button>
                );
                break;
            default:
                return (
                    <button size={this.GetButtonSize()} type="button" className={this.StatusClass()} onClick={() => { this.ButtonClick() }}>
                        <small>{this.StatusText()}</small>
                    </button>
                );
                break;
        }

    }

}
