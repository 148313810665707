import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import { Format } from '../shared/GlobalFns';

export class PackageGamesList extends Component {

    constructor(props) {
        super(props);

        this.format = new Format();
    }

    CognitiveItemsSorted() {
        var items = [];
        this.props.battery.cognitiveItems.forEach((game) => {
            var gameSummary = this.props.cognitiveItemSummaries.find(s => s.cognitiveItemCode === game.code && s.cognitiveItemVersion === game.version);
            game.timePlayed = gameSummary ? this.format.TimeSpanInSeconds(gameSummary.timePlayedLast7Days) : 0;
            items.push(game);
        });
        return items.sort((a, b) => a.timePlayed - b.timePlayed);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        let games = [];
        if (this.props.battery && this.props.battery.cognitiveItems) {

            this.CognitiveItemsSorted().forEach((game, index) => {
                games.push(
                    <Card key={`GAME-${index}`} className={`${this.props.theme.GetPlayGameClass()}`}>
                        <Card.Body className="p-2" onClick={() => { if (this.props.onGameSelected) this.props.onGameSelected(game) } }>
                            <Card.Text as="div">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-start">
                                        {game.icon && (
                                            <div className="pt-2 pe-3"><img alt="icon" className="game-icon" src={game.icon} /></div>
                                        )}
                                        <div className="pt-1">
                                            <div>
                                                <h4 dangerouslySetInnerHTML={{ __html: game.text ?? game.code }}></h4>
                                            </div>
                                            {game.description && (<div><small dangerouslySetInnerHTML={{ __html: game.description }}></small></div>)}
                                        </div>
                                    </div>
                                    {this.GetGameProgress(game, this.props.cognitiveItemSummaries ? this.props.cognitiveItemSummaries.find(s => s.cognitiveItemCode === game.code && s.cognitiveItemVersion === game.version) : [])}
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                );
            });

        }
        if (games.length > 0)
            return (
                <div>
                    <h2>Games</h2>
                    <div className="d-flex flex-wrap">
                        {games}
                    </div>
                </div>
            );
    }

    GetGameProgress(game, gameSummary) {
        let gameProgress = [];
        let progressSectionStep = this.props.battery.targetMinutes7Days ?? 0 * 60;

        if (gameSummary) {

            let timePlayed = this.format.TimeSpanInSeconds(gameSummary.timePlayedLast7Days);
            let progressSections = 10;

            if (timePlayed > 0 && progressSectionStep > 0) {
                let completedSections = timePlayed / progressSectionStep;
                for (var i = 0; i < progressSections - 1; i++) {
                    let backgroundVariant = "";
                    if (i <= 4) {
                        backgroundVariant = (8 - i) <= completedSections ? "bg-success" : "";
                    } else if (i <= 7) {
                        backgroundVariant = (8 - i) <= completedSections ? "bg-warning" : "";
                    } else {
                        backgroundVariant = (8 - i) <= completedSections ? "bg-danger" : "";
                    }
                    gameProgress.push(backgroundVariant);
                }
            } else {
                for (var j = 0; j < progressSections - 1; j++) {
                    gameProgress.push("");
                }
            }
            gameProgress.push("bg-danger");
        }

        let gameProgressScale = [];
        gameProgress.forEach((progressBackground, index) => {
            gameProgressScale.push(<div key={`PROGRESS-${game.code}-${game.version}-${index}`} className={`d-flex col game-progress ${progressBackground}`}></div>)
        })

        return (
            <div className={`${this.props.theme.GetPlayGameProgressClass()} ms-2 d-flex flex-column align-content-stretch`}>
                {gameProgressScale}
            </div>
        );
    }
}
