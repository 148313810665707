import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate } from '../../shared/GlobalFns'

export class QuestionInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(value) {
        let result = { questionCode: this.props.question.code, answer: `${value}`, isValid: this.IsValid(value) };
        this.setState({ result: result, hasChanges: result.answer !== this.state.result.answer }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional)
            return this.validate.IsEmpty(value);
        else
            return !this.validate.IsEmpty(value);
    }

    IsValidFormat(value) {
        let valid = true;
        if (this.props.format) {
            let regExp = new RegExp(this.props.format);
            valid = regExp.test(this.Value());
        }
        return valid;
    }

    IsInRange(value) {
        if (!this.validate.IsEmpty(value))
            return (new Date(this.MinValue()) <= new Date(value) && new Date(value) <= new Date(this.MaxValue()));
        else
            return false;
    }

    MinValue() {
        let result = this.defaultMin;
        if (this.props.question && this.props.question.validation && this.props.question.validation.min) {
            let min = this.props.question.validation.min;
            if (min === "Now")
                result = new Date().toISOString().split("T")[0];
            else
                result = min.split("T")[0];
        }
        return result;
    }

    MaxValue() {
        let result = this.defaultMax;
        if (this.props.question && this.props.question.validation && this.props.question.validation.max) {
            let max = this.props.question.validation.max;
            if (max === "Now")
                result = new Date().toISOString().split("T")[0];
            else
                result = max.split("T")[0];
        }
        return result;
    }

    MaxLength() {
        let maxLength = 250;

        if (this.props.question && this.props.question.validation && this.props.question.validation.max)
            maxLength = this.props.question.validation.max;
        else
            switch (this.props.inputType.toLowerCase()) {
                case "date": maxLength = 10; break;
                case "time": maxLength = 5; break;
                case "number": maxLength = 25; break;
                default: maxLength = 250; break;
            }
        return maxLength;
    }

    Value() {
        if (this.state.result && this.state.result.answer)
            return this.state.result.answer
        else
            return "";
    }

    GetQuestionText() {
        if (this.props.question && this.props.question.text)
            return this.props.question.text.text;
        else
            return '';
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    QuestionContainerClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mt-2 p-2"
            else
                return "p-3 pe-4"
        } else {
            if (this.props.device.IsPhone())
                return "mt-2 p-2 phone-question-frame"
            else
                return "p-3"
        }
    }

    QuestionControlClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mb-0 w-100"
            else
                return "mb-0 w-50"
        } else {
            if (this.props.device.IsPhone())
                return "mb-2 w-100"
            else
                return "mb-2 w-50"
        }
    }

    // Returns:
    // none - No virtual keyboard. For when the page implements its own keyboard input control.
    // text (default value) - Standard input keyboard for the user's current locale.
    // decimal - Fractional numeric input keyboard containing the digits and decimal separator for the user's locale (typically . or ,). Devices may or may not show a minus key (-).
    // numeric - Numeric input keyboard, but only requires the digits 0–9. Devices may or may not show a minus key.
    // tel - A telephone keypad input, including the digits 0–9, the asterisk(*), and the pound(#) key.Inputs that * require * a telephone number should typically use < input type = "tel" > instead.
    // search - A virtual keyboard optimized for search input.For instance, the return /submit key may be labeled "Search", along with possible other optimizations. Inputs that require a search query should typically use <input type="search"> instead.
    // email - A virtual keyboard optimized for entering email addresses.Typically includes the @character as well as other optimizations.Inputs that require email addresses should typically use < input type = "email" > instead.
    // url - A keypad optimized for entering URLs.This may have the / key more prominent, for example.Enhanced features could include history access and so on.Inputs that require a URL should typically use < input type = "url" > instead.
    GetInputMode() {
        console.log(this.props.inputType)
        switch (this.props.inputType) {
            case "number":
                return "numeric";
            default:
                return "text";
                break;
        }
    }


    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result) {
            this.Load();
        }
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className={this.QuestionContainerClass()} >
                <b><small dangerouslySetInnerHTML={{ __html: this.GetQuestionText() }}></small></b>
                <div className="d-flex justify-content-end pb-2">
                    <Form.Group className={this.QuestionControlClass()} >
                        {this.RenderControl()}
                    </Form.Group>
                </div>
            </div>
        );
    }

    RenderControl() {
        return (
            <Form.Control
                type={this.props.inputType}
                inputMode={this.GetInputMode()}
                maxLength={this.MaxLength()}
                value={this.Value()}
                onChange={(event) => this.UpdateResult(event.target.value)}
            />
        )
    }
}

