import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
export class ButtonBackApp extends Component {

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    render() {
        return (
            <div type="button" className="text-nowrap bg-transparent" disabled={this.props.disabled ?? false} onClick={() => { this.ButtonClick() }}>
                <span className="bi bi-chevron-left"></span>
                {this.props.text &&
                    <small className="hide-if-smaller-than-xxl">{this.props.text}</small>
                }
            </div>
        );
    }

}
