import React, { Component } from 'react';
import { StudyApi, AdminApi } from './shared/BEACONApi'
import { Format, Validate } from './shared/GlobalFns'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ParticipantDetailsAdmin } from './Dashboard/ParticipantDetailsAdmin'
import update from 'immutability-helper';

import './DashboardAdmin.css'

export class DashboardAdmin extends Component {
    static displayName = DashboardAdmin.name;
    static isAdmin = true;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            isBusy: true,
            regCodeSearch: '',
            summaryData: null,
            participant: null,
            participantActivity: null,
            participantActivityResults: null,
            searchErrors: [],
            showModal: {
                participant: false,
            }
        }

        this.StudyApi = new StudyApi();
        this.AdminApi = new AdminApi();
        this.Validate = new Validate();
        this.Format = new Format();
    }

    async GetStudyTemplate() {

        if (this.props.user && this.props.user.UserStudy()) {
            await this.StudyApi.Get(this.props.user.UserStudy().studyId, (data) => {

                this.setState({ study: data, summaryData: data.summary, isBusy: false }, () => {
                    //console.log("SummaryData", data.summary);
                });

            }, (errors) => {

                this.setState({ isBusy: false });
                console.warn("GetStudyTemplate", errors);

            });
        } else {
            console.warn("USER IS MISSING STUDY", this.props.user)
        }
    }

    async FindParticipant(regCode) {
        if (!this.Validate.IsEmpty(regCode)) {
            await this.StudyApi.FindParticipant(regCode, (data) => {

                let showModal = update(this.state.showModal, { participant: { $set: true } });
                this.setState({ participant: data, isBusy: false, searchErrors: null, showModal: showModal }, () => {

                });

            }, (errors) => {

                console.warn("FindParticipant", errors);
                let showModal = update(this.state.showModal, { participant: { $set: false } });
                this.setState({ participant: null, isBusy: false, searchErrors: ["Participant not found"], showModal: showModal });

            });
        }
        else {
            let showModal = update(this.state.showModal, { participant: { $set: true } });
            this.setState({ participant: null, isBusy: false, searchErrors: null, showModal: showModal });
        }
    }

    GetFactCount(stageCode, activityCode, version, occurrence, status) {
        let count = 0;
        if (this.state.summaryData) {
            this.state.summaryData.filter(summary => summary.code === `${stageCode}.${activityCode}` && parseInt(summary.version) === parseInt(version)).forEach(summary => {

                if (summary.instance && parseInt(summary.instance.toString().split(".")[0]) === occurrence) {
                    if (summary.statusCount[status]) {
                        let number = parseInt(summary.statusCount[status])
                        count += number;
                    }
                }

            });

        }
        return count;
    }

    UpdateSearchData(value) {
        this.setState({ regCodeSearch: value });
    }

    ParticipantClose() {
        let showModal = update(this.state.showModal, { participant: { $set: false } });
        this.setState({ participant: null, isBusy: false, searchErrors: null, showModal: showModal });
    }

    ParticipantActivityOpen(stage, activity, participant) {

        activity.stageCode = stage.code;

        let participantActivity = this.GetParticipantActivity(stage.code, activity, participant);
        let showModal = update(this.state.showModal, { participantActivity: { $set: true } });
        this.setState({ participantActivity: { current: participantActivity, studyActivity: activity }, isBusy: false, showModal: showModal });
    }

    GetParticipantActivity(stageCode, activity, participant) {

        let participantActivity = null;
        let scheduleStages = participant.schedule.stages.filter(s => s.code === stageCode);
        if (scheduleStages.length === 1) {
            let stageActivities = scheduleStages[0].activities.filter(a => a.code === activity.code && a.version === activity.version && a.occurrence === activity.occurrence && a.instance === activity.instance);
            if (stageActivities.length === 1) {
                participantActivity = stageActivities[0];
            }
        }
        return participantActivity;
    }

    GetStudyActivity(stageCode, code, version, occurrence, instance) {
        let studyActivity = null;
        this.state.study.stages.filter(s => s.code === stageCode).forEach((stage, sIndex) => {
            stage.activities.every((activity, aIndex) => {
                if (activity.code === code && activity.version === version && activity.occurrence === occurrence && activity.instance === instance)
                    studyActivity = activity;
                return studyActivity === null;
            });
        });
        return studyActivity;
    }

    SummaryActivityOpen(stage, activity, status) {

        // TODO - Open a page that shows all the participants with this activity in this status
        console.log("OPEN ACTIVITY", { code: `${stage.code}.${activity.code}.${activity.occurrence}.${activity.instance}`, version: activity.version, status: status });

    }

    LoadPage() {
        this.GetStudyTemplate();
        this.setState({ regCodeSearch: this.props.user.RegistrationCode() });
    }

    componentDidMount() {
        this.LoadPage();
    }

    render() {

        let tabulatedData = this.RenderSummary();
        let errors = this.state.searchErrors ? this.state.searchErrors : [];

        return (

            <div>
                <div className="d-flex justify-content-between">
                    <div className="w-50">
                        <h1>Study Dashboard</h1>
                        {this.state.study && (
                            <p>Last Updated: {this.Format.FormatDateTime(this.state.study.summaryDate)}</p>
                        )}
                    </div>
                    <div className="w-50">
                        <div>Participant Search</div>
                        <Form.Group>
                            <InputGroup>
                                <Form.Control
                                    required
                                    disabled={this.props.disabled}
                                    type="text"
                                    value={this.state.regCodeSearch}
                                    onChange={(event) => { this.UpdateSearchData(event.target.value) }}
                                    maxLength="450"
                                    isInvalid={errors.length !== 0}
                                />
                                <button type="button" className="text-nowrap btn btn-primary" onClick={() => { this.FindParticipant(this.state.regCodeSearch) }}>Search</button>
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>

                {tabulatedData}

                {this.props.user.IsDeveloper() && (
                    <div>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.UpdateParticipantSchedules((data) => {
                                    console.log("UpdateParticipantSchedules", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Update Schedules
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.UpdateBrainTrainingSummaries((data) => {
                                    console.log("UpdateBrainTrainingSummaries", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Update Brain Training Summaries
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.UpdateStudySummary((data) => {
                                    console.log("UpdateStudySummary", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Update Study Activity Summary
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.QueueStudyNotifications((data) => {
                                    console.log("QueueStudyNotifications", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Queue Study Notifications
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.QueueBrainTrainingNotifications((data) => {
                                    console.log("QueueBrainTrainingNotifications", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Queue Brain Training Notifications
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.FlagExpiredActivitiesForExtraction((data) => {
                                    console.log("FlagExpiredActivitiesForExtraction", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Flag Expired Activities For Extraction
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.FlagWithdrawnParticipantsForDeletion((data) => {
                                    console.log("FlagWithdrawnParticipantsForDeletion", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Flag Withdrawn Participants For Deletion
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.SendEmails((data) => {
                                    console.log("SendEmails", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Send Emails
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.ExtractResults((data) => {
                                    console.log("ExtractResults", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Extract Results
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.BuildStatusReport((data) => {
                                    console.log("BuildStatusReport", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Build Status Report
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.BuildSourceReport((data) => {
                                    console.log("BuildSourceReport", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Build Source Report
                            </button>
                            &nbsp;
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                this.AdminApi.RecalculateBatteryDataPoints((data) => {
                                    console.log("RecalculateBatteryDataPoints", data)
                                }, (errors) => {
                                    console.warn(errors)
                                })
                            }}>Recalculate Battery Results
                            </button>
                        </div>
                    </div>
                )}

                <Modal show={this.state.showModal.participant} onHide={() => this.ParticipantClose()} size="xl"
                       fullscreen="xl-down" backdrop="static" keyboard={false} scrollable>
                <Modal.Header className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">Participant Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <ParticipantDetailsAdmin
                            participant={this.state.participant}
                            study={this.state.study}
                            schedule={this.props.schedule}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.ParticipantClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

        );
    }

    OnlyUnique(value, index, array) {
        return array.findIndex(item => item.code === value.code && item.version === value.version && item.occurrence === value.occurrence && item.instance === value.instance) === index;
    }

    RenderSummary() {

        let tabulatedData = [];
        if (this.state.study) {
            this.state.study.stages.filter(s => s.owner === "PARTICIPANT" || s.owner === "SYSTEM").forEach((stage, sIndex) => {

                let activities = [];
                if (stage.activities) {
                    stage.activities.filter(s => s.owner === "PARTICIPANT" || s.owner === "SYSTEM").filter(this.OnlyUnique).forEach((activity, aIndex) => {

                        activities.push(

                            <Card key={`ACTIVITY_${aIndex}`} className="non-clickable-card m-1 p-0">
                                <Card.Body className="p-2">
                                    <Card.Text as="div">

                                        <div className="container">
                                            <div className="row justify-content-md-start">
                                                <div className="col">
                                                    {activity.text.displayName && (
                                                        <div>
                                                            <div><b>{activity.text.displayName}</b></div>
                                                            <div className="miniLabel">{activity.code} v{activity.version} ({activity.occurrence}.1)</div>
                                                        </div>
                                                    )}
                                                    {!activity.text.displayName && (
                                                        <div>
                                                            <div><b>{activity.code} v{activity.version} ({activity.occurrence}.1)</b></div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-auto">

                                                </div>

                                                {this.RenderValue("PENDING", stage, activity, "variant-pending", "Pending")}
                                                {this.RenderValue("TRIGGERED", stage, activity, "variant-triggered", "Triggered")}
                                                {this.RenderValue("OPEN", stage, activity, "variant-open", "Open")}
                                                {this.RenderValue("ACTIVE", stage, activity, "variant-active", "Active")}
                                                {this.RenderValue("PASSED", stage, activity, "variant-passed", "Passed")}
                                                {this.RenderValue("FAILED", stage, activity, "variant-failed", "Failed")}
                                                {this.RenderValue("EXPIRED", stage, activity, "variant-expired", "Expired")}
                                                {this.RenderValue("CLOSED", stage, activity, "variant-expired", "Closed")}

                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        )

                    });
                }

                if (activities.length > 0) {
                    tabulatedData.push(

                        <Accordion key={`STAGE_${sIndex}`} className="pb-2 accordian-dashboard" defaultActiveKey={`${stage.code}_${sIndex}`}>
                            <Accordion.Item className="accordian-item-dashboard w-100" eventKey={`${stage.code}_${sIndex}`}>
                                <Accordion.Header className="accordian-header-dashboard">
                                    <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="p-0 m-0" dangerouslySetInnerHTML={{ __html: stage.text.displayName }}></h4>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="accordian-body-dashboard">
                                    {activities}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    );
                }

            });
        }
        return tabulatedData;
    }

    RenderValue(status, stage, activity, cssName, desc) {
        let amount = this.GetFactCount(stage.code, activity.code, activity.version, activity.occurrence, status);
        if (amount > 0) {
            return (
                <div className={`clickable-admin-status col col-lg-1 text-center ${cssName}`} onClick={() => { this.SummaryActivityOpen(stage, activity, status) }}>
                    <div className="tinyLabel">{desc}</div>
                    <div>{amount}</div>
                </div>
            );
        } else {
            return (
                <div className="col col-lg-1 text-center variant-empty">
                    <div className="tinyLabel">{desc}</div>
                </div>
            );
        }
    }

}