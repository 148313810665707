import update from 'immutability-helper';
import { ConsoleLogging, ErrorHandler } from './APIHelpers'

export class GPSurgeryApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "GPSurgery";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // GPSurgeryApi
    async UploadEpraccurFile(file, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/UploadEpracurr`;
        var formData = new FormData()
        formData.append('file', file);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn)

    }

    // GPSurgeryApi
    async UploadScottishGPs(file, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/UploadScottishGps`;
        var formData = new FormData()
        formData.append('file', file);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn)

    }

    // GPSurgeryApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataQuery),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // GPSurgeryApi
    async Get(id, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Get/${id}`;
        if (id > 0) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // GPSurgeryApi
    async GetByODSCode(code, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/getByODSCode/${code}`;
        if (code) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }
    }

    // QuestionnaireApi
    async SaveItem(item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // QuestionnaireApi
    async DeleteItem(id, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data) 
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }

    async FetchBlob(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.blob();
                        if (data) {

                            this.consoleLog.Log(url, "File Downloaded");
                            if (callBackSuccessFn)
                                callBackSuccessFn(data);

                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}
