import React, { Component } from "react";
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import { BrainTrainingGame_PROTECT } from '../shared/CognitiveItem/BrainTrainingGame_PROTECT';
import { CognitiveItemDiploriaDiv } from '../shared/CognitiveItem/CognitiveItemDiploriaDiv';
import { CognitiveItemsApi } from '../shared/CTAGApi';
import { Format } from '../shared/GlobalFns';

export class PlayForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            cognitiveItem: {
                id: -1,
                code: '',
                version: 1,
                text: {
                    text: '',
                    description: '',
                },
                dataPoints: [],
            },
            cognitiveItemIndex: -1,
            saveError: null,
            results: {},
            prevResults: {},
            resultFields: [
                { key: 'code', label: 'Data Point', className: 'ps-3 text-start' },
                { key: 'value', label: 'Value', className: 'pe-3 text-end' },
            ],
            maxComplexity: 1,
            hasChanges: false
        };

        this.header = { name: '', score: '', time: '' };

        this.cognitiveItemFrame = React.createRef();

        this.cognitiveItemsApi = new CognitiveItemsApi();
        this.format = new Format();

    }

    async GetCognitiveItem(code, version, category, inputType) {
        await this.cognitiveItemsApi.GetByCode(code, version, category, (data) => {

            this.setState({ cognitiveItem: data });

        }, (error) => {

        })

    }

    async GetResultsWithPrevious(item, instance) {

        if (item && item.code && item.version) {
            await this.cognitiveItemsApi.GetResultsWithPrevious(item.code, item.version, item.category, instance, (data) => {

                // Add in properties
                if (this.props.inputType)
                    data.results.cognitiveItem.inputType = this.props.inputType;
                if (this.props.mode)
                    data.results.cognitiveItem.mode = this.props.mode;

                this.setState({ results: data.results, prevResults: data.previousResults }, () => {
                    this.GetCognitiveItem(item.code, item.version, item.category)
                });

            }, (error) => {

            })
        }
    }

    SaveResults(rawData, isCompleted) {
        if (this.state.isFormValid) {
            this.setState({ isBusy: true, saveError: null }, async () => {

                let uploadResults = null;
                if (rawData.exception) {
                    uploadResults = update(this.state.results, { rawData: { $set: rawData }, exception: { $set: rawData.exception.message }, isCompleted: { $set: isCompleted } });
                } else {
                    uploadResults = update(this.state.results, { rawData: { $set: rawData }, isCompleted: { $set: isCompleted } });
                }

                if (this.state.results.startTime === '0001-01-01T00:00:00' && this.props.defaultStartTime)
                    uploadResults = update(uploadResults, { startTime: { $set: this.props.defaultStartTime.toJSON() } });

                //console.log("SAVE RESULTS", uploadResults)

                await this.cognitiveItemsApi.SaveResults(uploadResults, (data) => {

                    if (this.props.onSavedCognitiveItemResults)
                        this.props.onSavedCognitiveItemResults(data, true);

                }, (error) => {

                    this.setState({ saveError: error, isBusy: false });

                })

            });
        }
    }

    TabulateResultsData() {
        let rows = [];
        if (this.state.results.dataPoints) {
            Array.prototype.forEach.call(this.state.results.dataPoints, (data) => {
                let row = {};
                this.state.resultFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                        case 'value':
                            row[field.key] = data[field.key];
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    UpdateHeader(header) {
        if (header.name) this.header.name = header.name;
        if (header.score) this.header.score = header.score;
        if (header.time) this.header.time = header.time;
        if (this.props.onUpdateHeader)
            this.props.onUpdateHeader(this.header);
    }

    IsPlacebo() {
        if (this.props.isPlacebo)
            return this.props.isPlacebo;
        else
            return false;
    }

    Mute() {
        if (this.cognitiveItemFrame.current)
            this.cognitiveItemFrame.current.Mute();
    }

    Unmute() {
        if (this.cognitiveItemFrame.current)
            this.cognitiveItemFrame.current.Unmute();
    }

    GetSeedValue() {
        let seedValue = 1;
        if (!this.IsPlacebo()) {
            if (this.state.cognitiveItem.type === "BRAINTRAINING" && this.state.maxComplexity && this.state.maxComplexity > 0) {
                seedValue = this.state.maxComplexity;
            } else {
                if (this.state.results && this.state.results.seedValue && this.state.results.seedValue > 0) {
                    seedValue = this.state.results.seedValue;
                }
            }
        }
        if (seedValue > 999) seedValue = 999;
        return seedValue;
    }

    componentDidMount() {
        this.setState({ cognitiveItemIndex: this.props.index, maxComplexity: this.props.maxComplexity });
        if (this.props.item)
            this.GetResultsWithPrevious(this.props.item, this.props.instance && this.props.instance > 0 ? this.props.instance : 0)
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item)
            this.GetResultsWithPrevious(this.props.item, this.props.instance && this.props.instance > 0 ? this.props.instance : 0)
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        if (errors) {
            return (
                <div className={`full-size p-5 text-center ${this.props.theme.GetTextBootstrapContrastVariant()}`}>
                    <h1>Sorry!</h1>
                    {!this.props.user.IsGuest() && (
                        errors.map((item, index) => (<div key={index}>{item}</div>))
                    )}
                    <hr />
                    <p className="p-3">We have recorded the fact that an error has occurred and we will look into it as soon as possible.</p>
                </div>
            );
        } else if (this.state.results && (this.state.results.isCompleted || this.state.results.exception)) {
            return (
                <div className="p-4">
                    <p><b className={this.props.theme.GetTextBootstrapContrastVariant()}>Completed on {this.format.FormatDateTime(this.state.results.endTime)}:</b></p>
                    <Table className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <thead>
                            <tr>
                                <th className="text-start">
                                    <b>Data Point</b>
                                </th>
                                <th className="text-end">
                                    <b>Value</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.RenderResults()}
                        </tbody>
                    </Table>
                </div>
            );
        } else {

            switch (this.state.cognitiveItem.category) {
                case "DIPLORIA":

                    return (
                        <div>
                            <CognitiveItemDiploriaDiv
                                practiceMode={false}
                                cognitiveItemCode={this.state.cognitiveItem.code}
                                cognitiveItemVersion={this.state.cognitiveItem.version}
                                cognitiveItemInputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                                category={this.state.cognitiveItem.category}
                                seedValue={this.GetSeedValue()}

                                theme={this.props.theme}
                                user={this.props.user}
                                device={this.props.device}

                                onUpdateHeader={(header) => { this.UpdateHeader(header) }}
                                onComplete={(results) => { this.SaveResults(results, true); }}
                                onError={(errors) => { this.SaveResults(errors, false); }}
                                onQuit={(callBackOK, callBackCancel) => { if (this.props.onQuit) this.props.onQuit(callBackOK, callBackCancel) }}
                                onStarted={() => { console.log("Task Started") }}
                            />
                        </div>
                    );

                default:

                    return (
                        <div>
                            <BrainTrainingGame_PROTECT
                                ref={this.cognitiveItemFrame}
                                practiceMode={false}
                                cognitiveItemCode={this.state.cognitiveItem.code}
                                cognitiveItemVersion={this.state.cognitiveItem.version}
                                cognitiveItemInputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                                category={this.state.cognitiveItem.category}
                                seedValue={this.GetSeedValue()}
                                languageCode="en-GB"
                                theme={this.props.theme}
                                user={this.props.user}
                                device={this.props.device}
                                onUpdateHeader={(header) => { this.UpdateHeader(header) }}
                                onComplete={(results) => { this.SaveResults(results, true); }}
                                onError={(errors) => { this.SaveResults(errors, false); }}
                                onQuit={() => { this.SaveResults({ exception: 'User Quit' }, false); }}
                            />
                        </div>
                    );

            }
        }
    }

    RenderResults() {
        let rows = this.TabulateResultsData();
        let renderResults = [];
        if (this.state.results.cognitiveItem && this.state.results.cognitiveItem.instance)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_IN`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_IN_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>Instance</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.cognitiveItem.instance }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.startTime)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_STRT`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_STRT_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>StartTime</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.format.FormatDateTime(this.state.results.startTime) }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.seedValue)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SV`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SV_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>SeedValue</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.seedValue }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.startComplexity)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SC`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SC_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>StartComplexity</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.startComplexity }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.endComplexity)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EC`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EC_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>EndComplexity</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.endComplexity }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.summaryScore)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SS`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SS_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>SummaryScore</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.summaryScore }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        rows.map((_, index) => {
            renderResults.push(
                <tr key={`ROW_DATAPOINT_${index}`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_${subindex}`} className={item.className}>
                            <div dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></div>
                        </td>
                    ))}
                </tr>
            );
            return true;
        });

        if (this.state.results.exception)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EX`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EX_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>Exception</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.exception }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (renderResults.length === 0)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EX`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EX_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>No Results</div>
                            )}
                            {item.key === 'value' && (
                                <div>Unknown Problem</div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        return renderResults;
    }
}
