import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
export class ModalConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            errors: null,
        }
    }

    ButtonCancel() {
        this.setState({ isBusy: false, errors: null });
        if (this.props.onCancel)
            this.props.onCancel();
    }

    ButtonConfirm() {
        this.setState({ isBusy: true, errors: null });
        if (this.props.onConfirm)
            this.props.onConfirm();
    }

    ConfirmFailed(error) {
        this.setState({ isBusy: false, errors: error });
    }

    render() {
        let errors = this.state.errors && this.state.errors ? this.state.errors.error : null;
        return (
            <Modal centered={this.props.device && this.props.device.ShowMobileUI()} show={this.props.show} onHide={() => this.props.onCancel ? this.ButtonCancel() : this.ButtonConfirm()}>

                <Modal.Header className={this.props.theme && this.props.theme.GetModalConfirmHeaderClassName()} data-bs-theme="dark">

                    <Modal.Title>{this.props.title ? this.props.title : 'Confirm'}</Modal.Title>

                </Modal.Header>

                <Modal.Body className={this.props.theme && this.props.theme.GetModalConfirmBodyClassName()}>

                    <span dangerouslySetInnerHTML={{ __html: this.props.message ? this.props.message : 'Are you sure?' }}></span>   
                    {this.props.warning && (
                        <Alert className="mt-3" variant="danger">
                            <span dangerouslySetInnerHTML={{ __html: this.props.warning }}></span>
                        </Alert>
                    )}
                    {errors && (
                        <Alert className="mt-3" variant="danger">{Array.prototype.map.call(errors, (item, index) => (
                            <div key={index}>{item}</div>
                        ))}</Alert>
                    )}

                </Modal.Body>

                <Modal.Footer className={this.props.theme && this.props.theme.GetModalConfirmBodyClassName()}>
                    {this.props.onCancel && (
                        <Button variant="secondary" onClick={() => this.ButtonCancel()}>
                            Cancel
                        </Button>
                    )}
                    {this.props.onCancel && (
                        <Button variant="primary" onClick={() => this.ButtonConfirm()}>
                            Confirm
                        </Button>
                    )}
                    {!this.props.onCancel && (
                        <Button variant="primary" onClick={() => this.ButtonConfirm()}>
                            Ok
                        </Button>
                    )}
                </Modal.Footer>

            </Modal>
        );
    }

}
