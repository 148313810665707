import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { GPSurgeryApi } from '../shared/GPSurgeryApi'

export class UploadForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            fileUploadError: null,
        };

        this.fileUploadInputEp = React.createRef();
        this.fileUploadInputSc = React.createRef();

        this.GPSurgeryApi = new GPSurgeryApi();
    }

    UploadFile() {
        if (this.fileUploadInputEp.current && this.fileUploadInputEp.current.files[0] && this.fileUploadInputSc.current && this.fileUploadInputSc.current.files[0])
            this.setState({ isBusy: false, fileUploadError: { title: 'System', status: 'UNKNOWN', errors: { error: ["Please upload one file at a time"] } } });
        else if (this.fileUploadInputEp.current && this.fileUploadInputEp.current.files[0]) 
            this.UploadEpraccurFile();
        else if (this.fileUploadInputSc.current && this.fileUploadInputSc.current.files[0]) 
            this.UploadScottishGPs();
        else
            this.setState({ isBusy: false, fileUploadError: { title: 'System', status: 'UNKNOWN', errors: { error: ["Please choose a GP surgery file to upload"] } } });
    }

    UploadEpraccurFile() {

        this.setState({ isBusy: true, fileUploadError: null }, async () => {

            await this.GPSurgeryApi.UploadEpraccurFile(this.fileUploadInputEp.current.files[0], (data) => {

                this.setState({ isBusy: false, fileUploadError: null }, () => {

                    if (this.props.onUploaded)
                        this.props.onUploaded();

                });

            }, (errors) => {

                this.setState({ isBusy: false, fileUploadError: errors });

            });

        });
    }

    UploadScottishGPs() {

        this.setState({ isBusy: true, fileUploadError: null }, async () => {

            await this.GPSurgeryApi.UploadScottishGPs(this.fileUploadInputSc.current.files[0], (data) => {

                this.setState({ isBusy: false, fileUploadError: null }, () => {

                    if (this.props.onUploaded)
                        this.props.onUploaded();

                });

            }, (errors) => {

                this.setState({ isBusy: false, fileUploadError: errors });

            });

        });
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        return (
            <div>
                <Form noValidate validated={this.state.isFormValidated}>
                    {this.state.isBusy ?
                        <div className="busy-overlay col d-flex align-items-center justify-content-center">
                            <Spinner animation="border" role="status" variant="dark" />
                        </div>
                        : ''}
                    {this.RenderUpload()}
                </Form>

                {errors && (
                    <Alert variant="danger">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
            </div>
        );
    }

    RenderUpload() {
        let errors = this.state.fileUploadError && this.state.fileUploadError.errors ? this.state.fileUploadError.errors.error : null;
        return (
            <div>
                <Form.Group controlId="formFile">
                    <Form.Label><h3>Upload Data</h3></Form.Label>
                    <div className="mb-2">Upload a spreadsheet containing the latest surgery details:</div>
                </Form.Group>

                <h4>Epraccur (England, NI and Wales)</h4>
                <div className="mb-4 mx-2"><small><i>
                    NOTE: The spreadsheet is available as a pre-defined report from <a href="https://odsdatapoint.digital.nhs.uk/predefined" target="_blank" rel="noreferrer">NHS Digital</a>.
                    Make sure to choose <b>Prescribing Cost Centres, Including GP Practices (epraccur)</b> from the list of reports, use the "Export Data Excel" format, and make sure to include headings in the export.<br /><br />
                    If importing the data from a different location, the expected headings are: <b>Organisation Name</b>*, <b>Name</b>*, <b>Address Line 1</b>, <b>Address Line 2</b>, <b>Address Line 3</b>, <b>Address Line 4</b>, <b>Address Line 5</b>, <b>Postcode</b>, <b>Telephone</b> and <b>Status</b> (mandatory headings are maked with an *).
                </i></small></div>
                <Form.Group className="mb-4" id="file_input">
                    <Form.Control ref={this.fileUploadInputEp} type="file" />
                </Form.Group>

                <h4>Scottish GPs</h4>
                <div className="mb-4 mx-2"><small><i>
                    NOTE: "S" will be added as a prefix to the paractice code to create an internal ODS code. The spreadsheet is available as a download from <a href="https://www.opendata.nhs.scot/dataset/gp-practice-contact-details-and-list-sizes" target="_blank" rel="noreferrer">Public Health Scotland</a>.
                    <br /><br />
                    If importing the data from a different location, the expected headings are: <b>Organisation Name</b>*, <b>Name</b>*, <b>Address Line 1</b>, <b>Address Line 2</b>, <b>Address Line 3</b>, <b>Address Line 4</b>, <b>Address Line 5</b>, <b>Postcode</b> and <b>Telephone</b> (mandatory headings are maked with an *).
                </i></small></div>
                <Form.Group className="mb-4" id="file_input">
                    <Form.Control ref={this.fileUploadInputSc} type="file" />
                </Form.Group>

                {errors && (
                        <Alert variant="danger my-1">{errors.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}</Alert>
                    )
                }
            </div>
        )
    }
}
