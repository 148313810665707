import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Dropdown from 'react-bootstrap/Dropdown';
export class StatusSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: 'NEW'
        };

        this.statuses = ["NEW", "IN_TEST", "LIVE", "ARCHIVED", "DO_NOT_USE"];

    }

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    StatusClass(status) {
        let classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
        switch (status) {
            case "NEW":
                classes = "text-nowrap btn btn-sm btn-primary rounded-pill white";
                break;
            case "IN_TEST":
                classes = "text-nowrap btn btn-sm btn-warning rounded-pill white";
                break;
            case "LIVE":
                classes = "text-nowrap btn btn-sm btn-success rounded-pill white";
                break;
            case "ARCHIVED":
                classes = "text-nowrap btn btn-sm btn-info rounded-pill white";
                break;
            case "DO_NOT_USE":
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
            default:
                classes = "text-nowrap btn btn-sm btn-danger rounded-pill white";
                break;
        }
        return classes;
    }

    StatusText(status) {
        let text = "";
        switch (status) {
            case "NEW":
                text = "NEW";
                break;
            case "IN_TEST":
                text = "TESTING";
                break;
            case "LIVE":
                text = "LIVE";
                break;
            case "ARCHIVED":
                text = "ARCHIVED";
                break;
            case "DO_NOT_USE":
                text = "DO NOT USE";
                break;
            default:
                text = "UNKNOWN";
                break;
        }
        return text;
    }

    Update(status) {
        this.setState({ status: status }, () => {
            if (this.props.onUpdated)
                this.props.onUpdated(this.state.status);
        });
    }

    componentDidMount() {
        this.setState({ status: this.props.status });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status !== this.props.status)
            this.setState({ status: this.props.status });
    }

    render() {
        return (
            <Dropdown>
                <Dropdown.Toggle className={this.StatusClass(this.state.status)} id="dropdown-basic">
                    { this.StatusText(this.state.status) }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.statuses.filter(status => status !== this.state.status).map((status, index) => (
                        <Dropdown.Item onClick={() => { this.Update(status)} } key={index}>{this.StatusText(status)}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

}
