import React, { Component } from "react";
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';

import { CognitiveItemFrame } from '../shared/CognitiveItem/CognitiveItemFrame';
import { CognitiveItemDiploriaDiv } from '../shared/CognitiveItem/CognitiveItemDiploriaDiv';
import { BatteryApi } from '../shared/CTAGApi';
import { Validate } from '../shared/GlobalFns';

export class PlayBatteryForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            battery: {
                id: -1,
                code: '',
                version: 1,
                text: {
                    text: '',
                    description: '',
                },
                dataPoints: [],
            },
            batteryIndex: -1,
            cognitiveItem: null,
            cognitiveItemIndex: 0,
            saveError: null,
            options: {
                begin: false,
                next: false,
                nextEnabled: false,
                done: false,
            },
            results: {},
            resultFields: [
                { key: 'code', label: 'Data Point', className: 'ps-3 text-start' },
                { key: 'value', label: 'Value', className: 'pe-3 text-end' },
            ],
            hasChanges: false,
            cognitiveItemResultsCount: 0,
        };

        this.header = { name: '', score: '', time: '', progress: '' };
        this.instructionsShown = false;

        this.batteryApi = new BatteryApi();
        this.validate = new Validate();
    }

    async GetBattery(code, version) {

        await this.batteryApi.GetByCode(code, version, (data) => {

            this.setState({ battery: data }, () => {
                this.SetNextCognitiveItem();
            });

        }, (error) => {

        })

    }

    async GetResults(code, version, instance) {
        if (code && version) {
            await this.batteryApi.GetResults(code, version, instance, (data) => {

                // Add in stage code if applicable (ie. running in schedule)
                if (this.props.stageCode)
                    data.battery.stageCode = this.props.stageCode;

                if (this.props.inputType)
                    data.battery.inputType = this.props.inputType;

                console.debug("DATA", data);

                this.setState({ results: data }, () => {
                    this.GetBattery(code, version)
                });

            }, (error) => {

                this.GetBattery(code, version)

            })
        }
    }

    SaveResults(rawData, isError = false) {

        if (this.state.isFormValid) {
            let numberOfResults = this.state.cognitiveItemResultsCount > 0 ? this.state.cognitiveItemResultsCount + 1 : this.state.results.cognitiveItems.length + 1;
            this.setState({ isBusy: true, saveError: null, cognitiveItemResultsCount: numberOfResults }, async () => {

                let uploadResults = null;
                if (isError)
                    uploadResults = update(this.state.results, { battery: { mode: { $set: this.props.mode }, inputType: { $set: this.props.inputType } }, cognitiveItem: { $set: this.state.cognitiveItem }, rawData: { $set: rawData }, exception: { $set: rawData.exception ?? 'Unexpected error occurred in javascript.' } });
                else
                    uploadResults = update(this.state.results, { battery: { mode: { $set: this.props.mode }, inputType: { $set: this.props.inputType } }, cognitiveItem: { $set: this.state.cognitiveItem }, rawData: { $set: rawData } });

                await this.batteryApi.SaveResults(uploadResults, (data) => {

                    // Update number of battery results on screen... once
                    if (this.state.cognitiveItemResultsCount === 1 && this.props.onSavedBatteryResults) {
                        this.props.onSavedBatteryResults(this.state.results);
                    }

                    if (this.props.onComplete && (data.isCompleted || data.exception)) {
                        this.props.onComplete()
                    } else {
                        this.setState({ saveError: null, isBusy: false, results: data }, () => {
                            this.SetNextCognitiveItem();
                        });
                    }

                }, (error) => {

                    this.setState({ saveError: error, isBusy: false });

                })

            });
        }

    }

    SessionBeingResumed() {
        if (this.state.results && this.state.results.cognitiveItems)
            return this.state.results.cognitiveItems.length > 0;
        else
            return false;
    }

    SetNextCognitiveItem() {
        let indexToShow = 0;

        if (!this.SessionBeingResumed() && (!this.state.battery.text || !this.state.battery.text.instructions))
            this.instructionsShown = true;
        if (this.SessionBeingResumed() && (!this.state.battery.text || !this.state.battery.text.resumeInstructions))
            this.instructionsShown = true;

        if (this.instructionsShown) {
            if (this.state.battery && this.state.battery.cognitiveItems && this.state.battery.cognitiveItems.length > 0) {

                if (this.state.results && this.state.results.cognitiveItems && this.state.results.cognitiveItems.length > 0) {
                    this.state.battery.cognitiveItems.every((cognitiveItem, index) => {
                        if (this.state.results.cognitiveItems.findIndex(ci => ci.code === cognitiveItem.code && ci.version === cognitiveItem.version) === -1) {
                            indexToShow = index;
                            return false;
                        } else {
                            return true;
                        }
                    });
                }

            }
        } else {
            indexToShow = -1;            
        }
        if (indexToShow < this.state.battery.cognitiveItems.length) {
            if (indexToShow > -1) {
                this.setState({ cognitiveItemIndex: indexToShow, cognitiveItem: this.state.battery.cognitiveItems[indexToShow] }, () => {
                    //console.debug(`SetCognitiveItem[${this.state.cognitiveItemIndex}]`, this.state.cognitiveItem);
                })
            } else {
                this.setState({ cognitiveItemIndex: indexToShow }, () => {
                    //console.debug(`ShowInstructions`);
                })
            }
        }
        this.SetOptions();
    }

    SetOptions() {

        let begin = false;
        let next = false;
        let nextEnabled = false;
        let done = false;

        if (this.state.results && this.state.results.isCompleted) {

            done = true;

        } else {

            if (!this.instructionsShown) {
                begin = true;
            } else {

                let totalPages = this.ProgressTotalPages();
                let currentPage = this.ProgressCurrentPage();
                let nextEnabledSpecified = false;

                next = currentPage < totalPages;
                if (this.props.nextEnabled !== undefined) {
                    nextEnabledSpecified = this.props.nextEnabled;
                }

                nextEnabled = currentPage < totalPages && nextEnabledSpecified;
            }
        }

        if (this.props.onUpdateOptions) {
            let options = { begin: begin, next: next, nextEnabled: nextEnabled, done: done };
            this.setState({ options: options }, () => {
                this.props.onUpdateOptions(options);
                //console.debug("OPTIONS", options);
            });
        }
    }

    BeginSession() {
        this.instructionsShown = true;
        this.SetNextCognitiveItem();
    }

    Skip() {
        console.debug("SKIP")
        this.SaveResults({ skipped: true });
    }

    TabulateResultsData() {
        let rows = [];
        if (this.state.results.dataPoints) {
            Array.prototype.forEach.call(this.state.results.dataPoints, (data) => {
                let row = {};
                this.state.resultFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                            row[field.key] = data['cognitiveItemCode'] ? `${data['cognitiveItemCode']} - ${data[field.key]}` : data[field.key];
                            break;
                        case 'value':
                            row[field.key] = data[field.key];
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    FormatDateTime(value, locale) {
        if (value) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            var date = new Date(value);
            return date.toLocaleDateString(locale ?? "en-GB", options);
        } else {
            return "-";
        }
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    ArraysAreEqual(array1, array2) {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element === array2[index];
        });
    }

    UpdateHeader(header) {
        if (header.name) this.header.name = header.name;
        if (header.score) this.header.score = header.score;
        if (header.time) this.header.time = header.time;
        if (header.progress) this.header.progress = header.progress;
        if (this.props.onUpdateHeader)
            this.props.onUpdateHeader(this.header);
    }

    TaskStarted() {
        if (this.props.onStarted)
            this.props.onStarted();
    }

    GetSeedValue() {
        let seedValue = 1;
        if (this.state.results && this.state.results.seedValue) {
            seedValue = this.state.results.seedValue;
        }
        return seedValue;
    }

    ShowProgress() {
        return this.ProgressTotalPages() > 1;
    }
    ProgressTotalPages() {
        if (this.state.battery && this.state.battery.cognitiveItems) {
            return this.state.battery.cognitiveItems.length;
        } else {
            return 1;
        }
    }
    ProgressCurrentPage() {
        if (this.state.cognitiveItemIndex) {
            return this.state.cognitiveItemIndex + 1;
        } else {
            return 1;
        }
    }
    ProgressPrecentage() {
        let totalPages = this.ProgressTotalPages();
        let currentPage = this.ProgressCurrentPage();
        return totalPages > 1 ? currentPage !== totalPages && !this.state.options.save ? currentPage * 100 / totalPages : 100 : 0;
    }
    ProgressText() {
        let totalPages = this.ProgressTotalPages();
        let currentPage = this.ProgressCurrentPage();
        return totalPages > 1 ? `${currentPage} of ${totalPages}` : '';
    }

    componentDidMount() {
        this.setState({ batteryIndex: this.props.index });
        this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    componentDidUpdate(prevProps) {
        if (this.props.code !== prevProps.code && this.props.version !== prevProps.version && this.props.instance !== prevProps.instance)
            this.GetResults(this.props.code, this.props.version, this.props.instance)
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        if (errors) {
            return (

                <div className="full-size p-5 text-center text-dark cognitive-item-summary">
                    <h1>Sorry!</h1>
                    {errors.map((item, index) => (<div key={index}>{item}</div>))}
                    <hr />
                    <p className="p-3">We have recorded the fact that an error has occurred and we will look into it as soon as possible.</p>
                </div>

            );
        } else if (this.state.results && (this.state.results.isCompleted || this.state.results.exception)) {
            return (

                <div className="p-4 cognitive-item-summary">
                    <h1 className="text-dark">Completed</h1>
                    <p>You completed this sesssion on {this.FormatDateTime(this.state.results.startTime)}</p>
                    {this.state.results.exception && (<p>{this.state.results.exception}</p>)}
                </div>

            );
        } else if (this.props.mode !== "PRACTICE" && (this.state.results.status === "PENDING" || this.state.results.status === "OPEN") && this.state.cognitiveItemIndex === -1 && this.state.battery.text && this.state.battery.text.instructions) {
            return (

                <div className="p-5 cognitive-item-summary">
                    <div dangerouslySetInnerHTML={{ __html: this.state.battery.text.instructions }}></div>
                    <Button className="text-nowrap" variant="primary" onClick={() => this.BeginSession()}>
                        Start Session
                    </Button>
                </div>

            );
        } else if (this.props.mode !== "PRACTICE" && this.state.results.status === "ACTIVE" && this.state.cognitiveItemIndex === -1 && this.state.battery.text && this.state.battery.text.resumeInstructions) {
            return (

                <div className="p-5 cognitive-item-summary">
                    <div dangerouslySetInnerHTML={{ __html: this.state.battery.text.resumeInstructions }}></div>
                    <Button className="text-nowrap" variant="primary" onClick={() => this.BeginSession()}>
                        Resume Session
                    </Button>
                </div>

            );
        } else if (this.props.mode === "PRACTICE" && (this.state.results.status === "PENDING" || this.state.results.status === "OPEN") && this.state.cognitiveItemIndex === -1 && this.state.battery.text && this.state.battery.text.practiceInstructions) {
            return (

                <div className="p-5 cognitive-item-summary">
                    <div dangerouslySetInnerHTML={{ __html: this.state.battery.text.practiceInstructions }}></div>
                    <Button className="text-nowrap" variant="primary" onClick={() => this.BeginSession()}>
                        Start Practice Session
                    </Button>
                </div>

            );
        } else if (this.props.mode === "PRACTICE" && this.state.results.status === "ACTIVE" && this.state.cognitiveItemIndex === -1 && this.state.battery.text && this.state.battery.text.resumePracticeInstructions) {
            return (

                <div className="p-5 cognitive-item-summary">
                    <div dangerouslySetInnerHTML={{ __html: this.state.battery.text.resumePracticeInstructions }}></div>
                    <Button className="text-nowrap" variant="primary" onClick={() => this.BeginSession()}>
                        Resume Practice Session
                    </Button>
                </div>

            );
        } else if (this.state.results.status === "EXPIRED" && this.state.cognitiveItemIndex === -1) {
            if (this.state.battery.text && this.state.battery.text.expiredText)
                return (

                    <div className="p-5 cognitive-item-summary">
                        <div dangerouslySetInnerHTML={{ __html: this.state.battery.text.expiredText }}></div>
                    </div>

                );
            else
                return (

                    <div className="p-5 cognitive-item-summary">
                        <h1 className="p-5 cognitive-item-summary">Expired</h1>
                        <p>This session has expired.</p>
                    </div>

                );
        } else if (this.state.cognitiveItem) {

            return (
                <div>
                    {this.ShowProgress() && (
                        <div className="p-3 cognitive-item-summary">
                            <ProgressBar now={this.ProgressPrecentage()} label={this.ProgressText()} />
                        </div>
                    )}
                    {!this.state.isBusy && (

                        <CognitiveItemDiploriaDiv
                            practiceMode={this.props.mode === "PRACTICE" ? true : false}
                            cognitiveItemInputType={this.props.inputType}

                            cognitiveItemCode={this.state.cognitiveItem.code}
                            cognitiveItemVersion={this.state.cognitiveItem.version}
                            category={this.state.cognitiveItem.category}
                            seedValue={this.GetSeedValue()}
                            languageCode={this.props.languageCode}

                            theme={this.props.theme}
                            user={this.props.user}
                            device={this.props.device}

                            onStarted={() => { this.TaskStarted(); }} 
                            onComplete={(results) => { this.SaveResults(results); }}
                            onError={(error) => { this.SaveResults(error, true); }}
                        />

                    )}
                    {this.state.isBusy && (
                        <div className="frame-container-EMPTY"><div className="iframe-EMPTY"></div></div>
                    )}
                </div>
            );

        } else {

            // Anything rendered here will show while loading

        }
    }

    RenderResults() {
        let rows = this.TabulateResultsData();
        let renderResults = [];

        if (this.state.results.battery && this.state.results.battery.instance)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_IN`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_IN_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>Instance</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.battery.instance }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.seedValue)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SV`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SV_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>SeedValue</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.seedValue }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.startComplexity)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SC`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SC_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>StartComplexity</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.startComplexity }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.endComplexity)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EC`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EC_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>EndComplexity</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.endComplexity }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (this.state.results.summaryScore)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_SS`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_SS_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>SummaryScore</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.summaryScore }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        // Data Points
        rows.forEach((dataPoint, index) => {
            if (!this.validate.IsEmpty(dataPoint.value))
                renderResults.push(
                    <tr key={`ROW_DATAPOINT_${index}`}>
                        {this.state.resultFields.map((item, subindex) => (
                            <td key={`CELL_DATAPOINT_${subindex}`} className={item.className}>
                                <div dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></div>
                            </td>
                        ))}
                    </tr>
                );
        });

        if (this.state.results.exception)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EX`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EX_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>Exception</div>
                            )}
                            {item.key === 'value' && (
                                <div dangerouslySetInnerHTML={{ __html: this.state.results.exception }}></div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        if (renderResults.length === 0)
            renderResults.push(
                <tr key={`ROW_DATAPOINT_EX`}>
                    {this.state.resultFields.map((item, subindex) => (
                        <td key={`CELL_DATAPOINT_EX_${subindex}`} className={item.className}>
                            {item.key === 'code' && (
                                <div>No Results</div>
                            )}
                            {item.key === 'value' && (
                                <div>Unknown Problem</div>
                            )}
                        </td>
                    ))}
                </tr>
            );

        return renderResults;
    }
}
