import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import { Format } from '../GlobalFns'

export class GameProgress extends Component {

    constructor(props) {
        super(props);

        this.format = new Format();
    }

    render() {
        let tableRows = [];

        let targetMinutesDay = this.props.showTargetProgress ? this.props.targetMinutesDay : 0;
        let targetMaxMinutesDay = this.props.showTargetProgress ? this.props.targetMaxMinutesDay : 0;
        let targetMinutes7Days = this.props.showTargetProgress ? this.props.targetMinutes7Days : 0;

        if (this.props.timePlayedToday) {
            tableRows.push(
                <tr key="PROGRESS-1">
                    <td>
                        {this.RenderTimePlayedIcon(this.props.timePlayedToday, targetMinutesDay, targetMaxMinutesDay)}
                    </td>
                    <td className="align-middle">
                        {this.RenderTimePlayedText(this.props.timePlayedToday, "today", targetMinutesDay, targetMaxMinutesDay)}
                    </td>
                </tr>
            );
        }
        if (this.props.timePlayedLast7Days && this.props.timePlayedLast7Days !== this.props.timePlayedToday) {
            tableRows.push(
                <tr key="PROGRESS-2">
                    <td>
                        {this.RenderTimePlayedIcon(this.props.timePlayedLast7Days, targetMinutes7Days)}
                    </td>
                    <td className="align-middle">
                        {this.RenderTimePlayedText(this.props.timePlayedLast7Days, "in the last 7 days", targetMinutes7Days)}
                    </td>
                </tr>
            );
        }
        if (this.props.timePlayedLast30Days && this.props.timePlayedLast30Days !== this.props.timePlayedLast7Days) {
            tableRows.push(
                <tr key="PROGRESS-3">
                    <td>
                        {this.RenderTimePlayedIcon(this.props.timePlayedLast30Days, 10000)}
                    </td>
                    <td className="align-middle">
                        {this.RenderTimePlayedText(this.props.timePlayedLast30Days, "in the last 30 days", 10000)}
                    </td>
                </tr>
            );
        }

        if (this.props.maxComplexity > 0) {
            tableRows.push(
                <tr key="PROGRESS-4">
                    <td>
                        <i className="veryBigLabel bi bi-graph-up-arrow"></i>
                    </td>
                    <td className="align-middle">
                        The most you have scored is {this.props.maxComplexity}.
                    </td>
                </tr>
            );
        }

        if (tableRows)
            return (
                <div className={this.props.theme.GetTextBootstrapContrastVariant()}>
                    <h2>Your Progress</h2>
                    <Table className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </Table>
                </div>
            );
    }

    RenderTimePlayedIcon(value, targetLevel, maxLevel) {
        let timeSpanInSeconds = this.format.TimeSpanInSeconds(value);
        if (timeSpanInSeconds === 0) {

            return (<i className="veryBigLabel text-info bi bi-exclamation-circle"></i>)

        } else if (maxLevel && maxLevel > 0 && timeSpanInSeconds >= (maxLevel * 60)) {

            return (<i className="veryBigLabel text-danger bi bi-emoji-dizzy"></i>)

        } else if (targetLevel && targetLevel > 0 && timeSpanInSeconds >= (targetLevel * 60)) {

            return (<i className="veryBigLabel text-warning bi-emoji-heart-eyes"></i>)

        } else {

            return (<i className="veryBigLabel text-warning bi-emoji-smile"></i>)

        }
    }

    RenderTimePlayedText(value, suffix, targetLevel, maxLevel) {
        let timeSpanString = this.format.TimeSpan(value);
        let timeSpanInSeconds = this.format.TimeSpanInSeconds(value);
        switch (timeSpanString) {
            case "Immediate":

                if (targetLevel > 0 && targetLevel < 100)
                    return `You haven't played ${suffix}. Let's get started and see if we can reach your target of ${targetLevel} minutes.`
                else
                    return `You haven't played ${suffix}.`

            case "Unlimited":
                return 'Sorry! We lost track somehow.'

            default:
                if (targetLevel && targetLevel > 0 && timeSpanInSeconds === (targetLevel * 60)) {

                    return `You have reached your target of ${targetLevel} minutes ${suffix}.`

                } else if (maxLevel && maxLevel > 0 && timeSpanInSeconds >= (maxLevel * 60)) {

                    return `You have played for more than ${maxLevel} minutes ${suffix}!`

                } else if (targetLevel && targetLevel > 0 && timeSpanInSeconds >= (targetLevel * 60)) {

                    return `You have beaten your target of ${targetLevel} minutes and played for ${timeSpanString} ${suffix}.`

                } else {

                    if (targetLevel > 0 && targetLevel < 100)
                        return `You have played for ${timeSpanString} ${suffix}. Your target is ${targetLevel} minutes.`
                    else
                        return `You have played for ${timeSpanString} ${suffix}.`

                }
        }
    }
}
