import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import update from 'immutability-helper';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';

export class TablePagination extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "",
                sortType: "",
                filter: "",
                totalCount: 0,
            },
            pageCount: 1,
            filterInput: '',
            itemsInPage: 10,
        };
        this.filterTimer = null;
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    EnterFilter(event) {
        let filterValue = event.target.value;
        if (filterValue !== this.state.filterInput) {
            this.setState({ filterInput: event.target.value }, () => {

                if (this.IsEmpty(this.state.filterInput))
                    this.UpdateFilter();
                else {
                    clearTimeout(this.filterTimer);
                    this.filterTimer = setTimeout(() => {
                        this.UpdateFilter();
                    }, 1000);
                }
                    
            })
        }
    }
    EnterFilterKey(event) {
        if (event && event.type === "keydown" && event.code === "Enter") {
            this.UpdateFilter();
        }
    }

    UpdateTablePage(page) {
        if (page && page > 0 && page <= this.state.pageCount) {
            let updatedDataQuery = update(this.state.dataQuery, { pageNumber: { $set: page }, filter: { $set: this.state.filterInput }, itemsInPage: { $set: this.state.itemsInPage } });
            this.setState({ dataQuery: updatedDataQuery }, () => {
                if (this.props.onUpdated)
                    this.props.onUpdated(updatedDataQuery);
            });
        }
    }

    UpdateFilter() {
        let updatedDataQuery = update(this.state.dataQuery, { pageNumber: { $set: 1 }, filter: { $set: this.state.filterInput }, itemsInPage: { $set: this.state.itemsInPage } });
        this.setState({ dataQuery: updatedDataQuery }, () => {
            if (this.props.onUpdated)
                this.props.onUpdated(updatedDataQuery);
        });
    }

    UpdateItemsInPage() {
        let updatedDataQuery = update(this.state.dataQuery, { pageNumber: { $set: 1 }, filter: { $set: this.state.filterInput }, itemsInPage: { $set: this.state.itemsInPage } });
        this.setState({ dataQuery: updatedDataQuery }, () => {
            if (this.props.onUpdated)
                this.props.onUpdated(updatedDataQuery);
        });
    }

    componentDidMount() {
        if (this.props.dataQuery)
            this.setState({
                dataQuery: this.props.dataQuery,
                pageCount: this.props.dataQuery.itemsInPage > 0 ? Math.ceil(this.props.dataQuery.totalCount / this.props.dataQuery.itemsInPage) : 0,
                itemsInPage: this.props.dataQuery.itemsInPage,
                filterInput: this.props.dataQuery.filter,
            }, () => {
                if (!this.IsEmpty(this.state.dataQuery.filter))
                    this.setState({ filterInput: this.state.dataQuery.filter })
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataQuery !== prevProps.dataQuery)
            this.setState({
                dataQuery: this.props.dataQuery,
                pageCount: this.props.dataQuery.itemsInPage > 0 ? Math.ceil(this.props.dataQuery.totalCount / this.props.dataQuery.itemsInPage) : 0,
                itemsInPage: this.props.dataQuery.itemsInPage,
                filterInput: this.props.dataQuery.filter,
            }, () => {
                if (!this.IsEmpty(this.state.dataQuery.filter))
                    this.setState({ filterInput: this.state.dataQuery.filter })
            });
    }

    render() {
        return (
            <div className="container">
                {this.props.canSearch && (this.props.forceSearch || this.state.dataQuery.totalCount > 10 || !this.IsEmpty(this.state.filterInput)) && (
                    <div className="row">
                        <InputGroup>
                            <Form.Control
                                maxLength="100"
                                placeholder="Search"
                                value={this.state.filterInput}
                                onKeyDown={(event) => this.EnterFilterKey(event)}
                                onChange={(event) => this.EnterFilter(event)} />
                            <Button variant="outline-secondary" onClick={() => { this.UpdateFilter() }}><span className="bi bi-search"></span></Button>
                        </InputGroup>
                    </div>
                )}
                <div className="row mt-1">
                    {this.state.pageCount > 1 && (
                        <div className="col">
                            <Pagination className={`mb-0 ${this.props.theme.GetPaginationClassVariant()}`}> {this.TablePaginationControls()} </Pagination>
                        </div>
                    )}
                    {this.props.canChangePageResults && this.state.dataQuery.totalCount > 10 && this.state.itemsInPage > 0 && (
                        <div className="col-3 hide-if-smaller-than-xl">
                            <Form.Select
                                value={this.state.itemsInPage}
                                onChange={(event) => this.setState({ itemsInPage: parseInt(event.target.value) }, () => { this.UpdateItemsInPage() })}>
                                <option value="10">10 per page</option>
                                <option value="25">25 per page</option>
                                <option value="50">50 per page</option>
                                <option value="100">100 per page</option>
                            </Form.Select>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    TablePaginationControls() {
        let controls = [];
        controls.push(
            <Pagination.First
                key="FIRST"
                onClick={() => { this.UpdateTablePage(1) }}
            />
        );
        controls.push(
            <Pagination.Prev
                key="PREV"
                onClick={() => { this.UpdateTablePage(this.state.dataQuery.pageNumber - 1) }}
            />
        );
        controls.push(
            <Pagination.Item
                key={`PAGE_1`}
                active={1 === this.state.dataQuery.pageNumber}
                onClick={() => { this.UpdateTablePage(1) }}>
                {1}
            </Pagination.Item>
        );

        let dynamicStartPage = 0;
        let dynamicEndPage = 0;
        let numberOfDynamicNumbers = 9;
        let numberOfStartOrEndNumbers = numberOfDynamicNumbers - 4;
        if (this.state.dataQuery.pageNumber <= numberOfStartOrEndNumbers) {

            dynamicStartPage = 2;
            dynamicEndPage = Math.min(this.state.pageCount - 1, numberOfDynamicNumbers);

            this.DynamicPaginationItems(controls, dynamicStartPage, dynamicEndPage);
            if (this.state.pageCount > numberOfDynamicNumbers + 1) {
                this.DynamicPaginationEllipsis(controls, "END", Math.floor((dynamicEndPage + this.state.pageCount) / 2));
            }

        } else if (this.state.dataQuery.pageNumber > this.state.pageCount - numberOfStartOrEndNumbers) {

            dynamicStartPage = this.state.pageCount - numberOfStartOrEndNumbers + 1;
            dynamicEndPage = this.state.pageCount - 1;

            if (this.state.pageCount > numberOfDynamicNumbers + 1) {
                this.DynamicPaginationEllipsis(controls, "START", Math.floor((dynamicStartPage + 1) / 2));
            }
            this.DynamicPaginationItems(controls, dynamicStartPage, dynamicEndPage);

        } else {

            dynamicStartPage = this.state.dataQuery.pageNumber - 2;
            dynamicEndPage = Math.min(this.state.pageCount - 1, dynamicStartPage + numberOfStartOrEndNumbers);

            this.DynamicPaginationEllipsis(controls, "START", Math.floor((dynamicStartPage + 1) / 2));
            this.DynamicPaginationItems(controls, dynamicStartPage, dynamicEndPage);
            this.DynamicPaginationEllipsis(controls, "END", Math.floor((dynamicEndPage + this.state.pageCount) / 2));

        }

        controls.push(
            <Pagination.Item
                key={`PAGE_${this.state.pageCount}`}
                active={this.state.pageCount === this.state.dataQuery.pageNumber}
                onClick={() => { this.UpdateTablePage(this.state.pageCount) }}>
                {this.state.pageCount}
            </Pagination.Item>
        );
        controls.push(
            <Pagination.Next
                key="NEXT"
                onClick={() => { this.UpdateTablePage(this.state.dataQuery.pageNumber + 1) }}
            />
        );
        controls.push(
            <Pagination.Last
                key="PREVIOUS"
                onClick={() => { this.UpdateTablePage(this.state.pageCount) }}
            />
        );
        return controls;
    }

    DynamicPaginationItems(controls, dynamicStartPage, dynamicEndPage) {
        for (let p = dynamicStartPage; p <= dynamicEndPage; p++) {
            controls.push(
                <Pagination.Item
                    key={`ITEM_${p}`}
                    active={p === this.state.dataQuery.pageNumber}
                    onClick={() => { this.UpdateTablePage(p) }}>
                    {p}
                </Pagination.Item>
            );
        }
    }

    DynamicPaginationEllipsis(controls, key, targetPage) {
        controls.push(
            <Pagination.Ellipsis
                key={key}
                onClick={() => { this.UpdateTablePage(targetPage) }}
            />
        );
    }

}

