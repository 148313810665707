import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ReportApi } from '../BEACONApi'

import Spinner from 'react-bootstrap/Spinner';

export class ButtonDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
        }

        this.ReportApi = new ReportApi();

    }

    ButtonClick() {
        switch (this.props.reportName) {
            case "BeaconStatus":
                this.setState({ isBusy: true }, () => {
                    this.DownloadBeaconStatusReport();
                });
                break;
            case "BeaconSource":
                this.setState({ isBusy: true }, () => {
                    this.DownloadBeaconSourceReport();
                });
                break;
            default:
                break;
        }
    }

    async DownloadBeaconStatusReport() {
        await this.ReportApi.BeaconStatus((fileBlob) => {

            this.setState({ isBusy: false }, () => {
                if (fileBlob)
                    this.DownloadFile(fileBlob, "BeaconStatus.xlsx")
            });

        }, (errors) => {

            this.setState({ isBusy: false }, () => {
                console.warn(errors);
            });

        });
    }

    async DownloadBeaconSourceReport() {
        await this.ReportApi.BeaconSource((fileBlob) => {

            this.setState({ isBusy: false }, () => {
                if (fileBlob)
                    this.DownloadFile(fileBlob, "BeaconSource.xlsx")
            });

        }, (errors) => {

            this.setState({ isBusy: false }, () => {
                console.warn(errors);
            });

        });
    }

    DownloadFile(fileResponseData, fileName) {
        const url = window.URL.createObjectURL(new Blob([fileResponseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <button type="button" className="text-nowrap btn btn-primary" disabled={this.props.disabled ?? false} onClick={() => { this.ButtonClick() }}>
                {this.state.isBusy && (
                    <div className="ps-5 pe-5">
                        <Spinner animation="border" size="sm" role="status" variant="light" />
                    </div>
                )}
                {!this.state.isBusy && (
                    <div className="ps-3 pe-3">Download</div>
                )}
            </button>
        );
    }

}
