import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { Validate } from '../../shared/GlobalFns'

export class QuestionInstruction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(event) {
        let result = { questionCode: this.props.question.code, answer: event.target.value };
        this.setState({ result: result }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    QuestionContainerClass() {
        if (this.props.device.IsPhone())
            return "pb-3 pt-3 ps-2 pe-1"
        else
            return "p-3"
    }

    Load() {
        this.setState({
            result: this.props.result,
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    componentWillUnmount() {
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        return (
            <div className={this.QuestionContainerClass()} >
                <span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>
            </div>
        );
    }

}

