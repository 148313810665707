import React, { Component } from 'react';
import { ModalConfirm } from './shared/ModalConfirm';
import { ButtonAdd } from './shared/Buttons/Add';
import { ButtonEdit } from './shared/Buttons/Edit';
import { ButtonPlay } from './shared/Buttons/Play';
import { ButtonStatus } from './shared/Buttons/Status';
import { ButtonStatusResults } from './shared/Buttons/StatusResults';
import { ButtonDelete } from './shared/Buttons/Delete';
import { PlayForm } from './Questionnaire/PlayForm';
import { EditForm } from './Questionnaire/EditForm';
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { TablePagination } from './shared/TablePagination';
import { Validate, Format } from './shared/GlobalFns';
import { QuestionnaireApi } from './shared/QuestionnaireApi';

import './Questionnaires.css'

export class Questionnaires extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fields: [
                { key: 'status', label: 'Status' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version' },
                { key: 'optional', label: 'Optional' },
                { key: 'algorithms', label: 'Pass If' },
                { key: 'numberOfResults', label: 'Total Results' },
                { key: 'actions', label: '' },
            ],
            resultsFields: [
                { key: 'status', label: 'Status' },
                { key: 'createdDate', label: 'Start Date' },
                { key: 'modifiedDate', label: 'End Date' },
                { key: 'instance', label: 'Instance' },
                { key: 'actions', label: '' },
            ],
            resultsOnMobileFields: [
                { key: 'details', label: '' },
            ],
            showModal: {
                confirm: false,
                confirmDevDelete: false,
                play: false,
                edit: false,
            },
            selectedItem: {},
            selectedIndex: -1,
            selectedInstance: 0,
            nextInstance: 1,
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            questionOptions: {
                previous: false,
                skip: false,
                next: false,
                nextEnabled: false,
                save: false
            },
            selectedLanguage: 'en-GB',
            participantsResults: [],
        };

        this.editForm = React.createRef();
        this.playForm = React.createRef();
        this.confirmModal = React.createRef();
        this.confirmDevDeleteModal = React.createRef();

        this.questionnaireApi = new QuestionnaireApi();
        this.validate = new Validate();
        this.format = new Format();
    }

    async GetParticipantResults(questionnaire, callBackFn) {

        if (questionnaire) {

            await this.questionnaireApi.GetParticipantResults(this.state.resultDataQuery, questionnaire, (results) => {

                let updatedDataQuery = update(results.query, { filter: { $set: this.state.resultDataQuery.filter }, totalCount: { $set: results.totalCount } });
                this.setState({
                    participantsResults: results.data,
                    resultDataQuery: updatedDataQuery,
                    nextInstance: parseFloat(`0.${results.totalCount + 1}`),
                }, () => {
                    if (callBackFn)
                        callBackFn();
                });

            }, (errors) => {

            });

        }
    }

    async UpdateQuestionnaireResults(index) {

        if (index > 0) {
            let questionnaire = this.state.data[index];
            if (questionnaire && questionnaire.id && questionnaire.id > 0) {

                await this.questionnaireApi.Get(questionnaire, (data) => {

                    let updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
                    this.setState({ data: updatedData });

                }, (errors) => {

                });

            } else {
                console.warn(`Questionnaire not found index [${index}]... cannot update result count`);
            }
        }
    }

    async GetData() {

        await this.questionnaireApi.Query(this.state.dataQuery, (data) => {
            let updatedDataQuery = update(data.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                data: data.data,
                dataQuery: updatedDataQuery,
            });

        }, (error) => {

        })

    }

    async SaveItem(index, item) {

        await this.questionnaireApi.SaveItem(index, item, (data) => {

            this.UpdateItemInList(index, data);
            this.EditClose();

        }, (errors) => {

            if (this.editForm.current) this.editForm.current.SaveFailed(errors)

        });

    }

    async DeleteItem() {

        await this.questionnaireApi.DeleteItem(this.state.selectedIndex, this.state.selectedItem, (deletedIndex) => {

            if (this.state.selectedIndex >= 0) {
                let updatedData = update(this.state.data, { $splice: [[this.state.selectedIndex, 1]] });
                this.setState({ showModal: { confirm: false }, data: updatedData });
                //this.GetData();
            }

        }, (errors) => {

            if (this.confirmModal.current) this.confirmModal.current.ConfirmFailed(errors)

        });

    }

    async DevDeleteItem() {

        await this.questionnaireApi.DevDeleteItem(this.state.selectedIndex, this.state.selectedItem, (deletedIndex) => {

            if (this.state.selectedIndex >= 0) {
                let updatedData = update(this.state.data, { $splice: [[this.state.selectedIndex, 1]] });
                this.setState({ showModal: { confirmDevDelete: false }, data: updatedData });
                //this.GetData();
            }

        }, (errors) => {

            if (this.confirmDevDeleteModal.current) this.confirmDevDeleteModal.current.ConfirmFailed(errors)

        });

    }

    TabulateData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            this.state.fields.forEach((field) => {
                switch (field.key) {
                    case 'code':
                        row[field.key] = `<div>${data[field.key]}</div><div>${data.text.text}</div>`;
                        break;
                    case 'version':
                    case 'numberOfResults':
                        row[field.key] = data[field.key] ?? 0;
                        break;
                    case 'optional':
                        row[field.key] = data[field.key] === true ? "Optional" : "Mandatory"
                        break
                    case 'algorithms':
                        row[field.key] = data[field.key] && data[field.key].length > 0 ? data[field.key].map(alg => `${alg.name}(${alg.parameters.join(",")})`) : '---'
                        break
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulateResultsData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.participantsResults, (data) => {
            let row = {};
            this.state.resultsFields.forEach((field) => {
                switch (field.key) {
                    case 'createdDate':
                    case 'modifiedDate':
                        row[field.key] = this.format.FormatDateTime(data[field.key], this.state.selectedLanguage);
                        break;
                    case 'instance':
                        row[field.key] = data.questionnaire[field.key] ?? '-';
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulateResultsOnMobileData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.participantsResults, (data) => {
            let row = {};
            this.state.resultsOnMobileFields.forEach((field) => {
                switch (field.key) {
                    case 'details':
                        row[field.key] = this.format.FormatDateTime(data["modifiedDate"], this.state.selectedLanguage);
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    EditOpen(index, item) {
        if (item)
            this.setState({ showModal: { edit: true }, selectedIndex: index ?? -1, selectedItem: item });
        else
            this.setState({ showModal: { edit: true }, selectedIndex: -2, selectedItem: { code: '', questions: [] } });
    }
    EditClose() {
        this.setState({ showModal: { edit: false } });
    }
    UploadFile() {
        if (this.editForm.current) this.editForm.current.UploadFile();
    }
    UploadDone(questionnaire) {
        this.setState({ selectedIndex: -1, selectedItem: questionnaire }, () => {
            if (this.editForm.current) this.editForm.current.ConvertToEdit(-1, questionnaire);
        });
    }
    EditSave() {
        if (this.editForm.current) this.editForm.current.SaveClick();
    }

    PlayOpen(index, item) {
        if (item) {
            let updatedDataQuery = update(this.state.resultDataQuery, { pageNumber: { $set: 1 } });
            this.setState({ resultDataQuery: updatedDataQuery }, () => {
                this.GetParticipantResults(item, () => {
                    // If there are no results start a new session automatically (instance > 0)
                    let instance = (this.state.participantsResults && this.state.participantsResults.length > 0) ? 0 : 0.1;
                    this.setState({ showModal: { play: true }, selectedIndex: index ?? -1, selectedItem: item, selectedInstance: instance });
                });
            });
        }
    }
    PlaySelectInstance(instance) {
        this.setState({ selectedInstance: instance });
    }
    PlayPrevious() {
        if (this.playForm.current) this.playForm.current.PreviousClick();
    }
    PlayNext() {
        if (this.playForm.current) this.playForm.current.NextClick();
    }
    SkipNext() {
        if (this.playForm.current) this.playForm.current.NextClick(true);
    }
    PlayClose() {
        this.setState({ showModal: { play: false } });
    }
    CloseAndSaveResults() {
        if (this.playForm.current) this.playForm.current.SaveResults(false, true);
    }
    SubmitAndSaveResults() {
        if (this.playForm.current) this.playForm.current.SaveResults(true, true);
    }
    SavedResults(index, results) {
        this.UpdateQuestionnaireResults(index);
        if (!results.isCompleted)
            this.RefreshResults();
    }
    RefreshResults() {
        this.GetParticipantResults(this.state.selectedItem, () => {
            if (!this.state.participantsResults || this.state.participantsResults.length === 0)
                this.setState({ showModal: { play: false }, selectedInstance: 0 });
            else 
                this.setState({ selectedInstance: 0 });
        });
    }

    DevDeleteOpen(index, item) {
        this.setState({ showModal: { confirmDevDelete: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DevDeleteClose() {
        this.setState({ showModal: { confirmDevDelete: false } });
    }

    DeleteOpen(index, item) {
        this.setState({ showModal: { confirm: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DeleteClose() {
        this.setState({ showModal: { confirm: false } });
    }

    UpdateItemInList(index, data) {
        let updatedData = {};
        if (index >= 0)
            updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
        else
            updatedData = update(this.state.data, { $splice: [[0, 0, data]] });
        this.setState({ data: updatedData }, () => { this.EditClose() });
    }

    UpdateQuestionOptions(options) {
        this.setState({ questionOptions: options })
    }

    ShowSkip() {
        return this.state.questionOptions.skip === true;
    }
    ShowNext() {
        return this.state.questionOptions.next === true;
    }
    NextEnabled() {
        return this.state.questionOptions.nextEnabled === true;
    }

    componentDidMount() {
        this.GetData();
    }

    render() {
        return (
            <div className="flex-fill">
                
                {this.RenderQuestionnaires()}

                <Modal show={this.state.showModal.edit} onHide={() => this.EditClose()} size="lg" fullscreen={this.props.device.ShowMobileUI() ? true : "lg-down"} backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Questionnaire Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <EditForm
                            ref={this.editForm}
                            index={this.state.selectedIndex}
                            item={this.state.selectedItem}
                            existing={this.state.data}
                            onUploaded={(data) => this.UploadDone(data)}
                            onSave={(index, data) => this.SaveItem(index, data)}
                            onUpdate={(index, data) => this.UpdateItemInList(index, data)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.EditClose()}>
                            Close
                        </Button>
                        {!this.props.user.IsGuest() && this.state.selectedIndex === -2 && (
                            <Button variant="primary" onClick={() => this.UploadFile()}>
                                Upload Template
                            </Button>
                        )}
                        {!this.props.user.IsGuest() && this.state.selectedIndex > -2 && (
                            <Button variant="primary" onClick={() => this.EditSave()}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal.play} onHide={() => this.PlayClose()} size="lg" fullscreen={this.props.device.ShowMobileUI() ? true : "lg-down"} backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton={this.props.device.ShowMobileUI() && this.state.selectedInstance === 0} className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">
                            {this.RenderHeaderText()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.RenderResultsOrQuestionnaire()}

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">

                        {this.RenderOptions()}

                    </Modal.Footer>
                </Modal>

                <ModalConfirm
                    ref={this.confirmModal}
                    show={this.state.showModal.confirm}
                    onCancel={() => this.DeleteClose()}
                    onConfirm={() => this.DeleteItem()}
                    title="Delete Questionnaire"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                    message="Are you sure you want to delete this questionnaire?"
                />

                <ModalConfirm
                    ref={this.confirmDevDeleteModal}
                    show={this.state.showModal.confirmDevDelete}
                    onCancel={() => this.DevDeleteClose()}
                    onConfirm={() => this.DevDeleteItem()}
                    title="Delete Questionnaire"
                    message="Are you sure you want to delete this questionnaire?"
                    warning="<b>YOU ARE LOGGED IN WITH A DEVELOPER ACCOUNT.</b><br/>Any results associated with this questionnaire will also be deleted."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderQuestionnaires() {
        let rows = this.TabulateData();
        if (this.props.device.ShowMobileUI()) {

            return (
                <div>
                    <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {this.RenderDashboardTableRows(rows)}
                        </tbody>
                    </Table>
                </div>
            );

        } else {

            return (
                <div>
                    <div className="d-flex pt-4">
                        <TablePagination
                            canSearch
                            canChangePageResults
                            dataQuery={this.state.dataQuery}
                            onUpdated={(query) => this.setState({ dataQuery: query }, () => { this.GetData(); })}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                        {!this.props.user.IsDeveloper() && (
                            <span></span>
                        )}
                        {this.props.user.IsDeveloper() && (
                            <span className="mt-auto">
                                <ButtonAdd onClick={() => this.EditOpen()} text="New Questionnaire" />
                            </span>
                        )}
                    </div>
                    <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()} >
                        <tbody>
                            {this.RenderFullWidthTableRows(rows)}
                        </tbody>
                    </Table>
                </div>
            );

        }
    }

    RenderDashboardTableRows(rows) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={`ROW-${index}`}>
                    <Card as="td" className="clickable-card m-1">
                        <Card.Body className="p-2" onClick={() => this.PlayOpen(index, this.state.data[index])}>
                            <Card.Text as="div">
                                <div className="d-flex justify-content-between">
                                    <div className="pt-1">
                                        {this.state.data[index].text && !this.validate.IsEmpty(this.state.data[index].text.text) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.text }}></span>
                                        )}
                                        {(!this.state.data[index].text || this.validate.IsEmpty(this.state.data[index].text.text)) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].code }}></span>
                                        )}
                                        &nbsp;v<span dangerouslySetInnerHTML={{ __html: this.state.data[index].version }}></span>
                                    </div>
                                    <div>
                                        <ButtonStatus size="sm" status={this.state.data[index].status} />
                                    </div>
                                </div>
                                {this.state.data[index].text && this.state.data[index].text.description && (
                                    <div>
                                        <hr />
                                        <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.description }}></span>
                                    </div>
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </tr>
            );
        })
        return tableRows;
    }

    RenderFullWidthTableRows(rows) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={`ROW-${index}`}>
                    {this.state.fields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            this.RowActions(index, subindex, this.props.user) :
                            rows[index][item.key] === 'Status' ?
                                <td key={`STATUS-${subindex}`} className="pt-4">
                                    <ButtonStatus status={this.state.data[index].status} />
                                </td> :
                                <td key={`KEY-${subindex}`} className={item.className}>
                                    <div><span className="miniLabel">{item.label}:</span></div>
                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                </td>
                    ))}
                </tr>
            );
        })
        return tableRows;
    }

    RowActions(index, subindex, user) {
        if (user.IsDeveloper()) {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DevDeleteOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else if (user.IsAdmin()) {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    {this.state.data[index].numberOfResults === 0 && (
                        <span>
                            <ButtonDelete onClick={() => this.DeleteOpen(index, this.state.data[index])} />&nbsp;
                        </span>
                    )}                    
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        }
    }

    RenderResultsOrQuestionnaire() {
        if (this.state.selectedInstance === 0) {
            return (
                <div>
                    {this.RenderResults()}
                </div>
            );
        } else {
            if (this.state.selectedItem) {
                return (
                    <PlayForm
                        ref={this.playForm}
                        index={this.state.selectedIndex}
                        code={this.state.selectedItem.code}
                        version={this.state.selectedItem.version}
                        instance={this.state.selectedInstance}
                        langaguageCode={this.state.selectedLanguage}
                        onSave={(index, results) => this.SavedResults(index, results)}
                        onUpdateQuestionOptions={(options) => this.UpdateQuestionOptions(options)}
                        user={this.props.user}
                        device={this.props.device}
                        theme={this.props.theme}
                    />
                );
            }
        }
    }

    RenderHeaderText() {
        return (
            <div className="d-flex justify-content-between">
                <div>{this.state.selectedItem.text && this.state.selectedItem.text.text ? this.state.selectedItem.text.text : 'Questionnaire'}</div>
                {this.state.selectedInstance > 0 && this.state.questionOptions && !this.state.questionOptions.done && (
                    <div>
                        <Button variant="danger" onClick={() => this.CloseAndSaveResults()}>
                            Quit
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    RenderOptions() {
        let buttons = [];
        let index = 0;
        if (this.state.selectedInstance === 0) {
            if (!this.props.device.ShowMobileUI())
                buttons.push(
                    <Button key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                        Close
                    </Button>
                );
            buttons.push(
                <Button key={index++} onClick={() => { this.PlaySelectInstance(this.state.nextInstance) }}>
                    Start a New Session
                </Button>
            );
        } else {
            if (this.state.questionOptions.done)
                buttons.push(
                    <Button key={index++} variant="secondary" onClick={() => this.RefreshResults()}>
                        Done
                    </Button>
                );
            if (this.state.questionOptions.previous)
                buttons.push(
                    <Button key={index++} variant="primary" onClick={() => this.PlayPrevious()}>
                        Previous
                    </Button>
                )
            if (this.ShowSkip())
                buttons.push(
                    <Button key={index++} variant="primary" onClick={() => this.SkipNext()}>
                        Skip
                    </Button>
                )
            if (this.ShowNext())
                buttons.push(
                    <Button key={index++} variant="primary" disabled={!this.NextEnabled()} onClick={() => { if (this.NextEnabled()) this.PlayNext() }}>
                        Next
                    </Button>
                )
            if (this.state.questionOptions.save)
                buttons.push(
                    <Button key={index++} variant="primary" disabled={!this.NextEnabled()} onClick={() => this.SubmitAndSaveResults()}>
                        Submit
                    </Button>
                )
        }
        return buttons;
    }

    RenderResults() {
        if (this.props.device.ShowMobileUI() || this.props.user.IsGuest()) {
            let rows = this.TabulateResultsOnMobileData();
            return (
                <Table responsive>
                    <tbody>
                        {rows.map((_, index) => (
                            <tr key={`ROW-${index}`}>
                                <Card as="td" className="clickable-card m-1">
                                    <Card.Body className="p-2" onClick={() => this.PlaySelectInstance(this.state.participantsResults[index].questionnaire.instance)}>
                                        <Card.Text as="div">
                                            <div className="d-flex justify-content-between">
                                                {this.format.FormatDateTime(this.state.data[index].modifiedDate)}
                                                <ButtonStatusResults status={this.state.participantsResults[index].status} />
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            );
        } else {
            let rows = this.TabulateResultsData();
            return (
                <div>
                    <TablePagination
                        dataQuery={this.state.resultDataQuery}
                        onUpdated={(query) => this.setState({ resultDataQuery: query }, () => { this.GetParticipantResults(this.state.selectedItem); })}
                        user={this.props.user}
                        device={this.props.device}
                        theme={this.props.theme}
                    />
                    <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {rows.map((_, index) => (
                                <tr key={index}>
                                    {this.state.resultsFields.map((item, subindex) => (
                                        rows[index][item.key] === 'Actions' ?
                                            this.ResultRowActions(index, subindex, this.state.participantsResults[index].questionnaire.instance, this.props.user) :
                                            rows[index][item.key] === 'Status' ?
                                                <td key={subindex} className="pt-4">
                                                    <ButtonStatusResults status={this.state.participantsResults[index].status} />
                                                </td> :
                                                <td key={subindex}>
                                                    <div><span className="miniLabel">{item.label}:</span></div>
                                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                                </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            );
        }
    }

    ResultRowActions(index, subindex, instance, user) {
        return (
            <td key={subindex} className="text-end text-nowrap pt-4">
                <ButtonPlay onClick={() => this.PlaySelectInstance(instance)} />
            </td>
        )
    }

}
