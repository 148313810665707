import update from 'immutability-helper';
import { ConsoleLogging, ErrorHandler } from './APIHelpers'

export class RandomisationApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "randomisation";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // RandomisationApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataQuery),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // RandomisationApi
    async SaveItem(item, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/save`;
        let postData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(item),
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // RandomisationApi
    async DeleteItem(id, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/delete/${id}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // RandomisationApi
    async DownloadRandomisedParticipants(ruleId, randomisationType, spreadsheetPassword, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/randomisedParticipants/${ruleId}/${randomisationType}/${spreadsheetPassword}`;
        await this.FetchBlob(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // RandomisationApi
    async ResetRule(ruleId, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/resetRule/${ruleId}`;
        let deleteData = {
            method: "DELETE",
        };
        await this.Fetch(url, deleteData, callBackSuccessFn, callBackErrorFn);

    }

    // RandomisationApi
    async UploadFile(ruleId, randomisationType, file, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/randomise`;
        var formData = new FormData()
        formData.append('id', ruleId);
        formData.append('type', randomisationType);
        formData.append('file', file);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchBlob(url, postData, callBackSuccessFn, callBackErrorFn)

    }

    // RandomisationApi
    async Get(ruleId, callBackSuccessFn, callBackErrorFn) {

        if (ruleId) {
            let url = `${this.endPoint}/Get/${ruleId}`;
            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
        } else {
            this.consoleLog.LogError(`${this.endPoint}/Get/?`, "INVALID PARAMETERS");
        }

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }

    async FetchBlob(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.blob();
                        if (data) {

                            this.consoleLog.Log(url, "File Downloaded");
                            if (callBackSuccessFn)
                                callBackSuccessFn(data);

                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class AccountApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "account";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // AccountApi
    async MyDetails(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/mydetails`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
    }

    // AccountApi
    async KeepLoggedIn(callBackSuccessFn, callBackErrorFn) {

        try {
            let url = `${this.endPoint}/KeepLoggedIn`;

            await fetch(url)
                .then(async response => {

                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {

                        this.consoleLog.Log(url);
                        if (callBackSuccessFn)
                            callBackSuccessFn();

                    }

                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });

        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}
