import React, { Component } from "react";
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

export class EditStudyGroupForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            isNameValid: true,
            isRatioValid: true,
            isMaxParticipantsValid: true,
            ratioMessage: 'Please enter a number between 0 and 100',
            maxParticipantsMessage: 'Please enter a number between 0 and 100,000',
            nameMessage: 'Please enter a valid name',
            editItem: {
                name: '',
                ratio: 1,
                maxParticipants: 0,
            },
            editIndex: -1,
            existingNames: [],
        };
        
    } 

    UpdateName(event) {
        let updatedItem = update(this.state.editItem, { name: { $set: event.target.value } });
        this.setState({ editItem: updatedItem });
    }

    NameIsValid() {
        let valid = true;
        var value = this.state.editItem.name.trim().toUpperCase();
        if (this.IsEmpty(value)) {
            this.setState({ nameMessage: "The name cannot be blank" });
            valid = false;
        } else if (this.state.existingNames.includes(value)) {
            this.setState({ nameMessage: "This name already exists" });
            valid = false;
        }
        this.setState({ isNameValid: valid });
        return valid;
    }

    UpdateRatio(event) {
        let value = Math.min(Math.max(parseInt(event.target.value), 1), 100);
        if (Number.isNaN(value)) value = '';
        let updatedItem = update(this.state.editItem, { ratio: { $set: value } });
        this.setState({ editItem: updatedItem });
    }

    UpdateMaxParticipants(event) {
        let value = Math.min(Math.max(parseInt(event.target.value ?? 0), 0), 100000);
        if (Number.isNaN(value)) value = '';
        let updatedItem = update(this.state.editItem, { maxParticipants: { $set: value } });
        this.setState({ editItem: updatedItem });
    }

    RatioIsValid() {
        let valid = true;
        let value = Math.min(Math.max(parseInt(this.state.editItem.ratio), 1), 100);
        if (Number.isNaN(value)) value = '';
        if (this.IsEmpty(value.toString())) {
            this.setState({ ratioMessage: "Ratio must be an integer between 1 and 100" });
            valid = false;
        }
        this.setState({ isRatioValid: valid });
        return valid;
    }

    MaxParticipantsIsValid() {
        let valid = true;
        let value = Math.min(Math.max(parseInt(this.state.editItem.maxParticipants ?? 0), 0), 100000);
        if (Number.isNaN(value)) value = '';
        if (this.IsEmpty(value.toString())) {
            this.setState({ maxParticipantsMessage: "Max participants must be an integer between 0 and 100,000" });
            valid = false;
        }
        this.setState({ isMaxParticipantsValid: valid });
        return valid;
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    SaveClick() {
        if (this.props.onSave) {
            this.setState({ isBusy: true });
            this.setState({
                isFormValidated: true,
                isFormValid: this.NameIsValid() & this.RatioIsValid() & this.MaxParticipantsIsValid(),
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    componentDidMount() {
        this.setState({ editIndex: this.props.index });
        if (this.props.item) {
            this.setState({ editItem: this.props.item }, () => {
                if (!this.state.editItem.maxParticipants) {
                    let updatedItem = update(this.state.editItem, { maxParticipants: { $set: 0 } });
                    this.setState({ editItem: updatedItem });
                }
            });
        }
        if (this.props.existing) {
            this.setState({ existingNames: this.props.existing.filter(item => this.props.item === undefined || item.name !== this.props.item.name).map(item => `${item.name.trim().toUpperCase()}`) });
        }
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <Form noValidate validated={this.state.isFormValidated}>
                    {this.state.isBusy ?
                        <div className="busy-overlay col d-flex align-items-center justify-content-center">
                            <Spinner animation="border" role="status" variant="dark" />
                        </div>
                    : ''}

                    <Form.Group className="mb-4" ref="name_input">
                        <Form.Label><b>Name</b></Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Please enter a unique name"
                            value={this.state.editItem.name}
                            onChange={(event) => { this.UpdateName(event) }}
                            isInvalid={!this.state.isNameValid}
                            maxLength="50"
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.nameMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" ref="ratio_input">
                        <Form.Label><b>Ratio</b></Form.Label>
                        <Form.Control
                            required
                            type="number"
                            min="0"
                            step="1"
                            max="100"
                            placeholder="Please enter a number"
                            value={this.state.editItem.ratio}
                            onChange={(event) => { this.UpdateRatio(event) }}
                            isInvalid={!this.state.isRatioValid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.ratioMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" ref="ratio_input">
                        <Form.Label><b>Max Participants</b><br /><small>An optional limit to the number of participants who can be randomised into this group (leave as zero for no limit).</small></Form.Label>
                        <Form.Control
                            required
                            type="number"
                            min="0"
                            step="1"
                            max="100000"
                            placeholder="Please enter a number"
                            value={this.state.editItem.maxParticipants}
                            onChange={(event) => { this.UpdateMaxParticipants(event) }}
                            isInvalid={!this.state.isMaxParticipantsValid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.maxParticipantsMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>
            </div>
        );
    }

}
