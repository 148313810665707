import React, { Component } from "react";
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { Validate } from '../shared/GlobalFns'
import { PlayDiploriaBattery } from './PlayDiploriaBattery';
import { ButtonBackApp } from '../shared/Buttons/BackApp';
import { ModalConfirm } from '../shared/ModalConfirm';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import update from 'immutability-helper';

export class PlayBatteryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                back: false,
                endSession: false,
                resumeSession: false,
                startSession: false,
            },
            showModal: {
                confirmEndSession: false,
            },
        };

        this.sessionStarted = false;

        this.questionnaireApi = new QuestionnaireApi();
        this.validate = new Validate();

        this.playFormModalBody = React.createRef();
        this.confirmEndSessionModal = React.createRef();

        this.playDiploriaBattery = React.createRef();
    }

    UpdateSessionOptions(options) {
        this.setState({ options: options })
    }

    UpdateHeader() {
        // Not impemented for BATTERIES (used by some BRAIN TRAINING GAMES)
    }

    SavedResults(index, results) {
        // Called each time the battery saves a set of results
    }

    BeginSession() {
        if (this.playDiploriaBattery.current)
            this.playDiploriaBattery.current.BeginSession();
    }

    PlayClose() {
        this.sessionStarted = false;
        if (this.props.onHide)
            this.props.onHide();
    }

    EndSessionTitle() {
        if (this.state.selectedItem && this.state.selectedItem.text && this.state.selectedItem.text.confirmEndSessionTitle)
            return this.state.selectedItem.text.confirmEndSessionTitle;
        else
            return "End Session";
    }

    EndSessionText() {
        if (this.state.selectedItem && this.state.selectedItem.text && this.state.selectedItem.text.confirmEndSessionText)
            return this.state.selectedItem.text.confirmEndSessionText;
        else
            return "<p>If you end this session you will need to complete it as soon as possible for the results to be accurate.</p><p><b>After 12 hours the session will expire and you will not be able to resume.</b></p><p>Are you sure you want to end this session now?</p>";
    }

    EndSession() {
        if (this.sessionStarted) {
            let modalState = update(this.state.showModal, { confirmEndSession: { $set: true } });
            this.setState({ showModal: modalState });
        } else {
            this.PlayClose();
        }
    }

    EndSessionModalClose() {
        let modalState = update(this.state.showModal, { confirmEndSession: { $set: false } });
        this.setState({ showModal: modalState });
    }

    LoadPage() {
        this.setState({ activity: this.props.activity })
    }

    GetSelectedName() {
        return this.state.activity && this.state.activity.displayName ? this.state.activity.displayName : 'Battery';
    }

    GetPlayHeaderItems() {
        let headerItems = [];

        if (this.state.selectedInstance === -1 || this.state.options.back) {

            headerItems.push(
                <div key="HEADER_NAME" className="pe-3 d-flex">
                    {this.props.device.ShowMobileUI() && (
                        <div className="pe-3">
                            <ButtonBackApp onClick={() => this.PlayClose()}></ButtonBackApp>
                        </div>
                    )}
                    <div className="text-start">{this.GetSelectedName()}</div>
                </div>
            );

        } else if (this.state.options.endSession) {
            headerItems.push(
                <div key="HEADER_NAME" className="text-start">{this.GetSelectedName()}</div>
            );
            headerItems.push(
                <Button key="HEADER_ENDSESSION" className="text-nowrap" variant="danger" onClick={() => this.EndSession()}>
                    End Session
                </Button>
            );
        }

        return headerItems;

    }

    GetPlayFooterItems() {
        let buttons = [];
        let index = 0;

        if (this.state.selectedInstance === -1) {
            if (!this.props.device.ShowMobileUI()) {
                buttons.push(
                    <Button key="OPTION_BACK" key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                        Back
                    </Button>
                );
            }
            buttons.push(
                <Button key="OPTION_CREATESESSION" className="text-nowrap" key={index++} variant="primary" onClick={() => this.PlaySelectInstance(0)}>
                    Create New Session
                </Button>
            );
        } else {
            if (this.state.options.back) {
                if (!this.props.device.ShowMobileUI()) {
                    buttons.push(
                        <Button key="OPTION_BACK" key={index++} variant="secondary" onClick={() => this.PlayClose()}>
                            Back
                        </Button>
                    );
                }
            }
            if (this.state.options.startSession) {
                buttons.push(
                    <Button key="OPTION_STARTSESSION" className="text-nowrap" key={index++} variant="primary" onClick={() => this.BeginSession()}>
                        Start Session
                    </Button>
                );
            }
            if (this.state.options.resumeSession) {
                buttons.push(
                    <Button key="OPTION_RESUMESESSION" className="text-nowrap" key={index++} variant="primary" onClick={() => this.BeginSession()}>
                        Resume Session
                    </Button>
                );
            }
        }
        return buttons;
    }

    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activity !== prevProps.activity)
            this.LoadPage();
    }

    render() {
        let playHeaderOptions = this.GetPlayHeaderItems()
        let playModalOptions = this.GetPlayFooterItems();
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={() => this.PlayClose()}
                    onEntered={() => this.LoadPage()}
                    fullscreen={true}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="cognitive-item-modal"
                    scrollable>

                    {playHeaderOptions.length > 0 && (
                        <Modal.Header className="modal-header-app" data-bs-theme="dark">
                            <Modal.Title className="w-100">
                                <div className="d-flex justify-content-between">
                                    {playHeaderOptions}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                    )}

                    <Modal.Body className="p-0 cognitive-item-modal-body-test" ref={this.playFormModalBody}>
                        {this.RenderCognitiveItem()}
                    </Modal.Body>

                    {playModalOptions.length > 0 && (
                        <Modal.Footer className="cognitive-item-modal-footer-test">
                            {playModalOptions}
                        </Modal.Footer>
                    )}

                </Modal>

                <ModalConfirm
                    ref={this.confirmEndSessionModal}
                    show={this.state.showModal.confirmEndSession}
                    onCancel={() => this.EndSessionModalClose()}
                    onConfirm={() => { this.EndSessionModalClose(); this.PlayClose(); }}
                    title={this.EndSessionTitle()}
                    message={this.EndSessionText()}
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />
            </div>
        );
    }

    RenderCognitiveItem() {
        if (this.state.activity) {
            return (

                <PlayDiploriaBattery
                    mode={this.state.activity.mode}
                    inputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                    cognitiveItemType={this.props.cognitiveItemType}

                    ref={this.playDiploriaBattery}

                    index="0"
                    stageCode={this.state.activity.stageCode}
                    code={this.state.activity.code}
                    version={this.state.activity.version}
                    instance={parseFloat(`${this.state.activity.occurrence}.${this.state.activity.instance}`)}
                    languageCode="en-GB"

                    theme={this.props.theme}
                    user={this.props.user}
                    device={this.props.device}

                    onSessionEnded={() => this.EndSession()}
                    onSavedBatteryResults={(results) => this.SavedResults(results)}
                    onUpdateOptions={(options) => this.UpdateSessionOptions(options)}
                    onComplete={() => { this.PlayClose() }}

                    className="full-size"
                />

            );
        } else {
            //console.debug("Activity/Battery not set");
        }
    }
}
