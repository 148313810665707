import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Validate, Format } from "../../shared/GlobalFns";
import Button from 'react-bootstrap/Button';

export class QuestionDate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormValidated: false,
            isFormValid: true,
            result: {},
            day: '',
            month: '',
            year: '',
            hasChanges: false,
        };

        this.defaultMin = '1800-01-01';
        this.defaultMax = '2500-12-31';

        this.validate = new Validate();
        this.format = new Format();

        this.dayRef = React.createRef();
        this.monthRef = React.createRef();
        this.yearRef = React.createRef();
    }

    ErrorMessage() {
        let message = '';
        if (!this.validate.IsEmpty(this.state.day) && !this.validate.IsEmpty(this.state.month) && !this.validate.IsEmpty(this.state.year)) {
            if (!this.IsInRange(this.Value())) {

                if (this.MinValue() === this.MaxValue())
                    message = `Please enter ${this.format.FormatDate(this.MaxValue())}`;

                else if (this.MinValue() !== this.MinValue && this.MaxValue() !== this.MaxValue)
                    message = `Must be between ${this.format.FormatDate(this.MinValue())} and ${this.format.FormatDate(this.MaxValue())}`;

                else if (this.MinValue() !== this.MinValue)
                    message = `Must be greater than ${this.format.FormatDate(this.MinValue())}`;

                else if (this.MaxValue() !== this.MaxValue)
                    message = `Must be less than ${this.format.FormatDate(this.MaxValue())}`;

            } else if (this.validate.IsEmpty(this.Value())) {
                message = 'We need an answer to this question'

            } else if (!this.IsValidFormat(this.Value())) {
                message = 'Please enter a valid date';

            }
        }
        return message;
    }

    IsValid(value) {
        let valid = false;
        if (this.props.question.validation && this.props.question.validation.optional) 
            valid = this.validate.IsEmpty(value) || (this.IsInRange(value) && this.IsValidFormat(value));
        else 
            valid = !this.validate.IsEmpty(value) && this.IsInRange(value) && this.IsValidFormat(value);
        //console.log("ISVALID DATE", value, valid);
        return valid;
     }

    IsValidFormat(value) {
        let valid = false;
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (value.match(regEx)) {
            //console.log("VALID DATE FORMAT", value, new Date(value).toISOString().split("T")[0] === value)
            valid = new Date(value).toISOString().split("T")[0] === value;
        }
        return valid;
    }

    IsInRange(value) {
        if (!this.validate.IsEmpty(value))
            return (new Date(this.MinValue()) <= new Date(value) && new Date(value) <= new Date(this.MaxValue()));
        else
            return false;
    }

    RemoveResult() {
        let result = {
            questionCode: this.props.question.code,
            answer: '',
            isValid: this.IsValid(this.Value())
        };
        this.setState({ result: result, hasChanges: result.answer !== this.state.result.answer, day: '', month: '', year: '' }, () => {
            if (this.dayRef.current)
                this.dayRef.current.focus();
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    UpdateResult(value) {
        let dateParts = value.split("-");
        let result = {
            questionCode: this.props.question.code,
            answer: value === "--" ? "" : value,
            isValid: this.IsValid(value)
        };
        this.setState({ result: result, hasChanges: result.answer !== this.state.result.answer, day: dateParts[2], month: dateParts[1], year: dateParts[0] }, () => {
            //console.log ("RESULT", result)
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    MaxLength() {
        return 10;
    }

    MinValue() {
        let result = this.defaultMin;
        if (this.props.question && this.props.question.validation && this.props.question.validation.min) {
            let min = this.props.question.validation.min;
            if (min === "Now")
                result = new Date().toISOString().split("T")[0];
            else
                result = min.split("T")[0];
        }
        return result;
    }

    MinYear() {
        return this.MinValue().split("-")[0];
    }

    MaxValue() {
        let result = this.defaultMax;
        if (this.props.question && this.props.question.validation && this.props.question.validation.max) {
            let max = this.props.question.validation.max;
            if (max === "Now")
                result = new Date().toISOString().split("T")[0];
            else
                result = max.split("T")[0];
        }
        return result;
    }

    MaxYear() {
        return this.MaxValue().split("-")[0];
    }

    FormatDatePart(value, min, max, length) {
        let valueInt = parseInt(value);
        if (!isNaN(valueInt)) {
            return String(parseInt(Math.max(min, Math.min(max, valueInt)))).padStart(length, '0');
        } else {
            return '';
        }
    }

    FormatDay() {
        let formatted = this.FormatDatePart(this.state.day, 1, 31, 2);
        if (formatted !== this.state.day) {
            this.setState({ day: formatted }, () => {
                this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
            });
        } else {
            this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
        }
    }

    UpdateDay(value) {
        value = value.replace(/[^0-9]/g, '');
        this.setState({ day: value }, () => {
            if (this.state.day.length === 2) {
                this.FormatDay();
                this.monthRef.current.focus();
            }
        });
    }

    GetDay() {
        return this.state.day;
    }

    FormatMonth() {
        let formatted = this.FormatDatePart(this.state.month, 1, 12, 2);
        if (formatted !== this.state.month) {
            this.setState({ month: formatted }, () => {
                this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
            });
        } else {
            this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
        }
    }

    UpdateMonth(value) {
        value = value.replace(/[^0-9]/g, '');
        this.setState({ month: value }, () => {
            if (this.state.month.length === 2) {
                this.FormatMonth();
                this.yearRef.current.focus();
            }
        });
    }

    GetMonth() {
        return this.state.month;
    }

    FormatYear() {
        let formatted = this.FormatDatePart(this.state.year, this.MinYear(), this.MaxYear(), 4);
        if (formatted !== this.state.year) {
            this.setState({ year: formatted }, () => {
                this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
            });
        } else {
            this.UpdateResult(`${this.GetYear()}-${this.GetMonth()}-${this.GetDay()}`);
        }
    }

    UpdateYear(value) {
        value = value.replace(/[^0-9]/g, '');
        this.setState({ year: value }, () => {
            if (this.state.year.length === 4)
                this.FormatYear();
        });
    }

    GetYear() {
        return this.state.year;
    }

    Value() {
        let value = '';
        if (this.state.result && this.state.result.answer && this.state.result.answer !== "--") 
            value = this.state.result.answer;
        return value;
    }

    GetQuestionText() {
        if (this.props.question && this.props.question.text)
            return this.props.question.text.text;
        else
            return '';
    }

    Load() {
        this.setState({
            result: this.props.result, 
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        }, () => {
            let value = this.Value();
            if (this.IsValidFormat(value)) {
                let dateParts = value.split("-");
                this.setState({
                    day: dateParts[2], month: dateParts[1], year: dateParts[0]
                });
            }
        });
    }

    componentDidMount() {
        this.Load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result) {
            this.Load();
        }
    }

    render() {
        if (this.props.editMode) {
            return (this.RenderEdit());
        } else {
            return (this.RenderPreview());
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    QuestionContainerClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mt-2 p-2"
            else
                return "p-3 pe-4"
        } else {
            if (this.props.device.IsPhone())
                return "mt-2 p-2 phone-question-frame"
            else
                return "p-3"
        }
    }

    QuestionControlClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "mb-0 w-100"
            else
                return "mb-0 w-50"
        } else {
            if (this.props.device.IsPhone())
                return "mb-2 w-100"
            else
                return "mb-2 w-50"
        }
    }

    RenderPreview() {
        if (this.props.isNested)
            return (
                <div className={this.QuestionContainerClass()} >
                    <b><small dangerouslySetInnerHTML={{ __html: this.GetQuestionText() }}></small></b>
                    <div className="d-flex justify-content-end pb-2">
                        <Form.Group className={this.QuestionControlClass()} ref="code_input">
                            {this.RenderControl()}
                        </Form.Group>
                    </div>
                </div>
            );
        else
            return (
                <div className={this.QuestionContainerClass()}>
                    <span dangerouslySetInnerHTML={{ __html: this.GetQuestionText() }}></span>
                    <div className="d-flex justify-content-end p-2">
                        <Form.Group className={this.QuestionControlClass()} ref="code_input">
                            {this.RenderControl()}
                        </Form.Group>
                    </div>
                </div>
            );
    }

    RenderControl() {
        if (this.props.device.ShowMobileUI()) {
            return (
                <InputGroup className="d-flex justify-content-end">
                    <Form.Control
                        ref={this.dateRef}
                        placeholder="dd/mm/yyyy"
                        className="inputgroup-DATE"
                        max={this.MaxValue()}
                        min={this.MinValue()}
                        type="date"
                        value={this.Value()}
                        onChange={(event) => this.UpdateResult(event.target.value)}
                    />
                    <Button className="inputgroup-clear-button" onClick={() => { this.RemoveResult() }}><span className="bi bi-x"></span></Button>
                    {!this.IsValid(this.Value()) && !this.validate.IsEmpty(this.Value()) && (
                        <Form.Control.Feedback className="d-flex justify-content-end question-error-message" type="invalid">
                            {this.ErrorMessage()}
                        </Form.Control.Feedback>
                    )}
                </InputGroup>
            );
        } else {
            return (
                <div>
                    <InputGroup className="d-flex justify-content-end">
                        <Form.Control
                            ref={this.dayRef}
                            className="inputgroup-DD"
                            type="text"
                            placeholder="DD"
                            maxLength="2"
                            value={this.GetDay()}
                            onChange={(event) => this.UpdateDay(event.target.value)}
                            onBlur={() => this.FormatDay()}
                            inputMode="numeric"
                        />
                        <div className="inputgroup-spacer">-</div>
                        <Form.Control
                            ref={this.monthRef}
                            className="inputgroup-MM"
                            type="text"
                            placeholder="MM"
                            maxLength="2"
                            value={this.GetMonth()}
                            onChange={(event) => this.UpdateMonth(event.target.value)}
                            onBlur={() => this.FormatMonth()}
                            inputMode="numeric"
                        />
                        <div className="inputgroup-spacer">-</div>
                        <Form.Control
                            ref={this.yearRef}
                            className="inputgroup-YYYY"
                            type="text"
                            placeholder="YYYY"
                            maxLength="4"
                            value={this.GetYear()}
                            onChange={(event) => this.UpdateYear(event.target.value)}
                            onBlur={() => this.FormatYear()}
                            inputMode="numeric"
                        />
                        <Button className="inputgroup-clear-button" onClick={() => { this.RemoveResult() }}><span className="bi bi-x"></span></Button>
                        <Form.Control
                            className="hidden-date-input"
                            type="date"
                            maxLength={this.MaxLength()}
                            value={this.Value()}
                            min={this.MinValue()}
                            max={this.MaxValue()}
                            onChange={(event) => this.UpdateResult(event.target.value)}
                        />
                    </InputGroup>
                    {!this.IsValid(this.Value()) && !this.validate.IsEmpty(this.Value()) && (
                        <Form.Control.Feedback className="d-flex justify-content-end question-error-message" type="invalid">
                            {this.ErrorMessage()}
                        </Form.Control.Feedback>
                    )}
                </div>
            );
        }
    }
}

