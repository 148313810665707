import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import { DisplayQuestion } from "../DisplayQuestion";
import { Validate } from '../../shared/GlobalFns'

export class QuestionSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            results: {},
            nestedQuestions: [],
            result: { },
            hasChanges: false,
        };

        this.validate = new Validate();
    }

    UpdateResult(group, option, checked) {
        let result = {};
        if (this.props.isMultiSelect) {

            let answers = this.validate.IsEmpty(this.state.result.answer) ? [] : this.state.result.answer.split(",");
            let scores = this.validate.IsEmpty(this.state.result.score) ? [] : this.state.result.score.split(",");
            let enableQuestionCodes = this.validate.IsEmpty(this.state.result.enableQuestionCode) ? [] : this.state.result.enableQuestionCode.split(",");
            let gotoQuestionCodes = this.validate.IsEmpty(this.state.result.gotoQuestionCode) ? [] : this.state.result.gotoQuestionCode.split(",");

            this.props.question.optionGroups.filter(og => og.code !== group).forEach(og => {
                og.options.forEach(o => {
                    answers = this.RemoveValueFromList(answers, o.value);
                    scores = this.RemoveValueFromList(scores, o.score);
                    enableQuestionCodes = this.RemoveValueFromList(enableQuestionCodes, o.enableQuestionCode);
                    gotoQuestionCodes = this.RemoveValueFromList(gotoQuestionCodes, o.gotoQuestionCode);
                });
            });

            if (checked) {
                answers = this.AddValueToList(answers, option.value);
                scores = this.AddValueToList(scores, option.score);
                enableQuestionCodes = this.AddValueToList(enableQuestionCodes, option.enableQuestionCode);
                gotoQuestionCodes = this.AddValueToList(gotoQuestionCodes, option.gotoQuestionCode);
            } else {
                answers = this.RemoveValueFromList(answers, option.value);
                scores = this.RemoveValueFromList(scores, option.score);
                enableQuestionCodes = this.RemoveValueFromList(enableQuestionCodes, option.enableQuestionCode);
                gotoQuestionCodes = this.RemoveValueFromList(gotoQuestionCodes, option.gotoQuestionCode);
            }

            let answer = answers.length === 0 ? '' : answers.join(",");
            let score = scores.length === 0 ? '' : scores.join(",");
            let enableQuestionCode = enableQuestionCodes.length === 0 ? '' : enableQuestionCodes.join(",");
            let gotoQuestionCode = gotoQuestionCodes.length === 0 ? '' : gotoQuestionCodes.join(",");

            result = { questionCode: this.props.question.code, answer: answer, score: score, enableQuestionCode: enableQuestionCode, gotoQuestionCode: gotoQuestionCode, isValid: this.IsValid(answer) };

        } else {

            //console.log("Option", option);
            result = { questionCode: this.props.question.code, answer: option.value, score: option.score, enableQuestionCode: option.enableQuestionCode, gotoQuestionCode: option.gotoQuestionCode, isValid: this.IsValid(option.value) };

        }
        this.setState({ result: result, nestedQuestions: this.GetVisibleNestedQuestions(result), hasChanges: result.answer !== this.state.result.answer }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.result);
        });
    }

    IsValid(value) {
        if (this.props.question.validation && this.props.question.validation.optional === false) {
            return !this.validate.IsEmpty(value);
        } else
            return true;
    }

    UpdateNestedQuestionResult(result) {
        if (this.props.onUpdate)
            this.props.onUpdate(result);
    }

    GetVisibleNestedQuestions(result) {
        let nestedQuestions = [];
        if (!this.validate.IsEmpty(result.enableQuestionCode) && result.enableQuestionCode.length > 0) {
            result.enableQuestionCode.split(",").forEach(code => {
                if (this.props.questionnaire && this.props.questionnaire.questionGroups && this.props.questionnaire.questionGroups.length > 0) {
                    this.props.questionnaire.questionGroups.forEach(qg => {
                        //console.log(qg.questions.filter(q => q.code === code.trim()))
                        qg.questions.filter(q => q.code === code.trim()).forEach(q => {

                            if (q.style === "DynamicInserted") {
                                //console.log("NESTED QUESTION", q)
                                nestedQuestions.push(q);
                            }

                        });

                    });
                }
            });
        }
       return nestedQuestions;
    }

    AddValueToList(values, value) {
        if (value) {
            let index = values.findIndex(currentValue => value.localeCompare(currentValue.trim(), undefined, { sensitivity: 'base' }) === 0);
            if (index === -1) {
                values.push(value);
            }
        }
        return values;
    }

    RemoveValueFromList(values, value) {
        if (value) {
            let index = values.findIndex(currentValue => value.localeCompare(currentValue.trim(), undefined, { sensitivity: 'base' }) === 0);
            if (index !== -1) {
                values.splice(index, 1);
            }
        }
        return values;
    }

    IsChecked(text) {
        let answer = this.Value();
        return !this.validate.IsEmpty(answer) && answer.split(",").findIndex(answer => text.localeCompare(answer.trim(), undefined, { sensitivity: 'base' }) === 0) !== -1;
    }

    Value() {
        if (this.state.result && this.state.result.answer) {
            return this.state.result.answer
        } else
            return "";
    }

    GroupText(optionGroup) {
        if (optionGroup.text && optionGroup.text.text) {
            if (/^\d+$/.test(optionGroup.text.text)) {
                switch (parseInt(optionGroup.text.text)) {
                    case 0: return "Choose from the following:";
                    default: return "Or choose:";
                }
            } else {
                return optionGroup.text.text;
            }
        } else {
            return '';
        }
    }

    GetNestedResult(resultsList, question) {
        let results = resultsList.answers && resultsList.answers.length > 0 ? resultsList.answers.filter(a => a.questionCode === question.code) : [];
        let result = { questionCode: question.code, answer: '', gotoQuestionCode: '', enableQuestionCode: '', prevQuestionCode: '' };
        if (results.length === 1)
            result = results[0];
        return result;
    }

    VirtualGroupsByQuestionTypes(nestedQuestions) {
        let virtualGroups = [];
        let previousQuestionType = '';
        let previousQuestionOptions = [];
        let virtualGroup = {};
        nestedQuestions.forEach(q => {
            let questionOptions = this.GetAllOptionsInQuestion(q);
            if (previousQuestionType !== q.questionType || !this.ArraysAreEqual(previousQuestionOptions, questionOptions)) {
                virtualGroup = { questionType: q.questionType, questions: [] };
                virtualGroups.push(virtualGroup);
                previousQuestionType = q.questionType;
                previousQuestionOptions = questionOptions;
            }
            virtualGroup.questions.push(q);
        });
        return virtualGroups;
    }

    ArraysAreEqual(array1, array2) {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element === array2[index];
        });
    }

    GetAllOptionsInGroup(group) {
        let options = [];
        if (group && group.questions.length > 0) {
            group.questions.forEach(q => {
                if (q.optionGroups && q.optionGroups.length > 0) {
                    q.optionGroups.forEach(og => {
                        if (og.options && og.options.length > 0) {
                            og.options.forEach(o => {
                                if (!options.includes(o.text))
                                    options.push(o.text);
                            })
                        }
                    })
                }
            });
        }
        return options;
    }

    GetAllOptionsInQuestion(question) {
        let options = [];
        if (question.optionGroups && question.optionGroups.length > 0) {
            question.optionGroups.forEach(og => {
                if (og.options && og.options.length > 0) {
                    og.options.forEach(o => {
                        if (!options.includes(o.text))
                            options.push(o.text);
                    })
                }
            })
        }
        return options;
    }

    QuestionContainerClass() {
        if (this.props.isNested) {
            if (this.props.device.IsPhone())
                return "pb-3 pt-3"
            else
                return "p-3"
        } else {
            if (this.props.device.IsPhone())
                return "mt-2 p-2 phone-question-frame"
            else
                return "p-3"
        }
    }

    Load() {
        this.setState({
            result: this.props.result,
            nestedQuestions: this.GetVisibleNestedQuestions(this.props.result),
            hasChanges: !this.validate.IsEmpty(this.props.result.answer)
        });
    }

    componentDidMount() {
        this.Load();    
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.Load();
    }

    componentWillUnmount() {
    }

    render() {
        if (this.props.question) {
            if (this.props.editMode) {
                return (this.RenderEdit());
            } else {
                return (this.RenderPreview());
            }
        }
    }

    RenderEdit() {
        return (<span>Edit Mode</span>);
    }

    RenderPreview() {
        if (!this.props.isHorizontal)
            return (
                <div className={this.QuestionContainerClass()}>
                    {this.RenderQuestionText()}
                    {this.props.question.optionGroups.map((optionGroup, groupIndex) => (
                        this.RenderOptions(this.props.question, groupIndex, optionGroup)
                    ))}
                </div>
            );
        else
            return (
                this.props.question.optionGroups.map((optionGroup, groupIndex) => (
                    this.RenderOptions(this.props.question, groupIndex, optionGroup)
                ))
            );
    }

    RenderOptions(question, groupIndex, optionGroup) {
        let hasOptionGroupText = !this.validate.IsEmpty(this.GroupText(optionGroup));
        if (!this.props.isHorizontal) {
            return (
                <Form.Group key={`${question.code}_${groupIndex}`} className="text-end mt-3 me-2" ref="code_input">
                    {hasOptionGroupText && (!this.props.isMultiSelect || optionGroup.options.length > 1) && (<Form.Label><span dangerouslySetInnerHTML={{ __html: this.GroupText(optionGroup) }}></span></Form.Label>)}
                    {optionGroup.options.map((option, optionIndex) =>
                        this.RenderOption(question.code, optionGroup, option, optionGroup.options.length === 1 || !this.props.isMultiSelect))
                    }
                </Form.Group>
            );
        } else {
            return (
                this.RenderHorizontalOptionsRow(question, groupIndex, optionGroup, optionGroup.options.length === 1 || !this.props.isMultiSelect) 
            )
        }
    }

    RenderHorizontalOptionsRow(question, groupIndex, optionGroup, isRadio) {
        let rows = [];

        rows.push(

            <tr key={`${question.code}_${groupIndex}`} className="border-top">
                {this.RenderHorizontalOptions(question, groupIndex, optionGroup, optionGroup.options.length === 1 || !this.props.isMultiSelect)}
            </tr>

        );

        let virtualGroups = this.VirtualGroupsByQuestionTypes(this.state.nestedQuestions);
        virtualGroups.forEach((virtualGroup, vgIndex) => {
            let allOptionsInGroup = this.GetAllOptionsInGroup(virtualGroup);
            rows.push(

                <tr className="text-end" key={`VIRTUALGROUP_${vgIndex}`}>
                    <td colSpan={optionGroup.options.length + 1}>

                        {(virtualGroup.questionType === "SingleSelectHorizontal" || virtualGroup.questionType === "MultiSelectHorizontal") && (
                            <div className="ms-3 me-3 mb-3">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {this.RenderHorizontalOptionHeaders(virtualGroup)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {virtualGroup.questions.map((nestedQuestion, index) => (

                                            <DisplayQuestion
                                                isNested
                                                key={`VIRTUALGROUP_${vgIndex}_NESTEDQUESTION_${index}`}
                                                allOptionsInGroup={allOptionsInGroup}
                                                question={nestedQuestion}
                                                questionnaire={this.props.questionnaire}
                                                results={this.props.results}
                                                result={this.GetNestedResult(this.props.results, nestedQuestion)}
                                                onUpdate={(result) => this.UpdateNestedQuestionResult(result)}
                                                user={this.props.user}
                                                device={this.props.device}
                                                theme={this.props.theme}
                                            />

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {(virtualGroup.questionType !== "SingleSelectHorizontal" && virtualGroup.questionType !== "MultiSelectHorizontal") && (
                            virtualGroup.questions.map((nestedQuestion, index) => (

                                <DisplayQuestion
                                    isNested
                                    key={`VIRTUALGROUP_${vgIndex}_NESTEDQUESTION_${index}`}
                                    allOptionsInGroup={allOptionsInGroup}
                                    question={nestedQuestion}
                                    results={this.props.results}
                                    questionnaire={this.props.questionnaire}
                                    result={this.GetNestedResult(this.props.results, nestedQuestion)}
                                    onUpdate={(result) => this.UpdateNestedQuestionResult(result)}
                                    user={this.props.user}
                                    device={this.props.device}
                                    theme={this.props.theme}
                                />

                            ))
                        )}

                    </td>
                </tr>
            );
        })

        return rows;
    }

    RenderHorizontalOptions(question, groupIndex, optionGroup, isRadio) {
        let optionCells = [];

        optionCells.push(

            <td className="m-0 p-3" key={`${question.code}_${groupIndex}`}>
                {this.RenderQuestionText()}
            </td>

        );

        this.props.allOptionsInGroup.forEach((optionText, index) => {
            let option = optionGroup.options.filter(o => o.text === optionText)[0];
            if (option)

                optionCells.push(

                    <td key={`OPT_${index}`} className="text-center p-3">
                        { this.RenderOption(question.code, optionGroup, option, isRadio, true) }
                    </td>

                );

            else

                optionCells.push(

                    <td key={`OPT_${index}`} className="text-center p-3">
                        &nbsp;
                    </td>

                );

        })
        return optionCells;
    }

    RenderQuestionText() {
        if (this.props.isNested)
            return (<b><small dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></small></b>)
        else
            return (<span dangerouslySetInnerHTML={{ __html: this.props.question.text.text }}></span>)
    }

    RenderOption(questionCode, optionGroup, option, isRadio, isHorizontal) {
        if (isHorizontal)

            return (

                <div key={`${questionCode}_${option.code}`}>
                    <Form.Check
                        reverse={!isHorizontal}
                        type={isRadio ? "radio" : "checkbox"}
                        name={`${questionCode}`}
                        value={option.value}
                        checked={this.IsChecked(option.value)}
                        onChange={(event) => { this.UpdateResult(optionGroup.code, option, event.target.checked); }}
                    />
                </div>

            )

        else

            //if (this.state.nestedQuestions.filter(q => option.enableQuestionCode.split(",").includes(q.code)).length > 0) {
            //    console.log(`${questionCode}_${option.code}`, option.enableQuestionCode);
            //}

            return (

                <div key={`${questionCode}_${option.code}`}>
                    <Form.Check reverse={!isHorizontal}>
                        <Form.Check.Input
                            type={isRadio ? "radio" : "checkbox"}
                            name={`${questionCode}`}
                            value={option.value}
                            checked={this.IsChecked(option.value)}
                            onChange={(event) => { this.UpdateResult(optionGroup.code, option, event.target.checked); }}
                        />
                        <Form.Check.Label><span dangerouslySetInnerHTML={{ __html: option.text }}></span></Form.Check.Label>
                    </Form.Check>
                    {this.state.nestedQuestions.filter(q => option.enableQuestionCode.split(",").includes(q.code)).map((nestedQuestion, nqIndex) => (
                        <div key={`${questionCode}_${option.code}_NESTEDQUESTION_${nqIndex}`}>

                            <DisplayQuestion
                                isNested
                                allOptionsInGroup={this.props.allOptionsInGroup}
                                question={nestedQuestion}
                                results={this.props.results}
                                questionnaire={this.props.questionnaire}
                                result={this.GetNestedResult(this.props.results, nestedQuestion)}
                                onUpdate={(result) => this.UpdateNestedQuestionResult(result)}
                                user={this.props.user}
                                device={this.props.device}
                                theme={this.props.theme}
                            />

                        </div>
                    ))}
                </div>

            );
    }

    RenderHorizontalOptionHeaders(virtualGroup) {
        let optionCells = [];

        // Blank header over question
        optionCells.push(

            <th key={`OPT_QUEST`} className="text-center">
            </th>

        );

        this.GetAllOptionsInGroup(virtualGroup).forEach((optionText, index) => {

            optionCells.push(

                <th key={`OPT_${index}`} className="text-center">
                    <b><span className="miniLabel" dangerouslySetInnerHTML={{ __html: optionText }}></span></b>
                </th>

            );

        })
        return optionCells;
    }
}

