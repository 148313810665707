import React, { Component } from "react";
import update from 'immutability-helper';
import { QuestionSelect } from './QuestionType/QuestionSelect';
import { QuestionMultiText } from './QuestionType/QuestionMultiText';
import { QuestionInput } from './QuestionType/QuestionInput';
import { QuestionDate } from './QuestionType/QuestionDate';
import { QuestionLengthOfTime } from './QuestionType/QuestionLengthOfTime';
import { QuestionHeight } from './QuestionType/QuestionHeight';
import { QuestionWeight } from './QuestionType/QuestionWeight';
import { QuestionInstruction } from './QuestionType/QuestionInstruction';
import { QuestionGPSurgery } from './QuestionType/QuestionGPSurgery';
import { QuestionRange } from './QuestionType/QuestionRange';
import { QuestionSubmit } from './QuestionType/QuestionSubmit';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';

export class DisplayQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            result: {
                questionCode : '',
                answer: '',
                gotoQuestionCode: '',
                enableQuestionCode: '',
                prevQuestionCode: '',
                score: '',
                timeStamp: '',
                isValid: true,
            }
        };
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    // Result passed in = { questionCode: ..., answer: ..., score: '', isValid: '' }
    // Add extra values and make sure there are no undefined/null values
    UpdateResult(result) {
        if (result.questionCode === this.state.result.questionCode) {

            let gotoQuestionCode = !this.IsEmpty(result.gotoQuestionCode) ? result.gotoQuestionCode : this.props.defaultGotoQuestionCode ?? "";
            let enableQuestionCode = !this.IsEmpty(result.enableQuestionCode) ? result.enableQuestionCode : this.props.defaultEnableQuestionCode ?? "";
            let updatedResult = update(this.state.result, {

                answer: { $set: result.answer ?? "" },
                gotoQuestionCode: { $set: gotoQuestionCode },
                enableQuestionCode: { $set: enableQuestionCode },
                score: { $set: `${result.score ?? ""}` },
                timestamp: { $set: (new Date()).toJSON() },
                isValid: { $set: result.isValid ?? true }

            });
            this.setState({ result: updatedResult }, () => {

                //console.debug("UPDATED RESULT", updatedResult)
                if (this.props.onUpdate)
                    this.props.onUpdate(updatedResult);

            });

        } else {

            // Nested question result - called by a DisplayQuestion in QuestionSelect....
            // will then pass back to this function again calling the code above when we get back to the correct question code.
            if (this.props.onUpdate)
                this.props.onUpdate(result);

        }
    }

    componentDidMount() {
        if (this.props.result)
            this.setState({ result: this.props.result });
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result)
            this.setState({ result: this.props.result });
    }

    componentWillUnmount() {
    }

    render() {
        if (this.props.editMode)
            return (
                <Tabs
                    defaultActiveKey="details"
                    id="justify-tab-example"
                    className="mb-3 tabs-app"
                    justify>
                    <Tab eventKey="details" title="Details">
                        {this.RenderQuestion(true)}
                    </Tab>
                    <Tab eventKey="preview" title="Quick Preview">
                        {this.RenderDebug()}
                        {this.RenderQuestion(false)}
                    </Tab>
                </Tabs>
            );
        else
            return (
                this.RenderQuestion(false)
            );
    }

    RenderDebug() {
        let result = this.state.result;
        return (
            <Alert variant="info">
                <div><small><b>Code:</b> <span>{this.props.question.code}</span></small></div>
                <div><small><b>Answer(s):</b> <span>{result.answer.split(",").join(", ")}</span></small></div>
                <div><small><b>Goto Question Code(s):</b> <span>{this.IsEmpty(result.gotoQuestionCode) ? '' : result.gotoQuestionCode.split(",").join(", ")}</span></small></div>
                <div><small><b>Enable Question Code(s):</b> <span>{this.IsEmpty(result.enableQuestionCode) ? '' : result.enableQuestionCode.split(",").join(", ")}</span></small></div>
            </Alert>
        );
    }

    RenderQuestion(editMode) {
        let question = this.props.question;
        let result = this.state.result;
        if (question) {
            let displayQuestion = "<tr></tr>";
            switch (question.questionType) {
                case 'SingleSelect':

                    displayQuestion = (
                        <QuestionSelect
                            editMode={editMode}
                            allOptionsInGroup={this.props.allOptionsInGroup}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'SingleSelectHorizontal':

                    displayQuestion = (
                        <QuestionSelect
                            isHorizontal={!this.props.device.IsPhone()}
                            editMode={editMode}
                            allOptionsInGroup={this.props.allOptionsInGroup}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'MultiSelect':

                    displayQuestion = (
                        <QuestionSelect
                            isMultiSelect
                            editMode={editMode}
                            allOptionsInGroup={this.props.allOptionsInGroup}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'MultiSelectHorizontal':

                    displayQuestion = (
                        <QuestionSelect
                            isMultiSelect
                            isHorizontal={!this.props.device.IsPhone()}
                            editMode={editMode}
                            allOptionsInGroup={this.props.allOptionsInGroup}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Text':

                    displayQuestion = (
                        <QuestionInput
                            inputType="text"
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'MultiTxt':

                    displayQuestion = (
                        <QuestionMultiText
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Integer':

                    displayQuestion = (
                        <QuestionInput
                            inputType="number"
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            results={this.props.results}
                            prevResults={this.props.prevResults}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Date':

                    displayQuestion = (
                        <QuestionDate
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Time':

                    displayQuestion = (
                        <QuestionInput
                            inputType="time"
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'LengthOfTime':

                    displayQuestion = (
                        <QuestionLengthOfTime
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Height':

                    displayQuestion = (
                        <QuestionHeight
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Weight':

                    displayQuestion = (
                        <QuestionWeight
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Instruction':

                    displayQuestion = (
                        <QuestionInstruction
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'GPSurgery':

                    displayQuestion = (
                        <QuestionGPSurgery
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Range':
                case 'SlidingScale':

                    displayQuestion = (
                        <QuestionRange
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                case 'Submit':

                    displayQuestion = (
                        <QuestionSubmit
                            editMode={editMode}
                            isNested={this.props.isNested}
                            questionnaire={this.props.questionnaire}
                            prevResults={this.props.prevResults}
                            results={this.props.results}
                            question={question}
                            result={result}
                            format={this.props.format}
                            defaultValue={this.props.defaultValue}
                            onUpdate={(result) => this.UpdateResult(result)}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    );
                    break;

                default:
                    displayQuestion = (
                        <Alert variant="danger">
                            <b>Question Type Not Recognised: {question.questionType}</b><br /><span>{question.code}<hr/>{question.text.text}</span>
                        </Alert>
                    );
                    break;

            }
            //console.log("displayQuestion", question)
            return displayQuestion;
        }
    }

}

