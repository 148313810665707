import React, { Component } from 'react';
import { ModalConfirm } from './shared/ModalConfirm';
import { ButtonUpload } from './shared/Buttons/Upload';
import { ButtonEdit } from './shared/Buttons/Edit';
import { ButtonInfo } from './shared/Buttons/Info';
import { ButtonDelete } from './shared/Buttons/Delete';
import { UploadForm } from './GPSurgery/UploadForm';
import { TablePagination } from './shared/TablePagination';
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GPSurgeryApi } from './shared/GPSurgeryApi'

import './GPSurgeries.css'

export class GPSurgeries extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fields: [
                { key: 'odsCode', label: 'ODS Code' },
                { key: 'siteName', label: 'Site Name' },
                { key: 'address', label: 'Address' },
                { key: 'postcode', label: 'Postcode' },
                { key: 'telephone', label: 'Telephone' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' },
            ],
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "siteName",
                sortType: "ASC",
                filter: "",
                totalCount: 0,
            },
            selectedItem: {},
            selectedIndex: -1,
            showModal: {
                confirm: false,
                edit: false,
                upload: false,
            },
        };

        this.uploadForm = React.createRef();
        this.confirmModal = React.createRef();

        this.GPSurgeryApi = new GPSurgeryApi();
    }

    async GetData() {

        await this.GPSurgeryApi.Query(this.state.dataQuery, (results) => {

            let updatedDataQuery = update(results.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: results.totalCount } });
            this.setState({ data: results.data, dataQuery: updatedDataQuery });

        }, (errors) => {


        });

    }

    async SaveItem(index, item) {

        await this.GPSurgeryApi.SaveItem(item, (data) => {

            this.UpdateItemInList(index, data);

        }, (errors) => {

        });

    }

    async DeleteItem(index, id) {

        await this.GPSurgeryApi.DeleteItem(id, (data) => {

            if (index >= 0) {
                let updatedData = update(this.state.data, { $splice: [[index, 1]] });
                this.setState({ showModal: { confirm: false }, data: updatedData });
                console.log(`DELETE [${this.state.selectedIndex}]`, id);
            }

        }, (errors) => {

        });

    }

    TabulateData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            this.state.fields.forEach((field) => {
                switch (field.key) {
                    case 'odsCode':
                    case 'postcode':
                    case 'telephone':
                    case 'status':
                        row[field.key] = `<span class="text-nowrap">${data[field.key] ?? ""}</span>`;
                        break;
                    case 'siteName':
                        row[field.key] = data[field.key] ?? "";
                        break;
                    case 'address':
                        let address = data['address1'] ? `${data['address1']}` : "";
                        address += data['address2'] ? `, ${data['address2']}` : "";
                        address += data['address3'] ? `, ${data['address3']}` : "";
                        address += data['address4'] ? `, ${data['address4']}` : "";
                        address += data['address5'] ? `, ${data['address5']}` : "";
                        row[field.key] = address;
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    UploadOpen() {
        this.setState({ showModal: { upload: true } });
    }
    UploadClose() {
        this.setState({ showModal: { upload: false } });
    }
    UploadFile() {
        if (this.uploadForm.current) this.uploadForm.current.UploadFile();
    }
    UploadDone() {
        this.setState({ showModal: { upload: false } });
        this.GetData();
    }

    EditOpen(index, item) {
        if (item)
            this.setState({ showModal: { edit: true }, selectedIndex: index ?? -1, selectedItem: item });
    }
    EditClose() {
        this.setState({ showModal: { edit: false } });
    }

    DeleteOpen(index, item) {
        this.setState({ showModal: { confirm: true }, selectedIndex: index ?? -1, selectedItem: item });
    }

    DeleteClose() {
        this.setState({ showModal: { confirm: false } });
    }

    UpdateItemInList(index, data) {
        let updatedData = {};
        if (index >= 0)
            updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
        else
            updatedData = update(this.state.data, { $push: [data] });
        this.setState({ data: updatedData }, () => { this.EditClose() });
    }

    componentDidMount() {
        this.GetData();
    }

    render() {
        let rows = this.TabulateData();
        return (
            <div className="flex-fill">

                <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                    <thead>
                        <tr>
                            {this.state.fields.map((item, index) => (
                                item.key === 'actions' ? this.props.user.role === "Guest" ?
                                    <th key={index} className="text-end text-nowrap"></th> :
                                    <th key={index} className="text-end text-nowrap">
                                        {this.props.user && (this.props.user.IsAdmin() || this.props.user.IsDeveloper()) && (
                                            <ButtonUpload onClick={() => this.UploadOpen()} text="Upload Surgeries" />
                                        )}
                                    </th> :
                                    index === 0 ?
                                        <th key={index} colSpan={this.state.fields.length - 1}>
                                            <TablePagination
                                                canSearch
                                                canChangePageResults
                                                dataQuery={this.state.dataQuery}
                                                onUpdated={(query) => this.setState({ dataQuery: query }, () => { this.GetData(); })}
                                                user={this.props.user}
                                                device={this.props.device}
                                                theme={this.props.theme}
                                            />
                                        </th> : ''
                                            
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length === 0 && (
                            <tr>
                                <td className="text-center" colSpan={this.state.fields.length}>No records found</td>
                            </tr>
                        )}
                        {rows.length > 0 && (
                            this.DataRows(rows)
                        )}
                    </tbody>
                </Table>

                <Modal show={this.state.showModal.upload} onHide={() => this.UploadClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>GP Surgery Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><UploadForm
                        ref={this.uploadForm}
                        onUploaded={() => this.UploadDone()}
                        user={this.props.user}
                    /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.UploadClose()}>
                            Close
                        </Button>
                        {this.props.user.role !== "Guest" && (
                            <Button variant="primary" onClick={() => this.UploadFile()}>
                                Upload
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal.edit} onHide={() => this.EditClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Surgery Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <tbody>
                                <tr>
                                    <td>ODSCode</td>
                                    <td>{this.state.selectedItem.odsCode}</td>
                                </tr>
                                <tr>
                                    <td>Site Name</td>
                                    <td>{this.state.selectedItem.siteName}</td>
                                </tr>
                                <tr>
                                    <td>Address 1</td>
                                    <td>{this.state.selectedItem.address1}</td>
                                </tr>
                                <tr>
                                    <td>Address 2</td>
                                    <td>{this.state.selectedItem.address2}</td>
                                </tr>
                                <tr>
                                    <td>Address 3</td>
                                    <td>{this.state.selectedItem.address3}</td>
                                </tr>
                                <tr>
                                    <td>Address 4</td>
                                    <td>{this.state.selectedItem.address4}</td>
                                </tr>
                                <tr>
                                    <td>Address 5</td>
                                    <td>{this.state.selectedItem.address5}</td>
                                </tr>
                                <tr>
                                    <td>Postcode</td>
                                    <td>{this.state.selectedItem.postcode}</td>
                                </tr>
                                <tr>
                                    <td>Telephone</td>
                                    <td>{this.state.selectedItem.telephone}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{this.state.selectedItem.status}</td>
                                </tr>
                                <tr>
                                    <td>Open Date</td>
                                    <td>{this.state.selectedItem.openDate}</td>
                                </tr>
                                <tr>
                                    <td>Close Date</td>
                                    <td>{this.state.selectedItem.closeDate}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.EditClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalConfirm ref={this.confirmModal} show={this.state.showModal.confirm} onCancel={() => this.DeleteClose()} onConfirm={() => this.DeleteItem()} title="Delete Surgery" message="Are you sure you want to delete this surgery?" />

            </div>
        );
    }

    DataRows(rows) {
        return (
            rows.map((_, index) => (
                <tr key={index}>
                    {this.state.fields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            this.RowActions(index, subindex, this.state.data[index].participantCount, this.props.user.role) :
                            <td key={subindex}>
                                <div><span className="miniLabel text-nowrap">{item.label}:</span></div>
                                <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                            </td>
                    ))}
                </tr>
            ))
        );
    }

    RowActions(index, subindex, participantCount, role) {
        if (role === 'Developer' || role === 'Admin' || role === 'User') {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DeleteOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                </td>
            )
        } else {
            return (
                <td key={subindex} className="text-end text-nowrap pt-4">
                    <ButtonInfo onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                </td>
            )
        }
    }
}
