import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
export class ButtonPlay extends Component {

    ButtonClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    render() {
        return (
            <button type="button" className="text-nowrap btn btn-sm btn-dark rounded-pill" disabled={ this.props.disabled ?? false } onClick={() => { this.ButtonClick() }}>
                {this.props.text &&
                    <small className="hide-if-smaller-than-xxl">{this.props.text}&nbsp;</small>
                }
                <span className="bi bi-play-fill"></span>
            </button>
        );
    }

}
