import React, { Component } from 'react';
import './ContactDetails.css'

export class ContactDetails extends Component {
    static displayName = ContactDetails.name;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill">

                <h1>Contact Details</h1>
                
                <h3>Research Team Contact (Study Helpline)</h3>
                <p>Email: <a href="mailto:beacon@exeter.ac.uk">beacon@exeter.ac.uk</a></p>
                <p>Tel: 01392725478</p>

                <h3>Further Information</h3>
                <p>If you would like to take part, please register for the study through the app or website using the register option. If you would like more information about the study before you decide whether or not to take part, you can contact a member of the study team by ringing the study help and information line 01392725478.</p>
                <p>Please note that this helpline is for general information and support for the study. It will connect you to a member of the study team who will be able to talk about the study but will not be able to provide medical advice. Please also note that we are not able to give out information about your personal performance or progress in the study. </p>

            </div>
        );
    }
}
