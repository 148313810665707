import React, { Component } from 'react';
import { StudyApi } from './shared/BEACONApi';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { PlayQuestionnaireModal } from './Questionnaire/PlayQuestionnaireModal';
import { PlayBatteryModal } from './Battery/PlayBatteryModal';
import Accordion from 'react-bootstrap/Accordion';
import { Format } from './shared/GlobalFns';
import { CountDown } from './shared/CountDown';
import { ActivityList } from './Activities/ActivityList'

import './StudyAdmin.css'

export class StudyAdmin extends Component {
    static displayName = StudyAdmin.name;
    static isAdmin = true;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            schedule: null,
            fileUploadError: null,
            isBusy: true,
            selectedActivity: null,
        }

        this.fileUploadInput = React.createRef();

        this.StudyApi = new StudyApi();
        this.Format = new Format();
    }

    UploadFile() {
        let file = this.fileUploadInput.current && this.fileUploadInput.current.files && this.fileUploadInput.current.files[0] ? this.fileUploadInput.current.files[0] : null;

        if (file) {
            //console.debug("UPLOAD", file);
            this.setState({ isBusy: true, fileUploadError: null }, async () => {
                //console.debug("UPLOAD", file);
                await this.StudyApi.UploadFile(file, (data) => {

                    this.setState({ isBusy: false, fileUploadError: null, study: data });

                }, (errors) => {
                    this.setState({ isBusy: false, fileUploadError: errors });
                });
            });
        } else {
            //console.debug("UPLOAD");
            let errorObject = ({ title: 'System', status: 'UNKNOWN', errors: { error: ["Please choose a questionnaire template to upload"] } });
            this.setState({ isBusy: false, fileUploadError: errorObject });
        }
    }

    StageIsActiveOrOpen(stage) {
        return stage.status === "ACTIVE" ||
            stage.status === "OPEN";
    }

    componentDidMount() {
        this.setState({ schedule: this.props.schedule, isBusy: false }, () => {
            //console.log("OPEN SCHEDULE", this.state.schedule);
        });
    }

    render() {
        if (this.state.isBusy) {

            return (
                <div></div>
            )

        } else {
            if (this.props.user.IsAdmin() || this.props.user.IsDeveloper()) {

                return (
                    <div className="flex-fill">
                        {this.RenderUpload()}
                    </div>
                );

            } else {

                return (
                    <div className="flex-fill">
                        <h1>Study Admin</h1>
                        <p>Only available to admin users.</p>
                    </div>
                );

            }
        }
    }

    RenderUpload() {
        let errors = this.state.fileUploadError && this.state.fileUploadError.errors ? this.state.fileUploadError.errors.error : null;
        return (
            <div>
                <Form.Group controlId="formFile">
                    <Form.Label><h3>Upload Study</h3></Form.Label>
                    <div className="mb-2">Upload a spreadsheet containing a Study template:</div>
                </Form.Group>
                <div className="mb-4 mx-2"><small><i>
                    NOTE: The spreadsheet should contain a full definition of the study, where the contents of each worksheet follow the standard PROTECT study format.
                </i></small></div>
                <Form.Group className="mb-1" id="file_input">
                    <Form.Control ref={this.fileUploadInput} type="file" />
                </Form.Group>
                {errors && (
                    <Alert variant="danger my-1">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
                <Button variant="primary" onClick={() => { this.UploadFile() }}>Upload Study Template</Button>
            </div>
        )
    }

}
