import React, { Component } from "react";
import { ModalConfirm } from '../shared/ModalConfirm';
import { ButtonAdd } from '../shared/Buttons/Add';
import { ButtonEdit } from '../shared/Buttons/Edit';
import { ButtonDelete } from '../shared/Buttons/Delete';
import { EditStudyGroupForm } from '../Randomisation/EditStudyGroupForm';
import { EditStratificationGroupForm } from '../Randomisation/EditStratificationGroupForm';
import update from 'immutability-helper';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import { RandomisationApi } from '../shared/RandomisationApi'

export class EditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            isCodeValid: true,
            isPasswordValid: true,
            spreadSheetPassword: '',
            passwordMessage: 'Please enter a password',
            codeMessage: 'Please enter a valid code.',
            editItem: {
                code: '',
                studyGroups: [],
                stratificationGroups: [],
                algorithm: {},
                summary: { numberRandomised: 0, numberRandomlyAllocated: 0, summary: [] },
                participantCount: 0,
            },
            editIndex: -1,
            existingCodes: [],
            studyGroupFields: [
                { key: 'name', label: 'Name' },
                { key: 'ratio', label: 'Ratio' },
                { key: 'maxParticipants', label: 'Max Participants' },
                { key: 'actions', label: '' },
            ],
            stratificationGroupFields: [
                { key: 'name', label: 'Name' },
                { key: 'bands', label: 'Values' },
                { key: 'actions', label: '' },
            ],
            summaryFields: [
                { key: 'stratificationGroup', label: 'Stratification Group' },
                { key: 'condition', label: 'Condition' },
                { key: 'studyGroupCount', label: 'Study Groups' },
            ],
            showModal: {
                confirmStudyGroup: false,
                confirmStratificationGroup: false,
                editStudyGroup: false,
                editStratificationGroup: false,
                resetRule: false,
            },
            selectedStudyGroup: {},
            selectedStudyGroupIndex: -1,
            selectedStratificationGroup: {},
            selectedStratificationGroupIndex: -1,
            saveError: null,
            randomisationType: 'Blind', // 'Blind', 'Unblinded', 'PartiallyBlinded',
        };

        this.editStudyGroupForm = React.createRef();
        this.editStratificationGroupForm = React.createRef();
        this.algorithmPercentage = React.createRef();
        this.algorithmMin = React.createRef();
        this.algorithmMax = React.createRef();

        this.RandomisationApi = new RandomisationApi();

    } 

    DownloadRandomisedParticipants() {
        if (this.PasswordIsValid()) {
            this.setState({ isBusy: true }, async () => {

                await this.RandomisationApi.DownloadRandomisedParticipants(this.state.editItem.id, this.state.randomisationType, this.state.spreadSheetPassword, (fileBlob) => {

                    if (fileBlob)
                        this.DownloadFile(fileBlob, "RandomisedParticipants.xlsx")

                    this.setState({ isBusy: false, fileUploadError: null });

                }, (errors) => {

                    this.setState({ isBusy: false, fileUploadError: errors });

                });

            });
        }
    }

    ResetRule() {
        this.setState({ showModal: { resetRule: false }, isBusy: true }, async () => {
            await this.RandomisationApi.ResetRule(this.state.editItem.id, (data) => {

                this.setState({ editItem: data, isBusy: false, resetError: null }, () => {
                    if (this.props.onUpdate)
                        this.props.onUpdate(this.state.editIndex, this.state.editItem);
                });

            }, (errors) => {

                this.setState({ isBusy: false, resetError: errors });

            });
        });
    }

    DownloadFile(fileResponseData, fileName) {
        const url = window.URL.createObjectURL(new Blob([fileResponseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    ResetRuleClose() {
        this.setState({ showModal: { resetRule: false } });
    }
    ResetRuleOpen() {
        this.setState({ showModal: { resetRule: true } });
    }

    UpdateCode(event) {
        let updatedItem = update(this.state.editItem, { code: { $set: this.CodeOnly(event.target.value) } });
        this.setState({ editItem: updatedItem });
    }

    UpdatePassword(event) {
        this.setState({ spreadSheetPassword: event.target.value });
    }

    PasswordIsValid() {
        let valid = true;
        var value = this.state.spreadSheetPassword.trim().toUpperCase();
        if (this.IsEmpty(value)) {
            this.setState({ codeMessage: "The password cannot be blank" });
            valid = false;
        } 
        this.setState({ isPasswordValid: valid });
        return valid;
    }

    CodeOnly(value) {
        return value.replace(/[^a-z0-9 _]/gi, '').replace(' ', '_').toUpperCase();
    }

    CodeIsValid() {
        let valid = true;
        var value = this.state.editItem.code.trim().toUpperCase();
        if (this.IsEmpty(value)) {
            this.setState({ codeMessage: "The code cannot be blank" });
            valid = false;
        } else if (this.state.existingCodes.includes(value)) {
            this.setState({ codeMessage: "This code already exists" });
            valid = false;
        }
        this.setState({ isCodeValid: valid });
        return valid;
    }

    TabulateStudyGroups() {
        let rows = [];
        Array.prototype.forEach.call(this.state.editItem.studyGroups, (data) => {
            let row = {};
            this.state.studyGroupFields.forEach((field) => {
                switch (field.key) {
                    case 'name':
                    case 'ratio':
                    case 'maxParticipants':
                        row[field.key] = data[field.key];
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulateStratificationGroups() {
        let rows = [];
        Array.prototype.forEach.call(this.state.editItem.stratificationGroups, (data) => {
            let row = {};
            this.state.stratificationGroupFields.forEach((field) => {
                switch (field.key) {
                    case 'name':
                        row[field.key] = data[field.key];
                        break;
                    case 'bands':
                        row[field.key] = data[field.key].map(band => band.startsWith('RANGE(') ? band.replace('RANGE(', '').replace(',',' to ').replace(')', '') : band).join(', ');
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    TabulateSummary() {
        let rows = [];
        Array.prototype.forEach.call(this.state.editItem.summary.summary, (data) => {
            let row = {};
            this.state.summaryFields.forEach((field) => {
                switch (field.key) {
                    case 'stratificationGroup':
                        row[field.key] = data[field.key];
                        break;
                    case 'condition':
                        row[field.key] = data[field.key].value ? data[field.key].value : `${data[field.key].min} to ${data[field.key].max}`;
                        break;
                    default:
                        this.state.editItem.studyGroups.forEach((studyGroup) => {
                            row[studyGroup.name] = data['studyGroupCount'][studyGroup.name];
                        });
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    GetDisplayFields() {
        let updatedsummaryFields = [];
        this.setState({ summaryFields: updatedsummaryFields }, () => {
            updatedsummaryFields.push({ key: 'stratificationGroup', label: 'Stratification Group' })
            updatedsummaryFields.push({ key: 'condition', label: 'Condition' })
            this.state.editItem.studyGroups.forEach((studyGroup) => {
                updatedsummaryFields.push({ key: studyGroup.name, label: studyGroup.name })
            });
            this.setState({ summaryFields: updatedsummaryFields });
        });
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    SaveClick() {
        if (this.props.onSave) {
            this.setState({ isBusy: true, saveError: null });
            this.setState({
                isFormValidated: true,
                isFormValid: this.CodeIsValid(),
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    SaveFailed(error) {
        this.setState({ isBusy: false, saveError: error });
    }

    EditStudyGroupOpen(index) {
        let item = this.state.editItem.studyGroups[index];
        this.setState({ showModal: { editStudyGroup: true }, selectedStudyGroup: item, selectedStudyGroupIndex: index });
    }

    EditStudyGroupClose() {
        this.setState({ showModal: { editStudyGroup: false } });
    }

    EditStudyGroupSave() {
        this.editStudyGroupForm.current.SaveClick();
    }

    SaveStudyGroup(index, data) {
        let updatedItem = {};
        if (index >= 0)
            updatedItem = update(this.state.editItem, { studyGroups: { $splice: [[index, 1, data]] } });
        else
            updatedItem = update(this.state.editItem, { studyGroups: { $push: [data] } });
        this.setState({ showModal: { editStudyGroup: false }, editItem: updatedItem });
        console.log(`SAVE [${index}]`, data);
    }

    DeleteStudyGroupOpen(index) {
        let item = this.state.editItem.studyGroups[index];
        this.setState({ showModal: { confirmStudyGroup: true }, selectedStudyGroup: item, selectedStudyGroupIndex: index });
    }

    DeleteStudyGroupClose() {
        this.setState({ showModal: { confirmStudyGroup: false } });
    }

    DeleteStudyGroup() {
        let index = this.state.selectedStudyGroupIndex;
        if (index >= 0) {
            let updatedItem = update(this.state.editItem, { studyGroups: { $splice: [[index, 1]] } });
            this.setState({ showModal: { confirmStudyGroup: false }, editItem: updatedItem });
        }
        console.log(`DELETE [${index}]`, this.state.selectedStudyGroup);
    }

    EditStratificationGroupOpen(index) {
        let item = this.state.editItem.stratificationGroups[index];
        this.setState({ showModal: { editStratificationGroup: true }, selectedStratificationGroup: item, selectedStratificationGroupIndex: index });
    }

    EditStratificationGroupClose() {
        this.setState({ showModal: { editStratificationGroup: false } });
    }

    EditStratificationGroupSave() {
        this.editStratificationGroupForm.current.SaveClick();
    }

    SaveStratificationGroup(index, data) {
        let updatedItem = {};
        if (index >= 0)
            updatedItem = update(this.state.editItem, { stratificationGroups: { $splice: [[index, 1, data]] } });
        else
            updatedItem = update(this.state.editItem, { stratificationGroups: { $push: [data] } });
        this.setState({ showModal: { editStudyGroup: false }, editItem: updatedItem });
    }

    DeleteStratificationGroupOpen(index) {
        let item = this.state.editItem.stratificationGroups[index];
        this.setState({ showModal: { confirmStratificationGroup: true }, selectedStratificationGroup: item, selectedStratificationGroupIndex: index });
    }

    DeleteStratificationGroupClose() {
        this.setState({ showModal: { confirmStratificationGroup: false } });
    }

    DeleteStratificationGroup() {
        let index = this.state.selectedStratificationGroupIndex;
        if (index >= 0) {
            let updatedItem = update(this.state.editItem, { stratificationGroups: { $splice: [[index, 1]] } });
            this.setState({ showModal: { confirmStudyGroup: false }, editItem: updatedItem });
        }
        console.log(`DELETE [${index}]`, this.state.selectedStudyGroup);
    }

    UpdateAlgorithmType(event) {
        let updatedItem = update(this.state.editItem, { algorithm: { type: { $set: event.target.value } } });
        this.setState({ editItem: updatedItem });
    }

    UpdateAlgorithmPercentage(event) {
        let value = Math.min(Math.max(parseInt(event.target.value), 1), 100);
        if (Number.isNaN(value)) value = '';
        let updatedItem = update(this.state.editItem, { algorithm: { minimisationProbability: { $set: value / 100 } } });
        this.setState({ editItem: updatedItem });
    }

    UpdateAlgorithmMin(event) {
        let value = Math.min(Math.max(parseInt(event.target.value), 1), 20);
        if (Number.isNaN(value)) value = '';
        let updatedItem = update(this.state.editItem, { algorithm: { minBlockRepeat: { $set: value } } });
        this.setState({ editItem: updatedItem });
    }

    UpdateAlgorithmMax(event) {
        let value = Math.min(Math.max(parseInt(event.target.value), 1), 20);
        if (Number.isNaN(value)) value = '';
        let updatedItem = update(this.state.editItem, { algorithm: { maxBlockRepeat: { $set: value } } });
        this.setState({ editItem: updatedItem });
    }

    GetMinimisationProbablility() {
        if (this.state.editItem.algorithm.minimisationProbability)
            return this.state.editItem.algorithm.minimisationProbability * 100;
        else
            return 100;
    }

    GetMinBlockRepeat() {
        if (this.state.editItem.algorithm.minBlockRepeat)
            return this.state.editItem.algorithm.minBlockRepeat;
        else
            return 4;
    }

    GetMaxBlockRepeat() {
        if (this.state.editItem.algorithm.maxBlockRepeat)
            return this.state.editItem.algorithm.maxBlockRepeat;
        else
            return 6;
    }

    CanEdit() {
        return !this.props.user.IsGuest() && this.state.editItem.participantCount === 0;
    }

    CanDownloadParticipants() {
        return (this.props.user.IsAdmin() || this.props.user.IsDeveloper()) && this.state.editItem.participantCount > 0;
    }

    RandomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    componentDidMount() {
        let _this = this;
        this.setState({ editIndex: this.props.index });
        if (this.props.item)
            this.setState({ editItem: this.props.item }, () => {
                _this.GetDisplayFields();
                _this.setState({ spreadSheetPassword: _this.state.editItem.code.toLowerCase() + _this.RandomInt(125, 999) });
            });
        if (this.props.existing)
            this.setState({ existingCodes: this.props.existing.filter(item => this.props.item === undefined || item.code !== this.props.item.code).map(item => `${item.code.trim().toUpperCase()}`) });
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                {this.state.isBusy ?
                    <div className="busy-overlay col d-flex align-items-center justify-content-center">
                        <Spinner animation="border" role="status" variant="dark" />
                    </div>
                    : ''}
                {this.CanEdit() && (
                    this.RenderForm() 
                )}
                {!this.CanEdit() && (
                    <Tabs
                        defaultActiveKey="details"
                        id="justify-tab-example"
                        className="mb-3 tabs-app"
                        justify>
                        <Tab eventKey="details" title="Details">
                            {this.RenderForm()}
                        </Tab>
                        {!this.CanEdit() && (
                            <Tab eventKey="summary" title="Summary">
                                {this.RenderSummary()}
                            </Tab>
                        )}
                        {!this.CanEdit() && (
                            <Tab eventKey="admin" title="Admin Options">
                                {this.AdminOptions()}
                            </Tab>
                        )}

                    </Tabs>

                )}

                <Modal show={this.state.showModal.editStudyGroup} onHide={() => this.EditStudyGroupClose()} size="md" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Study Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><EditStudyGroupForm ref={this.editStudyGroupForm} index={this.state.selectedStudyGroupIndex} item={this.state.selectedStudyGroup} existing={this.state.editItem.studyGroups} onSave={(index, data) => this.SaveStudyGroup(index, data)} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.EditStudyGroupClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.EditStudyGroupSave()}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalConfirm show={this.state.showModal.confirmStudyGroup} onCancel={() => this.DeleteStudyGroupClose()} onConfirm={() => this.DeleteStudyGroup()} title="Delete Study Group" message="Are you sure you want to delete this study group?" />
                
                <Modal show={this.state.showModal.editStratificationGroup} onHide={() => this.EditStratificationGroupClose()} size="md" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Stratification Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><EditStratificationGroupForm ref={this.editStratificationGroupForm} index={this.state.selectedStratificationGroupIndex} item={this.state.selectedStratificationGroup} existing={this.state.editItem.stratificationGroups} onSave={(index, data) => this.SaveStratificationGroup(index, data)} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.EditStratificationGroupClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.EditStratificationGroupSave()}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalConfirm show={this.state.showModal.confirmStratificationGroup} onCancel={() => this.DeleteStratificationGroupClose()} onConfirm={() => this.DeleteStratificationGroup()} title="Delete Stratification Group" message="Are you sure you want to delete this stratification group?" />

                <ModalConfirm show={this.state.showModal.resetRule} onCancel={() => this.ResetRuleClose()} onConfirm={() => this.ResetRule()} title="Reset Rule" message="Resetting this rule will PERMANENTLY delete all randomised participants and recalculate the randomisation codes. Are you sure that you want to continue?" />

            </div>
        );
    }

    RenderForm() {
        let studyGroups = this.TabulateStudyGroups();
        let stratificationGroups = this.TabulateStratificationGroups();
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        return (
            <Form noValidate validated={this.state.isFormValidated}>
                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label><b>Code</b></Form.Label>
                    <Form.Control
                        required
                        disabled={this.CanEdit() ? '' : 'disabled'}
                        type="text"
                        placeholder="Please enter a unique code"
                        value={this.state.editItem.code}
                        onChange={(event) => { this.UpdateCode(event) }}
                        isInvalid={!this.state.isCodeValid}
                        maxLength="25"
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.codeMessage}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label className="d-flex justify-content-between"><b>Study Groups</b><div className="mx-2">{this.CanEdit() && (<ButtonAdd onClick={() => this.EditStudyGroupOpen()} />)}</div></Form.Label>
                    <Table className="form-list transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {studyGroups.map((_, index) => (
                                <tr key={index}>
                                    {this.state.studyGroupFields.map((item, subindex) => (
                                        studyGroups[index][item.key] === 'Actions' ?
                                            this.CanEdit() && (<td key={subindex} className="py-1 px-2 text-end text-nowrap">
                                                <ButtonDelete onClick={() => this.DeleteStudyGroupOpen(index)} />&nbsp;
                                                <ButtonEdit onClick={() => this.EditStudyGroupOpen(index)} />
                                            </td>)
                                            :
                                            <td className="py-1 px-2" key={subindex}>
                                                <small><b>{item.label}:</b></small><br />
                                                <span dangerouslySetInnerHTML={{ __html: studyGroups[index][item.key] }}></span>
                                            </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form.Group>

                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label className="d-flex justify-content-between"><b>Stratification Groups</b><div className="mx-2">{this.CanEdit() && (<ButtonAdd onClick={() => this.EditStratificationGroupOpen()} />)}</div></Form.Label>
                    <Table className="form-list transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {stratificationGroups.map((_, index) => (
                                <tr key={index}>
                                    {this.state.stratificationGroupFields.map((item, subindex) => (
                                        stratificationGroups[index][item.key] === 'Actions' ?
                                            this.CanEdit() && (<td className="py-1 px-2" key={subindex} className="text-end text-nowrap">
                                                <ButtonDelete onClick={() => this.DeleteStratificationGroupOpen(index)} />&nbsp;
                                                <ButtonEdit onClick={() => this.EditStratificationGroupOpen(index)} />
                                            </td>) :
                                            <td className="py-1 px-2" key={subindex}>
                                                <small><b>{item.label}:</b></small><br />
                                                <span dangerouslySetInnerHTML={{ __html: stratificationGroups[index][item.key] }}></span>
                                            </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form.Group>

                <Form.Group className="mb-2" ref="code_input">
                    <Form.Label><b>Algorithm</b></Form.Label>

                    <Row className="align-items-top">
                        <Col>
                            <Form.Control as="select" value={this.state.editItem.algorithm.type} onChange={(event) => this.UpdateAlgorithmType(event)} className="prevent-validation mb-3"
                                disabled={this.CanEdit() ? '' : 'disabled'}>
                                <option value="Minimisation">Minimisation</option>
                                <option value="PermutedBlock">Permuted Block</option>
                            </Form.Control>
                        </Col>

                        {this.state.editItem.algorithm.type === "Minimisation" && (
                            <Col>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="prob-addon">Percentage</InputGroup.Text>
                                    <Form.Control
                                        ref={this.algorthmPercentage}
                                        disabled={this.CanEdit() ? '' : 'disabled'}
                                        type="number"
                                        onChange={(event) => this.UpdateAlgorithmPercentage(event)}
                                        value={this.GetMinimisationProbablility()}
                                        placeholder="Percentage"
                                        className="prevent-validation"
                                        min="0" max="100"
                                    />
                                </InputGroup>
                            </Col>
                        )}
                        {this.state.editItem.algorithm.type === "PermutedBlock" && (
                            <Col>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="min-addon">Minimum</InputGroup.Text>
                                            <Form.Control
                                                ref={this.algorthmMin}
                                                disabled={this.CanEdit() ? '' : 'disabled'}
                                                type="number"
                                                onChange={(event) => this.UpdateAlgorithmMin(event)}
                                                value={this.GetMinBlockRepeat()}
                                                placeholder="Min Length"
                                                className="prevent-validation"
                                                min="1" max="20"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="max-addon">Maximum</InputGroup.Text>
                                            <Form.Control
                                                ref={this.algorthmMax}
                                                disabled={this.CanEdit() ? '' : 'disabled'}
                                                type="number"
                                                onChange={(event) => this.UpdateAlgorithmMax(event)}
                                                value={this.GetMaxBlockRepeat()}
                                                placeholder="Max Length"
                                                className="prevent-validation"
                                                min="1" max="20"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        )}

                    </Row>

                    {this.state.editItem.algorithm.type === "Minimisation" && (
                        <Row className="align-items-center">
                            <Col>
                                <p>
                                    <small>Minimisation works by randomly selecting the study/stratification group with the fewest number of allocated participants. The "percentage" parameter determines the likelihood of randomly selecting one of these under-allocated groups, leaving a chance that one of the over allocated groups will still be selected.</small>
                                </p>
                                <p>
                                    <small><i>100% means that under-allocated groups are ALWAYS selected, in which case this becomes "Deterministic" Minimisation (as opposed to "Stochastic" Determination).</i></small><br />
                                    <small><i>0% means that groups are ALWAYS randomly selected, in which case this becomes "Simple" Randomisaton.</i></small>
                                </p>
                            </Col>
                        </Row>
                    )}
                    {this.state.editItem.algorithm.type === "PermutedBlock" && (
                        <Row className="align-items-center">
                            <Col>
                                <p>
                                    <small>Permuted Block Randomisation creates a predetermined random order to use when allocating participants to each study/stratification group, which only balances the study groups once each repeating block has completed. The study group "ratio" determines how many times a study group appears in the block.</small>
                                </p>
                                <p>
                                    <small><i>To reduce the chances of being able to work out which group a new participant will be allocated to (if the number of participants currently in a group is known) the number of times each group repeats in a block is randomised between the Mininum and Maximum lengths).</i></small>
                                </p>
                            </Col>
                        </Row>
                    )}

                </Form.Group>

                {errors && (
                    <Alert variant="danger">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
            </Form>
        );
    }

    RenderSummary() {
        let summary = this.TabulateSummary();
        return (
            <div>
                <Form.Group className="mb-4" ref="code_input">
                    <Table className="transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <thead>
                            <tr>
                                {this.state.summaryFields.map((item, subindex) => (
                                    <th key={subindex}>{item.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {summary.map((_, index) => (
                                <tr key={index}>
                                    {this.state.summaryFields.map((item, subindex) => (
                                        <td key={subindex}>
                                            <span dangerouslySetInnerHTML={{ __html: summary[index][item.key] }}></span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form.Group>
                <div><b>Total number of participants randomised so far: {this.state.editItem.summary.numberRandomised}</b></div>
            </div>
        );
    }

    AdminOptions() {
        return (
            <div>
                <h4>Download Participants</h4>
                <p>The downloaded file can inlude:</p>
                <ul>
                    <li><b>Codes Only</b>: Just the Participant Id and the Randomisation Code.</li>
                    <li><b>Codes and Study Groups</b>: The Participant Id, Randomisation Code and the Study Group the participant was randomised into.</li>
                    <li><b>Codes, Study Groups and Stratification Groups</b>: The Participant Id, Randomisation Code, Study Group and the Stratification Value/Band that was used to randomise the participant.</li>
                </ul>
                {this.CanDownloadParticipants() && (
                    <div className="ps-3">
                        <Form.Group className="mb-1" id="name_input">
                            <Form.Label><small><b>Return the results in a password protected XLSX spreadsheet:</b></small></Form.Label>
                            <Form.Control as="select" value={this.state.randomisationType} onChange={(event) => this.setState({ randomisationType: event.currentTarget.value })} className="prevent-validation">
                                <option value="Blind">Codes Only</option>
                                <option value="PartiallyBlinded">Codes and Study Groups</option>
                                <option value="Unblinded">Codes, Study Groups and Stratification Groups</option>
                            </Form.Control>
                        </Form.Group>
                        <InputGroup>
                            <InputGroup.Text>Set Password</InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Please enter a password"
                                value={this.state.spreadSheetPassword}
                                onChange={(event) => { this.UpdatePassword(event) }}
                                isInvalid={!this.state.isPasswordValid}
                                maxLength="25"
                            />
                            <Button variant="primary" onClick={() => this.DownloadRandomisedParticipants()}>
                                Download File
                            </Button>
                        </InputGroup>
                        {!this.state.isPasswordValid && (<div className="text-danger"><small>{this.state.codeMessage}</small></div>)}
                    </div>
                )}
                {!this.CanDownloadParticipants() && (
                    <Alert variant="danger">
                        <div>You must be logged in as an administrator to download this file.</div>
                    </Alert>
                )}

                {this.props.user.IsDeveloper() && (
                    <div>
                        <div className="pt-4">
                            <h4>Reset Rule</h4>
                            <p>Resetting the rule will permanently delete all participants, and recalculate the randomisation codes.<br/>DO NOT DO THIS FOR AN ACTIVE RANDOMISATION RULE.</p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="ps-3">
                                <Button variant="danger" onClick={() => this.ResetRuleOpen()}>
                                    Reset Rule
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
