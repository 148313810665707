import React, { Component } from "react";
import update from 'immutability-helper';
import { ModalConfirm } from '../shared/ModalConfirm';
import { ButtonAdd } from '../shared/Buttons/Add';
import { ButtonEdit } from '../shared/Buttons/Edit';
import { ButtonDelete } from '../shared/Buttons/Delete';
import { DisplayQuestion } from './DisplayQuestion';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { QuestionnaireApi } from '../shared/QuestionnaireApi'
import { CodeAndVersion } from '../shared/Inputs/CodeAndVersion';
import InputGroup from 'react-bootstrap/InputGroup';

export class EditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            isFormValidated: false,
            isFormValid: true,
            fileUploadError: null,
            isCodeValid: true,
            codeMessage: 'Please enter a valid code.',
            isOptionalValid: true,
            optionaMessage: 'Please set whether this is optional or not.',
            isVersionValid: true,
            versionMessage: 'Please enter a number.',
            isTextValid: true,
            textMessage: 'Please enter a display name.',
            isDescriptionValid: true,
            descriptionMessage: 'Please enter a description.',
            isWelcomeValid: true,
            welcomeMessage: 'Please enter a welcome message.',
            editItem: {
                code: '',
                version: 0,
                optional: false,
                languageCode: 'en-GB',
                status: 'NEW',
                text: {
                    text: '',
                    description: '',
                },
                questionGroups: [],
            },
            questionGroupFields: [
                { key: 'code', label: 'Code' },
                { key: 'text', label: 'Text' },
                { key: 'actions', label: '' },
            ],
            questionFields: [
                { key: 'code', label: 'Code' },
                { key: 'questionType', label: 'Type' },
                { key: 'text', label: 'Text' },
                { key: 'actions', label: '' },
            ],
            editIndex: -1,
            existingCodes: [],
            saveError: null,
            showModal: {
                confirmQuestionDelete: false,
            },
            selectedQuestionGroup: {},
            selectedQuestionGroupIndex: -1,
            selectedQuestion: {},
            selectedQuestionIndex: -1,
        };

        this.fileUploadInput = React.createRef();
        this.QuestionnaireApi = new QuestionnaireApi();
    }

    UploadFile() {

        this.setState({ isBusy: true, fileUploadError: null }, async () => {

            if (this.fileUploadInput.current && this.fileUploadInput.current.files[0]) {

                await this.QuestionnaireApi.UploadFile(this.fileUploadInput.current.files[0], (data) => {

                    this.setState({ isBusy: false, fileUploadError: null }, () => {
                        if (data && this.props.onUploaded)
                            this.props.onUploaded(data);
                    });

                }, (errors) => {

                    this.setState({ isBusy: false, fileUploadError: errors });

                });

            } else {
                this.setState({ isBusy: false, fileUploadError: { title: 'System', status: 'UNKNOWN', errors: { error: ["Please choose a questionnaire template to upload"] } } });
            }

        });

    }

    ReUploadFile() {

        this.setState({ isBusy: true, fileUploadError: null }, async () => {

            if (this.fileUploadInput.current && this.fileUploadInput.current.files[0]) {

                await this.QuestionnaireApi.UploadFile(this.fileUploadInput.current.files[0], (data) => {

                    let updatedItem = update(this.state.editItem, { questionGroups: { $set: data.questionGroups } });
                    this.setState({ isBusy: false, fileUploadError: null, editItem: updatedItem }, () => {

                    });

                }, (errors) => {

                    this.setState({ isBusy: false, fileUploadError: errors });

                });

            } else {
                this.setState({ isBusy: false, fileUploadError: { title: 'System', status: 'UNKNOWN', errors: { error: ["Please choose a questionnaire template to upload"] } } });
            }

        });

    }

    ConvertToEdit(index, item) {
        this.setState({ editIndex: index });
        if (item)
            this.setState({ editItem: item });
    }

    CanEdit() {
        return this.props.user.Role() !== "Guest";
    }

    TabulateQuestionGroups() {
        let rows = [];
        Array.prototype.forEach.call(this.state.editItem.questionGroups, (data) => {
            let row = {};
            this.state.questionGroupFields.forEach((field) => {
                switch (field.key) {
                    case 'code':
                    case 'text':
                        row[field.key] = data[field.key];
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            row.questions = data.questions;
            rows.push(row);
        });
        return rows;
    }

    TabulateQuestions(questionGroup) {
        let rows = [];
        if (questionGroup && questionGroup.questions) {
            Array.prototype.forEach.call(questionGroup.questions, (data) => {
                let row = {};
                this.state.questionFields.forEach((field) => {
                    switch (field.key) {
                        case 'code':
                        case 'questionType':
                            row[field.key] = data[field.key];
                            break;
                        case 'text':
                            row[field.key] = data[field.key].text;
                            break;
                        case 'actions':
                            row[field.key] = 'Actions';
                            break;
                        default:
                            if (data[field.key])
                                row[field.key] = JSON.stringify(data[field.key]);
                            break;
                    }
                });
                rows.push(row);
            });
        }
        return rows;
    }

    UpdateCodeAndVersion(code, version) {
        let updatedItem = update(this.state.editItem, { code: { $set: this.CodeOnly(code), version: { $set: parseFloat(version) } } });
        this.setState({ editItem: updatedItem });
    }

    UpdateCode(event) {
        let updatedItem = update(this.state.editItem, { code: { $set: this.CodeOnly(event.target.value) } });
        this.setState({ editItem: updatedItem });
    }
    CodeIsValid() {
        let valid = true;
        var value = this.state.editItem.code.trim().toUpperCase();
        if (this.IsEmpty(value)) {
            this.setState({ codeMessage: "The code cannot be blank" });
            valid = false;
        } else if (this.state.existingCodes.includes(`${value}_${this.state.editItem.version}`)) {
            this.setState({ codeMessage: "This code and version already exists (either change the code or the version)" });
            valid = false;
        }
        this.setState({ isCodeValid: valid });
        return valid;
    }
    VersionIsValid() {
        let valid = true;
        var value = this.state.editItem.version;
        if (typeof value !== 'number') {
            this.setState({ versionMessage: "The version must be a valid number" });
            valid = false;
        } else if (this.state.existingCodes.includes(`${this.state.editItem.code}_${value}`)) {
            this.setState({ versionMessage: "This code and version already exists (either change the code or the version)" });
            valid = false;
        }
        this.setState({ isVersionValid: valid });
        return valid;
    }
    UpdateOptional(event) {
        let updatedItem = update(this.state.editItem, { optional: { $set: event.target.value === "True" ? true : false } });
        this.setState({ editItem: updatedItem });
    }
    OptionalIsValid() {
        return true;
    }
    UpdateText(event) {
        let updatedItem = update(this.state.editItem, { text: { text: { $set: event.target.value } } });
        this.setState({ editItem: updatedItem });
    }
    TextIsValid() {
        let valid = true;
        var value = this.state.editItem.text && this.state.editItem.text.text ? this.state.editItem.text.text.trim() : '';
        if (this.IsEmpty(value)) {
            this.setState({ codeMessage: "The display name cannot be blank" });
            valid = false;
        }
        this.setState({ isTextValid: valid });
        return valid;
    }
    UpdateDescription(event) {
        let updatedItem = update(this.state.editItem, { text: { description: { $set: event.target.value } } });
        this.setState({ editItem: updatedItem });
    }
    DescriptionIsValid() {
        let valid = true;
        var value = this.state.editItem.text && this.state.editItem.text.description ? this.state.editItem.text.description.trim() : '';
        if (this.IsEmpty(value)) {
            this.setState({ codeMessage: "The description cannot be blank" });
            valid = false;
        }
        this.setState({ isDescriptionValid: valid });
        return valid;
    }
    UpdateWelcome(event) {
        let updatedItem = update(this.state.editItem, { text: { welcome: { $set: event.target.value } } });
        this.setState({ editItem: updatedItem });
    }
    WelcomeIsValid() {
        return true;
    }

    CodeOnly(value) {
        return value.replace(/[^a-z0-9 _]/gi, '').replace(' ', '_').toUpperCase();
    }

    EditQuestionOpen(groupIndex, questionIndex) {
        if (groupIndex >= 0 && questionIndex >= 0)
            this.setState({
                showModal: { editQuestion: true },
                selectedQuestionGroupIndex: groupIndex,
                selectedQuestionGroup: this.state.editItem.questionGroups[groupIndex],
                selectedQuestionIndex: questionIndex,
                selectedQuestion: this.state.editItem.questionGroups[groupIndex].questions[questionIndex]
            });
        else
            this.setState({
                showModal: { editQuestion: true },
                selectedQuestionGroupIndex: -1,
                selectedQuestionGroup: {},
                selectedQuestionIndex: -1,
                selectedQuestion: {}
            });
    }
    EditQuestionClose() {
        this.setState({ showModal: { editQuestion: false } });
    }
    EditQuestionSave() {
        this.UpdateQuestion(this.state.selectedQuestion);
    }
    UpdateQuestion(question) {
        let updatedGroup = update(this.state.selectedQuestionGroup, { questions: { $splice: [[this.state.selectedQuestionIndex, 1, question]] } });
        let updatedItem = update(this.state.editItem, { questionGroups: { $splice: [[this.state.selectedQuestionGroupIndex, 1, updatedGroup]] } });
        this.setState({ showModal: { editQuestion: false }, editItem: updatedItem });
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    SaveClick() {
        if (this.props.onSave) {
            this.setState({ isBusy: true, saveError: null });
            this.setState({
                isFormValidated: true,
                isFormValid: this.CodeIsValid() & this.VersionIsValid() & this.OptionalIsValid() & this.TextIsValid() & this.DescriptionIsValid() & this.WelcomeIsValid(),
            }, () => {
                if (this.state.isFormValid) {
                    this.props.onSave(this.state.editIndex, this.state.editItem);
                } else {
                    this.setState({ isBusy: false });
                }
            });
        }
    }

    SaveFailed(error) {
        this.setState({ isBusy: false, saveError: error });
    }

    componentDidMount() {
        this.setState({ editIndex: this.props.index });
        if (this.props.item)
            this.setState({ editItem: this.props.item });
        if (this.props.existing)
            this.setState({ existingCodes: this.props.existing.filter(item => this.props.item === undefined || !(item.code === this.props.item.code && item.version === this.props.item.version)).map(item => `${item.code.trim().toUpperCase()}_${item.version}`) });
    }

    componentWillUnmount() {
    }

    render() {
        let errors = this.state.saveError && this.state.saveError.errors ? this.state.saveError.errors.error : null;
        return (
            <div>
                <Form noValidate validated={this.state.isFormValidated}>
                    {this.state.isBusy ?
                        <div className="busy-overlay col d-flex align-items-center justify-content-center">
                            <Spinner animation="border" role="status" variant="dark" />
                        </div>
                        : ''}
                    {this.state.editIndex === -2 && (
                        this.RenderUpload()
                    )}
                    {this.state.editIndex > -2 && (
                        <div>
                            {this.RenderCodeAndVersion(this.state.editItem.code, this.state.editItem.version)}
                            <Tabs
                                defaultActiveKey="details"
                                id="justify-tab-example"
                                className="mb-3 tabs-app"
                                justify>
                                <Tab eventKey="details" title="Details">
                                    {this.RenderForm()}
                                </Tab>
                                <Tab eventKey="questions" title="Questions">
                                    {this.RenderFormQuestions()}
                                </Tab>
                                {this.props.user && (this.props.user.IsAdmin() || this.props.user.IsDeveloper()) && (
                                    <Tab eventKey="upload" title="Upload">
                                        {this.RenderReUpload()}
                                    </Tab>
                                )}
                            </Tabs>
                        </div>
                    )}
                </Form>

                {errors && (
                    <Alert variant="danger">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}

                <Modal show={this.state.showModal.editQuestion} onHide={() => this.EditQuestionClose()} size="lg" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header closeButton className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title>Question Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DisplayQuestion
                            editMode
                            question={this.state.selectedQuestion}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.EditQuestionClose()}>
                            Close
                        </Button>
                        {this.props.user.Role() !== "Guest" && (
                            <Button variant="primary" onClick={() => this.EditQuestionSave()}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <ModalConfirm show={this.state.showModal.confirmQuestionDelete} onCancel={() => this.DeleteQuestionClose()} onConfirm={() => this.DeleteQuestion()} title="Delete Question" message="Are you sure you want to delete this question?" />
            </div>
        );
    }

    RenderForm() {
        let questionGroups = this.TabulateQuestionGroups();
        return (
            <div>
                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label><b>Optional/Mandatory</b></Form.Label>
                    <Form.Control
                        as="select"
                        disabled={this.CanEdit() ? '' : 'disabled'}
                        value={this.state.editItem.optional === true ? "True" : "False"}
                        onChange={(event) => this.UpdateOptional(event)}
                        className="prevent-validation"
                        isInvalid={!this.state.isOptionalValid}>
                        <option value="False">Mandatory</option>
                        <option value="True">Optional</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {this.state.optionalMessage}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label><b>Dashboard Card Name</b></Form.Label>
                    <Form.Control
                        required
                        disabled={this.CanEdit() ? '' : 'disabled'}
                        type="text"
                        placeholder="Please enter a user friendly name to display to the participant"
                        value={this.state.editItem.text.text}
                        onChange={(event) => { this.UpdateText(event) }}
                        isInvalid={!this.state.isTextValid}
                        maxLength="100"
                    />
                    <Form.Text muted>
                        This display name shown to the participant on the dashboard.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        {this.state.textMessage}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label><b>Description</b></Form.Label>
                    <Form.Control
                        required
                        disabled={this.CanEdit() ? '' : 'disabled'}
                        as="textarea" rows={3}
                        placeholder="Please enter a user friendly name to display to the participant"
                        value={this.state.editItem.text.description}
                        onChange={(event) => { this.UpdateDescription(event) }}
                        isInvalid={!this.state.isDescriptionValid}
                        maxLength="1000"
                    />
                    <Form.Text muted>
                        Write a brief description of the content - this will help participants decide whether they want to do it (if optional).
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        {this.state.textMessage}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" ref="code_input">
                    <Form.Label><b>Welcome Text</b></Form.Label>
                    <Form.Control
                        disabled={this.CanEdit() ? '' : 'disabled'}
                        as="textarea" rows={3}
                        placeholder="Please enter a welcome message (optional - description will be used if left blank)"
                        value={this.state.editItem.text.welcome}
                        onChange={(event) => { this.UpdateWelcome(event) }}
                        isInvalid={!this.state.isWelcomeValid}
                        maxLength="100"
                    />
                    <Form.Text muted>
                        General description of the study to use on the My Studies Page (content to provide to participants to convince them to join your study).
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        {this.state.textMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
        );
    }

    RenderCodeAndVersion(code, version) {
        return (
            <Form.Group className="mb-4" ref="code_input">
                <CodeAndVersion
                    code={code}
                    version={version}
                    existingCodes={this.state.existingCodes}
                    onChange={(code, version) => { this.UpdateCodeAndVersion(code, version); }}
                />
                <Form.Control.Feedback type="invalid">
                    Error Message
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    RenderFormQuestions() {
        let questionGroups = this.TabulateQuestionGroups();
        let tableRows = [];
        questionGroups.forEach((group, groupIndex) => {

            tableRows.push(
                <tr key={groupIndex}>
                    <td className="py-1 px-2" colSpan={this.state.questionGroupFields.length}>
                        <h2>{group.code}</h2>
                    </td>
                </tr>
            );

            this.TabulateQuestions(group).map((question, index) => {

                tableRows.push(
                    <tr key={`${groupIndex}_${index}`}>
                        {this.state.questionGroupFields.map((item, subindex) => (
                            question[item.key] === 'Actions' ?
                                this.CanEdit() && (<td key={subindex} className="text-end text-nowrap pt-4">
                                    <ButtonEdit onClick={() => this.EditQuestionOpen(groupIndex, index)} />
                                </td>)
                                :
                                <td className="py-1 px-2" key={subindex}>
                                    <span className="tinyLabel"><b>{item.label}:</b></span><br />
                                    <span dangerouslySetInnerHTML={{ __html: question[item.key] }}></span>
                                </td>
                        ))}
                    </tr>
                );

            });
        });

        return (
            <Table className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        );
    }

    RenderUpload() {
        let errors = this.state.fileUploadError && this.state.fileUploadError.errors ? this.state.fileUploadError.errors.error : null;
        return (
            <div>
                <h3>Upload a New Questionnaire</h3>
                <div className="mb-2">Upload a spreadsheet containing a Questionnaire template:</div>
                <div className="mb-4 mx-2"><small><i>
                    NOTE: The spreadsheet should contain a "TEMPLATE" and a "DASHBOARD CONTENT" worksheet, where the contents of each worksheet follow the standard PROTECT questionnaire format.
                </i></small></div>
                <Form.Group className="mb-1" id="file_input">
                    <Form.Control ref={this.fileUploadInput} type="file" />
                </Form.Group>
                {errors && (
                        <Alert variant="danger my-1">{errors.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}</Alert>
                    )
                }
            </div>
        )
    }

    RenderReUpload() {
        let errors = this.state.fileUploadError && this.state.fileUploadError.errors ? this.state.fileUploadError.errors.error : null;
        return (
            <div>
                <h3>Update Questions</h3>
                <div className="mb-4 mx-2"><small><i>
                    NOTE: When re-uploading a template the "DASHBOARD CONTENT" worksheet will be ignored and only the "TEMPLATE" worksheet will be used to update the questions.
                </i></small></div>

                <InputGroup className="mb-1" id="file_input">
                    <Form.Control ref={this.fileUploadInput} type="file" />
                    <Button variant="primary" onClick={() => this.ReUploadFile()}>
                        Upload
                    </Button>
                </InputGroup>

                {errors && (
                    <Alert variant="danger my-1">{errors.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}</Alert>
                )}
            </div>
        )
    }
}
